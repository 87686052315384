<template>
  <div class="select-box-form">
    <div class="heading" :style="{ height: headingHeight }">
      <div class="text"><slot></slot></div>
      <hint-balloon v-if="note" :note="note" />
      <div class="required" v-if="requiredFlg">必須</div>
    </div>
    <div class="body">
      <validation-provider
        class="validate"
        :rules="{
          ...validationRules,
          requiredRule: requiredFlg,
          oneOfRule: selectData
        }"
        v-slot="{ errors }"
      >
        <base-select-box
          :value="value"
          :selectData="selectData"
          :disabled="disabled"
          :disabledColorWhite="disabledColorWhite"
          :styles="styles"
          v-model="input"
        />
        <div v-if="errorShowFlg" class="error" :style="errorStyles">
          <div v-for="(error, index) in errors" :key="index">{{ error }}</div>
        </div>
      </validation-provider>
    </div>
  </div>
</template>

<script>
import BaseSelectBox from '@/components/parts/atoms/BaseSelectBox'
import HintBalloon from '@/components/parts/atoms/HintBalloon'
import { ValidationProvider } from 'vee-validate'
import '@/utils/validation_rules'

export default {
  name: 'SelectBoxForm',

  components: {
    BaseSelectBox,
    HintBalloon,
    ValidationProvider
  },

  props: {
    requiredFlg: { type: Boolean, default: false },
    validationRules: { type: Object },
    value: { type: [String, Number] },
    selectData: { type: Array },
    disabled: { type: Boolean, default: false },
    disabledColorWhite: { type: Boolean, default: false },
    styles: { type: Object },
    errorStyles: { type: Object },
    errorShowFlg: { type: Boolean, default: true },
    note: { type: String, default: '' }
  },

  computed: {
    input: {
      get() {
        return this.value
      },
      set(val) {
        this.$emit('input', val)
      }
    },
    headingHeight() {
      return this.styles ? (this.styles.height ? this.styles.height : '') : ''
    }
  }
}
</script>

<style lang="scss" scoped>
.select-box-form {
  display: flex;
  align-items: flex-start;
  > .heading {
    display: flex;
    align-items: center;
    height: 33px;
    width: 40%;
    font-size: 15px;
    > .required {
      margin-left: 10px;
      font-size: 13px;
      color: #{$tomato};
    }
  }
  > .body {
    width: 60%;
    @include validate-message();
  }
}
</style>
