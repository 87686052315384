import { getAxiosObject } from '@/utils/library'
import {
  commonGetters,
  commonActions,
  commonMutations
} from '@/store/store_utils'

const dataName = 'mailSetting'

const state = {
  mailSetting: {},
  updatedAt: '20101010101010'
}

const getters = {
  ...commonGetters(dataName, ['getData', 'getUpdatedAt'], {})
}

const actions = {
  ...commonActions(['setData', 'setUpdatedAt', 'updateAfter']),
  async update({ commit }, mailSetting) {
    try {
      const axiosObject = getAxiosObject()
      const res = await axiosObject.put('/mail-setting', { mailSetting })
      if (res.status === 200) {
        commit('updateAfter', res.data.mailSetting)
        return true
      }
    } catch (error) {
      return false
    }
  }
}

const mutations = {
  ...commonMutations(dataName, ['setData', 'setUpdatedAt', 'updateAfter'], {})
}

export default {
  namespaced: true,
  state,
  mutations,
  getters,
  actions
}
