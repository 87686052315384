import axios from 'axios'
import store from '@/store/store'

export const getAxiosObject = () => {
  const axiosObject = axios.create({
    baseURL: getApiUrl(),
    headers: authHeaders().headers
  })
  axiosObject.interceptors.response.use(
    response => response,
    error => {
      if (error.response?.status === 503) {
        window.location.hash = '#/maintenance'
      } else if (
        error.response?.status === 400 &&
        error.response?.data.extra === 'not the same as required version'
      ) {
        store.dispatch('petorelu/setClinicVersionUpFlgToTrue')
      }
      throw error
    }
  )
  return axiosObject
}

export const getApiUrl = () => {
  return process.env.VUE_APP_API_URL
}

const getHeaders = token => {
  return {
    Authorization: 'Bearer ' + token,
    'Content-Type': 'application/json',
    'Clinic-Version': process.env.VERSION,
    'Socket-Id': store.getters['petorelu/get'].socket?.id
  }
}

export const authHeaders = () => {
  const token = store.getters['auth/token']
  return {
    headers: getHeaders(token)
  }
}

export const axiosObjectForReload = token => {
  const axiosObject = axios.create({
    baseURL: getApiUrl(),
    headers: getHeaders(token)
  })
  axiosObject.interceptors.response.use(
    response => response,
    error => {
      if (error.response?.status === 503) {
        window.location.hash = '#/maintenance'
      } else if (
        error.response?.status === 400 &&
        error.response?.data.extra === 'not the same as required version'
      ) {
        store.dispatch('petorelu/setClinicVersionUpFlgToTrue')
      }
      throw error
    }
  )
  return axiosObject
}

export const axiosObjectForPyxosManage = () => {
  const manageToken = store.getters['pyxosManage/token']
  const axiosObject = axios.create({
    baseURL: getApiUrl(),
    headers: getHeaders(manageToken)
  })
  axiosObject.interceptors.response.use(
    response => response,
    error => {
      if (error.response?.status === 503) {
        window.location.hash = '#/maintenance'
      } else if (
        error.response?.status === 400 &&
        error.response?.data.extra === 'not the same as required version'
      ) {
        store.dispatch('petorelu/setClinicVersionUpFlgToTrue')
      }
      throw error
    }
  )
  return axiosObject
}

export const getAxiosObjectForAnalytics = () => {
  const axiosObject = axios.create({
    baseURL: process.env.VUE_APP_ANALYTICS_URL,
    headers: authHeaders().headers
  })
  axiosObject.interceptors.response.use(
    response => response,
    error => {
      if (error.response?.status === 503) {
        window.location.hash = '#/maintenance'
      } else if (
        error.response?.status === 400 &&
        error.response?.data.extra === 'not the same as required version'
      ) {
        store.dispatch('petorelu/setClinicVersionUpFlgToTrue')
      }
      throw error
    }
  )
  return axiosObject
}
