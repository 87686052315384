import { extend } from 'vee-validate'
import * as Rules from 'vee-validate/dist/rules'
import moment from 'moment'

import {
  VALID_ANICOM_C_ID_REGEX,
  VALID_ANICOM_H_CD_REGEX,
  VALID_COLOR_CODE_REGEX,
  VALID_EMAIL_EMPTY_REGEX,
  VALID_FULL_NAME_KANA_REGEX,
  VALID_FULL_NAME_REGEX,
  VALID_INVOICE_NUMBER_REGEX,
  VALID_KATAKANA_EMPTY_REGEX,
  VALID_NUMERIC_STRING_REGEX,
  VALID_POSTALCODE_EMPTY_REGEX,
  VALID_SHOW_ID_EMPTY_REGEX,
  VALID_TEL_EMPTY_REGEX,
  VALID_TEL_HYPHEN_REGEX,
  VALID_VH_CODE_REGEX
} from '@/utils/define'

export const startDateRule = extend('startDateRule', {
  params: ['endDate'],
  validate: (startDate, params) => {
    if (params.endDate === 'null' || params.endDate === '') return true
    let s, e
    if (typeof startDate === 'string' && startDate.length === 8) {
      s = moment(startDate, 'YYYYMMDD').startOf('day')
      e = moment(params.endDate, 'YYYYMMDD').startOf('day')
    } else {
      s = moment(startDate.toISOString()).startOf('day')
      e = moment(new Date(params.endDate).toISOString()).startOf('day')
    }
    return s.isSameOrBefore(e)
  },
  message: '開始日または終了日を調整して正しい期間を入力して下さい。'
})

// 検証タイミング
// ファーカスを外したときに検証
export const blurMode = () => {
  return { on: ['blur'] }
}

// 検証ルール
export const requiredRule = extend('requiredRule', {
  ...Rules.required,
  message: '入力必須項目です'
})

export const regexKana = extend('regexKana', {
  message: '全角カナで入力して下さい',
  validate(value) {
    return VALID_KATAKANA_EMPTY_REGEX.test(value)
  }
})

export const emailRule = extend('emailRule', {
  message: '有効なメールアドレスを入力して下さい',
  validate(value) {
    const localPart = value.slice(0, value.lastIndexOf('@'))
    return (
      VALID_EMAIL_EMPTY_REGEX.test(value) &&
      localPart.length <= 64 &&
      value.length <= 254
    )
  }
})

export const regexTel = extend('regexTel', {
  message: '有効な電話番号を入力して下さい',
  validate(value) {
    return VALID_TEL_EMPTY_REGEX.test(value)
  }
})

export const regexTelHyphen = extend('regexTelHyphen', {
  message: '有効な電話番号を入力して下さい',
  validate(value) {
    return VALID_TEL_HYPHEN_REGEX.test(value)
  }
})

extend('regexNumber', {
  message: '10桁以内で入力して下さい',
  validate(value) {
    return /^[0-9]{1,10}$/.test(value)
  }
})

export const regexPostalCode = extend('regexPostalCode', {
  message: '7桁の半角数字で入力して下さい',
  validate(value) {
    return VALID_POSTALCODE_EMPTY_REGEX.test(value)
  }
})

export const apiResultPostalCode = extend('apiResultPostalCode', {
  message: '入力された郵便番号の住所が見つかりませんでした。',
  params: ['apiResultPostalCode'],
  validate(value, { apiResultPostalCode }) {
    return (
      apiResultPostalCode === null || apiResultPostalCode.existingPostalCodeFlg
    )
  }
})

export const minPassRule = extend('minPassRule', {
  ...Rules.min,
  params: ['length'],
  message: `{length}文字以上で入力して下さい`
})

export const isRePassRule = extend('isRePassRule', {
  ...Rules.is,
  message: 'パスワードが一致していません'
})

export const regexHalfWidthAlphaNumeric = extend('regexHalfWidthAlphaNumeric', {
  ...Rules.regex,
  message: '半角英数字記号で入力して下さい'
})

export const regexShowId = extend('regexShowId', {
  message: '半角数字かハイフン(-)のみで入力して下さい',
  validate(value) {
    return VALID_SHOW_ID_EMPTY_REGEX.test(value)
  }
})

// ひらがな・カタカナ・漢字・アルファベット・数字のみ許可
export const nameRule = extend('nameRule', {
  message: '使用できない文字が含まれています',
  validate(value) {
    return /^[\u30a0-\u30ff\u3040-\u309f\u3005-\u3006\u30e0-\u9fcfa-zA-Zａ-ｚＡ-Ｚ0-9０-９]+$/.test(
      value
    )
  }
})

// アニコムレセプターのカルテ番号
extend('medicalRecordNumRule', {
  message: '半角数字かハイフン(-)のみで入力して下さい',
  validate(value) {
    return /^[0-9]{1,20}[-][0-9]{1,20}$/.test(value)
  }
})

// アニコムレセプターのカルテ番号
extend('zeroRule', {
  message: `上四桁0000は利用できません。`,
  validate(value) {
    return !/^(0{4}-)/.test(value)
  }
})

// 苗字15+全角スペース+名前15
extend('regexFullName', {
  message: '苗字と名前を入力し、全角スペースで区切って下さい',
  validate(value) {
    return VALID_FULL_NAME_REGEX.test(value)
  }
})

// ミョウジ15+全角スペース+ナマエ15
extend('regexFullNameKana', {
  message: 'ミョウジとナマエを入力し、全角スペースで区切って下さい',
  validate(value) {
    return VALID_FULL_NAME_KANA_REGEX.test(value)
  }
})

// 全角文字・半角数字・半角英字・「!?',.」以外禁止
export const memoRule = extend('memoRule', {
  message: '使用できない文字が含まれています',
  validate(value) {
    return /^[^"#$%&()*+\-\\/:;<=>@[\\\]^_`{|}~]+$/.test(value)
  }
})

// 指定した値を禁止
export const excludedRule = extend('excludedRule', {
  ...Rules.excluded,
  message: `有効な値を選択して下さい`
})

// 指定した値以外を禁止
export const oneOfRule = extend('oneOfRule', {
  params: ['data'],
  message: `有効な値を選択して下さい`,
  validate(value, { data }) {
    return Array.isArray(data)
      ? data.some(v => v.id === value)
      : data.id === value
  }
})

// 非サロゲートペア
export const notSurrogatePair = extend('notSurrogatePair', {
  message: '使用できない文字が含まれています',
  validate(value) {
    return !/[\uD800-\uDBFF][\uDC00-\uDFFF]/.test(value)
  }
})

// 文字数制限
export const maxRule = extend('maxRule', {
  ...Rules.max,
  params: ['length'],
  message: `{length}文字以内で入力して下さい`
})

// 単価
export const intRule = extend('intRule', {
  message: '半角数字のみで入力して下さい',
  validate(value) {
    return /^([0-9]\d*)$/.test(value)
  }
})

// 小数
export const floatRule = extend('floatRule', {
  message: '0以上の整数または小数を半角数字で入力してください',
  validate(value) {
    return /^([1-9]\d*|0)(\.\d+)?$/.test(value)
  }
})

// パーセント(%)
export const percentRule = extend('percentRule', {
  message: '100%以下で入力して下さい',
  validate(value) {
    return value <= 100
  }
})

// アニコム 証券番号
export const anicomCIdRule = extend('anicomCIdRule', {
  message:
    '1桁目はアルファベット、2~9桁目は数字、10桁目は数字またはアルファベットを入力してください',
  validate(value) {
    return VALID_ANICOM_C_ID_REGEX.test(value)
  }
})

// アニコム 医療機関コード
export const anicomHCdRule = extend('anicomHCdRule', {
  message: '「a」+ 5桁数字で入力してください',
  validate(value) {
    return VALID_ANICOM_H_CD_REGEX.test(value)
  }
})

// アイペット 病院コード
// ※保険金請求マニュアル.pdf > p56 8-7
export const vhCodeRule = extend('vhCodeRule', {
  message: 'Hから始まる5桁の半角数字で入力して下さい',
  validate(value) {
    return VALID_VH_CODE_REGEX.test(value)
  }
})

// インボイス 登録番号
export const invoiceNumberRule = extend('invoiceNumberRule', {
  message: 'Tから始まる13桁の半角数字で入力して下さい',
  validate(value) {
    return VALID_INVOICE_NUMBER_REGEX.test(value)
  }
})

export const greaterThan = extend('greaterThan', {
  params: ['minValue'],
  message: `{minValue}より大きい数値を入力してください`,
  validate: (value, { minValue }) => {
    return minValue === '' || Number(value) > Number(minValue)
  }
})

export const lessThan = extend('lessThan', {
  params: ['maxValue'],
  message: `{maxValue}より小さい数値を入力してください`,
  validate: (value, { maxValue }) => {
    return maxValue === '' || Number(value) < Number(maxValue)
  }
})

export const lessThanOrEqualTo = extend('lessThanOrEqualTo', {
  params: ['maxValue', 'words'],
  message: (field, { maxValue, words }) => {
    return words
      ? `${words}以下で入力してください`
      : `${maxValue}以下で入力してください`
  },
  validate: (value, { maxValue }) => {
    return maxValue === '' || Number(value) <= Number(maxValue)
  }
})

export const numericString = extend('numericString', {
  params: ['length'],
  message: '半角数字{length}桁で入力してください',
  validate: (value, { length }) =>
    VALID_NUMERIC_STRING_REGEX(length).test(value)
})

extend('colorCode', {
  message: '有効なカラーコードを入力してください',
  validate: value => VALID_COLOR_CODE_REGEX.test(value)
})

// エラーメッセージ
export const timeRule = '終了時間は開始時間より後に設定して下さい'
export const lunchTimeRule = 'お昼時間は診療時間内に設定して下さい'
export const timePatternRule = '時間が重複しているパターンがあります'
export const targetColumnRule = '対象予約列は入力必須項目です'
export const targetStaffRule = '対象スタッフは入力必須項目です'
export const deathDateRule = '死亡年月日は生年月日よりも後に設定して下さい'
