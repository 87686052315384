<template>
  <node-view-wrapper
    class="rich-text-editor-image"
    data-target="richTextEditorImage"
    :style="hoverStyles"
  >
    <img
      v-if="uploadImage.image"
      :src="uploadImage.image"
      data-test="image"
      @click="handleClick"
      :style="borderStyle"
      ref="image"
    />
    <div
      v-else
      class="image-getting-status"
      :style="imageGettingStatusStyle"
      data-test="image-getting-status"
    >
      画像取得中...
    </div>
    <div
      v-show="selectFlg"
      class="mark"
      :style="markStyle"
      @pointerdown="resize"
      data-test="mark"
    ></div>
  </node-view-wrapper>
</template>

<script>
import { NodeViewWrapper, nodeViewProps } from '@tiptap/vue-2'
import { mapGetters } from 'vuex'
import { HISTORY_MEDICAL_CONTENT_WIDTH } from '@/utils/define'
import _ from 'lodash'

export default {
  name: 'RichTextEditorImage',

  components: {
    NodeViewWrapper
  },

  mixins: [],

  props: nodeViewProps,

  data() {
    return {
      uploadImage: { image: null },
      selectFlg: false,
      unsubscribe: null,
      startWidth: 0,
      startHeight: 0,
      startX: 0,
      startY: 0,
      maxWidth: 0,
      imageMagnification: 0,
      min: 0
    }
  },

  computed: {
    ...mapGetters({
      getKarteUploadImage: 'uploadImages/getKarteUploadImage',
      getResizedUploadImage: 'uploadImages/getResizedUploadImage',
      magnification: 'richTextEditor/getMagnification'
    }),
    hoverStyles() {
      return this.extension.options.richTextEditorShowFlg
        ? { '--cursor': 'auto', '--opacity': '1' }
        : { '--cursor': 'pointer', '--opacity': '0.8' }
    },
    borderStyle() {
      if (
        this.extension.options.richTextEditorShowFlg &&
        this.uploadImage.widthInKarte > HISTORY_MEDICAL_CONTENT_WIDTH
      ) {
        return {
          border: '2px dashed rgba(0, 0, 0, 0)',
          width: `${HISTORY_MEDICAL_CONTENT_WIDTH}px`,
          height: `${this.uploadImage.heightInKarte *
            (HISTORY_MEDICAL_CONTENT_WIDTH / this.uploadImage.widthInKarte)}px`
        }
      } else {
        return this.selectFlg
          ? {
              border: '2px dashed black',
              width: `${this.uploadImage.widthInKarte}px`,
              height: `${this.uploadImage.heightInKarte}px`
            }
          : {
              border: '2px dashed rgba(0, 0, 0, 0)',
              width: `${this.uploadImage.widthInKarte}px`,
              height: `${this.uploadImage.heightInKarte}px`
            }
      }
    },
    imageGettingStatusStyle() {
      const { border, ...noBorderStyle } = this.borderStyle
      return noBorderStyle
    },
    markStyle() {
      return {
        width: '10px',
        height: '10px',
        border: '1px solid black',
        position: 'absolute',
        'margin-top': `${this.uploadImage.heightInKarte - 4}px`,
        'margin-left': `${this.uploadImage.widthInKarte - 4}px`
      }
    }
  },

  watch: {
    selectFlg() {
      if (this.selectFlg) {
        const unselected = () => {
          this.selectFlg = false
          document.removeEventListener('pointerup', unselected)
        }
        document.addEventListener('pointerup', unselected)
        this.$parent.$emit('prevent-touch', true)
      }
      if (!this.selectFlg) {
        this.$parent.$emit('prevent-touch', false)
      }
    }
  },

  created() {
    this.setUploadImage()
  },

  mounted() {
    this.unsubscribe = this.$store.subscribe(mutation => {
      if (mutation.type === 'uploadImages/setKarteUploadImages')
        this.setUploadImage()
    })
  },

  beforeDestroy() {
    this.unsubscribe()
  },

  methods: {
    setUploadImage() {
      const resizedUploadImage = this.getResizedUploadImage(
        this.node.attrs.uploadImageId
      )
      this.uploadImage =
        resizedUploadImage &&
        !this.extension.options.isMedicalContentsHistoryDetail
          ? _.cloneDeep(resizedUploadImage)
          : _.cloneDeep(this.getKarteUploadImage(this.node.attrs.uploadImageId))
    },
    handleClick() {
      if (!this.extension.options.richTextEditorShowFlg) {
        this.selectFlg = !this.selectFlg
      }
    },
    resize(e) {
      if (e.pointerType === 'mouse' && e.button !== 0) return
      this.imageMagnification = this.magnification / 100
      const space = 50
      this.maxWidth =
        (e
          .composedPath()
          .find(v => v.dataset && v.dataset.target === 'richTextEditorImage')
          .getBoundingClientRect().width -
          space) /
        this.imageMagnification
      this.min = 50
      const image = this.$refs.image
      this.startWidth = image.getBoundingClientRect().width
      this.startHeight = image.getBoundingClientRect().height
      this.startX = e.clientX
      this.startY = e.clientY
      document.addEventListener('pointermove', this.handleResizeMove)
      document.addEventListener('pointerup', this.handleResizeEnd)
    },
    handleResizeMove(e) {
      const width =
        (this.startWidth + e.clientX - this.startX) / this.imageMagnification
      this.uploadImage.widthInKarte =
        width <= this.min
          ? this.min
          : this.min < width && width <= this.maxWidth
          ? width
          : this.maxWidth
      const height =
        (this.startHeight + e.clientY - this.startY) / this.imageMagnification
      this.uploadImage.heightInKarte = height <= this.min ? this.min : height
    },
    handleResizeEnd() {
      document.removeEventListener('pointermove', this.handleResizeMove)
      document.removeEventListener('pointerup', this.handleResizeEnd)
      this.selectFlg = false
      const resizedUploadImage = {
        ...this.uploadImage,
        widthInKarte: parseInt(this.uploadImage.widthInKarte),
        heightInKarte: parseInt(this.uploadImage.heightInKarte)
      }
      this.$store.dispatch(
        'uploadImages/setResizedUploadImage',
        resizedUploadImage
      )
      this.$parent.$emit('unsaved-resized-image')
    }
  }
}
</script>

<style lang="scss" scoped>
.rich-text-editor-image {
  display: flex;
  > .image-getting-status {
    display: flex;
    justify-content: center;
    align-items: center;
    border: solid 1px #{$light-grey};
    font-weight: bold;
  }
  > img {
    &:hover {
      cursor: var(--cursor);
      opacity: var(--opacity);
    }
  }
  > .mark {
    &:hover {
      cursor: nwse-resize;
    }
  }
}
</style>
