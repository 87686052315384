import { getAxiosObject } from '@/utils/library'
const apiUrl = '/vaccinations'

const state = {}

const getters = {}

const actions = {
  async simpleSearch(
    { commit },
    { vaccineId, periodStart, periodEnd, typeId, sortTypeId, orderTypeId, page }
  ) {
    try {
      const axiosObject = getAxiosObject()
      const search = {
        vaccineId,
        periodStart,
        periodEnd,
        typeId,
        sortTypeId,
        orderTypeId,
        page
      }
      const res = await axiosObject.get(`${apiUrl}/search/simple`, {
        params: { search }
      })
      if (res.status === 200) {
        const vaccinationStores = [
          'medicalRecords',
          'reservations',
          'vaccineReminders',
          'patientVaccines',
          'patientVaccinePeriods',
          'antibodies',
          'antibodyTypes'
        ]
        vaccinationStores.forEach(store => {
          const storeData = res.data[store]
          if (storeData.length > 0)
            commit(`${store}/updateAfter`, storeData, { root: true })
        })
        return {
          result: true,
          hitAllDataCounts: res.data.hitAllDataCounts,
          patientIds: res.data.patientIds,
          reminderVaccinationSets: res.data.reminderVaccinationSets
        }
      }
    } catch (error) {
      return { result: false }
    }
  },
  async detailSearch(
    { commit },
    { conditions, sortTypeId, orderTypeId, page }
  ) {
    try {
      const axiosObject = getAxiosObject()
      const search = { conditions, sortTypeId, orderTypeId, page }
      const res = await axiosObject.get(`${apiUrl}/search/detail`, {
        params: { search }
      })
      if (res.status === 200) {
        const vaccinationStores = [
          'medicalRecords',
          'reservations',
          'vaccineReminders',
          'patientVaccines',
          'patientVaccinePeriods',
          'antibodies',
          'antibodyTypes'
        ]
        vaccinationStores.forEach(store => {
          const storeData = res.data[store]
          if (storeData.length > 0)
            commit(`${store}/updateAfter`, storeData, { root: true })
        })
        return {
          result: true,
          hitAllDataCounts: res.data.hitAllDataCounts,
          patientIds: res.data.patientIds,
          reminderVaccinationSets: res.data.reminderVaccinationSets
        }
      }
    } catch (error) {
      return { result: false }
    }
  }
}

const mutations = {}

export default {
  namespaced: true,
  state,
  getters,
  actions,
  mutations
}
