import { getAxiosObject } from '@/utils/library'
import {
  commonGetters,
  commonActions,
  commonMutations
} from '@/store/store_utils'
import { toStateName } from '@/utils/convert_string'

const dataName = 'microchipFacilities'
const keys = ['patientId']

const state = {
  [dataName]: [],
  updatedAt: '20101010101010'
}
keys.forEach(key => {
  state[toStateName(key)] = null
})

const getters = {
  ...commonGetters(dataName, ['getData', 'getUpdatedAt'], {
    filterDel: true,
    getDataBy: keys
  })
}

const actions = {
  ...commonActions(['setData', 'setUpdatedAt', 'updateAfter']),
  async create({ commit }, microchipFacility) {
    try {
      const axiosObject = getAxiosObject()
      const res = await axiosObject.post('/microchip-facilities', {
        microchipFacility
      })
      if (res.status === 200) {
        commit('updateAfter', [res.data.microchipFacility])
        return true
      }
    } catch (error) {
      return error.response?.data?.message
        ? error.response.data.message === 'no data'
          ? `no ${error.response.data.extra}`
          : error.response.data.message
        : 'server error'
    }
  },
  async update({ commit }, microchipFacility) {
    try {
      const axiosObject = getAxiosObject()
      const res = await axiosObject.put('/microchip-facilities', {
        microchipFacility
      })
      if (res.status === 200) {
        commit('updateAfter', [res.data.microchipFacility])
        return true
      }
    } catch (error) {
      if (error.response?.data?.message) {
        if (error.response.data.message === 'no data') {
          return `no ${error.response.data.extra}`
        } else if (error.response.data.message === 'no data in clinic') {
          commit('setData', error.response.data.extra.data)
          return `no ${error.response.data.extra.dataName}`
        }
      } else {
        return 'server error'
      }
    }
  }
}

const mutations = {
  ...commonMutations(dataName, ['setData', 'setUpdatedAt', 'updateAfter'], {
    setDataBy: keys
  })
}

export default {
  namespaced: true,
  state,
  getters,
  actions,
  mutations
}
