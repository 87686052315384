var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('div',{staticClass:"base-text-box"},[((_vm.type)==='checkbox')?_c('input',{directives:[{name:"model",rawName:"v-model",value:(_vm.inputText),expression:"inputText"}],staticClass:"text-box",class:{
      pointer: _vm.pointer,
      'disabled-color-white': _vm.disabledColorWhite,
      'placeholder-small-font': _vm.placeholderSmallFont
    },style:(_vm.styles),attrs:{"placeholder":_vm.placeholder,"maxlength":_vm.maxlength,"max":_vm.max,"min":_vm.min,"disabled":_vm.disabled,"data-e2e":"base-text-box","type":"checkbox"},domProps:{"checked":Array.isArray(_vm.inputText)?_vm._i(_vm.inputText,null)>-1:(_vm.inputText)},on:{"blur":function($event){return _vm.$emit('blur')},"dblclick":function($event){return _vm.$emit('dblclick')},"keyup":function($event){if(!$event.type.indexOf('key')&&_vm._k($event.keyCode,"enter",13,$event.key,"Enter")){ return null; }return _vm.$emit('keyup')},"change":function($event){var $$a=_vm.inputText,$$el=$event.target,$$c=$$el.checked?(true):(false);if(Array.isArray($$a)){var $$v=null,$$i=_vm._i($$a,$$v);if($$el.checked){$$i<0&&(_vm.inputText=$$a.concat([$$v]))}else{$$i>-1&&(_vm.inputText=$$a.slice(0,$$i).concat($$a.slice($$i+1)))}}else{_vm.inputText=$$c}}}}):((_vm.type)==='radio')?_c('input',{directives:[{name:"model",rawName:"v-model",value:(_vm.inputText),expression:"inputText"}],staticClass:"text-box",class:{
      pointer: _vm.pointer,
      'disabled-color-white': _vm.disabledColorWhite,
      'placeholder-small-font': _vm.placeholderSmallFont
    },style:(_vm.styles),attrs:{"placeholder":_vm.placeholder,"maxlength":_vm.maxlength,"max":_vm.max,"min":_vm.min,"disabled":_vm.disabled,"data-e2e":"base-text-box","type":"radio"},domProps:{"checked":_vm._q(_vm.inputText,null)},on:{"blur":function($event){return _vm.$emit('blur')},"dblclick":function($event){return _vm.$emit('dblclick')},"keyup":function($event){if(!$event.type.indexOf('key')&&_vm._k($event.keyCode,"enter",13,$event.key,"Enter")){ return null; }return _vm.$emit('keyup')},"change":function($event){_vm.inputText=null}}}):_c('input',{directives:[{name:"model",rawName:"v-model",value:(_vm.inputText),expression:"inputText"}],staticClass:"text-box",class:{
      pointer: _vm.pointer,
      'disabled-color-white': _vm.disabledColorWhite,
      'placeholder-small-font': _vm.placeholderSmallFont
    },style:(_vm.styles),attrs:{"placeholder":_vm.placeholder,"maxlength":_vm.maxlength,"max":_vm.max,"min":_vm.min,"disabled":_vm.disabled,"data-e2e":"base-text-box","type":_vm.type},domProps:{"value":(_vm.inputText)},on:{"blur":function($event){return _vm.$emit('blur')},"dblclick":function($event){return _vm.$emit('dblclick')},"keyup":function($event){if(!$event.type.indexOf('key')&&_vm._k($event.keyCode,"enter",13,$event.key,"Enter")){ return null; }return _vm.$emit('keyup')},"input":function($event){if($event.target.composing){ return; }_vm.inputText=$event.target.value}}})])}
var staticRenderFns = []

export { render, staticRenderFns }