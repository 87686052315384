<template>
  <div class="base-back-ground-cover">
    <div class="cover-layer" :style="{ zIndex }" @click="coverClick">
      <slot></slot>
    </div>
  </div>
</template>

<script>
export default {
  name: 'BaseBackGroundCover',
  props: {
    layerNumber: {
      type: Number,
      default: 1
    }
  },
  computed: {
    zIndex() {
      return this.layerNumber * 1000
    }
  },
  methods: {
    coverClick() {
      this.$emit('cover-click')
    }
  }
}
</script>

<style lang="scss" scoped>
.base-back-ground-cover {
  > .cover-layer {
    position: fixed;
    top: 0;
    left: 0;
    width: 100vw;
    height: 100vh;
    background: rgba(204, 204, 204, 0.5);
    display: flex;
    flex-direction: columns;
    justify-content: center;
    align-items: center;
  }
}
</style>
