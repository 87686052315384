import moment from 'moment'

const minSubColumnWidth = 40
const baseSubColumnLength = 5
const blankWidth = 40
const baseUnitWidth = 240

const state = {
  initialReservation: null,
  reservationRequestId: 0,
  date: moment().format('YYYYMMDD'),
  timeTableWidth: 0,
  subColumnWidth: 0,
  blankWidth,
  subColumnLength: 0,
  availableWidth: 0,
  unitWidth: 0,
  popupFlg: false,
  reservationId: 0,
  moveEndTimeObj: { reservation: { id: 0 }, afterEndTime: '' },
  unitHeightForWeekTable: 70,
  highlightedReservation: null
}

const getters = {
  get(state) {
    return state
  }
}

const actions = {
  changeDate({ commit }, date) {
    commit('changeDate', date)
  },
  setInitialReservation({ commit }, initialReservation) {
    commit('initialReservation', initialReservation)
  },
  setReservationRequestId({ commit }, id) {
    commit('reservationRequestId', id)
  },
  deleteInitials({ commit }) {
    commit('deleteInitials')
  },
  showPopup({ commit }) {
    commit('showPopup')
  },
  deletePopup({ commit }) {
    commit('deletePopup')
  },
  setReservationColumnWidthParts({ commit }, obj) {
    commit('setReservationColumnWidthParts', obj)
  },
  setReservationId({ commit }, reservationId) {
    commit('setReservationId', reservationId)
  },
  clearReservationId({ commit }) {
    commit('clearReservationId')
  },
  setMoveEndTimeOfReservation({ commit }, moveEndTimeObj) {
    commit('setMoveEndTimeOfReservation', moveEndTimeObj)
  },
  deleteMoveEndTimeOfReservation({ commit }) {
    commit('deleteMoveEndTimeOfReservation')
  },
  setHighlightedReservation({ commit }, reservation) {
    commit('setHighlightedReservation', reservation)
  },
  resetHighlightedReservation({ commit }) {
    commit('resetHighlightedReservation')
  }
}

const mutations = {
  changeDate(state, date) {
    state.date = date
  },
  initialReservation(state, reservation) {
    state.initialReservation = reservation
  },
  reservationRequestId(state, id) {
    state.reservationRequestId = id
  },
  deleteInitials(state) {
    state.initialReservation = null
    state.reservationRequestId = 0
  },
  showPopup(state) {
    state.popupFlg = true
  },
  deletePopup(state) {
    state.popupFlg = false
  },
  setReservationColumnWidthParts(state, obj) {
    state.timeTableWidth = obj.timeTableWidth
    if (state.timeTableWidth > baseUnitWidth * obj.reservationColumnLength) {
      state.unitWidth = state.timeTableWidth / obj.reservationColumnLength
      state.availableWidth = state.unitWidth - blankWidth
      state.subColumnLength = state.availableWidth / minSubColumnWidth
      state.subColumnWidth = state.availableWidth / state.subColumnLength
    } else {
      state.unitWidth = baseUnitWidth
      state.availableWidth = baseUnitWidth - blankWidth
      state.subColumnLength = baseSubColumnLength
      state.subColumnWidth = state.availableWidth / state.subColumnLength
    }
  },
  setReservationId(state, reservationId) {
    state.reservationId = reservationId
  },
  clearReservationId(state) {
    state.reservationId = 0
  },
  setMoveEndTimeOfReservation(state, moveEndTimeObj) {
    state.moveEndTimeObj = moveEndTimeObj
  },
  deleteMoveEndTimeOfReservation(state) {
    state.moveEndTimeObj = { reservation: { id: 0 }, afterEndTime: '' }
  },
  setHighlightedReservation(state, reservation) {
    state.highlightedReservation = reservation
  },
  resetHighlightedReservation(state) {
    state.highlightedReservation = null
  }
}

export default {
  namespaced: true,
  state,
  mutations,
  getters,
  actions
}
