import { getAxiosObject } from '@/utils/library'
import {
  commonGetters,
  commonMutations,
  commonActions
} from '@/store/store_utils'
import { toStateName } from '@/utils/convert_string'

const dataName = 'payments'
const keys = ['medicalPaymentId']

const state = {
  payments: [],
  updatedAt: '20101010101010'
}
keys.forEach(key => {
  state[toStateName(key)] = null
})

const getters = {
  ...commonGetters(dataName, ['getData', 'getUpdatedAt'], { getDataBy: keys })
}

const actions = {
  ...commonActions(['setData', 'setUpdatedAt', 'updateAfter']),
  async create({ commit }, { payment, cancelFlg }) {
    try {
      const axiosObject = getAxiosObject()
      const res = await axiosObject.post('/payments', { payment, cancelFlg })
      if (res.status === 200) {
        commit('updateAfter', res.data.payments)
        if (res.data.medicalPayment) {
          commit('medicalPayments/updateAfter', [res.data.medicalPayment], {
            root: true
          })
        }
        if (res.data.reservation) {
          commit('reservations/updateAfter', [res.data.reservation], {
            root: true
          })
        }
        return true
      }
    } catch (error) {
      if (error.response?.data?.message) {
        if (error.response.data.message === 'no data in clinic') {
          if (error.response.data.extra.dataName === 'owner') {
            commit('owners/setData', error.response.data.extra.data, {
              root: true
            })
          } else if (error.response.data.extra.dataName === 'patient') {
            commit('patients/setData', error.response.data.extra.data, {
              root: true
            })
          } else if (error.response.data.extra.dataName === 'medicalPayment') {
            commit('medicalPayments/setData', error.response.data.extra.data, {
              root: true
            })
          }
          return `no data in clinic`
        } else if (error.response.data.message === 'no data') {
          return `no ${error.response.data.extra}`
        } else {
          return error.response.data.message
        }
      } else {
        return 'server error'
      }
    }
  },
  async cancel({ commit }, id) {
    try {
      const axiosObject = getAxiosObject()
      const res = await axiosObject.put('/payments', { id })
      if (res.status === 200) {
        commit('updateAfter', res.data.payments)
        if (res.data.medicalPayment) {
          commit('medicalPayments/updateAfter', [res.data.medicalPayment], {
            root: true
          })
        }
        if (res.data.reservation) {
          commit('reservations/updateAfter', [res.data.reservation], {
            root: true
          })
        }
        return true
      }
    } catch (error) {
      if (error.response?.data?.message) {
        if (error.response.data.message === 'no data in clinic') {
          if (error.response.data.extra.dataName === 'payment') {
            commit('setData', error.response.data.extra.data)
          } else if (error.response.data.extra.dataName === 'medicalPayment') {
            commit('medicalPayments/setData', error.response.data.extra.data, {
              root: true
            })
          }
          return `no data in clinic`
        } else {
          return error.response.data.message
        }
      } else {
        return 'server error'
      }
    }
  },
  async search(
    { commit },
    { trimSearchText, period, checkInfo, page, sortType, orderType }
  ) {
    try {
      const axiosObject = getAxiosObject()
      const searchValues = {
        trimSearchText,
        period,
        checkInfo,
        page,
        sortType,
        orderType
      }
      const res = await axiosObject.get('/payments/search', {
        params: { searchValues }
      })
      if (res.status === 200) {
        if (res.data.payments.length > 0) {
          commit('updateAfter', res.data.payments)
        }
        if (res.data.medicalPayments.length > 0) {
          commit('medicalPayments/updateAfter', res.data.medicalPayments, {
            root: true
          })
        }
        if (res.data.medicalRecords.length > 0) {
          commit('medicalRecords/updateAfter', res.data.medicalRecords, {
            root: true
          })
        }
        return {
          result: true,
          displayPayments: res.data.displayPayments,
          hitAllDataCounts: res.data.hitAllDataCounts
        }
      }
    } catch (error) {
      return { result: false }
    }
  }
}

const mutations = {
  ...commonMutations(dataName, ['setData', 'setUpdatedAt', 'updateAfter'], {
    setDataBy: keys
  })
}

export default {
  namespaced: true,
  state,
  getters,
  actions,
  mutations
}
