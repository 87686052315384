import {
  openFontLicense,
  zenKakuGothicNewRegular,
  notoSansMonoCJKJPRegular
} from '@/utils/fonts_base64'

window.pdfMake = window.pdfMake || {}
window.pdfMake.vfs = {
  'OFL.txt': openFontLicense,
  'ZenKakuGothicNew-Regular.ttf': zenKakuGothicNewRegular,
  'NotoSansMonoCJKJPRegular.otf': notoSansMonoCJKJPRegular
}
