import moment from 'moment'

// 西暦 => 和暦
// 例  convertEraName(2000) → 平成12年
export const convertEraName = year => {
  const eras = [
    { year: 2018, name: '令和' },
    { year: 1988, name: '平成' },
    { year: 1925, name: '昭和' },
    { year: 1911, name: '大正' },
    { year: 1867, name: '明治' }
  ]
  for (let i = 0; i < eras.length; i++) {
    const era = eras[i]
    const baseYear = era.year
    const eraName = era.name
    if (year > baseYear) {
      const eraYear = year - baseYear
      if (eraYear === 1) {
        const beforeEra = eras[i + 1]
        const beforeEraYear = year - beforeEra.year
        return beforeEra.name + beforeEraYear + '年/' + eraName + '元年'
      }
      return eraName + eraYear + '年'
    }
  }
  return null
}

export const dateDiff = (aYYYYMMDD, bYYYYMMDD, type) => {
  const a = moment(aYYYYMMDD, 'YYYYMMDD')
  const b = moment(bYYYYMMDD, 'YYYYMMDD')
  const diff = a > b ? a.diff(b, type) : a < b ? b.diff(a, type) : 0
  return diff
}
