<template>
  <div class="popup-footer-one-button">
    <div class="one-button">
      <base-button-border-orange v-if="buttonBorderOrangeFlg" @click="close">
        {{ text }}</base-button-border-orange
      >
      <div v-else>
        <base-button-medium-white
          v-if="oneButtonColor === 'white'"
          @click="close"
        >
          {{ text }}
        </base-button-medium-white>
        <base-button-medium-orange
          v-if="oneButtonColor === 'orange'"
          @click="close"
        >
          {{ text }}
        </base-button-medium-orange>
      </div>
    </div>
  </div>
</template>

<script>
import BaseButtonBorderOrange from '@/components/parts/atoms/BaseButtonBorderOrange'
import BaseButtonMediumWhite from '@/components/parts/atoms/BaseButtonMediumWhite'
import BaseButtonMediumOrange from '@/components/parts/atoms/BaseButtonMediumOrange'
export default {
  name: 'PopupFooterOneButton',
  components: {
    BaseButtonBorderOrange,
    BaseButtonMediumWhite,
    BaseButtonMediumOrange
  },
  props: {
    text: { type: String, default: '閉じる' },
    buttonBorderOrangeFlg: { type: Boolean, default: false },
    oneButtonColor: { type: String, default: 'white' }
  },
  methods: {
    close() {
      this.$emit('close')
    }
  }
}
</script>

<style lang="scss" scoped>
.popup-footer-one-button {
  display: flex;
}
</style>
