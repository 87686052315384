import { getAxiosObject } from '@/utils/library'
import {
  commonGetters,
  commonActions,
  commonMutations
} from '@/store/store_utils'

const dataName = 'previousMail'

const state = {
  previousMail: {},
  updatedAt: '20101010101010'
}

const getters = {
  ...commonGetters(dataName, ['getData', 'getUpdatedAt'], {})
}

const actions = {
  ...commonActions(['setData', 'setUpdatedAt', 'updateAfter']),
  async update({ commit }, previousMail) {
    try {
      const axiosObject = getAxiosObject()
      const res = await axiosObject.put('/previous-mail', { previousMail })
      if (res.status === 200) {
        commit('updateAfter', res.data.previousMail)
        return true
      }
    } catch (error) {
      return error.response?.data?.message
        ? error.response.data.message === 'no data'
          ? `no ${error.response.data.extra}`
          : error.response.data.message
        : 'server error'
    }
  }
}

const mutations = {
  ...commonMutations(dataName, ['setData', 'updateAfter', 'setUpdatedAt'], {})
}

export default {
  namespaced: true,
  state,
  mutations,
  getters,
  actions
}
