<template>
  <div class="customer-login-management">
    <div class="password-input-form" v-if="!token">
      <base-text-input-password
        class="password-text-box"
        :placeholder="'パスワード'"
        v-model="password"
      />
      <base-button-medium-orange @click="fetchCustomerData" :disabled="waitFlg"
        >送信</base-button-medium-orange
      >
    </div>
    <div class="customer-login-management-area" v-else>
      <div class="button-area">
        <base-button-small-white
          class="button"
          :disabled="viewOnlyFlg"
          @click="toggleChangeModeFlg"
          data-test="toggle-change-mode-button"
        >
          {{ toggleChangeModeButtonText }}
        </base-button-small-white>
        <base-button-small-white
          class="button"
          @click="toggleShowDevFlg"
          data-test="toggle-show-dev-button"
        >
          {{ toggleShowDevButtonText }}
        </base-button-small-white>
        <base-button-small-white
          class="button"
          @click="toggleShowDelFlg"
          data-test="toggle-show-del-button"
        >
          {{ toggleShowDelButtonText }}
        </base-button-small-white>
        <base-button-register
          :text="'お知らせ登録'"
          @click="$router.push({ path: '/notice-new' })"
        />
      </div>
      <table>
        <thead>
          <tr>
            <th>医院ID</th>
            <th>医院名</th>
            <th>メール</th>
            <th>最終ログイン日</th>
            <th>アカウント状況</th>
            <th>契約</th>
            <th>開発用</th>
            <th>アカウント停止・再開</th>
          </tr>
        </thead>
        <tbody>
          <tr v-for="clinic in tableData" :key="clinic.id + 'management'">
            <td data-test="clinic-id">{{ clinic.id }}</td>
            <td data-test="clinic-name">{{ clinic.name }}</td>
            <td data-test="clinic-email">{{ clinic.email }}</td>
            <td data-test="last-login-date">{{ clinic.lastLoginDate }}</td>
            <td data-test="status">{{ formatDelFlg(clinic.delFlg) }}</td>
            <td class="contract-type" data-test="contract-type">
              <div v-if="changeModeFlg">
                <base-select-box
                  data-test="contract-type-select-box"
                  :selectData="contractTypes"
                  :styles="{ height: '25px' }"
                  :value="clinic.planId"
                  @input="changeClinicContract(clinic, $event)"
                />
              </div>
              <div v-else>
                {{ formatKarteFlg(clinic.karteFlg, clinic.loginLimit) }}
              </div>
            </td>
            <td data-test="dev-flg">
              <div v-if="viewOnlyFlg">{{ formatDevFlg(clinic.devFlg) }}</div>
              <div v-else>
                <base-select-box
                  :selectData="devSelectData"
                  :styles="{ height: '25px' }"
                  :value="clinic.devFlg"
                  @input="changeDevFlg(clinic, $event)"
                />
              </div>
            </td>
            <td class="del-flg">
              <button
                :disabled="viewOnlyFlg"
                @click="toggleClinicDelFlg(clinic)"
                data-test="status-button"
              >
                {{ makeButtonText(clinic.delFlg) }}
              </button>
            </td>
          </tr>
        </tbody>
      </table>
    </div>
  </div>
</template>

<script>
import BaseTextInputPassword from '@/components/parts/atoms/BaseTextInputPassword'
import BaseButtonMediumOrange from '@/components/parts/atoms/BaseButtonMediumOrange'
import BaseButtonRegister from '@/components/parts/atoms/BaseButtonRegister'
import BaseButtonSmallWhite from '@/components/parts/atoms/BaseButtonSmallWhite'
import BaseSelectBox from '@/components/parts/atoms/BaseSelectBox'
import { getAxiosObject, axiosObjectForPyxosManage } from '@/utils/library'
import moment from 'moment'
import { mapGetters } from 'vuex'

export default {
  name: 'CustomerLoginManagement',

  components: {
    BaseTextInputPassword,
    BaseButtonMediumOrange,
    BaseButtonSmallWhite,
    BaseButtonRegister,
    BaseSelectBox
  },

  data() {
    return {
      password: process.env.NODE_ENV === 'development' ? 'password' : '',
      tableData: [],
      waitFlg: false,
      showDevFlg: false,
      showDelFlg: false,
      changeModeFlg: false,
      contractTypes: [
        { id: 0, name: '予約' },
        { id: 1, name: '電子カルテAプラン' },
        { id: 2, name: '電子カルテBプラン' }
      ],
      devSelectData: [
        { id: 0, name: '×' },
        { id: 1, name: '〇' }
      ]
    }
  },

  computed: {
    ...mapGetters({
      token: 'pyxosManage/token',
      viewOnlyFlg: 'pyxosManage/viewOnlyFlg',
      clinics: 'pyxosManage/clinics'
    }),
    toggleShowDevButtonText() {
      return this.showDevFlg ? '「開発用」表示' : '「開発用」非表示'
    },
    toggleShowDelButtonText() {
      return this.showDelFlg ? '「停止」表示' : '「停止」非表示'
    },
    toggleChangeModeButtonText() {
      return this.changeModeFlg ? '契約閲覧' : '契約変更'
    }
  },

  created() {
    if (this.token) {
      this.createTableData()
    }
  },

  methods: {
    async fetchCustomerData() {
      this.waitFlg = true
      try {
        const axiosObject = getAxiosObject()
        const res = await axiosObject.get('/customer-login-management', {
          params: { password: this.password }
        })
        this.$store.dispatch('pyxosManage/login', {
          token: res.data.token,
          clinics: res.data.clinics,
          noticeCategories: res.data.noticeCategories,
          loginHistories: res.data.loginHistories,
          viewOnlyFlg: res.data.viewOnlyFlg
        })
        this.createTableData()
      } catch (error) {
        const text =
          error.response?.status === 400
            ? 'パスワードが異なります'
            : '通信エラーが起きました'
        alert(text)
      }
      this.waitFlg = false
    },
    createTableData() {
      const loginHistories = this.$store.getters['pyxosManage/loginHistories']
      const clinics =
        this.showDevFlg && this.showDelFlg
          ? this.clinics
          : this.showDevFlg
          ? this.clinics.filter(c => c.delFlg === 0)
          : this.showDelFlg
          ? this.clinics.filter(c => c.devFlg === 0)
          : this.clinics.filter(c => c.delFlg === 0 && c.devFlg === 0)
      const clinicAttributes = [
        'id',
        'name',
        'delFlg',
        'karteFlg',
        'devFlg',
        'loginLimit',
        'email'
      ]
      this.tableData = clinics.map(clinic => {
        const tableDatum = {}
        clinicAttributes.forEach(
          attribute => (tableDatum[attribute] = clinic[attribute])
        )
        const loginHistory = loginHistories[clinic.id]
        const lastLoginDate =
          loginHistory !== undefined
            ? moment(loginHistory.createdAt).format('YYYY年MM月DD日')
            : '未ログイン'
        tableDatum.lastLoginDate = lastLoginDate
        tableDatum.planId =
          clinic.karteFlg === 0 ? 0 : clinic.loginLimit === 0 ? 2 : 1
        return tableDatum
      })
    },
    async toggleClinicDelFlg(clinic) {
      const text = clinic.delFlg === 0 ? '停止' : '再開'
      const confirm = window.confirm(
        `${clinic.name}を${text}させて宜しいですか？`
      )
      if (confirm) {
        await this.updateClinic({
          ...clinic,
          delFlg: clinic.delFlg === 1 ? 0 : 1
        })
        this.createTableData()
      }
    },
    async changeClinicContract(clinic, event) {
      const text =
        event === 0
          ? '「予約」'
          : event === 1
          ? '「電子カルテAプラン」'
          : '「電子カルテBプラン」'
      const confirm = window.confirm(
        `${clinic.name}の契約を\n${text}に変更させて宜しいですか？`
      )
      if (confirm) {
        const karteFlg = event === 0 ? 0 : 1
        const loginLimit = event === 1 ? 3 : 0
        await this.updateClinic({ ...clinic, karteFlg, loginLimit })
      }
      this.createTableData()
    },
    async changeDevFlg(clinic, event) {
      const confirm = window.confirm(
        event === 0
          ? `${clinic.name}の「開発用」の設定を解除して宜しいですか？`
          : `${clinic.name}を「開発用」に変更して宜しいですか？`
      )
      if (confirm) {
        await this.updateClinic({ ...clinic, devFlg: event === 0 ? 0 : 1 })
      }
      this.createTableData()
    },
    async updateClinic(clinic) {
      try {
        const axiosObject = axiosObjectForPyxosManage()
        const res = await axiosObject.put('/customer-login-management', {
          clinic
        })
        this.$store.dispatch('pyxosManage/updateClinic', res.data.clinic)
      } catch (error) {
        const text =
          error.response?.status === 401
            ? '無効なリクエストです'
            : '通信エラーが起きました'
        alert(text)
      }
    },
    toggleShowDevFlg() {
      this.showDevFlg = !this.showDevFlg
      this.createTableData()
    },
    toggleShowDelFlg() {
      this.showDelFlg = !this.showDelFlg
      this.createTableData()
    },
    toggleChangeModeFlg() {
      this.changeModeFlg = !this.changeModeFlg
    },
    formatDelFlg(delFlg) {
      return delFlg === 0 ? '継続中' : '停止'
    },
    formatKarteFlg(karteFlg, loginLimit) {
      return karteFlg === 0
        ? '予約'
        : loginLimit !== 0
        ? '電子カルテAプラン'
        : '電子カルテBプラン'
    },
    formatDevFlg(devFlg) {
      return devFlg === 1 ? '〇' : ''
    },
    makeButtonText(delFlg) {
      return delFlg === 0 ? '停止' : '再開'
    }
  }
}
</script>

<style lang="scss" scoped>
.customer-login-management {
  > .password-input-form {
    > .password-text-box {
      margin: 20px 0;
    }
  }
  > .customer-login-management-area {
    margin: 20px auto;
    max-width: 1150px;
    font-size: 15px;
    > .button-area {
      display: flex;
      justify-content: flex-end;
      > .button {
        margin-right: 10px;
      }
    }
    > table {
      margin: 20px auto;
      border: 1px solid #{$light-grey};
      border-spacing: 0;
      border-collapse: collapse;
      > thead > tr {
        background: #{$light-orange};
        > th {
          padding: 10px;
          border: 1px solid #{$light-grey};
        }
      }
      > tbody > tr {
        background-color: #{$white};
        cursor: default;
        &:hover {
          opacity: 0.8;
        }
        &:nth-child(even) {
          background-color: #{$white_f7};
        }
        > td {
          padding: 5px;
          height: 25px;
          &.contract-type {
            width: 200px;
            padding: 0;
          }
          &.dev-flg {
            width: 50px;
          }
          &.del-flg {
            width: 100px;
            > button {
              width: 100%;
              &:not([disabled]) {
                cursor: pointer;
              }
            }
          }
        }
      }
    }
  }
}
</style>
