import { POSTAL_CODE_API_URL, PREFECTURES } from '@/utils/define'
import axios from 'axios'

export default {
  data() {
    return {
      mixinApiResultPostalCode: null
    }
  },
  computed: {
    mixinPrefectures() {
      const prefectures = this.$store.getters['master/getPrefectures']
      return prefectures.length !== 0 ? prefectures : PREFECTURES
    }
  },
  methods: {
    mixinInputAddress: async function(objName) {
      try {
        const zipcode =
          this[objName].postalCode.slice(0, 3) +
          '/' +
          this[objName].postalCode.slice(3) +
          '.json'
        const res = await axios.get(POSTAL_CODE_API_URL + zipcode)
        if (res.status === 200) {
          this[objName].prefectureId = this.mixinPrefectures.find(
            v => v.id === Number(res.data.data[0].prefcode)
          ).id
          const resAddress = res.data.data[0].ja
          this[objName].address =
            resAddress.address1 +
            resAddress.address2 +
            resAddress.address3 +
            resAddress.address4
        }
        this.mixinApiResultPostalCode = { existingPostalCodeFlg: true }
      } catch (error) {
        if (error.response?.status === 404) {
          this.mixinApiResultPostalCode = { existingPostalCodeFlg: false }
        }
      }
    }
  }
}
