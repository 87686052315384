//v-htmlを使用している箇所で特殊文字・html・cssが適用されないように
//エスケープ文字に変換する関数
//参考サイト
//https://developer.cybozu.io/hc/ja/articles/201850320
//https://techacademy.jp/magazine/46853
export const escapeHtml = string => {
  return string
    .replace(/&/g, '&amp;')
    .replace(/</g, '&lt;')
    .replace(/>/g, '&gt;')
    .replace(/"/g, '&quot;')
    .replace(/'/g, '&#x27;')
}

export const unescapeHtml = string => {
  if (typeof string !== 'string') return string
  const patterns = {
    '&lt;': '<',
    '&gt;': '>',
    '&amp;': '&',
    '&quot;': '"',
    '&#x27;': "'",
    '&#x60;': '`'
  }
  return string.replace(/&(lt|gt|amp|quot|#x27|#x60);/g, function(match) {
    return patterns[match]
  })
}
