const state = {
  editor: null,
  magnification: 100
}

const getters = {
  getEditor(state) {
    return state.editor
  },
  getJson(state) {
    return state.editor !== null
      ? state.editor.getJSON()
      : { type: 'doc', content: [] }
  },
  getContent(state) {
    return state.editor !== null ? state.editor.getHTML() : ''
  },
  getMagnification(state) {
    return state.magnification
  }
}

const actions = {
  set({ commit }, editor) {
    commit('set', editor)
  },
  reset({ commit }) {
    commit('reset')
  },
  fontSize({ commit }, size) {
    commit('fontSize', size)
  },
  bold({ commit }) {
    commit('bold')
  },
  italic({ commit }) {
    commit('italic')
  },
  strike({ commit }) {
    commit('strike')
  },
  underline({ commit }) {
    commit('underline')
  },
  fontColor({ commit }, color) {
    commit('fontColor', color)
  },
  backgroundColor({ commit }, color) {
    commit('backgroundColor', color)
  },
  examinationTable({ commit }, examinationResultGroupId) {
    commit('examinationTable', examinationResultGroupId)
  },
  uploadImage({ commit }, uploadImageId) {
    commit('uploadImage', uploadImageId)
  },
  medicalContentImage(
    { commit },
    { medicalContentImageId, uploadImageId, inputType }
  ) {
    commit('medicalContentImage', {
      medicalContentImageId,
      uploadImageId,
      inputType
    })
  },
  undo({ commit }) {
    commit('undo')
  },
  redo({ commit }) {
    commit('redo')
  },
  fontFamily({ commit }, fontFamily) {
    commit('fontFamily', fontFamily)
  },
  moveCursorPosition({ commit }, cursorPosition) {
    commit('moveCursorPosition', cursorPosition)
  },
  changeMagnification({ commit }, value) {
    commit('changeMagnification', value)
  },
  setTemplate({ commit }, content) {
    commit('setTemplate', content)
  }
}

const mutations = {
  set(state, editor) {
    state.editor = editor
  },
  reset(state) {
    state.editor.destroy()
    state.editor = null
  },
  fontSize(state, size) {
    state.editor
      .chain()
      .focus()
      .setFontSize(size)
      .run()
  },
  bold(state) {
    state.editor
      .chain()
      .focus()
      .toggleBold()
      .run()
  },
  italic(state) {
    state.editor
      .chain()
      .focus()
      .toggleItalic()
      .run()
  },
  strike(state) {
    state.editor
      .chain()
      .focus()
      .toggleStrike()
      .run()
  },
  underline(state) {
    state.editor
      .chain()
      .focus()
      .toggleUnderline()
      .run()
  },
  fontColor(state, color) {
    state.editor
      .chain()
      .focus()
      .setColor(color)
      .run()
  },
  backgroundColor(state, color) {
    state.editor
      .chain()
      .focus()
      .setBackgroundColor(color)
      .run()
  },
  examinationTable(state, examinationResultGroupId) {
    const currPos = state.editor.state.selection.node
      ? state.editor.state.selection.$anchor.pos + 1
      : state.editor.state.selection.$anchor.pos

    state.editor
      .chain()
      .focus()
      .insertContentAt(
        currPos,
        `<vue-rich-text-editor-examination-table examinationResultGroupId="${examinationResultGroupId}"></vue-rich-text-editor-examination-table><p></p>`
      )
      .run()
  },
  uploadImage(state, uploadImageId) {
    const currPos = state.editor.state.selection.node
      ? state.editor.state.selection.$anchor.pos + 1
      : state.editor.state.selection.$anchor.pos
    state.editor
      .chain()
      .focus()
      .insertContentAt(
        currPos,
        `<vue-rich-text-editor-image uploadImageId="${uploadImageId}"></vue-rich-text-editor-image><p></p>`
      )
      .run()
  },
  medicalContentImage(
    state,
    { medicalContentImageId, uploadImageId, inputType }
  ) {
    if (inputType === 'update') {
      state.editor
        .chain()
        .focus()
        .insertContent(
          `<vue-rich-text-editor-medical-content-image medicalContentImageId="${medicalContentImageId}" uploadImageId="${uploadImageId}"></vue-rich-text-editor-medical-content-image><p></p>`
        )
        .run()
    } else {
      const currPos = state.editor.state.selection.node
        ? state.editor.state.selection.$anchor.pos + 1
        : state.editor.state.selection.$anchor.pos
      state.editor
        .chain()
        .focus()
        .insertContentAt(
          currPos,
          `<vue-rich-text-editor-medical-content-image medicalContentImageId="${medicalContentImageId}" uploadImageId="${uploadImageId}"></vue-rich-text-editor-medical-content-image><p></p>`
        )
        .run()
    }
  },
  undo(state) {
    state.editor
      .chain()
      .focus()
      .undo()
      .run()
  },
  redo(state) {
    state.editor
      .chain()
      .focus()
      .redo()
      .run()
  },
  fontFamily(state, fontFamily) {
    state.editor
      .chain()
      .focus()
      .setFontFamily(fontFamily)
      .run()
  },
  moveCursorPosition(state, cursorPosition) {
    state.editor
      .chain()
      .focus(cursorPosition + 1)
      .run()
  },
  changeMagnification(state, value) {
    const setValue = value <= 0 ? 0 : 0 < value && value <= 1000 ? value : 1000
    state.magnification = setValue
  },
  setTemplate(state, content) {
    state.editor
      .chain()
      .focus()
      .insertContent(content)
      .run()
  }
}

export default {
  namespaced: true,
  state,
  getters,
  actions,
  mutations
}
