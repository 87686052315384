<template>
  <focus-trap
    v-model="activeTrap"
    :escapeDeactivates="false"
    :clickDeactivates="false"
  >
    <div class="logout-popup" @keyup.esc.stop="close" tabindex="-1">
      <announce-popup
        v-if="showFlg"
        :title="'ログアウト'"
        :type="'alert'"
        :buttons="['閉じる']"
        @close="close"
      >
        タブの複製等には対応していません。<br />ログアウトします。
      </announce-popup>
    </div>
  </focus-trap>
</template>

<script>
import AnnouncePopup from '@/components/popups/AnnouncePopup'
import { FocusTrap } from 'focus-trap-vue'

export default {
  name: 'Logout',
  components: {
    AnnouncePopup,
    FocusTrap
  },
  data() {
    return { activeTrap: false }
  },
  computed: {
    showFlg() {
      return this.$store.getters['auth/showLogoutPopupFlg']
    }
  },
  watch: {
    showFlg: function() {
      this.activeTrap = this.showFlg
    }
  },
  methods: {
    close() {
      this.$router.push({ name: 'login' })
    }
  }
}
</script>
<style lang="scss" scoped></style>
