import { getAxiosObject } from '@/utils/library'
import {
  commonGetters,
  commonMutations,
  commonActions
} from '@/store/store_utils'
import { toStateName } from '@/utils/convert_string'

const dataName = 'medicineSmallClasses'
const keys = ['medicineMediumClassOriginalId']

const state = {
  medicineSmallClasses: [],
  dataIndexedById: {},
  dataIndexedByOriginalId: {},
  updatedAt: '20101010101010'
}
keys.forEach(key => {
  state[toStateName(key)] = null
})

const getters = {
  ...commonGetters(
    dataName,
    ['getData', 'getDataById', 'getDataByOriginalId', 'getUpdatedAt'],
    { filterDel: true, getDataBy: keys }
  )
}

const actions = {
  ...commonActions(['setData', 'setUpdatedAt', 'updateAfter']),
  async delete({ commit }, id) {
    try {
      const axiosObject = getAxiosObject()
      const res = await axiosObject.delete('/medicine-small-classes', {
        data: { id }
      })
      if (res.status === 200) {
        commit('updateAfter', [res.data.medicineSmallClass])
        commit('medicines/updateAfter', res.data.medicines, { root: true })
        return true
      }
    } catch (error) {
      if (error.response?.data?.extra) {
        if (error.response.data.extra.data) {
          commit('setData', error.response.data.extra.data)
        } else {
          return error.response.data.extra
        }
        return error.response.data.message
      } else {
        return 'server error'
      }
    }
  },
  async update({ commit }, medicineSmallClass) {
    try {
      const axiosObject = getAxiosObject()
      const res = await axiosObject.put('/medicine-small-classes', {
        medicineSmallClass
      })
      if (res.status === 200) {
        if (Object.keys(res.data.medicineLargeClass).length > 0) {
          commit(
            'medicineLargeClasses/updateAfter',
            [res.data.medicineLargeClass],
            { root: true }
          )
        }
        if (Object.keys(res.data.medicineMediumClass).length > 0) {
          commit(
            'medicineMediumClasses/updateAfter',
            [res.data.medicineMediumClass],
            { root: true }
          )
        }
        commit('updateAfter', res.data.medicineSmallClasses)
        commit('medicines/updateAfter', res.data.medicines, { root: true })
        return true
      }
    } catch (error) {
      if (error.response?.data?.message) {
        if (error.response.data.message === 'no data in clinic') {
          commit('setData', error.response.data.extra.data)
          return 'no data in clinic'
        }
        if (error.response.data.message === 'no data') {
          return error.response.data.extra ===
            'medicineSmallClass already edited'
            ? 'already edited'
            : 'already deleted'
        } else {
          return error.response.data.message
        }
      } else {
        return 'server error'
      }
    }
  }
}

const mutations = {
  ...commonMutations(dataName, ['setData', 'setUpdatedAt', 'updateAfter'], {
    order: true,
    indexedById: true,
    indexedByOriginalId: true,
    setDataBy: keys
  })
}

export default {
  namespaced: true,
  state,
  getters,
  actions,
  mutations
}
