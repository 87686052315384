<template>
  <node-view-wrapper
    class="rich-text-editor-medical-content-image"
    data-target="richTextEditorMedicalContentImage"
    :style="hoverStyles"
  >
    <div
      class="image-frame"
      :style="borderStyle"
      @click="handleClick"
      data-test="image-frame"
      ref="image"
    >
      <span
        v-for="textObj in medicalContentImageTexts"
        :key="textObj.id"
        :style="textStyle(textObj.style)"
        v-html="$sanitize(escapeTextExceptHighLight(textObj.text))"
        data-test="text"
      ></span>
      <div class="image-area" :style="overImageStyle">
        <div
          class="image-getting-status"
          v-if="underImage.usedUnderImage && !underImage.image"
          :style="underImageStyle"
          data-test="image-getting-status"
        >
          画像取得中...
        </div>
        <img
          v-else
          class="under-image"
          :src="underImage.image"
          :style="underImageStyle"
          data-test="under-image"
        />
        <img
          class="over-image"
          :src="medicalContentImage.overImage"
          :style="overImageStyle"
          data-test="over-image"
        />
      </div>
      <img
        v-show="selectFlg"
        class="edit"
        src="@/assets/images/schema_pen_orange.png"
        @click.stop="openEditPopup"
        @touchend.stop="openEditPopup"
        :style="editPenStyle"
        data-test="edit-pen"
      />
    </div>
    <div
      v-show="selectFlg"
      class="mark"
      :style="markStyle"
      @pointerdown="resize"
      data-test="mark"
    ></div>
  </node-view-wrapper>
</template>

<script>
import { NodeViewWrapper, nodeViewProps } from '@tiptap/vue-2'
import { mapGetters } from 'vuex'
import { HISTORY_MEDICAL_CONTENT_WIDTH } from '@/utils/define'
import { highLightText } from '@/utils/high_light'
import { escapeHtml } from '@/utils/escape'
import { unescapeHighLightHtml } from '@/utils/unescape'
import _ from 'lodash'

export default {
  name: 'RichTextEditorMedicalContentImage',

  components: {
    NodeViewWrapper
  },

  mixins: [],

  props: nodeViewProps,

  data() {
    return {
      medicalContentImage: {
        overImage: null,
        widthInKarte: 0,
        heightInKarte: 0,
        schemaImageId: 0,
        uploadImageId: 0
      },
      underImage: { image: null, width: 0, height: 0, usedUnderImage: false },
      medicalContentImageTexts: [],
      selectFlg: false,
      unsubscribe: null,
      startWidth: 0,
      startHeight: 0,
      startX: 0,
      startY: 0,
      maxWidth: 0,
      imageMagnification: 0,
      min: 0
    }
  },

  computed: {
    ...mapGetters({
      getKarteMedicalContentImage:
        'medicalContentImages/getKarteMedicalContentImage',
      getResizedMedicalContentImage:
        'medicalContentImages/getResizedMedicalContentImage',
      getKarteUploadImage: 'uploadImages/getKarteUploadImage',
      getImageTextsByMedicalContentImageId:
        'medicalContentImageTexts/getDataByMedicalContentImageId',
      magnification: 'richTextEditor/getMagnification',
      getSchemaImage: 'schemaImages/getDataById'
    }),
    hoverStyles() {
      return this.extension.options.richTextEditorShowFlg
        ? { '--cursor': 'auto', '--opacity': '1' }
        : { '--cursor': 'pointer', '--opacity': '0.8' }
    },
    borderStyle() {
      if (
        this.extension.options.richTextEditorShowFlg &&
        this.medicalContentImage.widthInKarte >= HISTORY_MEDICAL_CONTENT_WIDTH
      ) {
        return {
          border: '2px dashed rgba(0, 0, 0, 0)',
          width: `${HISTORY_MEDICAL_CONTENT_WIDTH}px`,
          height: `${this.medicalContentImage.heightInKarte *
            this.calculateWidthReductionRate()}px`
        }
      } else {
        return this.selectFlg
          ? {
              border: '2px dashed black',
              width: `${this.medicalContentImage.widthInKarte}px`,
              height: `${this.medicalContentImage.heightInKarte}px`
            }
          : {
              border: '2px dashed rgba(0, 0, 0, 0)',
              width: `${this.medicalContentImage.widthInKarte}px`,
              height: `${this.medicalContentImage.heightInKarte}px`
            }
      }
    },
    editPenStyle() {
      return {
        width: '20px',
        height: '20px',
        border: '2px solid #ef6c00',
        padding: '1px',
        position: 'absolute',
        top: '2px',
        'margin-left': `${this.medicalContentImage.widthInKarte - 28}px`,
        background: 'white',
        'z-index': 5
      }
    },
    markStyle() {
      return {
        width: '10px',
        height: '10px',
        border: '1px solid black',
        position: 'absolute',
        'margin-top': `${this.medicalContentImage.heightInKarte - 4}px`,
        'margin-left': `${this.medicalContentImage.widthInKarte - 4}px`,
        'z-index': 5
      }
    },
    overImageStyle() {
      if (
        this.extension.options.richTextEditorShowFlg &&
        this.medicalContentImage.widthInKarte >= HISTORY_MEDICAL_CONTENT_WIDTH
      ) {
        return {
          border: '2px dashed rgba(0, 0, 0, 0)',
          width: `${HISTORY_MEDICAL_CONTENT_WIDTH}px`,
          height: `${this.medicalContentImage.heightInKarte *
            this.calculateWidthReductionRate()}px`
        }
      } else {
        return {
          width: `${this.medicalContentImage.widthInKarte}px`,
          height: `${this.medicalContentImage.heightInKarte}px`
        }
      }
    },
    ratio() {
      const baseSize = 400
      if (
        this.extension.options.richTextEditorShowFlg &&
        this.medicalContentImage.widthInKarte > HISTORY_MEDICAL_CONTENT_WIDTH
      ) {
        return {
          width: HISTORY_MEDICAL_CONTENT_WIDTH / baseSize,
          height:
            (this.medicalContentImage.heightInKarte *
              this.calculateWidthReductionRate()) /
            baseSize
        }
      } else {
        return {
          width: this.medicalContentImage.widthInKarte / baseSize,
          height: this.medicalContentImage.heightInKarte / baseSize
        }
      }
    },
    underImageStyle() {
      return {
        width: `${this.underImage.width * this.ratio.width}px`,
        height: `${this.underImage.height * this.ratio.height}px`
      }
    },
    textStyle: function() {
      return function(style) {
        return {
          position: 'absolute',
          top: `${style.top * this.ratio.height}px`,
          left: `${style.left * this.ratio.width}px`,
          width: `${style.width * this.ratio.width}px`,
          height: `${style.height * this.ratio.height}px`,
          color: style.color,
          'font-size':
            this.ratio.width > this.ratio.height
              ? `${style.size * this.ratio.height}px`
              : `${style.size * this.ratio.width}px`,
          'z-index': 3,
          overflow: 'hidden'
        }
      }
    }
  },

  watch: {
    selectFlg() {
      const richTextEditorForm = document.querySelector(
        '.rich-text-editor-form'
      )
      if (this.selectFlg) {
        const unselected = () => {
          this.selectFlg = false
          document.removeEventListener('pointerup', unselected)
        }
        document.addEventListener('pointerup', unselected)
        richTextEditorForm?.classList.add('prevent-touch')
      }
      if (!this.selectFlg) {
        richTextEditorForm?.classList.remove('prevent-touch')
      }
    }
  },

  created() {
    this.setMedicalContentImage()
  },

  mounted() {
    this.unsubscribe = this.$store.subscribe(mutation => {
      if (
        mutation.type === 'medicalContentImages/setKarteMedicalContentImages' ||
        mutation.type === 'richTextEditor/medicalContentImage' ||
        //↓開いたカルテに紐づいたシェーマ画像の下敷き画像(uploadImageのimageカラム)をapiで取得後に、その画像が画面に表示されないため、
        // 下記で取得した画像をstateにセットする処理を監視し、その処理が走った後に画像を表示する処理を再度走らせることで画像を表示させている。
        mutation.type === 'uploadImages/setKarteUploadImages'
      ) {
        this.setMedicalContentImage()
      }
    })
  },

  beforeDestroy() {
    this.unsubscribe()
  },

  methods: {
    calculateWidthReductionRate() {
      return (
        HISTORY_MEDICAL_CONTENT_WIDTH / this.medicalContentImage.widthInKarte
      )
    },
    setMedicalContentImage() {
      const resizedMedicalContentImage = this.getResizedMedicalContentImage(
        this.node.attrs.medicalContentImageId
      )
      const medicalContentImage =
        resizedMedicalContentImage &&
        !this.extension.options.isMedicalContentsHistoryDetail
          ? resizedMedicalContentImage
          : this.getKarteMedicalContentImage(
              this.node.attrs.medicalContentImageId
            )
      this.insertMedicalContentImage(medicalContentImage)
    },
    insertMedicalContentImage(medicalContentImage) {
      if (medicalContentImage.uploadImageId !== 0) {
        this.underImage = {
          ...this.getKarteUploadImage(medicalContentImage.uploadImageId),
          usedUnderImage: true
        }
      } else if (medicalContentImage.schemaImageId !== 0) {
        const schemaImage = this.getSchemaImage(
          medicalContentImage.schemaImageId
        )
        this.underImage = {
          image: require(`@/assets/images/schemas/${schemaImage.id}.jpg`),
          width: schemaImage.width,
          height: schemaImage.height,
          usedUnderImage: true
        }
      } else {
        this.underImage = {
          image: null,
          width: 0,
          height: 0,
          usedUnderImage: false
        }
      }
      this.medicalContentImage = _.cloneDeep(medicalContentImage)
      const medicalContentImageTexts = this.getImageTextsByMedicalContentImageId(
        medicalContentImage.id
      )
      if (medicalContentImageTexts !== undefined) {
        this.medicalContentImageTexts = medicalContentImageTexts.map(v => {
          return {
            id: v.id,
            text: v.text,
            style: {
              top: v.top,
              left: v.left,
              width: v.width,
              height: v.height,
              color: v.color,
              size: v.size
            }
          }
        })
      }
    },
    handleClick() {
      if (!this.extension.options.richTextEditorShowFlg) {
        this.selectFlg = !this.selectFlg
      }
    },
    openEditPopup() {
      this.$parent.$emit(
        'edit-medical-content-image',
        this.medicalContentImage.id
      )
    },
    resize(e) {
      if (e.pointerType === 'mouse' && e.button !== 0) return
      this.imageMagnification = this.magnification / 100
      const space = 50
      this.maxWidth =
        (e
          .composedPath()
          .find(
            v =>
              v.dataset &&
              v.dataset.target === 'richTextEditorMedicalContentImage'
          )
          .getBoundingClientRect().width -
          space) /
        this.imageMagnification
      this.min = 50
      const image = this.$refs.image
      this.startWidth = image.getBoundingClientRect().width
      this.startHeight = image.getBoundingClientRect().height
      this.startX = e.clientX
      this.startY = e.clientY
      document.addEventListener('pointermove', this.handleResizeMove)
      document.addEventListener('pointerup', this.handleResizeEnd)
    },
    handleResizeMove(e) {
      const width =
        (this.startWidth + e.clientX - this.startX) / this.imageMagnification
      this.medicalContentImage.widthInKarte =
        width <= this.min
          ? this.min
          : this.min < width && width <= this.maxWidth
          ? width
          : this.maxWidth
      const height =
        (this.startHeight + e.clientY - this.startY) / this.imageMagnification
      this.medicalContentImage.heightInKarte =
        height <= this.min ? this.min : height
    },
    handleResizeEnd() {
      document.removeEventListener('pointermove', this.handleResizeMove)
      document.removeEventListener('pointerup', this.handleResizeEnd)
      this.selectFlg = false
      const resizedMedicalContentImage = {
        ...this.medicalContentImage,
        image: null,
        widthInKarte: parseInt(this.medicalContentImage.widthInKarte),
        heightInKarte: parseInt(this.medicalContentImage.heightInKarte)
      }
      this.$store.dispatch(
        'medicalContentImages/setResizedMedicalContentImage',
        resizedMedicalContentImage
      )
      this.$parent.$emit('unsaved-resized-image')
    },
    escapeTextExceptHighLight(text) {
      if (
        this.extension.options.richTextEditorShowFlg &&
        this.extension.options.searchRegExp &&
        text.length > 0
      ) {
        const highLightedText = highLightText(
          text,
          this.extension.options.searchRegExp
        )
        const escapedContent = escapeHtml(highLightedText)
        return unescapeHighLightHtml(escapedContent)
      } else {
        return escapeHtml(text)
      }
    }
  }
}
</script>

<style lang="scss" scoped>
.rich-text-editor-medical-content-image {
  display: flex;
  > .image-frame {
    width: 400px;
    height: 400px;
    position: relative;
    > .image-area {
      display: table-cell;
      text-align: center;
      vertical-align: middle;
      &:hover {
        cursor: var(--cursor);
        opacity: var(--opacity);
      }
      > img {
        width: auto;
        height: auto;
        vertical-align: top;
      }
      > .image-getting-status {
        display: flex;
        justify-content: center;
        align-items: center;
        margin: 0 auto;
        border: solid 1px #{$light-grey};
        font-weight: bold;
        z-index: 1;
      }
      > .under-image {
        z-index: 1;
      }
      > .over-image {
        position: absolute;
        top: 0px;
        left: 0px;
        z-index: 2;
      }
    }
    > .edit {
      z-index: 4;
      &:hover {
        cursor: pointer;
        background: #{$very-light-shade-orange};
      }
    }
  }
  > .mark {
    &:hover {
      cursor: nwse-resize;
    }
  }
}
</style>
