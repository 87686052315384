import { getAxiosObject } from '@/utils/library'
import {
  commonGetters,
  commonActions,
  commonMutations
} from '@/store/store_utils'

const dataName = 'commonSetting'

const state = {
  commonSetting: {},
  updatedAt: '20101010101010'
}

const getters = {
  ...commonGetters(dataName, ['getData', 'getUpdatedAt'], {})
}

const actions = {
  ...commonActions(['setData', 'setUpdatedAt', 'updateAfter']),
  async update({ commit }, commonSetting) {
    try {
      const axiosObject = getAxiosObject()
      const res = await axiosObject.put('/common-setting', { commonSetting })
      if (res.status === 200) {
        commit('updateAfter', res.data.commonSetting)
        return true
      }
    } catch (error) {
      return false
    }
  }
}

const mutations = {
  ...commonMutations(dataName, ['setData', 'updateAfter', 'setUpdatedAt'], {})
}

export default {
  namespaced: true,
  state,
  mutations,
  getters,
  actions
}
