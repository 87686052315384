<template>
  <focus-trap :escapeDeactivates="false" :clickDeactivates="false">
    <validation-observer class="validation-observer" v-slot="{ invalid }">
      <div
        class="medical-content-template-new-popup"
        @keyup.esc.stop="checkDiff"
        tabindex="-1"
      >
        <popup
          :invalid="invalid || waitFlg || invalidContent"
          :buttons="['閉じる', '登録']"
          :layerNumber="2"
          @cancel="checkDiff"
          @decision="create"
          @click-close-mark="checkDiff"
        >
          <template v-slot:content>
            <div class="content">
              <div class="header">
                <div class="title">テンプレート新規登録</div>
              </div>
              <div class="input-form-wrapper">
                <medical-content-template-popup-input-form
                  :templateName="newMedicalContentTemplate.name"
                  v-model="newMedicalContentTemplate.content"
                  @invalid-content="val => (invalidContent = val)"
                  @input-name="val => (newMedicalContentTemplate.name = val)"
                />
              </div>
            </div>
          </template>
        </popup>
        <announce-popup
          v-if="popup.popupFlg"
          v-bind="popup"
          @cancel="resetPopup"
          @decision="closePopup"
          @close="closePopup"
          >{{ popup.content }}</announce-popup
        >
      </div>
    </validation-observer>
  </focus-trap>
</template>

<script>
import Popup from '@/components/popups/Popup'
import MedicalContentTemplatePopupInputForm from '@/components/parts/organisms/MedicalContentTemplatePopupInputForm'
import AnnouncePopup from '@/components/popups/AnnouncePopup'
import CheckPopupInputDifference from '@/components/mixins/CheckPopupInputDifference'
import { ValidationObserver } from 'vee-validate'
import _ from 'lodash'
import { FocusTrap } from 'focus-trap-vue'

export default {
  name: 'MedicalContentTemplateNewPopup',

  components: {
    FocusTrap,
    Popup,
    MedicalContentTemplatePopupInputForm,
    ValidationObserver,
    AnnouncePopup
  },

  mixins: [CheckPopupInputDifference],

  data() {
    return {
      initialData: { name: '', content: '<p></p>' },
      newMedicalContentTemplate: { name: '', content: '<p></p>' },
      waitFlg: false,
      popup: {
        popupFlg: false,
        type: '',
        title: '',
        buttons: [],
        layerNumber: 3,
        content: ''
      },
      richTextEditorFlg: true,
      invalidContent: false
    }
  },

  computed: {
    diffFlg() {
      return !_.isEqual(this.initialData, this.newMedicalContentTemplate)
    }
  },

  methods: {
    checkDiff() {
      if (this.diffFlg) {
        this.popup = {
          popupFlg: true,
          type: 'alert',
          title: '確認',
          buttons: ['キャンセル', '閉じる'],
          layerNumber: 3,
          content:
            '入力内容を保存せずに閉じようとしています。\nよろしいですか？'
        }
      } else {
        this.closePopup()
      }
    },
    resetPopup() {
      this.popup = {
        popupFlg: false,
        type: '',
        title: '',
        buttons: [],
        layerNumber: 3,
        content: ''
      }
    },
    closePopup() {
      this.popup.type !== 'failure' ? this.$emit('close') : this.resetPopup()
    },
    async create() {
      this.waitFlg = true
      const res = await this.$store.dispatch(
        'medicalContentTemplates/create',
        this.newMedicalContentTemplate
      )
      if (res === true) {
        this.initialData = _.cloneDeep(this.newMedicalContentTemplate)
        this.popup.type = 'success'
        this.popup.title = '完了'
        this.popup.content = '新規登録しました'
      } else {
        this.popup.type = 'failure'
        this.popup.title = '失敗'
        this.popup.content = '登録に失敗しました'
      }
      this.popup.buttons = ['閉じる']
      this.popup.popupFlg = true
      this.waitFlg = false
    },
    closeAlert() {
      this.alertFlg = false
      if (this.type === 'success') {
        this.$store.dispatch('petorelu/okLeave')
        this.$emit('close')
      }
    }
  }
}
</script>

<style lang="scss" scoped>
.medical-content-template-new-popup {
  .content {
    text-align: left;
    > .header {
      display: flex;
      > .title {
        font-size: 16px;
        font-weight: bold;
        color: #{$greyish-brown};
      }
    }
    > .input-form-wrapper {
      margin-top: 25px;
    }
  }
}
</style>
