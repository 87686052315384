import { getAxiosObject } from '@/utils/library'
import {
  commonGetters,
  commonActions,
  commonMutations
} from '@/store/store_utils'
import _ from 'lodash'
import { toStateName } from '@/utils/convert_string'

const dataName = 'anicomReports'
const keys = ['medicalPaymentId']

const state = {
  anicomReports: [],
  dataIndexedById: {},
  dataIndexedByMedicalPaymentId: {},
  updatedAt: '20101010101010'
}
keys.forEach(key => {
  state[toStateName(key)] = null
})

const getters = {
  ...commonGetters(
    dataName,
    ['getData', 'getDataById', 'getDataIncludeDel', 'getUpdatedAt'],
    { filterDel: true, getDataBy: keys, getDataByIncludeDel: keys }
  )
}

const actions = {
  ...commonActions(['setData', 'setUpdatedAt', 'updateAfter']),
  async create({ commit }, medicalPaymentIds) {
    try {
      const axiosObject = getAxiosObject()
      const res = await axiosObject.post('/anicom/anicom-reports', {
        medicalPaymentIds
      })
      commit('updateAfter', res.data.anicomReports)
      return { status: 200, anicomReports: res.data.anicomReports }
    } catch (err) {
      const message =
        err.response?.data?.message === 'no data'
          ? `no ${err.response.data.extra}`
          : ''
      return { status: err.response?.status ?? 500, message }
    }
  },
  async cancel({ commit }, anicomReportId) {
    try {
      const axiosObject = getAxiosObject()
      const res = await axiosObject.post('/anicom/anicom-reports/cancel', {
        anicomReportId
      })
      commit('updateAfter', [res.data.anicomReport])
      return 'success'
    } catch (err) {
      return err.response?.data?.message === 'no data'
        ? 'no data'
        : err.response?.data?.extra === 'limit over'
        ? 'limit over'
        : 'server error'
    }
  },
  async search({ commit }, { searchWord, startDate, endDate, page, order }) {
    try {
      const axiosObject = getAxiosObject()
      const res = await axiosObject.get('/anicom/anicom-reports/search', {
        params: { searchWord, startDate, endDate, page, order }
      })
      const stores = [
        'medicalPayments',
        'anicomReports',
        'medicalTreatmentItems',
        'payments',
        'medicalRecords',
        'hospitalizations',
        'anicomCIdChecks'
      ]
      stores.forEach(store => {
        commit(`${store}/updateAfter`, res.data[store], { root: true })
      })
      return {
        result: 'success',
        hitPartMedicalPayments: res.data.hitPartMedicalPayments,
        unsentHitAllMedicalPaymentsCount:
          res.data.unsentHitAllMedicalPaymentsCount,
        sentHitAllMedicalPaymentsCount: res.data.sentHitAllMedicalPaymentsCount,
        count: res.data.count
      }
    } catch (err) {
      return { result: 'failure' }
    }
  }
}

const mutations = {
  ...commonMutations(dataName, ['setData', 'setUpdatedAt', 'updateAfter'], {
    setDataBy: keys
  })
}

export default {
  namespaced: true,
  state,
  mutations,
  getters,
  actions
}
