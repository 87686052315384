import { getAxiosObject } from '@/utils/library'
import {
  commonGetters,
  commonActions,
  commonMutations
} from '@/store/store_utils'

const dataName = 'printSetting'

const state = {
  printSetting: {},
  updatedAt: '20101010101010'
}

const getters = {
  ...commonGetters(dataName, ['getData', 'getUpdatedAt'], {})
}

const actions = {
  ...commonActions(['setData', 'setUpdatedAt', 'updateAfter']),
  async update({ commit }, printSetting) {
    try {
      const axiosObject = getAxiosObject()
      const res = await axiosObject.put('/print-setting', { printSetting })
      if (res.status === 200) {
        commit('updateAfter', res.data.printSetting)
        return true
      }
    } catch (error) {
      return false
    }
  }
}

const mutations = {
  ...commonMutations(dataName, ['setData', 'updateAfter', 'setUpdatedAt'], {
    base64: true
  })
}

export default {
  namespaced: true,
  state,
  mutations,
  getters,
  actions
}
