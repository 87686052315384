import { getAxiosObject } from '@/utils/library'
import { returnLeftAlignMessageStaffWorkingDates } from '@/utils/check_reservable_days'
import {
  commonGetters,
  commonActions,
  commonMutations
} from '@/store/store_utils'
import { dataCsvToArray } from '@/utils/comma_string'

const dataName = 'staffWorkingDates'

const state = { staffWorkingDates: [], updatedAt: '20101010101010' }

const getters = {
  ...commonGetters(dataName, ['getData', 'getUpdatedAt'], { filterDel: true })
}

const actions = {
  ...commonActions(['setData', 'setUpdatedAt', 'updateAfter']),
  async update({ commit }, obj) {
    try {
      const axiosObject = getAxiosObject()
      const res = await axiosObject.post('/staff-working-dates', obj)
      commit('updateAfter', res.data.staffWorkingDates)
      commit('shiftSetting/updateAfter', res.data.shiftSetting, { root: true })
      const leftAlignMessage = returnLeftAlignMessageStaffWorkingDates()
      const staffWorkingDates = dataCsvToArray(res.data.staffWorkingDates, [
        'shiftIds'
      ])
      return {
        status: res.status,
        leftAlignMessage,
        staffWorkingDates
      }
    } catch (err) {
      return err.response?.data?.message && err.response?.data?.extra
        ? { status: err.response.status, extra: err.response?.data?.extra }
        : { status: 500, extra: 'server error' }
    }
  }
}

const mutations = {
  ...commonMutations(dataName, ['setData', 'setUpdatedAt', 'updateAfter'], {
    csvColumns: ['shiftIds']
  })
}

export default {
  namespaced: true,
  state,
  mutations,
  getters,
  actions
}
