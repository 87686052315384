<template>
  <div class="base-button-schema-header">
    <button
      :class="{ active: active }"
      type="button"
      @click="$emit('click', id)"
      :disabled="disabled"
      :style="styles"
      data-e2e="base-button-schema-header"
    >
      <font-awesome-icon
        v-if="icon !== ''"
        class="icon"
        :icon="icon"
        data-test="icon"
      />
      <div
        :class="{ text: true, 'active-text': active }"
        v-else
        data-test="text"
      >
        {{ text }}
      </div>
    </button>
  </div>
</template>

<script>
import { FontAwesomeIcon } from '@fortawesome/vue-fontawesome'
import { faUndo, faRedo } from '@fortawesome/free-solid-svg-icons'

export default {
  name: 'BaseButtonSchemaHeader',

  components: {
    FontAwesomeIcon
  },

  props: {
    id: { type: Number, default: 0 },
    text: { type: String, default: '' },
    disabled: { type: Boolean, default: false },
    styles: {
      type: Object,
      validator: function(obj) {
        return Object.values(obj).every(v => typeof v === 'string')
      }
    },
    active: { type: Boolean, default: false }
  },

  computed: {
    icon() {
      return this.text === 'undo' ? faUndo : this.text === 'redo' ? faRedo : ''
    }
  }
}
</script>

<style lang="scss" scoped>
.base-button-schema-header {
  > button {
    width: 70px;
    height: 34px;
    border-radius: 6px;
    border: solid 1px #{$pumpkin};
    cursor: pointer;
    background-color: #{$white};
    &:hover {
      opacity: 0.8;
    }
    &:active {
      margin: 1px auto 0 auto;
      height: 33px;
    }
    > .icon {
      color: #{$pumpkin};
    }
    > .text {
      color: #{$pumpkin};
    }
  }
  > button:disabled {
    background-color: #{$white};
    border: solid 1px #{$light-grey};
    cursor: default;
    > .icon {
      color: #{$light-grey};
    }
    > .text {
      color: #{$light-grey};
    }
    &:hover {
      opacity: 1;
    }
    &:active {
      margin: 0 auto 0 auto;
      height: 34px;
    }
  }
  > .active {
    background-color: #{$pumpkin};
    > .active-text {
      color: #{$white};
    }
  }
}
</style>
