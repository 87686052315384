<template>
  <div class="apply-registered">
    <img
      src="@/assets/images/login_logo.png"
      width="315px"
      height="54px"
      alt="login-logo"
    />
    <base-check-mark />
    <h2>お申込みありがとうございました。</h2>
    <div class="message">
      確認メールを医院用に設定されたメールアドレスに送信しましたのでご確認ください。<br />
      <a href="https://www.plus-petorelu.jp/">ホームページへ</a>
    </div>
  </div>
</template>

<script>
import BaseCheckMark from '@/components/parts/atoms/BaseCheckMark.vue'
import PreventBack from '@/components/mixins/PreventBack'
export default {
  name: 'ApplyRegistered',
  components: {
    BaseCheckMark
  },
  mixins: [PreventBack]
}
</script>

<style lang="scss" scoped>
.apply-registered {
  img {
    margin: 20px 0;
  }
  > .message {
    > a {
      color: blue;
      text-decoration: underline;
      @include hover();
    }
  }
}
</style>
