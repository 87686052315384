const state = {
  token: '',
  clinics: [],
  noticeCategories: [],
  loginHistories: {},
  viewOnlyFlg: true
}

const getters = {
  token(state) {
    return state.token
  },
  clinics(state) {
    return state.clinics
  },
  noticeCategories(state) {
    return state.noticeCategories
  },
  loginHistories(state) {
    return state.loginHistories
  },
  viewOnlyFlg(state) {
    return state.viewOnlyFlg
  }
}

const actions = {
  login({ commit }, obj) {
    commit('login', obj)
  },
  updateClinic({ commit }, clinic) {
    commit('updateClinic', clinic)
  }
}

const mutations = {
  login(state, obj) {
    state.token = obj.token
    state.clinics = obj.clinics
    state.noticeCategories = obj.noticeCategories
    state.loginHistories = {}
    obj.loginHistories.forEach(lh => {
      state.loginHistories[lh.clinicId] = lh
    })
    state.viewOnlyFlg = obj.viewOnlyFlg
  },
  updateClinic(state, clinic) {
    const index = state.clinics.findIndex(c => c.id === clinic.id)
    state.clinics[index] = clinic
  }
}

export default {
  namespaced: true,
  state,
  mutations,
  getters,
  actions
}
