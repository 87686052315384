import {
  commonGetters,
  commonActions,
  commonMutations
} from '@/store/store_utils'
import { toStateName } from '@/utils/convert_string'

const dataName = 'medicalContentImageTexts'
const keys = ['medicalContentImageId']

const state = {
  medicalContentImageTexts: [],
  updatedAt: '20101010101010'
}
keys.forEach(key => {
  state[toStateName(key)] = null
})

const getters = {
  ...commonGetters(dataName, ['getUpdatedAt'], {
    filterDel: true,
    getDataBy: keys
  })
}

const actions = {
  ...commonActions(['setData', 'setUpdatedAt', 'updateAfter'])
}

const mutations = {
  ...commonMutations(dataName, ['setData', 'setUpdatedAt', 'updateAfter'], {
    setDataBy: keys
  })
}

export default {
  namespaced: true,
  state,
  mutations,
  getters,
  actions
}
