<template>
  <div class="vaccine-check-list">
    <div class="list left">
      <vaccine-check-list-item
        class="item"
        v-for="vaccine in leftVaccines"
        :key="vaccine.id"
        :checkedId="checkedId"
        :vaccine="vaccine"
        @check="checkVaccine"
      ></vaccine-check-list-item>
    </div>
    <div class="list right">
      <vaccine-check-list-item
        class="item"
        v-for="vaccine in rightVaccines"
        :key="vaccine.id"
        :checkedId="checkedId"
        :vaccine="vaccine"
        @check="checkVaccine"
      ></vaccine-check-list-item>
    </div>
  </div>
</template>

<script>
import VaccineCheckListItem from '@/components/parts/atoms/VaccineCheckListItem'
import { mapGetters } from 'vuex'

export default {
  name: 'VaccineCheckList',

  components: { VaccineCheckListItem },

  props: {
    checkedId: { type: Number, default: 0 },
    vaccineTypeId: { type: [String, Number], default: 0 }
  },

  data() {
    return {
      dogVaccinesIds: {
        left: [999, 6, 7, 8, 9, 10, 11, 12, 14],
        right: [15, 16, 17, 1, 2, 3, 4, 5, 13]
      },
      catVaccinesIds: {
        left: [104, 105, 106, 107, 108, 109, 111],
        right: [101, 102, 103, 110]
      }
    }
  },

  computed: {
    ...mapGetters({
      getMasterDatum: 'master/getDataById'
    }),
    leftVaccines() {
      return this.makeVaccines('left')
    },
    rightVaccines() {
      return this.makeVaccines('right')
    }
  },

  methods: {
    makeVaccines(key) {
      const vaccineIds =
        this.vaccineTypeId === 1
          ? this.dogVaccinesIds[key]
          : this.vaccineTypeId === 2
          ? this.catVaccinesIds[key]
          : []
      return vaccineIds.map(id => this.getMasterDatum('vaccines', id))
    },
    checkVaccine(id) {
      this.$emit('check', id)
    }
  }
}
</script>

<style lang="scss" scoped>
.vaccine-check-list {
  display: flex;
  > .list {
    > .item {
      margin-bottom: 10px;
    }
  }
  > .left + .right {
    margin-left: 20px;
  }
}
</style>
