import { getAxiosObject } from '@/utils/library'
import {
  commonGetters,
  commonMutations,
  commonActions
} from '@/store/store_utils'
import { toStateName } from '@/utils/convert_string'

const dataName = 'treatmentClasses'
const keys = ['treatmentLargeClassOriginalId']

const state = {
  treatmentClasses: [],
  dataIndexedById: {},
  dataIndexedByOriginalId: {},
  updatedAt: '20101010101010'
}
keys.forEach(key => {
  state[toStateName(key)] = null
})

const getters = {
  ...commonGetters(
    dataName,
    ['getData', 'getDataById', 'getDataByOriginalId', 'getUpdatedAt'],
    { filterDel: true, getDataBy: keys }
  )
}

const actions = {
  ...commonActions(['setData', 'setUpdatedAt', 'updateAfter']),
  async delete({ commit }, id) {
    try {
      const axiosObject = getAxiosObject()
      const res = await axiosObject.delete('/treatment-classes', {
        data: { id }
      })
      if (res.status === 200) {
        commit('updateAfter', [res.data.treatmentClass])
        commit('treatments/updateAfter', res.data.treatments, {
          root: true
        })
        if (res.data.examinationFormats) {
          commit(
            'examinationFormats/updateAfter',
            res.data.examinationFormats,
            { root: true }
          )
          commit(
            'examinationTopicClasses/updateAfter',
            res.data.examinationTopicClasses,
            { root: true }
          )
          commit('examinationTopics/updateAfter', res.data.examinationTopics, {
            root: true
          })
          commit(
            'examinationSpecies/updateAfter',
            res.data.examinationSpecies,
            { root: true }
          )
        }
        return true
      }
    } catch (error) {
      if (error.response?.data?.extra) {
        if (error.response.data.extra.data) {
          commit('setData', error.response.data.extra.data)
        } else {
          return error.response.data.extra
        }
        return error.response.data.message
      } else {
        return 'server error'
      }
    }
  },
  async update({ commit }, treatmentClass) {
    try {
      const axiosObject = getAxiosObject()
      const res = await axiosObject.put('/treatment-classes', {
        treatmentClass
      })
      if (res.status === 200) {
        if (Object.keys(res.data.treatmentLargeClass).length > 0) {
          commit(
            'treatmentLargeClasses/updateAfter',
            [res.data.treatmentLargeClass],
            { root: true }
          )
        }
        commit('updateAfter', res.data.treatmentClasses)
        commit('treatments/updateAfter', res.data.treatments, { root: true })
        return true
      }
    } catch (error) {
      if (error.response?.data?.message) {
        if (error.response.data.message === 'no data in clinic') {
          commit('setData', error.response.data.extra.data)
          return 'no data in clinic'
        }
        if (error.response.data.message === 'no data') {
          return error.response.data.extra === 'treatmentClass already edited'
            ? 'already edited'
            : 'already deleted'
        } else {
          return error.response.data.message
        }
      } else {
        return 'server error'
      }
    }
  }
}

const mutations = {
  ...commonMutations(dataName, ['setData', 'setUpdatedAt', 'updateAfter'], {
    order: true,
    indexedById: true,
    indexedByOriginalId: true,
    setDataBy: keys
  })
}

export default {
  namespaced: true,
  state,
  getters,
  actions,
  mutations
}
