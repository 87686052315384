import moment from 'moment'

export const makeBirthday = patient => {
  return patient.birthday.length === 8
    ? moment(patient.birthday).format('YYYY.MM.DD')
    : patient.birthday.length === 6
    ? moment(patient.birthday).format('YYYY.MM')
    : patient.birthday.length === 4
    ? moment(patient.birthday).format('YYYY')
    : ''
}

export const makeBirthdayJp = patient => {
  return patient.birthday.length === 8
    ? moment(patient.birthday).format('Y年M月D日')
    : patient.birthday.length === 6
    ? moment(patient.birthday).format('Y年M月')
    : patient.birthday.length === 4
    ? moment(patient.birthday).format('Y年')
    : ''
}

export const makeBirthAge = patient => {
  const birthdayText =
    patient.birthday.length === 8
      ? moment(patient.birthday).format('YYYY.MM.DD')
      : patient.birthday.length === 6
      ? moment(patient.birthday).format('YYYY.MM')
      : patient.birthday.length === 4
      ? moment(patient.birthday).format('YYYY')
      : ''
  if (patient.deathFlg === 1) {
    if (patient.birthday && patient.deathDate) {
      return calcAge(patient) + '(死亡)' + '/' + birthdayText
    } else if (patient.deathDate) {
      return '死亡/生年月日未設定'
    }
  } else {
    return patient.birthday ? calcAge(patient) + '/' + birthdayText : ''
  }
}

export const calcAge = patient => {
  const latestDate = patient.deathDate ? moment(patient.deathDate) : moment()
  if (patient.birthday.length === 8 || patient.birthday.length === 6) {
    const y =
      latestDate.diff(moment(patient.birthday), 'years') > 0
        ? latestDate.diff(moment(patient.birthday), 'years')
        : 0
    const m =
      latestDate.diff(moment(patient.birthday), 'month') % 12 > 0
        ? latestDate.diff(moment(patient.birthday), 'month') % 12
        : 0
    return patient.birthday.length === 8
      ? y + '歳' + m + 'ヶ月'
      : '推定' + y + '歳' + m + 'ヶ月'
  } else if (patient.birthday.length === 4) {
    const y = latestDate.year() - moment(patient.birthday).year()
    return y >= 0 ? '推定' + y + '歳' : '推定0歳'
  } else {
    return ''
  }
}
