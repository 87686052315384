const compareShowId = (aShowId, bShowId) => {
  const showIdA = aShowId.split('-')
  const showIdB = bShowId.split('-')
  const longerLength =
    showIdA.length >= showIdB.length ? showIdA.length : showIdB.length
  let result = 0
  for (let i = 0; i < longerLength; i++) {
    const numShowIdA = Number(showIdA[i])
    const numShowIdB = Number(showIdB[i])
    if (
      typeof showIdA[i] === 'undefined' ||
      numShowIdA < numShowIdB ||
      (numShowIdA === numShowIdB && showIdA[i].length < showIdB[i].length)
    ) {
      result = -1
      break
    } else if (
      typeof showIdB[i] === 'undefined' ||
      numShowIdA > numShowIdB ||
      (numShowIdA === numShowIdB && showIdA[i].length > showIdB[i].length)
    ) {
      result = 1
      break
    }
  }
  return result
}

module.exports = {
  compareShowId
}
