import { getAxiosObject } from '@/utils/library'
import {
  commonGetters,
  commonMutations,
  commonActions
} from '@/store/store_utils'
import { toStateName } from '@/utils/convert_string'

const dataName = 'vaccineReminders'
const keys = ['patientId']
const apiUrl = '/vaccine-reminders'

const state = {
  vaccineReminders: [],
  dataIndexedById: {},
  updatedAt: '20101010101010'
}
keys.forEach(key => {
  state[toStateName(key)] = null
})

const getters = {
  ...commonGetters(dataName, ['getDataById', 'getUpdatedAt'], {
    filterDel: true,
    getDataBy: keys
  })
}

const actions = {
  ...commonActions(['setData', 'setUpdatedAt', 'updateAfter']),
  async create({ commit }, vaccineReminder) {
    try {
      const axiosObject = getAxiosObject()
      const res = await axiosObject.post(apiUrl, { vaccineReminder })
      if (res.status === 200) {
        commit('updateAfter', [res.data.vaccineReminder])
        return true
      }
    } catch (error) {
      if (error.response?.data?.message) {
        return error.response.data.message === 'no data'
          ? `no ${error.response.data.extra}`
          : error.response.data.message
      } else {
        return 'server error'
      }
    }
  },
  async bulkCreateMail({ commit }, { reminderVaccinationSets, mail }) {
    try {
      const axiosObject = getAxiosObject()
      const res = await axiosObject.post(`${apiUrl}/bulk-mail`, {
        reminderVaccinationSets,
        mail
      })
      if (res.status === 200) {
        commit('sendMails/updateAfter', [res.data.sendMail], { root: true })
        commit('updateAfter', res.data.vaccineReminders)
        return { result: true, errorPatientIds: res.data.errorPatientIds }
      }
    } catch (error) {
      return error.response?.data?.message || 'server error'
    }
  },
  async bulkCreateLetter({ commit }, vaccineReminders) {
    try {
      const axiosObject = getAxiosObject()
      const res = await axiosObject.post(`${apiUrl}/bulk-letter`, {
        vaccineReminders
      })
      if (res.status === 200) {
        if (res.data.vaccineReminders.length > 0) {
          commit('updateAfter', res.data.vaccineReminders)
        }
        return {
          result: true,
          vaccineReminders: res.data.vaccineReminders,
          errorPatientIds: res.data.errorPatientIds
        }
      }
    } catch (error) {
      return error.response?.data?.message || 'server error'
    }
  },
  async delete({ commit }, id) {
    try {
      const axiosObject = getAxiosObject()
      const res = await axiosObject.delete(apiUrl, { data: { id } })
      if (res.status === 200) {
        commit('updateAfter', [res.data.vaccineReminder])
        return true
      }
    } catch (error) {
      if (error.response?.data?.message) {
        if (error.response.data.extra?.data) {
          commit('setData', error.response.data.extra.data)
        }
        return error.response.data.message
      } else {
        return 'server error'
      }
    }
  },
  async update({ commit }, vaccineReminder) {
    try {
      const axiosObject = getAxiosObject()
      const res = await axiosObject.put(apiUrl, { vaccineReminder })
      if (res.status === 200) {
        commit('updateAfter', [res.data.vaccineReminder])
        return true
      }
    } catch (error) {
      if (error.response?.data?.message) {
        if (error.response.data.message === 'no data') {
          return `no ${error.response.data.extra}`
        } else {
          if (error.response.data.extra?.data) {
            commit('setData', error.response.data.extra.data)
          }
          return error.response.data.message
        }
      } else {
        return 'server error'
      }
    }
  }
}

const mutations = {
  ...commonMutations(dataName, ['setData', 'setUpdatedAt', 'updateAfter'], {
    setDataBy: keys,
    indexedById: true,
    csvColumns: ['vaccineIds']
  })
}

export default {
  namespaced: true,
  state,
  getters,
  actions,
  mutations
}
