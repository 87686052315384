<template>
  <div class="hint-balloon">
    <span class="circle" @click="showBalloon = true" data-test="circle">?</span>
    <div
      class="balloon"
      v-if="showBalloon"
      @click="showBalloon = false"
      data-test="balloon"
    >
      <div class="text">{{ note }}</div>
    </div>
    <div
      class="cover"
      v-if="showBalloon"
      @click="showBalloon = false"
      data-test="cover"
    ></div>
  </div>
</template>

<script>
export default {
  name: 'HintBalloon',
  props: { note: { type: String } },
  data() {
    return {
      showBalloon: false
    }
  }
}
</script>

<style lang="scss" scoped>
.hint-balloon {
  position: relative;
  width: 20px;
  height: 20px;
  margin-left: 5px;
  text-align: center;
  white-space: pre-line;
  > .circle {
    position: absolute;
    top: 0;
    left: 0;
    font-size: 13px;
    width: 20px;
    height: 20px;
    background-color: #{$pumpkin};
    color: #{$white};
    border-radius: 50%;
    caret-color: transparent;
    user-select: none;
    @include hover();
  }
  > .balloon {
    top: 40px;
    left: -30px;
    padding: 10px;
    background: #{$white};
    border: 1px solid #{$light-grey};
    box-shadow: 0px 0px 1px;
    border-radius: 5px;
    z-index: 1100;
    position: absolute;
    > .text {
      text-align: left;
      font-weight: normal;
      font-size: 15px;
      white-space: pre;
    }
  }
  > .balloon:after,
  .balloon:before {
    bottom: 100%;
    left: 40px;
    border: solid transparent;
    content: ' ';
    height: 0;
    width: 0;
    position: absolute;
    pointer-events: none;
  }
  > .balloon:after {
    border-color: rgba(255, 255, 255, 0);
    border-bottom-color: #{$white};
    border-width: 15px;
    margin-left: -15px;
  }
  > .balloon:before {
    border-color: rgba(206, 206, 206, 0);
    border-bottom-color: #{$light-grey};
    border-width: 16px;
    margin-left: -16px;
  }
  > .cover {
    position: fixed;
    top: 0;
    left: 0;
    width: 100vw;
    height: 100vh;
    opacity: 1;
    z-index: 1000;
  }
}
</style>
