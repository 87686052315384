import { mmToPt, makePageSize } from '@/utils/print_utils'
import { createPdf } from 'pdfmake/build/pdfmake.min.js'
import '@/utils/vfs_fonts'
import _ from 'lodash'

const makeOwnerPatientTable = (owner, patient) => {
  const ownerAddress =
    owner.prefectureName + owner.address + '\n' + owner.building
  let tableBody = [
    [
      { text: '飼主：' },
      { text: `${owner.lastName} ${owner.firstName}様`, colSpan: 3 },
      {},
      {}
    ],
    [{ text: '住所：' }, { text: ownerAddress, colSpan: 3 }, {}, {}],
    [{ text: '患者：' }, { text: `${patient.name}様`, colSpan: 3 }, {}, {}],
    [
      { text: '種別：' },
      { text: `${patient.speciesName} ${patient.breed || ''}` },
      { text: '性別：' },
      { text: patient.sexName }
    ]
  ]
  const birthdayRow = [
    { text: '生年月日：' },
    { text: patient.displayBirthday },
    { text: '年齢：' },
    { text: patient.age }
  ]
  return [
    {
      layout: 'noBorder',
      table: {
        body: tableBody,
        widths: [mmToPt(15), mmToPt(85), mmToPt(15), mmToPt(30)] // 155 - 10 max
      }
    },
    {
      layout: 'noBorder',
      table: {
        body: [birthdayRow],
        widths: [mmToPt(25), mmToPt(75), mmToPt(15), mmToPt(30)]
      }
    }
  ]
}

const makeTopTable = (owner, patient) => {
  const ownerPatientTable = makeOwnerPatientTable(owner, patient)
  const patientImage =
    patient.image === 'preview'
      ? [
          {
            canvas: [
              {
                type: 'rect',
                x: 0,
                y: 0,
                h: 95,
                w: 95,
                lineWidth: 0.5,
                lineColor: '#c6c6c6'
              }
            ],
            margin: [3, 12, 0, 0]
          },
          {
            relativePosition: { x: -95 / 2 + 42, y: -95 / 2 - 15 },
            stack: [{ text: '患者画像\nプレビュー', alignment: 'center' }]
          }
        ]
      : patient.image
      ? [
          {
            image: patient.image,
            fit: [95, 95],
            margin: [3, 12, 0, 0],
            alignment: 'center'
          }
        ]
      : [{}]
  return {
    layout: 'topTable',
    table: {
      body: [[ownerPatientTable, patientImage]],
      widths: [mmToPt(155), mmToPt(40)]
    }
  }
}

const makeVaccinationTable = vaccineData => {
  const mainTable = {
    layout: 'noBorderNoPadding',
    table: {
      body: [
        [{ text: '接種したワクチン：' }, { text: vaccineData.name }],
        [{ text: '接種日：' }, { text: vaccineData.date }],
        [{ text: '次回の接種：' }, { text: vaccineData.nextDate }]
      ],
      widths: [105, '*']
    }
  }
  const memoTable = {
    layout: 'noBorderNoPadding',
    table: {
      body: [
        [
          { text: '備考：', fontSize: 9 },
          { text: vaccineData.memo, fontSize: 9, maxHeight: 120 }
        ]
      ],
      widths: [36, '*']
    },
    heights: [120],
    margin: [0, -5, 0, 0]
  }
  if (vaccineData.lotNo.length > 16) {
    memoTable.table.body.push([
      { text: 'Lot No.:', fontSize: 9 },
      { text: vaccineData.lotNo, fontSize: 9, maxHeight: 120 }
    ])
  }

  return {
    layout: 'vaccinationTable',
    table: {
      body: [[mainTable], [memoTable]],
      widths: ['*'],
      heights: ['*', 120]
    }
  }
}

const makeSealTable = vaccineData => {
  const sealRect = {
    canvas: [
      {
        type: 'rect',
        x: 0,
        y: 0,
        w: mmToPt(40),
        h: mmToPt(20),
        lineWidth: 0.5,
        lineColor: '#3e3e3e',
        color: 'white'
      }
    ]
  }
  const tableBody = [
    [
      [
        sealRect,
        {
          relativePosition: { x: mmToPt(40) / 2 - 37, y: -mmToPt(20) / 2 - 9 },
          stack: [{ text: 'シール添付欄' }]
        }
      ],
      [
        _.cloneDeep(sealRect),
        {
          relativePosition: { x: 5, y: -45 },
          stack: [
            {
              text: `Lot No. ${
                vaccineData.lotNo.length > 16 ? '備考欄参照' : vaccineData.lotNo
              }`
            },
            { text: 'No.' }
          ]
        }
      ]
    ]
  ]
  return {
    layout: 'sealTable',
    table: { body: tableBody }
  }
}

const makeClinicTable = ({
  printOptions,
  clinic,
  clinicImage,
  clinicStamp,
  vaccineData
}) => {
  const clinicName = printOptions.showClinicName ? clinic.name : ''
  const headerRow = clinicImage
    ? [
        {
          image: clinicImage,
          fit: printOptions.showClinicName ? [50, 50] : [150, 50],
          height: 'auto',
          width: 'auto',
          alignment: 'center'
        },
        { text: clinicName, fontSize: 12 }
      ]
    : [{ text: clinicName, fontSize: 12, colSpan: 2 }, {}]
  const headerTable = {
    layout: 'noBorderNoPadding',
    table: {
      body: [headerRow],
      widths: ['*', 'auto']
    },
    margin: [0, 5, 0, 0]
  }

  let tableBody = []
  if (clinic.tel) {
    tableBody.push([{ text: `TEL ${clinic.tel}`, fontSize: 9 }])
  }
  if (clinic.prefectureName || clinic.address) {
    tableBody.push([
      { text: clinic.prefectureName + clinic.address, fontSize: 9 }
    ])
  }
  if (clinic.building) {
    tableBody.push([{ text: clinic.building, fontSize: 9 }])
  }
  tableBody.push([{ text: `担当者： ${vaccineData.staffName}`, fontSize: 9 }])

  const signatureMark = clinicStamp
    ? [
        {
          absolutePosition: { x: mmToPt(186.5), y: mmToPt(124) },
          image: clinicStamp,
          maxWidth: mmToPt(15),
          maxHeight: mmToPt(15)
        }
      ]
    : [
        {
          absolutePosition: { x: mmToPt(186.5), y: mmToPt(124) },
          canvas: [
            {
              type: 'rect',
              x: 0,
              y: 0,
              w: mmToPt(15),
              h: mmToPt(15),
              lineWidth: 0.5,
              lineColor: '#c6c6c6'
            }
          ],
          margin: [0, -5, 0, 0]
        },
        {
          absolutePosition: { x: mmToPt(192), y: mmToPt(129) },
          stack: [{ text: '印' }]
        }
      ]

  return {
    stack: [
      headerTable,
      {
        layout: 'noBorderNoPadding',
        table: { body: tableBody, widths: [mmToPt(68)] }
      },
      signatureMark
    ]
  }
}

const makeBottomTable = ({
  printOptions,
  clinic,
  clinicImage,
  clinicStamp,
  vaccineData
}) => {
  const vaccinationTable = makeVaccinationTable(vaccineData)
  const sealTable = makeSealTable(vaccineData)
  const clinicTable = makeClinicTable({
    printOptions,
    clinic,
    clinicImage,
    clinicStamp,
    vaccineData
  })
  return {
    layout: 'bottomTable',
    table: {
      body: [[vaccinationTable, [sealTable, clinicTable]]],
      widths: [mmToPt(110) - 10, mmToPt(85)]
    }
  }
}

export const makeDocDefinition = ({
  printOptions,
  clinic,
  clinicImage,
  clinicStamp,
  owner,
  patient,
  vaccineData // 予防接種履歴のテーブルから証明書を印刷する場合は配列
}) => {
  let content = []
  if (Array.isArray(vaccineData) === false) {
    vaccineData = [vaccineData]
  }
  for (let i = 0; i < vaccineData.length; i++) {
    let page = [
      {
        text: '予防接種証明書',
        fontSize: 20,
        alignment: 'center',
        margin: [0, -3, 0, 5]
      },
      makeTopTable(owner, patient),
      {
        text: '下記のワクチンを接種したことを証明いたします。',
        fontSize: 14,
        margin: [0, 10, 0, 5]
      },
      makeBottomTable({
        printOptions,
        clinic,
        clinicImage,
        clinicStamp,
        vaccineData: vaccineData[i]
      })
    ]
    if (i + 1 < vaccineData.length) {
      page.push({ pageBreak: 'after', columns: [] })
    }
    content.push(page)
  }

  return {
    content,
    styles: {},
    defaultStyle: {
      font: 'NotoSansMono',
      fontSize: 11,
      color: '#3e3e3e',
      preserveLeadingSpaces: true
    },
    pageSize: makePageSize(printOptions.paperSize),
    pageMargins: [mmToPt(7), mmToPt(7)],
    pageOrientation: printOptions.paperSize === 'A4' ? 'portrait' : 'landscape'
  }
}

// args = {
//   printOptions,
//   clinic,
//   clinicImage,
//   clinicStamp,
//   vaccineData
// }
export const printVaccinationCertificate = args => {
  const docDefinition = makeDocDefinition(args)
  const fonts = {
    NotoSansMono: {
      normal: 'NotoSansMonoCJKJPRegular.otf',
      bold: 'NotoSansMonoCJKJPRegular.otf',
      italics: 'NotoSansMonoCJKJPRegular.otf',
      bolditalics: 'NotoSansMonoCJKJPRegular.otf'
    }
  }
  const tableLayouts = {
    noBorder: {
      defaultBorder: false
    },
    noBorderNoPadding: {
      defaultBorder: false,
      paddingRight: () => 0,
      paddingLeft: () => 0,
      paddingTop: () => 0,
      paddingBottom: () => 0
    },
    topTable: {
      hLineWidth: (i, node) => {
        return i === 0 || i === node.table.body.length ? 0.5 : 0
      },
      vLineWidth: i => (i === 1 ? 0 : 0.5),
      paddingRight: () => 0,
      paddingLeft: () => 0
    },
    bottomTable: {
      defaultBorder: false,
      paddingRight: i => (i === 0 ? 10 : 0),
      paddingLeft: () => 0
    },
    vaccinationTable: {
      hLineWidth: (i, node) => {
        return i === 0 || i === node.table.body.length ? 0.5 : 0
      },
      vLineWidth: () => 0.5
    },
    sealTable: {
      defaultBorder: false,
      paddingRight: i => (i === 0 ? 10 : 0),
      paddingLeft: () => 0,
      paddingTop: () => 0,
      paddingBottom: () => 0
    }
  }
  createPdf(docDefinition, tableLayouts, fonts).open()
}
