<template>
  <focus-trap
    v-model="activeTrap"
    :escapeDeactivates="false"
    :clickDeactivates="false"
  >
    <div class="karte-lock-popup" @keyup.esc.stop="close" tabindex="-1">
      <announce-popup
        v-if="showFlg"
        :title="'ロック解除確認'"
        :type="'alert'"
        :buttons="['キャンセル', '続行']"
        @cancel="close"
        @decision="lock"
      >
        {{ message }}
      </announce-popup>
    </div>
  </focus-trap>
</template>

<script>
import AnnouncePopup from '@/components/popups/AnnouncePopup'
import { FocusTrap } from 'focus-trap-vue'

export default {
  name: 'KarteLockPopup',
  components: {
    AnnouncePopup,
    FocusTrap
  },
  data() {
    return { activeTrap: false }
  },
  computed: {
    showFlg() {
      return this.$store.getters['petorelu/get'].showKarteLockPopupFlg
    },
    nextFunction() {
      return this.$store.getters['petorelu/get'].nextFunction
    },
    isTryingDelete() {
      return this.$store.getters['petorelu/get'].isTryingDelete
    },
    message() {
      return this.isTryingDelete
        ? '他の端末でカルテあるいは会計が編集中です。\n他の端末の編集を強制的に終わらせて操作しますか？'
        : '他の端末で編集中の画面に移動しようとしています。\n他の端末の編集を強制的に終わらせて移動しますか？'
    }
  },
  watch: {
    showFlg: function() {
      this.activeTrap = this.showFlg
    }
  },
  methods: {
    close() {
      this.$store.dispatch('petorelu/hideKarteLockPopup')
    },
    lock() {
      const {
        tryPatientId: patientId,
        tryMedicalRecordOriginalId: medicalRecordOriginalId,
        tryHospitalizationId: hospitalizationId
      } = this.$store.getters['petorelu/get']
      const tryParams = {
        patientId,
        medicalRecordOriginalId,
        hospitalizationId
      }
      if (this.isTryingDelete) {
        this.$store.dispatch('auth/flashLockKarte', tryParams)
      } else {
        this.$store.dispatch('auth/lockKarte', tryParams)
      }
      this.close()
      this.nextFunction()
    }
  }
}
</script>
<style lang="scss" scoped></style>
