<template>
  <div class="notice-input-form">
    <div class="forms">
      <select-box-form
        class="form"
        :selectData="noticeCategoriesExcludedVaccine"
        :styles="boxStyles"
        v-model="notice.noticeCategoryId"
        >カテゴリ</select-box-form
      >
      <select-box-form
        class="form"
        :selectData="sendTypes"
        :styles="boxStyles"
        :value="selectedSendType"
        @input="inputSelectedSendType"
        >送信先</select-box-form
      >
      <div class="check-boxes" v-if="selectedSendType === 2">
        <div class="left-space" />
        <div class="check-box-area">
          <div class="bulk-check">
            <div class="row">
              <div class="field">
                <base-check-box :isChecked="bulkCheckFlg" @input="bulkCheck" />
              </div>
              <div class="label">
                {{ bulkCheckFlg ? '一括解除' : '一括チェック' }}
              </div>
            </div>
          </div>
          <div class="clinic-check-boxes">
            <div class="row" v-for="clinic in clinicData" :key="clinic.id">
              <div class="field">
                <base-check-box
                  :isChecked="clinic.checkedFlg"
                  v-model="clinic.checkedFlg"
                />
              </div>
              <div class="label">{{ clinic.name }}</div>
            </div>
          </div>
        </div>
      </div>
      <text-input-form
        class="form"
        :requiredFlg="true"
        :maxlength="50"
        :placeholder="'50文字まで'"
        v-model="notice.title"
        >件名</text-input-form
      >
      <multiple-text-input-form
        class="form"
        :requiredFlg="true"
        v-model="notice.message"
        >内容</multiple-text-input-form
      >
    </div>
  </div>
</template>

<script>
import SelectBoxForm from '@/components/parts/molecules/SelectBoxForm'
import TextInputForm from '@/components/parts/molecules/TextInputForm'
import BaseCheckBox from '@/components/parts/atoms/BaseCheckBox'
import MultipleTextInputForm from '@/components/parts/molecules/MultipleTextInputForm'
import { mapGetters } from 'vuex'

export default {
  name: 'NoticeInputForm',
  components: {
    SelectBoxForm,
    TextInputForm,
    BaseCheckBox,
    MultipleTextInputForm
  },
  props: {
    notice: { type: Object },
    selectedSendType: { type: Number },
    clinicData: { type: Array },
    bulkCheckFlg: { type: Boolean }
  },
  data() {
    return {
      sendTypes: [
        { id: 1, name: '全員に送信' },
        { id: 2, name: '送信する医院を選択' }
      ],
      boxStyles: { height: '33px', width: '202px' }
    }
  },
  computed: {
    ...mapGetters({
      noticeCategories: 'pyxosManage/noticeCategories'
    }),
    noticeCategoriesExcludedVaccine() {
      return this.noticeCategories.filter(v => v.id !== 4)
    }
  },
  methods: {
    inputSelectedSendType(selectedSendType) {
      this.$emit('input-selected-send-type', selectedSendType)
    },
    bulkCheck(bulkCheckFlg) {
      this.$emit('bulk-check', bulkCheckFlg)
    }
  }
}
</script>

<style lang="scss" scoped>
.notice-input-form {
  > .forms {
    text-align: left;
    > .form {
      width: 600px;
      margin-bottom: 20px;
    }
    > .check-boxes {
      display: flex;
      margin-bottom: 20px;
      > .left-space {
        width: 240px;
      }
      > .check-box-area {
        width: 700px;
        > .bulk-check {
          display: flex;
          margin-bottom: 20px;
          > .row {
            display: flex;
            align-items: center;
            > .label {
              font-size: 15px;
              margin-left: 10px;
            }
          }
        }
        > .clinic-check-boxes {
          max-height: 400px;
          overflow: auto;
          display: flex;
          flex-wrap: wrap;
          > .row {
            width: 50%;
            box-sizing: border-box;
            margin-bottom: 10px;
            display: flex;
            align-items: center;
            > .label {
              font-size: 15px;
              margin-left: 10px;
            }
          }
        }
      }
    }
  }
}
</style>
