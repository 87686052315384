<template>
  <div class="password-reset-send-after">
    <main-header :logined-flg="false" />
    <div class="page-title">
      パスワードの再設定
    </div>
    <div class="password-reset-progress-bar">
      <div class="progress-bar-list-text orange">
        メールアドレス入力
      </div>
      <div class="arrow orange"></div>
      <div class="progress-bar-list-text orange">
        本人確認
      </div>
      <div class="arrow"></div>
      <div class="progress-bar-list-text">
        パスワード設定
      </div>
      <div class="arrow"></div>
      <div class="progress-bar-list-text">
        完了
      </div>
    </div>
    <div class="message">
      <div class="message-p1">
        登録されたメールアドレスへ本人確認用のメールを送信しました。
      </div>
      <div class="message-p2">
        メールに記載されたリンクからパスワード設定ページに移動してください。
      </div>
      <div class="message-p3">
        メールが届かない場合は、迷惑メールフォルダー等もご確認ください。
      </div>
    </div>
  </div>
</template>

<script>
import MainHeader from '@/components/parts/organisms/MainHeader'
export default {
  name: 'PasswordResetSendAfter',

  components: {
    MainHeader
  }
}
</script>

<style lang="scss" scoped>
.password-reset-send-after {
  > .page-title {
    margin-top: 67px;
    font-size: 30px;
    font-weight: bold;
  }
  > .password-reset-progress-bar {
    margin: 27px auto 0;
    width: 630px;
    height: 45px;
    border: solid 2px #{$pumpkin};
    border-radius: 50px;
    box-sizing: border-box;
    display: flex;
    justify-content: space-around;
    align-items: center;
    > .arrow {
      width: 8px;
      height: 8px;
      border-top: 2px solid #{$light-grey};
      border-right: 2px solid #{$light-grey};
      transform: rotate(45deg);
      &.orange {
        border-top: 2px solid #{$pumpkin};
        border-right: 2px solid #{$pumpkin};
      }
    }
    > .progress-bar-list-text {
      font-size: 15px;
      font-weight: bold;
      color: #{$light-grey};
      &.orange {
        color: #{$pumpkin};
      }
    }
  }
  > .message {
    margin-top: 38px;
    font-weight: bold;
    > .message-p2 {
      margin-top: 30px;
      color: #{$tomato};
    }
    > .message-p3 {
      margin-top: 27px;
    }
  }
}
</style>
