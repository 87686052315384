import { getAxiosObject } from '@/utils/library'
import {
  commonGetters,
  commonMutations,
  commonActions
} from '@/store/store_utils'
import { toStateName } from '@/utils/convert_string'

const dataName = 'estimates'
const keys = ['patientId']

const state = {
  estimates: [],
  dataIndexedById: {},
  updatedAt: '20101010101010'
}
keys.forEach(key => {
  state[toStateName(key)] = null
})

const getters = {
  ...commonGetters(dataName, ['getData', 'getDataById', 'getUpdatedAt'], {
    filterDel: true,
    getDataBy: keys
  })
}

const actions = {
  ...commonActions(['setData', 'setUpdatedAt', 'updateAfter']),
  async create({ commit }, { estimate, estimateTreatmentItems }) {
    try {
      const axiosObject = getAxiosObject()
      const res = await axiosObject.post('/estimates', {
        estimate,
        estimateTreatmentItems
      })
      if (res.status === 200) {
        commit('updateAfter', [res.data.estimate])
        commit(
          'estimateTreatmentItems/updateAfter',
          res.data.estimateTreatmentItems,
          { root: true }
        )
        return true
      }
    } catch (error) {
      if (error.response?.data?.message) {
        if (error.response.data.message === 'no data') {
          if (error.response.data.extra.dataName === 'treatment') {
            commit('treatments/setData', error.response.data.extra.data, {
              root: true
            })
          }
          if (error.response.data.extra.dataName === 'medicine') {
            commit('medicines/setData', error.response.data.extra.data, {
              root: true
            })
          }
          return `no ${error.response.data.extra.dataName}`
        } else {
          return error.response.data.message
        }
      } else {
        return 'server error'
      }
    }
  },
  async delete({ commit }, id) {
    try {
      const axiosObject = getAxiosObject()
      const res = await axiosObject.delete('/estimates', { data: { id } })
      if (res.status === 200) {
        commit('updateAfter', [res.data.estimate])
        commit(
          'estimateTreatmentItems/updateAfter',
          res.data.estimateTreatmentItems,
          { root: true }
        )
        return true
      }
    } catch (error) {
      if (error.response?.data?.extra?.data) {
        commit('setData', error.response.data.extra.data)
        return error.response.data.message
      } else {
        return 'server error'
      }
    }
  },
  async update(
    { commit },
    { estimate, estimateTreatmentItems, deleteEstimateTreatmentItems }
  ) {
    try {
      const axiosObject = getAxiosObject()
      const res = await axiosObject.put('/estimates', {
        estimate,
        estimateTreatmentItems,
        deleteEstimateTreatmentItems
      })
      if (res.status === 200) {
        commit('updateAfter', [res.data.estimate])
        commit(
          'estimateTreatmentItems/updateAfter',
          res.data.estimateTreatmentItems,
          { root: true }
        )
        return true
      }
    } catch (error) {
      if (error.response?.data?.message) {
        if (error.response.data.message === 'no data in clinic') {
          commit('setData', error.response.data.extra.data)
          return `no ${error.response.data.extra.dataName}`
        } else if (error.response.data.message === 'no data') {
          if (error.response.data.extra.dataName === 'treatment') {
            commit('treatments/setData', error.response.data.extra.data, {
              root: true
            })
            return `no ${error.response.data.extra.dataName}`
          } else if (error.response.data.extra.dataName === 'medicine') {
            commit('medicines/setData', error.response.data.extra.data, {
              root: true
            })
            return `no ${error.response.data.extra.dataName}`
          } else {
            return `no ${error.response.data.extra}`
          }
        } else {
          return error.response.data.message
        }
      } else {
        return 'server error'
      }
    }
  }
}

const mutations = {
  ...commonMutations(dataName, ['setUpdatedAt', 'setData', 'updateAfter'], {
    indexedById: true,
    setDataBy: keys
  })
}

export default {
  namespaced: true,
  state,
  getters,
  actions,
  mutations
}
