import { getAxiosObject } from '@/utils/library'
import {
  commonGetters,
  commonMutations,
  commonActions
} from '@/store/store_utils'
import { toStateName } from '@/utils/convert_string'

const dataName = 'hospitalizations'
const keys = ['patientId']

const state = {
  hospitalizations: [],
  updatedAt: '20101010101010'
}
keys.forEach(key => {
  state[toStateName(key)] = null
})

const getters = {
  ...commonGetters(dataName, ['getData', 'getUpdatedAt'], {
    filterDel: true,
    getDataBy: ['patientId']
  })
}

const actions = {
  ...commonActions(['setData', 'setUpdatedAt', 'updateAfter']),
  async search({ commit }, { period }) {
    try {
      const axiosObject = getAxiosObject()
      const searchValues = { period }
      const res = await axiosObject.get('/hospitalizations/search', {
        params: { searchValues }
      })
      if (res.status === 200) {
        if (res.data.hospitalizations.length > 0) {
          commit('updateAfter', res.data.hospitalizations)
        }
        if (res.data.medicalRecords.length > 0) {
          commit('medicalRecords/updateAfter', res.data.medicalRecords, {
            root: true
          })
        }
        if (res.data.medicalPayments.length > 0) {
          commit('medicalPayments/updateAfter', res.data.medicalPayments, {
            root: true
          })
        }
        return {
          result: true,
          hospitalizations: res.data.hospitalizations
        }
      }
    } catch (error) {
      return { result: false }
    }
  }
}

const mutations = {
  ...commonMutations(dataName, ['setData', 'setUpdatedAt', 'updateAfter'], {
    setDataBy: keys,
    csvColumns: [
      'medicalPaymentOriginalIdsUntilMiddleCalculate',
      'medicalPaymentOriginalIds'
    ],
    csvTypes: ['number']
  })
}

export default {
  namespaced: true,
  state,
  getters,
  actions,
  mutations
}
