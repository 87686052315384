<template>
  <div class="text-input-form">
    <div
      class="heading"
      :style="{ height: headingHeight, width: headingWidth }"
    >
      <div class="text"><slot></slot></div>
      <hint-balloon v-if="note" :note="note" />
      <div class="footnote-mark" v-if="footnoteMark">{{ footnoteMark }}</div>
      <div class="required" v-if="requiredFlg">必須</div>
    </div>
    <div class="body" :style="bodyStyle">
      <validation-provider
        class="validate"
        :rules="{
          ...validationRules,
          requiredRule: requiredFlg,
          notSurrogatePair: true
        }"
        :vid="vid"
        v-slot="{ errors }"
      >
        <base-text-box
          :maxlength="maxlength"
          :max="max"
          :min="min"
          :placeholder="placeholder"
          :type="type"
          :disabled="disabled"
          :disabledColorWhite="disabledColorWhite"
          :styles="styles"
          :fullWidthNumbersToHalfWidthNumbers="
            fullWidthNumbersToHalfWidthNumbers
          "
          v-model="input"
          @blur="$emit('blur')"
          @dblclick="$emit('dblclick')"
        />
        <div v-if="errorShowFlg" class="error" :style="errorStyles">
          <div v-for="(error, index) in errors" :key="index">{{ error }}</div>
        </div>
      </validation-provider>
    </div>
  </div>
</template>

<script>
import BaseTextBox from '@/components/parts/atoms/BaseTextBox'
import HintBalloon from '@/components/parts/atoms/HintBalloon.vue'
import { ValidationProvider } from 'vee-validate'
import '@/utils/validation_rules'

export default {
  name: 'TextInputForm',

  components: {
    BaseTextBox,
    HintBalloon,
    ValidationProvider
  },

  props: {
    value: { type: [String, Number], default: '' },
    headingStyle: { type: Object },
    bodyStyle: { type: Object },
    errorStyles: { type: Object },
    requiredFlg: { type: Boolean, default: false },
    validationRules: { type: Object },
    vid: { type: String, default: '' },
    maxlength: { type: Number, default: 255 },
    max: { type: Number },
    min: { type: Number },
    placeholder: { type: String, default: '' },
    type: { type: String, default: 'text' },
    disabled: { type: Boolean, default: false },
    disabledColorWhite: { type: Boolean, default: false },
    styles: { type: Object },
    errorShowFlg: { type: Boolean, default: true },
    note: { type: String, default: '' },
    fullWidthNumbersToHalfWidthNumbers: { type: Boolean, default: false },
    footnoteMark: { type: String, default: '' }
  },

  computed: {
    input: {
      get() {
        return this.value
      },
      set(val) {
        this.$emit('input', val)
      }
    },
    headingHeight() {
      return this.styles ? (this.styles.height ? this.styles.height : '') : ''
    },
    headingWidth() {
      return this.headingStyle
        ? this.headingStyle.width
          ? this.headingStyle.width
          : ''
        : ''
    }
  }
}
</script>

<style lang="scss" scoped>
.text-input-form {
  display: flex;
  align-items: flex-start;
  > .heading {
    display: flex;
    align-items: center;
    height: 33px;
    width: 40%;
    font-size: 15px;
    > .footnote-mark {
      margin-left: 6px;
      font-size: 12px;
    }
    > .required {
      margin-left: 10px;
      font-size: 13px;
      color: #{$tomato};
    }
  }
  > .body {
    width: 60%;
    display: flex;
    flex-direction: row;
    position: relative;
    @include validate-message();
  }
}
</style>
