import store from '@/store/store'
import { HELP_LINK, HELP_LINK_KARTE } from '@/utils/define'
export default {
  methods: {
    mixinHelpLink() {
      if (store.getters['clinic/getData'].karteFlg === 1) {
        return HELP_LINK_KARTE
      } else {
        return HELP_LINK
      }
    }
  }
}
