<template>
  <div class="base-button-small-white">
    <button
      type="button"
      :disabled="disabled"
      @click="handleClick"
      :style="styles"
      data-e2e="base-button-small-white"
    >
      <div class="text" :style="textStyles"><slot /></div>
    </button>
  </div>
</template>

<script>
export default {
  name: 'BaseButtonSmallWhite',

  props: {
    disabled: {
      type: Boolean,
      default: false
    },
    styles: {
      type: Object,
      default() {
        return {}
      }
    },
    textStyles: {
      type: Object,
      default() {
        return {}
      }
    }
  },

  methods: {
    handleClick(event) {
      this.$emit('click', event)
    }
  }
}
</script>

<style lang="scss" scoped>
.base-button-small-white {
  > button {
    width: 135px;
    height: 33px;
    padding: 0;
    border-radius: 6px;
    border: solid 2px #{$light-grey};
    background-color: #{$white};
    cursor: pointer;
    &:disabled {
      pointer-events: none;
      background-color: #{$white_f7};
      color: #{$light-grey};
      border: none;
    }
    &:hover {
      opacity: 0.8;
    }
    &:active {
      margin: 2px auto 0 auto;
      height: 31px;
    }
    > .text {
      display: flex;
      align-items: center;
      justify-content: center;
      font-size: 15px;
      font-weight: bold;
    }
  }
}
@media (max-width: $tablet-width) {
  .base-button-small-white {
    > button > .text {
      font-size: 12px;
    }
  }
}
</style>
