<template>
  <div class="radio-button-form">
    <div class="title" :style="titleStyles">
      <div class="text">{{ title }}</div>
      <hint-balloon v-if="titleDescription" :note="titleDescription" />
      <div class="required" v-if="requiredFlg">必須</div>
    </div>
    <div class="content" :style="contentStyles">
      <div
        class="radio-button"
        v-for="datum in radioButtonData"
        :key="datum.id"
      >
        <base-radio-button
          :option="datum"
          :value="value"
          :styles="radioButtonStyles"
          :disabled="disabled || datum.disabled"
          @input="inputSelectValue"
        />
      </div>
    </div>
  </div>
</template>
<script>
import BaseRadioButton from '@/components/parts/atoms/BaseRadioButton.vue'
import HintBalloon from '@/components/parts/atoms/HintBalloon.vue'
export default {
  name: 'RadioButtonForm',
  components: {
    BaseRadioButton,
    HintBalloon
  },
  props: {
    title: {
      type: String,
      required: true
    },
    titleDescription: {
      type: String,
      default: ''
    },
    radioButtonData: {
      type: Array, // [ {id: 1, eachValue: 1 or '値', labelName: '有効' } ]
      required: true,
      validator: function(arr) {
        return arr.every(v => {
          return (
            Number.isInteger(v.id) &&
            (Number.isInteger(v.eachValue) ||
              typeof v.eachValue === 'string') &&
            typeof v.labelName === 'string' &&
            v.id !== null &&
            v.eachValue !== null &&
            v.labelName !== null
          )
        })
      }
    },
    value: {
      type: [String, Number],
      required: true
    },
    titleStyles: {
      width: { type: String },
      height: { type: String },
      marginRight: { type: String }
    },
    contentStyles: { type: Object },
    radioButtonStyles: {
      marginLeft: { type: String },
      marginRight: { type: String }
    },
    requiredFlg: { type: Boolean, default: false },
    disabled: { type: Boolean, default: false }
  },
  methods: {
    inputSelectValue(v) {
      this.$emit('input', v)
    }
  }
}
</script>
<style lang="scss" scoped>
.radio-button-form {
  display: flex;
  font-size: 15px;
  > .title {
    display: flex;
    align-items: center;
    width: 220px;
    height: 22px;
    margin-right: 20px;
    white-space: pre-line;
    > .required {
      margin-left: 10px;
      font-size: 13px;
      color: #{$tomato};
    }
  }
  > .content {
    display: flex;
    flex-wrap: wrap;
    width: 300px;
    > .radio-button {
      margin-top: 30px;
      &:nth-child(1) {
        margin-top: 0px;
      }
      &:nth-child(2) {
        margin-top: 0px;
      }
    }
  }
}
</style>
