<template>
  <div class="base-check-box" :style="styles">
    <label
      :class="{ disabled: disabled }"
      data-test="checkbox"
      data-e2e="base-check-box"
    >
      <input
        type="checkbox"
        class="checkbox-input"
        :checked="isChecked"
        :disabled="disabled"
        v-model="inputCheck"
      />
      <div
        class="checkbox-parts"
        :class="{ disabled: disabled }"
        :style="styleObj"
      ></div>
      <div v-if="labelText.length > 0" class="label-text" :style="labelStyle">
        <span>{{ labelText }}</span>
      </div>
    </label>
  </div>
</template>

<script>
import ColorMethods from '@/components/mixins/ColorMethods'

export default {
  name: 'BaseCheckBox',

  mixins: [ColorMethods],

  props: {
    numFlg: { type: Boolean, default: false },
    isChecked: { type: [Boolean, Number], default: false },
    color: { type: String, default: '' },
    styles: { type: Object },
    labelStyle: { type: Object },
    labelText: { type: String, default: '' },
    disabled: { type: Boolean, default: false }
  },

  computed: {
    inputCheck: {
      get() {
        return this.numFlg ? this.toBool(this.isChecked) : this.isChecked
      },
      set(val) {
        const emitVal = this.numFlg ? this.toNum(val) : val
        this.$emit('input', emitVal)
      }
    },
    styleObj() {
      return /^#[a-fA-F0-9]{6}$/.test(this.color)
        ? {
            '--border-color': this.color,
            '--background-color': this.color,
            '--check-color': this.mixinAdaptTextColor(this.color)
          }
        : {
            '--border-color': '#cecece',
            '--background-color': '#ef6c00',
            '--check-color': '#ffffff'
          }
    }
  },

  methods: {
    toBool(num) {
      return num === 1
    },
    toNum(bool) {
      return bool ? 1 : 0
    }
  }
}
</script>

<style lang="scss" scoped>
.base-check-box {
  > label {
    cursor: pointer;
    display: flex;
    align-items: center;
    &.disabled {
      pointer-events: none;
    }
    > .checkbox-input {
      display: none;
    }
    > .checkbox-parts {
      position: relative;
      width: 20px;
      height: 20px;
      &.disabled {
        &::before {
          background-color: #{$light-grey};
          opacity: 0.7;
        }
      }
    }
    > .checkbox-parts::before {
      content: '';
      display: block;
      position: absolute;
      top: 0;
      left: 0;
      width: 20px;
      height: 20px;
      border-radius: 5px;
      border: solid 2px var(--border-color);
      box-sizing: border-box;
      background-color: #{$white};
    }
    > .checkbox-input:checked + .checkbox-parts::before {
      border: none;
      background-color: var(--background-color);
    }
    > .checkbox-input:checked + .disabled::before {
      background-color: #{$light-grey};
    }
    > .checkbox-input:checked + .checkbox-parts::after {
      content: '';
      display: block;
      position: absolute;
      top: 6px;
      left: 5px;
      width: 8px;
      height: 4px;
      -webkit-transform: rotate(-45deg);
      transform: rotate(-45deg);
      border-left: 2px solid var(--check-color);
      border-bottom: 2px solid var(--check-color);
    }
    > .label-text {
      margin-left: 10px;
      font-size: 13px;
    }
  }
}
</style>
