<template>
  <div class="base-button-register">
    <button
      class="register-button"
      type="button"
      :style="styles"
      :disabled="disabled"
      @click="handleClick"
      data-e2e="base-button-register"
    >
      <span class="plus-icon">
        <span class="plus-icon-line vertical"></span>
        <span class="plus-icon-line horizontal"></span>
      </span>
      <span class="button-text" v-if="text.length > 0">{{ text }}</span>
    </button>
  </div>
</template>

<script>
export default {
  name: 'BaseButtonRegister',

  props: {
    text: { type: String, default: '' },
    styles: {
      type: Object,
      validator: function(obj) {
        return Object.values(obj).every(v => typeof v === 'string')
      }
    },
    disabled: { type: Boolean, default: false }
  },

  methods: {
    handleClick() {
      this.$emit('click')
    }
  }
}
</script>

<style lang="scss" scoped>
.base-button-register {
  > .register-button {
    display: flex;
    align-items: center;
    justify-content: center;
    height: 33px;
    min-width: 33px;
    padding: 0;
    border-radius: 6px;
    border: none;
    background-color: #{$pumpkin};
    cursor: pointer;
    &:hover {
      background-color: #{$pumpkin};
      opacity: 0.8;
    }
    &:active {
      height: 31px;
      margin: 2px auto 0 auto;
    }
    &:disabled {
      pointer-events: none;
      background-color: #{$light-grey};
    }
    > .plus-icon {
      position: relative;
      width: 16px;
      height: 16px;
      margin: 0 8px;
      > .plus-icon-line {
        position: absolute;
        background-color: #{$white};
        border-radius: 1px;
        &.vertical {
          top: 0px;
          left: 7px;
          width: 2px;
          height: 16px;
        }
        &.horizontal {
          top: 7px;
          left: 0px;
          width: 16px;
          height: 2px;
        }
      }
    }
    > .button-text {
      font-size: 15px;
      font-weight: bold;
      margin-right: 8px;
      color: #{$white};
    }
  }
}
</style>
