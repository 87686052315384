<template>
  <div class="base-button-plus">
    <button
      class="button-plus"
      type="button"
      :style="styles"
      :disabled="disabled"
      @click="handleClick"
      data-e2e="base-button-plus"
    >
      <span class="plus-icon">
        <span
          class="plus-icon-line vertical"
          :class="{ disabled: disabled }"
        ></span>
        <span
          class="plus-icon-line horizontal"
          :class="{ disabled: disabled }"
        ></span>
      </span>
      <span class="button-text" :class="{ disabled: disabled }">{{
        text
      }}</span>
    </button>
  </div>
</template>

<script>
export default {
  name: 'BaseButtonPlus',

  props: {
    text: { type: String, default: '' },
    styles: {
      type: Object,
      validator: function(obj) {
        return Object.values(obj).every(v => typeof v === 'string')
      }
    },
    disabled: { type: Boolean, default: false }
  },

  methods: {
    handleClick() {
      this.$emit('click')
    }
  }
}
</script>

<style lang="scss" scoped>
.base-button-plus {
  display: inline-block;
  > .button-plus {
    display: flex;
    align-items: center;
    justify-content: center;
    gap: 0 8px;
    height: 33px;
    padding: 0 8px;
    border-radius: 6px;
    border: solid 2px #{$light-grey};
    background-color: #{$white};
    cursor: pointer;
    &:hover {
      opacity: 0.8;
    }
    &:active {
      height: 31px;
      margin: 2px auto 0 auto;
    }
    &:disabled {
      pointer-events: none;
      background-color: #{$light-grey};
    }
    > .plus-icon {
      position: relative;
      width: 16px;
      height: 16px;
      > .plus-icon-line {
        position: absolute;
        background-color: #{$pumpkin};
        border-radius: 1px;
        &.disabled {
          background-color: #{$brown-gray};
        }
        &.vertical {
          top: 0px;
          left: 7px;
          width: 2px;
          height: 16px;
        }
        &.horizontal {
          top: 7px;
          left: 0px;
          width: 16px;
          height: 2px;
        }
      }
    }
    > .button-text {
      font-size: 15px;
      font-weight: bold;
      color: #{$greyish-brown};
      &.disabled {
        color: #{$brown-gray};
      }
    }
  }
}
@media (max-width: $tablet-width) {
  .base-button-plus {
    > .button-plus {
      .button-text {
        font-size: 13px;
        line-height: 20px;
      }
    }
  }
}
</style>
