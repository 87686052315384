<template>
  <div class="base-button-large-orange">
    <button
      type="button"
      :disabled="disabled || preventContinuousClickFlg"
      @click="handleClick"
      data-e2e="base-button-large-orange"
    >
      <span><slot /></span>
    </button>
  </div>
</template>

<script>
export default {
  name: 'BaseButtonLargeOrange',

  props: {
    disabled: {
      type: Boolean,
      default: false
    }
  },

  data() {
    return {
      preventContinuousClickFlg: false,
      id: null
    }
  },

  beforeDestroy() {
    clearTimeout(this.id)
  },

  methods: {
    handleClick() {
      this.preventContinuousClickFlg = true
      this.$emit('click')
      this.id = setTimeout(() => {
        this.preventContinuousClickFlg = false
      }, 500)
    }
  }
}
</script>

<style lang="scss" scoped>
.base-button-large-orange {
  > button {
    width: 335px;
    height: 60px;
    border-radius: 6px;
    background-color: #{$pumpkin};
    cursor: pointer;
    border: none;
    color: #{$white};
    &:disabled {
      pointer-events: none;
      background-color: #{$light-grey};
      color: #{$brown-gray};
    }
    &:hover {
      opacity: 0.8;
      background-color: #{$pumpkin};
    }
    &:active {
      margin: 2px auto 0 auto;
      height: 58px;
    }
    > span {
      height: 22px;
      font-size: 15px;
      font-weight: bold;
      line-height: 1.47;
      text-align: left;
      display: inline-block;
      vertical-align: middle;
    }
  }
}
</style>
