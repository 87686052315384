<template>
  <div class="apply-input-form">
    <div class="body">
      <div class="radio-button-form-area">
        <radio-button-form
          v-bind="plans"
          v-model="data.planId"
          :requiredFlg="true"
        />
      </div>
      <div class="plan-link">
        <a href="https://www.plus-petorelu.jp/price/" target="_blank"
          >各プランの詳細はこちら</a
        >
      </div>
      <full-name-input-form
        class="form"
        :lastName="data.staffLastName"
        :firstName="data.staffFirstName"
        :requiredFlg="true"
        @input-last-name="inputStaffLastName"
        @input-first-name="inputStaffFirstName"
        >お名前</full-name-input-form
      >
      <auto-input-form
        class="form"
        :value="data.postalCode"
        :requiredFlg="true"
        :validationRules="{
          regexPostalCode: true,
          apiResultPostalCode: apiResultPostalCode
        }"
        :placeholder="'例：1234567'"
        :maxlength="7"
        :inputStyle="{ width: '120px' }"
        :buttonText="'住所自動入力'"
        :buttonStyle="{ width: '135px' }"
        :fullWidthNumbersToHalfWidthNumbers="true"
        @input="inputPostalCode"
        @click="$emit('auto-input-address')"
        >郵便番号（ハイフン不要）</auto-input-form
      >
      <select-box-form
        class="form select-box-form"
        v-model.number="data.prefectureId"
        :requiredFlg="true"
        :selectData="prefectures"
        >都道府県</select-box-form
      >
      <text-input-form
        class="form"
        v-model="data.address"
        :requiredFlg="true"
        :placeholder="'例：鹿児島県鹿児島市中央町18番地1'"
        :maxlength="50"
        :styles="{ width: '364px' }"
        >住所</text-input-form
      >
      <text-input-form
        class="form"
        v-model="data.building"
        :placeholder="'例：南国センタービル2F'"
        :maxlength="50"
        :styles="{ width: '364px' }"
        >ビル・マンション名</text-input-form
      >
      <text-input-form
        class="form"
        v-model="data.tel"
        :requiredFlg="true"
        :validationRules="{ regexTel: true }"
        :placeholder="'例：09012345678'"
        :maxlength="11"
        :styles="{ width: '364px' }"
        :fullWidthNumbersToHalfWidthNumbers="true"
        >電話番号（半角数字）</text-input-form
      >
      <text-input-form
        class="form"
        v-model="data.clinicName"
        :requiredFlg="true"
        :placeholder="'例：ピクオスペットクリニック'"
        :styles="{ width: '364px' }"
        >クリニック名</text-input-form
      >
      <text-input-form
        class="form"
        v-model="data.clinicEmail"
        :requiredFlg="true"
        :validationRules="{ emailRule: true }"
        :placeholder="'例：petorelu@email.com'"
        :type="'email'"
        :maxlength="254"
        :styles="{ width: '364px' }"
        >医院のメールアドレス</text-input-form
      >
      <text-input-form
        class="form"
        v-model="data.staffEmail"
        :validationRules="{ emailRule: true }"
        :placeholder="'例：petorelu@email.com'"
        :type="'email'"
        :maxlength="254"
        :styles="{ width: '364px' }"
        >ユーザーのメールアドレス</text-input-form
      >
      <div class="hosoku">
        ログイン時に使用するメールアドレス。<br />
        医院のメールアドレスと同一の場合は省略可。
      </div>
      <text-input-form
        class="form"
        :maxlength="6"
        :placeholder="'例：a12345'"
        :styles="{ width: '364px' }"
        :validationRules="{ anicomHCdRule: true }"
        v-model="data.anicomHCd"
        >アニコム医療機関コード</text-input-form
      >
      <text-input-form
        class="form"
        data-test="vh-code"
        :maxlength="6"
        :placeholder="'例：H12345'"
        :styles="{ width: '364px' }"
        :validationRules="{ vhCodeRule: true }"
        v-model="data.vhCode"
        >アイペット病院コード</text-input-form
      >
      <image-form
        class="form"
        :requiredFlg="true"
        :image="data.image"
        @input-image="inputImage"
        >獣医師免許の画像</image-form
      >
      <multiple-text-input-form class="form" v-model="data.memo"
        >備考</multiple-text-input-form
      >
      <div class="terms-of-use">
        <div class="title">PETORELU<SUP>+</SUP>利用規約</div>
        <terms-of-use />
        <base-check-box
          class="check-box"
          :labelText="'利用規約に同意する'"
          :isChecked="flags.acceptTermsOfUse"
          v-model="flags.acceptTermsOfUse"
        />
      </div>
      <div class="privacy-policy">
        <a href="https://www.pyxos-jk.co.jp/privacyPolicy.html" target="_blank"
          >プライバシーポリシーの確認はこちらから</a
        >
        <base-check-box
          class="check-box"
          :labelText="'プライバシーポリシーに同意する'"
          :isChecked="flags.acceptPrivacyPolicy"
          v-model="flags.acceptPrivacyPolicy"
        />
      </div>
    </div>
  </div>
</template>

<script>
import FullNameInputForm from '@/components/parts/molecules/FullNameInputForm'
import TextInputForm from '@/components/parts/molecules/TextInputForm'
import AutoInputForm from '@/components/parts/molecules/AutoInputForm'
import SelectBoxForm from '@/components/parts/molecules/SelectBoxForm'
import ImageForm from '@/components/parts/molecules/ImageForm'
import MultipleTextInputForm from '@/components/parts/molecules/MultipleTextInputForm'
import TermsOfUse from '@/components/parts/atoms/TermsOfUse'
import BaseCheckBox from '@/components/parts/atoms/BaseCheckBox'
import RadioButtonForm from '@/components/parts/molecules/RadioButtonForm.vue'

export default {
  name: 'ApplyInputForm',

  components: {
    FullNameInputForm,
    TextInputForm,
    AutoInputForm,
    SelectBoxForm,
    ImageForm,
    MultipleTextInputForm,
    TermsOfUse,
    BaseCheckBox,
    RadioButtonForm
  },

  props: {
    data: {
      planId: { type: Number, default: null },
      staffLastName: { type: String, default: '' },
      staffFirstName: { type: String, default: '' },
      postalCode: { type: String, default: '' },
      prefectureId: { type: Number, default: 0 },
      address: { type: String, default: '' },
      building: { type: String, default: '' },
      tel: { type: String, default: '' },
      clinicName: { type: String, default: '' },
      clinicEmail: { type: String, default: '' },
      staffEmail: { type: String, default: '' },
      anicomHCd: { type: String, default: '' },
      vhCode: { type: String, default: '' },
      memo: { type: String, default: '' },
      image: { type: [String, null], default: null }
    },
    prefectures: { type: Array },
    flags: {
      acceptTermsOfUse: { type: Boolean, default: false },
      acceptPrivacyPolicy: { type: Boolean, default: false }
    },
    apiResultPostalCode: {
      type: Object,
      default: () => {
        return null
      }
    },
    plans: { type: Object }
  },

  methods: {
    inputStaffLastName(val) {
      this.$emit('input-staff-last-name', val)
    },
    inputStaffFirstName(val) {
      this.$emit('input-staff-first-name', val)
    },
    inputPostalCode(val) {
      this.$emit('input-postal-code', val)
    },
    inputImage(image) {
      this.$emit('input-image', image)
    }
  }
}
</script>

<style lang="scss" scoped>
.apply-input-form {
  margin: 0 auto;
  width: 700px;
  border: 1px solid #{$light-grey};
  border-radius: 5px;
  > .body {
    width: 600px;
    margin: 0 auto 40px;
    > .form {
      margin-top: 40px;
      ::v-deep .error {
        text-align: left;
        width: auto;
        font-feature-settings: 'palt';
      }
    }
    > .radio-button-form-area {
      margin-top: 40px;
      ::v-deep .radio-button {
        margin-top: 0px;
      }
    }
    > .plan-link a {
      margin-top: 20px;
      margin-left: 240px;
      text-align: left;
      display: flex;
      font-size: 15px;
      color: blue;
      text-decoration: underline;
      &:hover {
        opacity: 0.8;
        text-decoration: none;
      }
    }
    > .select-box-form {
      ::v-deep .base-select-box {
        width: 90px;
      }
    }
    > .image {
      margin-top: 40px;
      display: flex;
      font-size: 15px;
      text-align: left;
      > .heading {
        width: 40%;
      }
    }
    > .hosoku {
      text-align: left;
      font-size: 10px;
    }
    > .terms-of-use {
      width: 100%;
      height: 500px;
      margin-top: 40px;
      > .title {
        font-size: 20px;
        font-weight: bold;
        margin-bottom: 20px;
      }
      > .check-box {
        width: 147px;
        margin: 10px auto 0;
      }
    }
    > .privacy-policy {
      margin-top: 40px;
      > a {
        color: blue;
        text-decoration: underline;
        &:hover {
          opacity: 0.8;
          text-decoration: none;
        }
      }
      > .check-box {
        width: 225px;
        margin: 10px auto 0;
      }
    }
  }
}
</style>
