import { getAxiosObject } from '@/utils/library'
import {
  commonGetters,
  commonMutations,
  commonActions
} from '@/store/store_utils'
import { toStateName } from '@/utils/convert_string'

const dataName = 'treatments'
const keys = ['treatmentClassOriginalId']

const state = {
  treatments: [],
  dataIndexedById: {},
  dataIndexedByOriginalId: {},
  updatedAt: '20101010101010'
}
keys.forEach(key => {
  state[toStateName(key)] = null
})

const getters = {
  ...commonGetters(
    dataName,
    ['getData', 'getDataById', 'getDataByOriginalId', 'getUpdatedAt'],
    { filterDel: true, getDataBy: keys }
  )
}

const actions = {
  ...commonActions(['setData', 'setUpdatedAt', 'updateAfter']),
  async create({ commit }, { treatmentLargeClass, treatmentClass, treatment }) {
    try {
      const axiosObject = getAxiosObject()
      const res = await axiosObject.post('/treatments', {
        treatmentLargeClass,
        treatmentClass,
        treatment
      })
      if (res.status === 200) {
        commit('updateAfter', [res.data.treatment])
        commit('treatmentClasses/updateAfter', [res.data.treatmentClass], {
          root: true
        })
        commit(
          'treatmentLargeClasses/updateAfter',
          [res.data.treatmentLargeClass],
          { root: true }
        )
        return true
      }
    } catch (error) {
      if (error.response?.data?.message) {
        if (error.response.data.message === 'no data in clinic') {
          const dataName = error.response.data.extra.dataName
          if (
            dataName === 'treatmentClass' ||
            dataName === 'treatmentLargeClass'
          ) {
            commit(`${dataName}es/setData`, error.response.data.extra.data, {
              root: true
            })
          }
          return 'no data in clinic'
        } else if (error.response.data.message === 'no data') {
          return `no ${error.response.data.extra}`
        } else {
          return error.response.data.message
        }
      } else {
        return 'server error'
      }
    }
  },
  async delete({ commit }, id) {
    try {
      const axiosObject = getAxiosObject()
      const res = await axiosObject.delete('/treatments', { data: { id } })
      if (res.status === 200) {
        commit('updateAfter', [res.data.treatment])
        if (res.data.examinationFormat) {
          commit(
            'examinationFormats/updateAfter',
            [res.data.examinationFormat],
            { root: true }
          )
          commit(
            'examinationTopicClasses/updateAfter',
            res.data.examinationTopicClasses,
            { root: true }
          )
          commit('examinationTopics/updateAfter', res.data.examinationTopics, {
            root: true
          })
          commit(
            'examinationSpecies/updateAfter',
            res.data.examinationSpecies,
            { root: true }
          )
        }
        return true
      }
    } catch (error) {
      if (error.response?.data?.extra) {
        if (error.response.data.extra.data) {
          commit('setData', error.response.data.extra.data)
        } else {
          return error.response.data.extra
        }
        return error.response.data.message
      } else {
        return 'server error'
      }
    }
  },
  async update({ commit }, { treatment }) {
    try {
      const axiosObject = getAxiosObject()
      const res = await axiosObject.put('/treatments', {
        treatment
      })
      if (res.status === 200) {
        if (Object.keys(res.data.treatmentLargeClass).length > 0) {
          commit(
            'treatmentLargeClasses/updateAfter',
            [res.data.treatmentLargeClass],
            { root: true }
          )
        }
        if (Object.keys(res.data.treatmentClass).length > 0) {
          commit('treatmentClasses/updateAfter', [res.data.treatmentClass], {
            root: true
          })
        }
        commit('updateAfter', res.data.treatments)
        return true
      }
    } catch (error) {
      if (error.response?.data?.message) {
        if (error.response.data.message === 'no data in clinic') {
          const dataName = error.response.data.extra.dataName
          if (
            dataName === 'treatmentClass' ||
            dataName === 'treatmentLargeClass'
          ) {
            commit(`${dataName}es/setData`, error.response.data.extra.data, {
              root: true
            })
          } else {
            commit('setData', error.response.data.extra.data)
          }
          return 'no data in clinic'
        }
        if (error.response.data.message === 'no data') {
          return error.response.data.extra === 'treatment already edited'
            ? 'already edited'
            : 'already deleted'
        } else {
          return error.response.data.message
        }
      } else {
        return 'server error'
      }
    }
  }
}

const mutations = {
  ...commonMutations(dataName, ['setData', 'setUpdatedAt', 'updateAfter'], {
    order: true,
    indexedById: true,
    indexedByOriginalId: true,
    setDataBy: keys
  })
}

export default {
  namespaced: true,
  state,
  getters,
  actions,
  mutations
}
