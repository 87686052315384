<template>
  <div class="login" @keyup.enter.stop="trySignIn">
    <img
      src="@/assets/images/login_logo.png"
      class="login-logo-img"
      width="315px"
      height="54px"
      alt="login-logo"
    />
    <div class="login-text">ペトレルアカウントでログイン</div>
    <div class="text-input-email">
      <base-text-box v-bind="emailBox" v-model="email" name="email" />
    </div>
    <div class="text-input-password">
      <base-text-input-password placeholder="パスワード" v-model="password" />
    </div>
    <div class="login-button">
      <base-button-medium-orange @click="trySignIn" :disabled="waitFlg"
        >ログイン</base-button-medium-orange
      >
    </div>
    <router-link
      :to="{ name: 'password-reset-send-mail' }"
      class="forget-password"
      >パスワード再設定</router-link
    >
    <announce-popup
      v-if="alertFlg"
      @close="closeAlert"
      :title="title"
      :buttons="buttons"
      :type="type"
      >{{ popupMessage }}</announce-popup
    >
  </div>
</template>

<script>
import BaseTextBox from '@/components/parts/atoms/BaseTextBox'
import BaseTextInputPassword from '@/components/parts/atoms/BaseTextInputPassword.vue'
import BaseButtonMediumOrange from '@/components/parts/atoms/BaseButtonMediumOrange'
import AnnouncePopup from '@/components/popups/AnnouncePopup'
import ApiLogin from '@/components/mixins/ApiLogin'

export default {
  name: 'Login',

  components: {
    BaseTextBox,
    BaseTextInputPassword,
    BaseButtonMediumOrange,
    AnnouncePopup
  },

  mixins: [ApiLogin],

  data() {
    return {
      email: process.env.VUE_APP_DEV_EMAIL,
      password: process.env.VUE_APP_DEV_PASSWORD,
      emailBox: {
        type: 'email',
        placeholder: 'メールアドレス',
        styles: {
          width: '299px',
          height: '44px'
        }
      },
      alertFlg: false,
      title: '失敗',
      buttons: ['閉じる'],
      type: 'failure',
      popupMessage: '',
      waitFlg: false
    }
  },

  mounted() {
    if (this.$store.getters['auth/loginStaffId'] === 0) {
      this.notLogined()
    } else {
      location.reload()
    }
  },

  methods: {
    notLogined() {
      this.$store.dispatch('auth/authLogout')
    },
    async trySignIn() {
      this.waitFlg = true
      const password = this.password?.trim()
      const res = await this.mixinHandleSignIn(this.email, password)
      if (res === true) {
        this.$router.push({ path: '/main' })
      } else {
        if (res === 'not authorized') {
          this.popupMessage = '有効時間が過ぎています。'
        } else if (res === 'account suspension') {
          this.popupMessage = `アカウントの期限が過ぎています。\n再度アカウントを利用したい場合、担当者にご連絡ください。`
        } else if (res === 'login limit over') {
          this.popupMessage = `契約プランの同時ログイン数の上限を超えている可能性があります。\nログインの数を確認したうえで再度お試しください。`
        } else {
          this.popupMessage = 'メールアドレスもしくはパスワードが無効です。'
        }
        this.alertFlg = true
      }
      this.waitFlg = false
    },
    closeAlert() {
      this.alertFlg = false
    }
  }
}
</script>

<style lang="scss" scoped>
.login {
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  height: 100%;
  > .login-text {
    margin: 55px 0 30px;
    font-size: 15px;
    font-weight: bold;
  }
  > .text-input-password {
    margin: 30px 0 20px;
  }
  > .login-button {
    margin: 30px 0 20px;
  }
  > .forget-password {
    font-size: 13px;
    color: #{$pumpkin};
  }
}
</style>
