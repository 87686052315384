import store from '@/store/store'
import { io } from 'socket.io-client'
import { mixinDataUpdate } from '@/components/mixins/ApiDataUpdate'

export async function mixinSocketConnection() {
  const token = store.getters['auth/token']
  const connect = (io, token) => {
    return io.connect(process.env.VUE_APP_SOCKET_URL, {
      transports: ['websocket'],
      query: `token=${token}`
    })
  }
  const socket = await connect(io, token)
  socket.on('connect', () => {
    store.dispatch('petorelu/setSocket', socket)
  })
  socket.on('logout', () => {
    store.dispatch('auth/showLogoutPopup')
  })
  socket.on('updateTables', async data => {
    if (
      data.allFlg ||
      data.errFlg ||
      data.socketId !== store.getters['petorelu/get'].socket.id
    )
      await mixinDataUpdate(data)
  })
  socket.on(
    'setLockingClientKeys',
    ({ patient, medicalRecord, hospitalization }) => {
      store.dispatch('auth/setLockingClientKeys', {
        patient,
        medicalRecord,
        hospitalization
      })
    }
  )
  socket.on(
    'setFlashLockParams',
    ({ patientId, medicalRecordOriginalId, hospitalizationId }) => {
      store.dispatch('auth/setFlashLockParams', {
        patientId,
        medicalRecordOriginalId,
        hospitalizationId
      })
    }
  )
}

export default {
  methods: {
    mixinSocketConnection
  }
}
