import { getAxiosObject } from '@/utils/library'
import {
  commonGetters,
  commonActions,
  commonMutations
} from '@/store/store_utils'
import { returnLeftAlignMessageReservationPurposes } from '@/utils/check_reservable_days'

const dataName = 'reservationPurposes'

const state = {
  reservationPurposes: [],
  dataIndexedById: {},
  updatedAt: '20101010101010'
}

const getters = {
  ...commonGetters(
    dataName,
    ['getData', 'getDataById', 'selectData', 'selectDataZero', 'getUpdatedAt'],
    { filterDel: true }
  )
}

const actions = {
  ...commonActions(['setData', 'setUpdatedAt', 'updateAfter']),
  async create({ commit }, obj) {
    try {
      const axiosObject = getAxiosObject()
      const res = await axiosObject.post('/reservation-purposes', obj)
      return afterProcess(commit, res, obj)
    } catch (err) {
      return err.response?.data?.message
        ? err.response.data.message === 'no data'
          ? { status: err.response.status, extra: err.response.data.extra }
          : err.response.data.message
        : { status: err.response?.status || '', extra: 'server error' }
    }
  },
  async update({ commit }, obj) {
    try {
      const axiosObject = getAxiosObject()
      const res = await axiosObject.put('/reservation-purposes', obj)
      return afterProcess(commit, res, obj)
    } catch (err) {
      if (err.response?.data?.extra?.data) {
        commit('setData', err.response.data.extra.data)
      }
      return err.response?.data?.message
        ? err.response.data.message === 'no data' ||
          err.response.data.message === 'no data in clinic'
          ? { status: err.response.status, extra: err.response.data.extra }
          : err.response.data.message
        : { status: err.response?.status || '', extra: 'server error' }
    }
  },
  async delete({ commit }, id) {
    try {
      const axiosObject = getAxiosObject()
      const res = await axiosObject.delete('/reservation-purposes', {
        data: { id }
      })
      commit('updateAfter', [res.data.reservationPurpose])
      commit(
        'specialReservationAvailablePatterns/updateAfter',
        res.data.specialReservationAvailablePatterns,
        { root: true }
      )
      return true
    } catch (error) {
      if (error.response?.data?.extra?.data) {
        commit('setData', error.response.data.extra.data)
        return error.response.data.message
      } else if (error.response?.data?.message === 'invalid data') {
        return `${error.response.data.extra}`
      } else {
        return 'server error'
      }
    }
  }
}

const afterProcess = (commit, res, obj) => {
  commit('updateAfter', [res.data.reservationPurpose])
  commit(
    'specialReservationAvailablePatterns/updateAfter',
    res.data.specialReservationAvailablePatterns,
    { root: true }
  )
  let leftAlignMessage = ''
  if (obj.reservationPurpose.customFlg === 1) {
    leftAlignMessage = returnLeftAlignMessageReservationPurposes(obj)
  }
  return { status: res.status, leftAlignMessage }
}

const mutations = {
  ...commonMutations(dataName, ['setData', 'updateAfter', 'setUpdatedAt'], {
    order: true,
    indexedById: true,
    csvColumns: [
      'reservationColumnsIds',
      'staffsIds',
      'reservationAvailablePatternsIds'
    ]
  })
}

export default {
  namespaced: true,
  state,
  mutations,
  getters,
  actions
}
