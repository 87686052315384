import { getAxiosObject } from '@/utils/library'
import {
  commonGetters,
  commonActions,
  commonMutations
} from '@/store/store_utils'

const dataName = 'receivedMails'

const state = {
  receivedMails: [],
  dataIndexedById: {},
  updatedAt: '20101010101010',
  count: 0
}

const getters = {
  ...commonGetters(
    dataName,
    ['getData', 'getDataById', 'getUpdatedAt', 'getCount'],
    { filterDel: true }
  )
}

const actions = {
  ...commonActions(['setData', 'setUpdatedAt', 'updateAfter', 'setCount']),
  async readAll({ commit }, readIds) {
    try {
      const axiosObject = getAxiosObject()
      const res = await axiosObject.put('/received-mails/all', { readIds })
      if (res.status === 200) {
        commit('read', res.data.readIds)
        commit('setCount', res.data.receivedMailsNoReadCount)
        return true
      }
    } catch (error) {
      return false
    }
  },
  async read({ commit }, id) {
    try {
      const axiosObject = getAxiosObject()
      const res = await axiosObject.put('/received-mails', { id })
      if (res.status === 200) {
        commit('read', [res.data.id])
        commit('setCount', res.data.receivedMailsNoReadCount)
        return true
      }
    } catch (error) {
      if (error.response?.data?.message) {
        if (error.response.data.extra?.data) {
          commit('setData', error.response.data.extra.data)
        }
        return error.response.data.message
      } else {
        return 'server error'
      }
    }
  },
  async delete({ commit }, id) {
    try {
      const axiosObject = getAxiosObject()
      const res = await axiosObject.delete('/received-mails', {
        data: { id }
      })
      if (res.status === 200) {
        commit('delete', res.data.id)
        commit('setCount', res.data.receivedMailsNoReadCount)
        return true
      }
    } catch (error) {
      if (error.response?.data?.message) {
        if (error.response?.data?.extra?.data) {
          commit('setData', error.response.data.extra.data)
        }
        return error.response.data.message
      } else {
        return 'server error'
      }
    }
  },
  async search(
    { commit },
    { startDate, endDate, sortType, order, filter, page }
  ) {
    try {
      const axiosObject = getAxiosObject()
      const res = await axiosObject.get('/received-mails', {
        params: { startDate, endDate, sortType, order, filter, page }
      })
      commit('updateAfterSearch', res.data.receivedMails)
      commit('setCount', res.data.receivedMailsNoReadCount)
      return {
        result: 'success',
        hitAllDataCounts: res.data.hitAllDataCounts,
        receivedMails: res.data.receivedMails
      }
    } catch (error) {
      return { result: 'failure' }
    }
  }
}

const mutations = {
  ...commonMutations(
    dataName,
    [
      'setData',
      'setUpdatedAt',
      'read',
      'delete',
      'updateAfter',
      'updateAfterSearch',
      'setCount'
    ],
    { indexedById: true, csvColumns: ['reservationIds'] }
  )
}

export default {
  namespaced: true,
  state,
  mutations,
  getters,
  actions
}
