<template>
  <focus-trap :escapeDeactivates="false" :clickDeactivates="false">
    <div class="announce-popup" @keyup.esc.stop="escAction" tabindex="-1">
      <base-back-ground-cover :layerNumber="layerNumber">
        <div class="popup" :style="{ ...popupStyle, zIndex }">
          <div
            :class="{ alert: isAlert, success: isSuccess, failure: isFailure }"
          >
            <div class="popup-title">{{ title }}</div>
          </div>
          <div class="mark">
            <img
              src="@/assets/images/alert.png"
              v-if="isAlert"
              width="80px"
              height="83px"
              alt="alert"
            />
            <img
              src="@/assets/images/success.png"
              v-if="isSuccess"
              width="90px"
              height="90px"
              alt="success"
            />
            <img
              src="@/assets/images/failure.png"
              v-if="isFailure"
              width="72px"
              height="72px"
              alt="failure"
            />
          </div>
          <div class="popup-content">
            <div class="text"><slot></slot></div>
          </div>
          <div class="popup-sub-content" v-if="leftAlignMessage">
            <div class="text">{{ leftAlignMessage }}</div>
          </div>
          <div class="button">
            <slot name="button"></slot>
          </div>
          <div class="popup-footer">
            <slot name="footer">
              <popup-footer-one-button
                v-if="buttonNum === 1"
                @close="close"
                :text="buttons[0]"
                :oneButtonColor="oneButtonColor"
              />
              <popup-footer-two-buttons
                v-if="buttonNum === 2"
                @cancel="cancel"
                @decision="decision"
                :cancelText="buttons[0]"
                :decisionText="buttons[1]"
                :disabledFlg="disabled"
              />
            </slot>
          </div>
        </div>
      </base-back-ground-cover>
    </div>
  </focus-trap>
</template>

<script>
import BaseBackGroundCover from '@/components/parts/atoms/BaseBackGroundCover'
import PopupFooterTwoButtons from '@/components/parts/molecules/PopupFooterTwoButtons'
import PopupFooterOneButton from '@/components/parts/molecules/PopupFooterOneButton'
import { FocusTrap } from 'focus-trap-vue'

export default {
  name: 'AnnouncePopup',

  components: {
    PopupFooterOneButton,
    PopupFooterTwoButtons,
    BaseBackGroundCover,
    FocusTrap
  },

  props: {
    layerNumber: { type: Number, default: 1 },
    type: { type: String },
    title: { type: String },
    leftAlignMessage: { type: String, default: '' },
    buttons: {
      type: Array,
      default: () => ['閉じる'],
      validator: function(arr) {
        if (arr.length !== 1 && arr.length !== 2) return false
        arr.forEach(v => {
          if (typeof v !== 'string') return false
        })
        return true
      }
    },
    disabled: { type: Boolean },
    oneButtonColor: { type: String, default: 'white' },
    popupStyle: { type: Object, default: () => {} }
  },

  computed: {
    buttonNum: function() {
      return this.buttons.length
    },
    isAlert() {
      return this.type === 'alert'
    },
    isSuccess() {
      return this.type === 'success'
    },
    isFailure() {
      return this.type === 'failure'
    },
    zIndex() {
      return this.layerNumber * 1000 + 100
    }
  },

  methods: {
    close() {
      this.$emit('close')
    },
    cancel() {
      this.$emit('cancel')
    },
    decision() {
      this.$emit('decision')
    },
    escAction() {
      this.buttonNum === 1 ? this.close() : this.cancel()
    }
  }
}
</script>

<style lang="scss" scoped>
@mixin header() {
  border-bottom: solid 1px #{$light-grey};
  display: flex;
  height: 46.5px;
  width: 100%;
  justify-content: center;
  align-items: center;
  > .popup-title {
    text-align: center;
    width: 100%;
    font-size: 20px;
    font-weight: bold;
    color: #{$white};
  }
}
.announce-popup {
  position: absolute;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  .popup {
    max-width: calc(100% - 10px);
    display: flex;
    background-color: white;
    display: flex;
    flex-direction: column;
    justify-content: space-between;
    > .alert,
    .failure {
      @include header();
      background-color: #{$tomato};
    }
    > .success {
      @include header();
      background-color: #{$pumpkin};
    }
    > .mark {
      margin-top: 20px;
      text-align: center;
    }
    > .popup-content {
      text-align: center;
      margin: 20px;
      max-height: 300px;
      overflow-y: auto;
      font-size: 18px;
      > .text {
        white-space: pre-line;
      }
    }
    > .popup-sub-content {
      text-align: justify;
      margin: 0px 20px 20px 20px;
      > .text {
        font-size: 15px;
        white-space: pre-line;
      }
    }
    > .popup-footer {
      display: flex;
      margin-top: auto;
      padding-top: 20px;
      padding-bottom: 33px;
      justify-content: center;
      min-width: 244px;
    }
  }
}
</style>
