<template>
  <focus-trap :escapeDeactivates="false" :clickDeactivates="false">
    <div class="examination-popup" @keyup.esc.stop="checkDiff" tabindex="-2">
      <validation-observer class="validation-observer" v-slot="{ invalid }">
        <popup
          :buttons="['閉じる', '登録']"
          :invalid="invalid"
          :contentStyle="{}"
          @cancel="checkDiff"
          @decision="copy"
          @click-close-mark="checkDiff"
        >
          <template v-slot:content>
            <div class="content">
              <div class="title">検査登録詳細</div>
              <div class="info-table">
                <div class="table-header">
                  <div class="cell">登録日</div>
                  <div class="cell">検査名</div>
                  <div class="cell">表示種別</div>
                </div>
                <div class="table-body">
                  <div class="cell">
                    <v-date-picker
                      data-test="v-date-picker"
                      :input-props="{ class: 'v-date-picker', readonly: true }"
                      is-required
                      v-model="date"
                    />
                  </div>
                  <div class="cell">
                    <div
                      class="text"
                      data-test="treatment-name"
                      :title="treatment.name"
                    >
                      {{ treatment.name }}
                    </div>
                  </div>
                  <div class="cell">
                    <div class="text" data-test="species-name">
                      {{ speciesName }}
                    </div>
                  </div>
                </div>
              </div>
              <div
                class="topic-class"
                v-for="topicClass in displayTopicClasses"
                :key="topicClass.id"
                data-test="topic-class"
              >
                <div class="topic-class-name">{{ topicClass.name }}</div>
                <div class="result-table">
                  <div class="table-header">
                    <div class="cell">項目名</div>
                    <div class="cell">結果（単位）</div>
                    <div class="cell">基準値</div>
                    <div class="cell">コメント</div>
                  </div>
                  <div class="table-body">
                    <div
                      class="row"
                      data-test="topic"
                      v-for="topic in topicClass.topics"
                      :key="topic.id"
                    >
                      <div class="cell">
                        <div class="text" :title="topic.name">
                          {{ topic.name }}
                        </div>
                      </div>
                      <div class="cell">
                        <div class="input">
                          <text-input-form
                            class="result number"
                            data-test="result-number"
                            v-if="topic.type === '数値'"
                            v-model="results[topic.id].result"
                            :maxlength="24"
                            :validationRules="{ floatRule: true }"
                            :styles="{
                              width: '90px',
                              height: '26px',
                              fontSize: '13px'
                            }"
                            :fullWidthNumbersToHalfWidthNumbers="true"
                            @input="
                              inputResult({
                                val: results[topic.id].result,
                                topic: topic
                              })
                            "
                          />
                          <text-input-form
                            class="result text"
                            data-test="result-text"
                            v-if="topic.type === 'テキスト'"
                            v-model="results[topic.id].result"
                            :styles="{
                              width: '90px',
                              height: '26px',
                              fontSize: '13px'
                            }"
                          />
                          <base-select-box
                            class="result"
                            v-if="topic.type === '選択'"
                            v-model="results[topic.id].selectId"
                            :selectData="topic.selectData"
                            :styles="{
                              width: '90px',
                              height: '26px',
                              fontSize: '13px'
                            }"
                            @input="selectOption({ val: $event, topic: topic })"
                          />
                          <div
                            class="unit"
                            data-test="unit"
                            :title="topic.unit"
                          >
                            {{ topic.unit ? '（' + topic.unit + '）' : '' }}
                          </div>
                        </div>
                      </div>
                      <div class="cell">
                        <div
                          class="text"
                          data-test="reference"
                          :title="topic.reference"
                        >
                          {{ topic.reference }}
                        </div>
                      </div>
                      <div class="cell">
                        <div class="input">
                          <text-input-form
                            class="result"
                            data-test="comment"
                            :styles="{
                              width: '165px',
                              height: '26px',
                              fontSize: '13px'
                            }"
                            v-model="results[topic.id].comment"
                          />
                        </div>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </template>
        </popup>
        <announce-popup
          v-if="popup.popupFlg"
          v-bind="popup"
          @cancel="resetPopup"
          @decision="popup.decide"
          @close="closePopup"
          >{{ popup.content }}</announce-popup
        >
      </validation-observer>
    </div>
  </focus-trap>
</template>

<script>
import BaseSelectBox from '@/components/parts/atoms/BaseSelectBox'
import Popup from '@/components/popups/Popup'
import TextInputForm from '@/components/parts/molecules/TextInputForm.vue'
import AnnouncePopup from '@/components/popups/AnnouncePopup'
import CheckPopupInputDifference from '@/components/mixins/CheckPopupInputDifference'
import { FocusTrap } from 'focus-trap-vue'
import { mapGetters } from 'vuex'
import { ValidationObserver } from 'vee-validate'
import _ from 'lodash'
import moment from 'moment'

export default {
  name: 'ExaminationPopup',

  components: {
    Popup,
    BaseSelectBox,
    FocusTrap,
    TextInputForm,
    ValidationObserver,
    AnnouncePopup
  },

  mixins: [CheckPopupInputDifference],

  props: {
    treatment: { type: Object },
    format: { type: Object },
    examinationResultGroups: { type: Object },
    examinationResultGroupsIncludeDel: { type: Object },
    changeExaminationResultGroupId: { type: Number, default: 0 },
    patientId: { type: Number },
    medicalRecordDate: { type: String }
  },

  data() {
    return {
      date: '',
      speciesId: 0,
      speciesName: '',
      displayTopicClasses: [],
      results: {},
      initialData: {},
      popup: {
        popupFlg: false,
        type: '',
        title: '',
        buttons: [],
        layerNumber: 3,
        content: '',
        decide: () => {}
      }
    }
  },

  computed: {
    ...mapGetters({
      examinationTopicClasses: 'examinationTopicClasses/getData',
      getExaminationTopicsByClassOriginalId:
        'examinationTopics/getDataByExaminationTopicClassOriginalId',
      examinationSpecies: 'examinationSpecies/getData',
      getSpecies: 'species/getDataById',
      getPatient: 'patients/getDataById'
    }),
    topicClasses() {
      return this.examinationTopicClasses.filter(
        v => v.examinationFormatOriginalId === this.format.originalId
      )
    },
    diffFlg() {
      return !_.isEqual(this.initialData, {
        date: moment(this.date).format('YYYYMMDD'),
        results: this.results
      })
    }
  },

  created() {
    if (this.changeExaminationResultGroupId) {
      this.examinationResultGroups[this.changeExaminationResultGroupId].forEach(
        v => {
          this.results[v.examinationTopicId] = _.cloneDeep(v)
        }
      )
      this.date = moment(
        this.examinationResultGroups[this.changeExaminationResultGroupId][0]
          .date
      ).toDate()
      this.speciesId = this.examinationResultGroups[
        this.changeExaminationResultGroupId
      ][0].speciesId
      this.speciesName = this.getSpecies(this.speciesId)?.name || ''
      this.displayTopicClasses = this.makeDisplayTopicClasses(true)
    } else {
      this.date = moment(this.medicalRecordDate).toDate() ?? new Date()
      const patient = this.$store.getters['patients/getDataById'](
        this.patientId
      )
      this.speciesId = patient?.speciesId || 0
      this.speciesName = this.getSpecies(this.speciesId)?.name || ''

      this.displayTopicClasses = this.makeDisplayTopicClasses(true)
    }
    this.initialData = {
      date: moment(this.date).format('YYYYMMDD'),
      results: _.cloneDeep(this.results)
    }
  },

  methods: {
    makeDisplayTopicClasses(firstTimeProcessFlg) {
      const data = this.topicClasses.map(topicClass => {
        const topics = this.getExaminationTopicsByClassOriginalId(
          topicClass.originalId
        ).map(topic => {
          const examinationSpecies = this.examinationSpecies.filter(
            v => v.examinationTopicOriginalId === topic.originalId
          )
          const examinationSpeciesDatum = examinationSpecies.find(s => {
            return s.speciesId === this.speciesId
          })
          const comments = JSON.parse(topic.comments)
          const selectData =
            topic.examinationType === '選択'
              ? comments.options.map((v, i) => {
                  return { id: i, name: v[0] }
                })
              : []
          const reference = examinationSpeciesDatum?.reference || ''
          const numFlg = topic.examinationType === '数値' && reference !== ''
          const min = numFlg ? reference.split(',')[0] : ''
          const max = numFlg ? reference.split(',')[1] : ''
          const referenceValue =
            min === '' && max === '' ? reference : `${min} – ${max}`
          const minMax =
            topic.examinationType === '数値'
              ? {
                  min: min === '' ? NaN : Number(min),
                  minComment: comments.minMax[0],
                  max: max === '' ? NaN : Number(max),
                  maxComment: comments.minMax[1]
                }
              : {}
          if (!this.changeExaminationResultGroupId) {
            const maxExaminationResultGroupId =
              Object.keys(this.examinationResultGroupsIncludeDel).length > 0
                ? Math.max.apply(
                    null,
                    Object.keys(this.examinationResultGroupsIncludeDel)
                  )
                : 0
            this.$set(this.results, topic.id, {
              result: '',
              comment: comments.comment,
              examinationResultGroupId: maxExaminationResultGroupId + 1,
              speciesId: this.speciesId,
              speciesName: this.speciesName,
              examinationSpeciesId: examinationSpeciesDatum.id,
              patientId: this.patientId,
              treatmentId: this.treatment.id,
              examinationFormatId: this.format.id,
              examinationTopicClassId: topicClass.id,
              examinationTopicId: topic.id,
              treatmentName: this.treatment.name,
              topicClassName: topicClass.name,
              topicName: topic.name,
              unit: topic.unit,
              reference: referenceValue
            })
          } else if (
            this.changeExaminationResultGroupId &&
            !firstTimeProcessFlg
          ) {
            this.$set(this.results, topic.id, {
              ...this.results[topic.id],
              result: '',
              comment: comments.comment,
              speciesId: this.speciesId,
              speciesName: this.getSpecies(this.speciesId)?.name,
              examinationSpeciesId: examinationSpeciesDatum.id
            })
            this.$delete(this.results[topic.id], 'selectId')
          }
          return {
            id: topic.id,
            name: topic.name,
            type: topic.examinationType,
            unit: topic.unit,
            reference: referenceValue,
            options: comments.options,
            selectData,
            minMax,
            comment: comments.comment
          }
        })
        return {
          id: topicClass.id,
          name: topicClass.name,
          topics
        }
      })
      return data
    },
    async selectOption({ val, topic }) {
      const changeResults = { ...this.results }
      changeResults[topic.id] = {
        ...changeResults[topic.id],
        selectId: val,
        result: topic.options[val][0],
        comment: topic.options[val][1]
      }
      this.results = changeResults
    },
    inputResult({ val, topic }) {
      if (!isNaN(val)) {
        if (!isNaN(topic.minMax.max) && Number(val) > topic.minMax.max) {
          this.results[topic.id].comment = topic.minMax.maxComment
        } else if (!isNaN(topic.minMax.min) && Number(val) < topic.minMax.min) {
          this.results[topic.id].comment = topic.minMax.minComment
        } else {
          this.results[topic.id].comment = topic.comment
        }
      }
    },
    copy() {
      const examinationResultGroup = Object.keys(this.results).map(key => {
        const result = this.results[key]
        return { ...result, date: moment(this.date).format('YYYYMMDD') }
      })
      this.$emit('copy', examinationResultGroup)
    },
    checkDiff() {
      if (this.diffFlg) {
        this.popup = {
          popupFlg: true,
          type: 'alert',
          title: '確認',
          buttons: ['キャンセル', '閉じる'],
          layerNumber: 3,
          content:
            '入力内容を保存せずに閉じようとしています。\nよろしいですか？',
          decide: () => this.closePopup()
        }
      } else {
        this.closePopup()
      }
    },
    closePopup() {
      this.$emit('close')
    },
    resetPopup() {
      this.popup = {
        popupFlg: false,
        type: '',
        title: '',
        buttons: [],
        layerNumber: 3,
        content: '',
        decide: () => {}
      }
    }
  }
}
</script>

<style lang="scss" scoped>
@mixin cell-text {
  flex: 1;
  width: 0;
  white-space: nowrap;
  overflow: hidden;
  text-overflow: ellipsis;
}
.examination-popup {
  position: absolute;
  top: 0;
  left: 0;
  .content {
    width: 700px;
    font-size: 13px;
    > .title {
      margin-bottom: 20px;
      font-size: 16px;
      font-weight: bold;
      color: #{$greyish-brown};
    }
    > .info-table {
      box-sizing: border-box;
      display: grid;
      grid-template-rows: 28px 34px;
      gap: 1px;
      border: solid 1px #{$light-grey};
      background-color: #{$light-grey};
      > .table-header {
        box-sizing: border-box;
        display: grid;
        grid-template-columns: repeat(3, 1fr);
        gap: 1px;
        > .cell {
          box-sizing: border-box;
          line-height: 28px;
          background-color: #{$very-light-shade-orange};
          font-size: 10px;
          font-weight: bold;
          text-align: center;
        }
      }
      > .table-body {
        box-sizing: border-box;
        display: grid;
        grid-template-columns: repeat(3, 1fr);
        gap: 1px;
        > .cell {
          box-sizing: border-box;
          padding: 0 4px;
          display: flex;
          justify-content: center;
          align-items: center;
          background-color: #{$white};
          font-size: 13px;
          text-align: center;
          ::v-deep {
            > span {
              .v-date-picker {
                text-align: right;
                padding-right: 12px;
                width: 140px;
                height: 26px;
                border-radius: 5px;
                border: solid 2px #{$light-grey};
                text-indent: 5px;
                cursor: pointer;
                background-image: url('../../assets/images/calendar.png');
                background-size: 18px 18px;
                background-repeat: no-repeat;
                background-position: 5px center;
              }
            }
          }
          > .text {
            @include cell-text;
          }
        }
      }
    }
    > .topic-class {
      margin: 20px 0;
      > .topic-class-name {
        overflow-wrap: break-word;
        word-break: keep-all;
      }
      > .result-table {
        box-sizing: border-box;
        display: grid;
        grid-template-rows: 28px auto;
        gap: 1px;
        border: solid 1px #{$light-grey};
        background-color: #{$light-grey};
        > .table-header {
          box-sizing: border-box;
          display: grid;
          grid-template-columns: repeat(4, 1fr);
          gap: 1px;
          > .cell {
            box-sizing: border-box;
            line-height: 28px;
            background-color: #{$very-light-shade-orange};
            font-size: 10px;
            font-weight: bold;
            text-align: center;
          }
        }
        > .table-body {
          display: grid;
          grid-template-rows: auto;
          > .row {
            box-sizing: border-box;
            height: 40px;
            display: grid;
            grid-template-columns: repeat(4, 1fr);
            gap: 1px;
            > .cell {
              box-sizing: border-box;
              padding: 0 4px;
              display: flex;
              align-items: center;
              background-color: #{$white};
              font-size: 13px;
              text-align: center;
              > .text {
                @include cell-text;
              }
              > .input {
                flex: 1;
                display: flex;
                justify-content: flex-start;
                align-items: center;
                ::v-deep .heading {
                  display: none;
                }
                ::v-deep .error {
                  z-index: 1;
                  width: 100%;
                  line-height: 12px;
                  font-size: 10px !important;
                  white-space: nowrap;
                }
                > .unit {
                  @include cell-text;
                  text-align: left;
                }
              }
            }
          }
        }
      }
    }
  }
}
</style>
