import { CONSUMPTION_TAX_RATE } from '@/utils/define'
import { makePageSize } from '@/utils/print_utils'
import { createPdf } from 'pdfmake/build/pdfmake.min.js'
import moment from 'moment'
import '@/utils/vfs_fonts'
import _ from 'lodash'

const makeClinicText = ({
  fontSize,
  clinic,
  showClinicName,
  showInvoiceNumber
}) => {
  let rows = showClinicName
    ? [{ text: clinic.name, fontSize: fontSize + 1 }]
    : []
  if (clinic.tel) rows.push({ text: 'TEL ' + clinic.tel, fontSize })
  if (clinic.postalCode) rows.push({ text: `〒${clinic.postalCode}`, fontSize })
  if (clinic.prefectureId || clinic.address)
    rows.push({ text: clinic.prefectureName + clinic.address, fontSize })
  if (clinic.building) rows.push({ text: clinic.building, fontSize })
  if (showInvoiceNumber) {
    rows.push({ text: `登録番号 ${clinic.invoiceNumber}`, fontSize })
  }
  return rows
}

const centerContent = content => {
  return {
    columns: [{ text: '', width: '*' }, content, { text: '', width: '*' }]
  }
}

const hLine = ratio => {
  return {
    type: 'line',
    x1: 20 * ratio,
    y1: 0,
    x2: 510 * ratio,
    y2: 0,
    lineWidth: 0.5
  }
}

const dashedLine = ratio => {
  return {
    type: 'line',
    x1: -10,
    y1: 0,
    x2: 560 * ratio,
    y2: 0,
    lineWidth: 0.5,
    dash: { length: 3, space: 3 }
  }
}

const bigRect = ratio => {
  return {
    type: 'rect',
    x: 0,
    y: 0,
    w: 64 * ratio,
    h: 74 * ratio,
    lineWidth: 0.5,
    lineColor: '#c6c6c6',
    color: 'white'
  }
}

const smallRect = ratio => {
  return {
    type: 'rect',
    x: 0,
    y: 0,
    w: 40 * ratio,
    h: 40 * ratio,
    lineWidth: 0.5,
    lineColor: '#c6c6c6',
    color: 'white'
  }
}

// 「印紙」のところ
const bigStampObject = ratio => {
  return {
    stack: [
      { canvas: [bigRect(ratio)] },
      {
        columns: [{ width: bigRect(ratio).w, text: '印紙', color: '#c6c6c6' }],
        relativePosition: {
          x: bigRect(ratio).w / 2 - 12,
          y: -bigRect(ratio).h / 2 - 6
        }
      },
      {
        canvas: [_.cloneDeep(smallRect(ratio))],
        relativePosition: {
          x: (bigRect(ratio).w / 4) * 3,
          y: -(bigRect(ratio).h + smallRect(ratio).h) / 2
        }
      },
      {
        columns: [{ width: smallRect(ratio).w, text: '印', color: '#c6c6c6' }],
        relativePosition: {
          x: bigRect(ratio).w - 4,
          y: -bigRect(ratio).h / 2 - 6
        }
      }
    ],
    margin: [0, 5 * ratio, 20 * ratio, 0],
    width: 108 * ratio
  }
}

// 「印」のところ
const smallStampObj = ratio => {
  return {
    stack: [
      { canvas: [_.cloneDeep(smallRect(ratio))] },
      {
        columns: [{ width: smallRect(ratio).w, text: '印', color: '#c6c6c6' }],
        relativePosition: {
          x: smallRect(ratio).w / 2 - 6,
          y: -smallRect(ratio).h / 2 - 6
        }
      }
    ],
    margin: [0, 0, 20 * ratio, 0],
    width: 60 * ratio
  }
}

export const makeDocDefinition = ({
  printOptions,
  clinic,
  clinicImage,
  payment
}) => {
  const paperSize = printOptions.paperSize
  const ratio = paperSize === 'A4' ? 1 : paperSize === 'B5' ? 0.84 : 0.7
  const clinicText = makeClinicText({
    fontSize: 9,
    clinic,
    showClinicName: printOptions.showClinicName,
    showInvoiceNumber: printOptions.showInvoiceNumber
  })
  const signature = clinicImage
    ? {
        columns: [
          { text: '', width: '*' },
          {
            image: clinicImage,
            fit: printOptions.showClinicName
              ? [75 * ratio, 75 * ratio]
              : [150 * ratio, 75 * ratio],
            height: 'auto',
            width: 'auto'
          },
          {
            stack: clinicText,
            width: 'auto',
            alignment: 'right',
            margin: [0, 0, 10, 5 * ratio]
          },
          smallStampObj(ratio)
        ]
      }
    : {
        columns: [
          { text: '', width: '*' },
          {
            stack: clinicText,
            width: 250,
            alignment: 'right',
            margin: [0, 0, 10, 5 * ratio]
          },
          smallStampObj(ratio)
        ]
      }
  const contentBody = [
    [{ text: `${printOptions.ownerName}様`, style: 'ownerStyle' }],
    [
      {
        text: `¥${payment.burdenAmount.toLocaleString()}-`,
        style: 'amountStyle'
      }
    ],
    [{ canvas: [hLine(ratio)] }],
    [
      {
        columns: [
          { text: '', width: '*' },
          {
            width: 300 * ratio,
            text: printOptions.proviso
              ? `但し、${printOptions.proviso} として（${
                  payment.taxExemptItemsFlg ? '※' : ''
                }適用税率${CONSUMPTION_TAX_RATE}%）\n上記金額、正に領収いたしました。${
                  payment.taxExemptItemsFlg ? '\n※非課税項目は適用から除く' : ''
                }`
              : '上記金額、正に領収いたしました。',
            alignment: 'center'
          },
          {
            width: '*',
            columns: [{ text: '', width: '*' }, bigStampObject(ratio)]
          }
        ]
      }
    ],
    [
      {
        text: moment(printOptions.receiptDate).format('Y年M月D日'),
        style: 'dateStyle'
      }
    ],
    [signature]
  ]

  let content
  if (printOptions.printCopy) {
    content = [
      centerContent({
        layout: 'contentTable',
        width: 'auto',
        table: {
          widths: [530 * ratio],
          body: [
            [{ text: '領収書', style: 'headerStyle' }],
            ..._.cloneDeep(contentBody)
          ]
        }
      }),
      { canvas: [dashedLine(ratio)], margin: [0, 15 * ratio, 0, 15 * ratio] },
      centerContent({
        layout: 'contentTable',
        width: 'auto',
        table: {
          widths: [530 * ratio],
          body: [
            [{ text: '領収書(控え)', style: 'headerStyle' }],
            ..._.cloneDeep(contentBody)
          ]
        }
      })
    ]
  } else {
    content = centerContent({
      layout: 'contentTable',
      width: 'auto',
      table: {
        widths: [530 * ratio],
        body: [[{ text: '領収書', style: 'headerStyle' }], ...contentBody]
      }
    })
  }

  return {
    content,
    styles: {
      headerStyle: {
        margin: [0, 5 * ratio, 0, 0],
        fontSize: 22 * ratio,
        alignment: 'center'
      },
      ownerStyle: {
        alignment: 'left',
        decoration: 'underline',
        fontSize: 20 * ratio,
        lineHeight: 1.1,
        margin: [20 * ratio, 0, 20 * ratio, 0]
      },
      amountStyle: { alignment: 'center', fontSize: 24 * ratio },
      dateStyle: {
        alignment: 'right',
        margin: [0, 5 * ratio, 20 * ratio, 5 * ratio]
      }
    },
    defaultStyle: {
      font: 'NotoSansMono',
      fontSize: 11,
      color: '#3e3e3e',
      preserveLeadingSpaces: true
    },
    pageSize: makePageSize(printOptions.paperSize),
    pageMargins: [20 * ratio, 20 * ratio, 20 * ratio, 20 * ratio],
    pageOrientation: 'portrait'
  }
}

// args = {
//   printOptions,
//   clinic,
//   clinicImage,
//   payment
// }
export const printReceipt = args => {
  const docDefinition = makeDocDefinition(args)
  const fonts = {
    NotoSansMono: {
      normal: 'NotoSansMonoCJKJPRegular.otf',
      bold: 'NotoSansMonoCJKJPRegular.otf',
      italics: 'NotoSansMonoCJKJPRegular.otf',
      bolditalics: 'NotoSansMonoCJKJPRegular.otf'
    }
  }
  const tableLayouts = {
    contentTable: {
      hLineWidth: (i, node) => {
        return i === 0 || i === node.table.body.length ? 0.5 : 0
      },
      vLineWidth: () => 0.5
    },
    signatureTable: {
      hLineWidth: () => 0,
      vLineWidth: () => 0
    }
  }
  createPdf(docDefinition, tableLayouts, fonts).open()
}
