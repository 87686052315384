import { getAxiosObject } from '@/utils/library'
import { decodeBase64fromBuffer } from '@/utils/base64'
import {
  commonGetters,
  commonActions,
  commonMutations
} from '@/store/store_utils'

const dataName = 'clinic'

const state = {
  clinic: {},
  encryptedClinicId: '',
  updatedAt: '20101010101010'
}

const getters = {
  ...commonGetters(dataName, ['getData', 'getUpdatedAt'], {}),
  getEncryptedClinicId(state) {
    return state.encryptedClinicId
  }
}

const actions = {
  ...commonActions(['setData', 'setUpdatedAt', 'updateAfter']),
  addClinic({ commit }, obj) {
    commit('setClinic', obj)
  },
  async update({ commit }, clinic) {
    try {
      const axiosObject = getAxiosObject()
      const res = await axiosObject.put('/clinic', { clinic })
      if (res.status === 200) {
        commit('updateAfter', res.data.clinic)
        return true
      }
    } catch (error) {
      return error.response?.data?.message
        ? error.response.data.message === 'no data'
          ? `no ${error.response.data.extra}`
          : error.response.data.message
        : 'server error'
    }
  }
}

const mutations = {
  ...commonMutations(dataName, ['setData', 'setUpdatedAt', 'updateAfter'], {
    base64: true
  }),
  setClinic(state, obj) {
    obj.clinic = decodeBase64fromBuffer(obj.clinic)
    state.clinic = obj.clinic
    state.encryptedClinicId = obj.encryptedClinicId
  }
}

export default {
  namespaced: true,
  state,
  mutations,
  getters,
  actions
}
