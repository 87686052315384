import {
  commonGetters,
  commonMutations,
  commonActions
} from '@/store/store_utils'
import { toStateName } from '@/utils/convert_string'

const dataName = 'ipetPatients'
const keys = ['patientId']

const state = {
  [dataName]: [],
  updatedAt: '20101010101010'
}
keys.forEach(key => {
  state[toStateName(key)] = null
})

const getters = {
  ...commonGetters(dataName, ['getUpdatedAt'], {
    filterDel: true,
    getDataBy: keys
  }),
  getIpetPatient(state, getters) {
    return patientId => {
      const ipetPatients = getters.getDataByPatientId(patientId) || []
      const ipetPatient = ipetPatients.reduce((target, cur) => {
        if (!target || (cur.delFlg === 0 && target.id < cur.id)) target = cur
        return target
      }, null)
      return ipetPatient
    }
  }
}

const actions = {
  ...commonActions(['setData', 'setUpdatedAt', 'updateAfter'])
}

const mutations = {
  ...commonMutations(dataName, ['setData', 'setUpdatedAt', 'updateAfter'], {
    setDataBy: keys
  })
}

export default {
  namespaced: true,
  state,
  getters,
  actions,
  mutations
}
