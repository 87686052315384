import axios from 'axios'
import { getApiUrl, getAxiosObject } from '@/utils/library'
import store from '@/store/store'

export default {
  methods: {
    async mixinSendMail(email) {
      try {
        const axiosObject = getAxiosObject()
        const res = await axiosObject.post('/password-reset/send-mail', {
          email: email
        })
        if (res.status === 200) {
          return true
        }
      } catch (error) {
        return error.response?.data?.message
          ? error.response.data.message === 'no data'
            ? `no ${error.response.data.extra}`
            : error.response.data.message
          : 'server error'
      }
    },
    async mixinAuthenticateToken(token) {
      try {
        const axiosObject = axios.create({
          baseURL: getApiUrl(),
          headers: {
            Authorization: 'Bearer ' + token,
            'Content-Type': 'application/json',
            'Clinic-Version': process.env.VERSION
          }
        })
        const res = await axiosObject.get('/password-reset/authenticate')
        if (res.status === 200) {
          return true
        }
      } catch (error) {
        if (
          error.response?.status === 400 &&
          error.response?.data?.extra === 'not the same as required version'
        ) {
          return error.response.data.extra
        } else {
          return false
        }
      }
    },
    async mixinUpdatePassword(token, password) {
      try {
        const axiosObject = axios.create({
          baseURL: getApiUrl(),
          headers: {
            Authorization: 'Bearer ' + token,
            'Content-Type': 'application/json',
            'Clinic-Version': process.env.VERSION
          }
        })
        const res = await axiosObject.put('/password-reset', {
          password: password
        })
        if (res.status === 200) {
          return true
        }
      } catch (error) {
        if (
          error.response?.status === 400 &&
          error.response?.data.extra === 'not the same as required version'
        ) {
          store.dispatch('petorelu/setClinicVersionUpFlgToTrue')
          return false
        } else {
          return error.response?.data?.message
            ? error.response.data.extra
              ? error.response.data.extra
              : error.response.data.message
            : 'server error'
        }
      }
    }
  }
}
