import { getAxiosObject } from '@/utils/library'
import {
  commonGetters,
  commonMutations,
  commonActions
} from '@/store/store_utils'

const dataName = 'diseaseClasses'

const state = {
  diseaseClasses: [],
  dataIndexedById: {},
  dataIndexedByOriginalId: {},
  updatedAt: '20101010101010'
}

const getters = {
  ...commonGetters(
    dataName,
    [
      'getData',
      'getDataById',
      'getDataByOriginalId',
      'selectDataZero',
      'getUpdatedAt'
    ],
    { filterDel: true, selectZeroText: '新規分類名' }
  )
}

const actions = {
  ...commonActions(['setData', 'setUpdatedAt', 'updateAfter']),
  async delete({ commit }, id) {
    try {
      const axiosObject = getAxiosObject()
      const res = await axiosObject.delete('/disease-classes', {
        data: { id }
      })
      if (res.status === 200) {
        commit('updateAfter', [res.data.diseaseClass])
        commit('diseases/updateAfter', res.data.diseases, { root: true })
        return true
      }
    } catch (error) {
      if (error.response?.data?.extra?.data) {
        commit('setData', error.response.data.extra.data)
        return error.response.data.message
      } else {
        return 'server error'
      }
    }
  },
  async update({ commit }, data) {
    try {
      const axiosObject = getAxiosObject()
      const res = await axiosObject.put('/disease-classes', {
        diseaseClass: data.diseaseClass
      })
      if (res.status === 200) {
        commit('updateAfter', res.data.diseaseClasses)
        commit('diseases/updateAfter', res.data.diseases, { root: true })
        return true
      }
    } catch (error) {
      if (error.response?.data?.message) {
        if (error.response.data.message === 'no data in clinic') {
          commit('setData', error.response.data.extra.data)
          return 'no data in clinic'
        }
        if (error.response.data.message === 'no data') {
          return error.response.data.extra === 'diseaseClass already edited'
            ? 'already edited'
            : 'already deleted'
        } else {
          return error.response.data.message
        }
      } else {
        return 'server error'
      }
    }
  },
  async order({ commit }, idObj) {
    try {
      const axiosObject = getAxiosObject()
      const res = await axiosObject.put('/disease-classes/order', { idObj })
      if (res.status === 200) {
        commit('updateAfter', res.data.diseaseClasses)
        commit('diseases/updateAfter', res.data.diseases, { root: true })
        return true
      }
    } catch (error) {
      return false
    }
  }
}

const mutations = {
  ...commonMutations(dataName, ['setData', 'setUpdatedAt', 'updateAfter'], {
    order: true,
    indexedById: true,
    indexedByOriginalId: true
  })
}

export default {
  namespaced: true,
  state,
  getters,
  actions,
  mutations
}
