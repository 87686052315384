<template>
  <div class="base-button-schema-icon">
    <button
      :class="{ 'active-button': active }"
      type="button"
      :style="styles"
      @click="$emit('click')"
    >
      <div class="icon-text">
        <div
          class="text"
          :class="{ 'active-text': active }"
          v-if="text"
          data-test="text"
        >
          {{ text }}
        </div>
        <img class="icon" v-else :src="src" />
      </div>
    </button>
  </div>
</template>

<script>
export default {
  name: 'BaseButtonSchemaIcon',

  props: {
    text: { type: String, default: '' },
    icon: { type: String, default: '' },
    active: { type: Boolean, default: false },
    styles: {
      type: Object,
      validator: function(obj) {
        return Object.values(obj).every(v => typeof v === 'string')
      }
    }
  },

  computed: {
    src() {
      return this.icon === 'trash'
        ? require(`@/assets/images/trash_orange.png`)
        : this.icon !== '' && this.active
        ? require(`@/assets/images/schema_${this.icon}_white.png`)
        : require(`@/assets/images/schema_${this.icon}_orange.png`)
    }
  },

  methods: {
    handleClick() {
      this.$emit('click')
    }
  }
}
</script>

<style lang="scss" scoped>
.base-button-schema-icon {
  > button {
    width: 86px;
    height: 33px;
    border-radius: 6px;
    border: solid 1px #{$white};
    cursor: pointer;
    background-color: #{$white};
    &:hover {
      opacity: 0.8;
    }
    > .icon-text {
      > .text {
        font-family: Times New Roman;
        font-weight: bold;
        line-height: 30px;
        font-size: 20px;
        color: #{$pumpkin};
      }
      > .active-text {
        color: #{$white};
      }
    }
  }
  > .active-button {
    background-color: #{$pumpkin};
    border: solid 1px #{$pumpkin};
  }
}
</style>
