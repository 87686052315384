import { getAxiosObject } from '@/utils/library'
import {
  commonGetters,
  commonMutations,
  commonActions
} from '@/store/store_utils'

const dataName = 'medicineLargeClasses'

const state = {
  medicineLargeClasses: [],
  dataIndexedById: {},
  dataIndexedByOriginalId: {},
  updatedAt: '20101010101010'
}

const getters = {
  ...commonGetters(
    dataName,
    [
      'getData',
      'getDataById',
      'getDataByOriginalId',
      'getUpdatedAt',
      'selectDataZero'
    ],
    { filterDel: true, selectZeroText: '新規分類名' }
  )
}

const actions = {
  ...commonActions(['setData', 'setUpdatedAt', 'updateAfter']),
  async delete({ commit }, id) {
    try {
      const axiosObject = getAxiosObject()
      const res = await axiosObject.delete('/medicine-large-classes', {
        data: { id }
      })
      if (res.status === 200) {
        commit('updateAfter', [res.data.medicineLargeClass])
        commit(
          'medicineMediumClasses/updateAfter',
          res.data.medicineMediumClasses,
          { root: true }
        )
        commit(
          'medicineSmallClasses/updateAfter',
          res.data.medicineSmallClasses,
          { root: true }
        )
        commit('medicines/updateAfter', res.data.medicines, { root: true })
        return true
      }
    } catch (error) {
      if (error.response?.data?.extra) {
        if (error.response.data.extra.data) {
          commit('setData', error.response.data.extra.data)
        } else {
          return error.response.data.extra
        }
        return error.response.data.message
      } else {
        return 'server error'
      }
    }
  },
  async update({ commit }, medicineLargeClass) {
    try {
      const axiosObject = getAxiosObject()
      const res = await axiosObject.put('/medicine-large-classes', {
        medicineLargeClass
      })
      if (res.status === 200) {
        commit('updateAfter', res.data.medicineLargeClasses)
        commit(
          'medicineMediumClasses/updateAfter',
          res.data.medicineMediumClasses,
          { root: true }
        )
        commit(
          'medicineSmallClasses/updateAfter',
          res.data.medicineSmallClasses,
          { root: true }
        )
        commit('medicines/updateAfter', res.data.medicines, { root: true })
        return true
      }
    } catch (error) {
      if (error.response?.data?.message) {
        if (error.response.data.message === 'no data in clinic') {
          commit('setData', error.response.data.extra.data)
          return 'no data in clinic'
        }
        if (error.response.data.message === 'no data') {
          return error.response.data.extra ===
            'medicineLargeClass already edited'
            ? 'already edited'
            : 'already deleted'
        } else {
          return error.response.data.message
        }
      } else {
        return 'server error'
      }
    }
  },
  async order(
    { commit },
    {
      largeClassesOrderIds,
      mediumClassesOrderIds,
      smallClassesOrderIds,
      orderIds
    }
  ) {
    try {
      const axiosObject = getAxiosObject()
      const res = await axiosObject.put('/medicine-large-classes/order', {
        largeClassesOrderIds,
        mediumClassesOrderIds,
        smallClassesOrderIds,
        orderIds
      })
      if (res.status === 200) {
        commit('updateAfter', res.data.medicineLargeClasses)
        commit(
          'medicineMediumClasses/updateAfter',
          res.data.medicineMediumClasses,
          { root: true }
        )
        commit(
          'medicineSmallClasses/updateAfter',
          res.data.medicineSmallClasses,
          { root: true }
        )
        commit('medicines/updateAfter', res.data.medicines, { root: true })
        return true
      }
    } catch (error) {
      return false
    }
  }
}

const mutations = {
  ...commonMutations(dataName, ['setData', 'setUpdatedAt', 'updateAfter'], {
    order: true,
    indexedById: true,
    indexedByOriginalId: true
  })
}

export default {
  namespaced: true,
  state,
  getters,
  actions,
  mutations
}
