import { getAxiosObject } from '@/utils/library'
import {
  commonGetters,
  commonActions,
  commonMutations
} from '@/store/store_utils'
import {
  ownerRelatedStoreNamesExcludeReservation,
  ownerRelatedStoreNamesExcludeReservationKartePlan
} from '@/utils/delete_owner_patients'

const dataName = 'reservations'

const state = {
  reservations: [],
  dataIndexedById: {},
  updatedAt: '20101010101010',
  obtainedDates: [],
  count: 0
}

const getters = {
  ...commonGetters(
    dataName,
    ['getData', 'getDataById', 'getUpdatedAt', 'getCount'],
    { filterDel: true }
  ),
  getCancelReservations(state) {
    return state[dataName].filter(v => v.cancelFlg === 1 && v.delFlg === 0)
  },
  getPatientReservations: state => patientId => {
    return state[dataName].filter(
      v => v.patientId === patientId && v.delFlg === 0
    )
  },
  getOwnerReservations: state => ownerId => {
    return state[dataName].filter(
      v => v.ownerId === ownerId && v.delFlg === 0 && v.cancelFlg === 0
    )
  },
  getObtainedDates(state) {
    return state.obtainedDates
  }
}

const actions = {
  ...commonActions(['setData', 'setUpdatedAt', 'updateAfter', 'setCount']),
  async create({ commit }, { reservation, reservationRequestId, mailFlgs }) {
    try {
      const axiosObject = getAxiosObject()
      const res = await axiosObject.post('/' + dataName, {
        reservation,
        reservationRequestId,
        mailFlgs
      })
      if (res.status === 200) {
        commit('updateAfter', res.data[dataName])
        if (res.data.reservationRequests) {
          commit('reservationRequests/setData', res.data.reservationRequests, {
            root: true
          })
        }
        if (res.data.sendMail) {
          commit('sendMails/updateAfter', [res.data.sendMail], {
            root: true
          })
        }
        if (res.data.reservationRequestsCount) {
          commit(
            'reservationRequests/setCount',
            res.data.reservationRequestsCount,
            { root: true }
          )
        }
        return {
          result: 'success',
          reservationId: res.data.reservationId,
          reservationIds: res.data.reservations.map(v => v.id),
          sendMailFlg: Boolean(res.data.sendMail),
          insuranceWarnings: res.data.insuranceWarnings
        }
      }
    } catch (error) {
      const regex = /(?=.*expected)(?=.*patients)(?=.*received)(?=.*reservationRequests)/g
      return error.response?.data?.message
        ? error.response.data.message === 'no data'
          ? error.response.data.extra.match(regex)
            ? 'no patient'
            : `no ${error.response.data.extra}`
          : error.response.data.message
        : 'server error'
    }
  },
  async update(
    { commit, rootGetters },
    {
      reservation,
      mailFlgs,
      netReservationSameNameNewOwnerInfo = { ownerId: 0, patientId: 0 }
    }
  ) {
    try {
      const axiosObject = getAxiosObject()
      const res = await axiosObject.put('/' + dataName, {
        reservation,
        mailFlgs,
        netReservationSameNameNewOwnerInfo
      })
      if (res.status === 200) {
        commit('updateAfter', res.data[dataName])
        if (res.data.sendMails.length) {
          commit('sendMails/updateAfter', res.data.sendMails, {
            root: true
          })
        }
        if (netReservationSameNameNewOwnerInfo.ownerId > 0) {
          if (res.data.owner && Object.keys(res.data.owner).length > 0) {
            commit('owners/updateAfter', [res.data.owner], {
              root: true
            })
          }
          const karteFlg = rootGetters['auth/karteFlg']
          const stores = karteFlg // reservationsは上のコードで既にupdateAfterの処理をしている
            ? ownerRelatedStoreNamesExcludeReservationKartePlan
            : ownerRelatedStoreNamesExcludeReservation
          stores.forEach(store => {
            const storeData = res.data[store]
            if (storeData?.length > 0)
              commit(`${store}/updateAfter`, storeData, { root: true })
          })
        }
        return {
          result: 'success',
          reservationId: res.data.reservationId,
          reservationIds: res.data.reservations.map(v => v.id),
          sendMailFlg: res.data.sendMails.length ? true : false,
          insuranceWarnings: res.data.insuranceWarnings,
          isNewOwnerDeleted: Boolean(
            res.data.owner && Object.keys(res.data.owner).length > 0
          )
        }
      }
    } catch (error) {
      if (error.response?.data?.message === 'no data') {
        return `no ${error.response.data.extra}`
      } else if (error.response?.data?.message) {
        if (error.response.data.extra?.data) {
          commit('setData', error.response.data.extra.data)
        }
        return error.response.data.message
      } else {
        return 'server error'
      }
    }
  },
  async cancel({ commit }, { reservation, mailFlg }) {
    try {
      const axiosObject = getAxiosObject()
      const res = await axiosObject.put('/' + dataName + '/cancel', {
        reservation,
        mailFlg
      })
      if (res.status === 200) {
        commit('updateAfter', res.data[dataName])
        commit('setCount', res.data.cancelReservationsNoReadCount)
        if (res.data.sendMail) {
          commit('sendMails/updateAfter', [res.data.sendMail], {
            root: true
          })
        }
        return { result: 'success', sendMailFlg: Boolean(res.data.sendMail) }
      }
    } catch (error) {
      if (error.response?.data?.message) {
        if (error.response.data.extra?.data) {
          commit('setData', error.response.data.extra.data)
        }
        return error.response.data.message
      } else {
        return 'server error'
      }
    }
  },
  async read({ commit }, id) {
    try {
      const axiosObject = getAxiosObject()
      const res = await axiosObject.put('/' + dataName + '/cancel-read', { id })
      if (res.status === 200) {
        commit('readCancelReservations', [res.data.id])
        commit('setCount', res.data.cancelReservationsNoReadCount)
        return true
      }
    } catch (error) {
      if (error.response?.data?.message) {
        if (error.response.data.extra?.data) {
          commit('setData', error.response.data.extra.data)
        }
        return error.response.data.message
      } else {
        return 'server error'
      }
    }
  },
  async readAll({ commit }, readIds) {
    try {
      const axiosObject = getAxiosObject()
      const res = await axiosObject.put('/' + dataName + '/cancel-read-all', {
        readIds
      })
      if (res.status === 200) {
        commit('readCancelReservations', res.data.readIds)
        commit('setCount', res.data.cancelReservationsNoReadCount)
        return true
      }
    } catch (error) {
      return false
    }
  },
  async delete({ commit }, reservation) {
    try {
      const axiosObject = getAxiosObject()
      const res = await axiosObject.delete('/' + dataName, {
        data: { reservation }
      })
      if (res.status === 200) {
        commit('updateAfter', res.data[dataName])
        return true
      }
    } catch (error) {
      if (error.response?.data?.extra?.data) {
        commit('setData', error.response.data.extra.data)
        return error.response.data.message
      } else {
        return 'server error'
      }
    }
  },
  async repeat({ commit }, { reservations }) {
    try {
      const axiosObject = getAxiosObject()
      const res = await axiosObject.post('/' + dataName + '/repeat', {
        reservations
      })
      if (res.status === 200) {
        commit('updateAfter', res.data[dataName])
        if (res.data.sendMails) {
          commit('sendMails/updateAfter', res.data.sendMails, {
            root: true
          })
        }
        return {
          result: 'success',
          sendMailFlg: Boolean(res.data.sendMails),
          reservationIds: res.data.reservations.map(v => v.id)
        }
      }
    } catch (error) {
      return error.response?.data?.message
        ? error.response.data.message
        : 'server error'
    }
  },
  async getPastData({ commit, rootGetters }, dates) {
    try {
      const axiosObject = getAxiosObject()
      const res = await axiosObject.get('/' + dataName + '/past-data', {
        params: { dates }
      })
      commit('updateAfter', res.data[dataName])
      if (rootGetters['auth/karteFlg'] === 1) {
        const storeNames = ['medicalRecords', 'medicalPayments', 'payments']
        storeNames.forEach(storeName => {
          if (res.data[storeName]?.length > 0) {
            commit(`${storeName}/updateAfter`, res.data[storeName], {
              root: true
            })
          }
        })
      }
      return true
    } catch (error) {
      return false
    }
  },
  async search({ commit }, { startDate, endDate, page }) {
    try {
      const axiosObject = getAxiosObject()
      const res = await axiosObject.get('/reservations/search', {
        params: { startDate, endDate, page }
      })
      if (res.status === 200) {
        if (res.data.reservations.length > 0) {
          commit('updateAfterSearch', res.data.reservations)
        }
        return {
          result: true,
          reservations: res.data.reservations,
          hitAllDataCounts: res.data.hitAllDataCounts
        }
      }
    } catch (error) {
      return { result: false }
    }
  },
  addDates({ commit }, dates) {
    commit('addDates', dates)
  },
  async updateStatus({ commit }, { id }) {
    try {
      const axiosObject = getAxiosObject()
      const res = await axiosObject.put('/' + dataName + '/status', { id })
      if (res.status === 200) {
        commit('updateAfter', [res.data.reservation])
        return 'success'
      }
    } catch (error) {
      if (error.response?.data?.message === 'invalid data') {
        return error.response?.data?.extra
      } else if (error.response?.data?.message) {
        if (error.response.data.extra?.data) {
          commit('setData', error.response.data.extra.data)
        }
        return error.response.data.message
      } else {
        return 'server error'
      }
    }
  },
  async updateDuplicateCheckShowFlgToZero({ commit }, { id }) {
    try {
      const axiosObject = getAxiosObject()
      const res = await axiosObject.put(
        '/' + dataName + '/duplicate-check-show-flg',
        { id }
      )
      if (res.status === 200) {
        commit('updateAfter', [res.data.reservation])
        return true
      }
    } catch (error) {
      if (error.response?.data?.message) {
        if (error.response.data.extra?.data) {
          commit('setData', error.response.data.extra.data)
        }
        return error.response.data.message
      } else {
        return 'server error'
      }
    }
  }
}

const mutations = {
  ...commonMutations(
    dataName,
    ['setData', 'setUpdatedAt', 'updateAfter', 'updateAfterSearch', 'setCount'],
    { indexedById: true }
  ),
  readCancelReservations(state, reservationIds) {
    reservationIds.forEach(reservationId => {
      state[dataName].find(v => v.id === reservationId).cancelReadFlg = 1
    })
  },
  addDates(state, dates) {
    state.obtainedDates = state.obtainedDates.concat(dates)
  }
}

export default {
  namespaced: true,
  state,
  getters,
  actions,
  mutations
}
