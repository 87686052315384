import { makeClinicText, makeFooter, makePageSize } from '@/utils/print_utils'
import { createPdf } from 'pdfmake/build/pdfmake.min.js'
import { removeWeekday } from '@/utils/convert_string.js'
import '@/utils/vfs_fonts'

export const makeDocDefinition = ({
  printOptions,
  clinic,
  clinicImage,
  patient,
  treatmentDate,
  estimateDate,
  prescriptionItems
}) => {
  const paperSize = printOptions.paperSize
  const ratio = paperSize === 'A4' ? 1 : paperSize === 'B5' ? 0.84 : 0.7
  const clinicText = makeClinicText({
    ratio,
    clinic,
    clinicImage,
    showClinicName: printOptions.showClinicName
  })
  const contentHeader = {
    columns: [
      {
        stack: [
          {
            //右端の医院情報のどれかの文字数が長い時に「処方箋」の文字が改行されないようwidthを設定。
            //widthはcolumnsと組み合わせて使用しないと反映されないようなので下記のような書き方をしている。
            columns: [
              { text: '処方箋', fontSize: 40 * ratio, width: 260 * ratio }
            ]
          }
        ],
        width: '*'
      },
      { stack: clinicText, width: 'auto', margin: [10, 0, 0, 0] }
    ]
  }
  const patientRow = { text: `${patient.name}様のお薬`, style: 'patientStyle' }
  const displayDate = treatmentDate
    ? `診療日 ${removeWeekday(treatmentDate)}`
    : `見積日 ${removeWeekday(estimateDate)}`
  const dateRow = {
    margin: [0, 0, 6 * ratio, 5],
    alignment: 'right',
    text: displayDate
  }
  const prescriptionTableRows = prescriptionItems.map(v => {
    return [{ text: v.medicineName }, { text: v.prescription }]
  })
  const content = [
    contentHeader,
    patientRow,
    dateRow,
    {
      columns: [
        { width: '*', text: '' },
        {
          width: 'auto',
          layout: 'prescriptionTable',
          table: {
            headerRows: 0,
            widths: [150 * ratio, 370 * ratio],
            body: prescriptionTableRows
          }
        },
        { width: '*', text: '' }
      ]
    }
  ]

  return {
    content,
    footer: (currentPage, pageCount) =>
      makeFooter({
        currentPage,
        pageCount,
        fontSize: 11 * ratio
      }),
    styles: {
      patientStyle: {
        alignment: 'center',
        color: 'black',
        fontSize: 16 * ratio,
        margin: [0, 15, 0, 10]
      }
    },
    defaultStyle: {
      font: 'NotoSansMono',
      fontSize: 12 * ratio,
      color: '#3e3e3e',
      preserveLeadingSpaces: true
    },
    pageSize: makePageSize(printOptions.paperSize),
    pageMargins: [20, 20, 20, 30 + 11 * ratio],
    pageOrientation: 'portrait'
  }
}

// args = {
//   printOptions,
//   clinic,
//   clinicImage,
//   patient,
//   treatmentDate,
//   estimateDate,
//   prescriptionItems
// }
export const printPrescription = args => {
  const docDefinition = makeDocDefinition(args)
  const fonts = {
    NotoSansMono: {
      normal: 'NotoSansMonoCJKJPRegular.otf',
      bold: 'NotoSansMonoCJKJPRegular.otf',
      italics: 'NotoSansMonoCJKJPRegular.otf',
      bolditalics: 'NotoSansMonoCJKJPRegular.otf'
    }
  }
  const tableLayouts = {
    prescriptionTable: {
      hLineColor: '#3e3e3e',
      vLineColor: '#3e3e3e',
      fillColor: (rowIndex, node, colIndex) =>
        colIndex === 0 ? '#f0f0f0' : null
    }
  }
  createPdf(docDefinition, tableLayouts, fonts).open()
}
