import { getAxiosObject } from '@/utils/library'
import {
  commonGetters,
  commonActions,
  commonMutations
} from '@/store/store_utils'

const dataName = 'shifts'

const state = { shifts: [], dataIndexedById: {}, updatedAt: '20101010101010' }

const getters = {
  ...commonGetters(
    dataName,
    [
      'getData',
      'getDataIncludeDel',
      'getDataById',
      'selectData',
      'getUpdatedAt'
    ],
    { filterDel: true }
  )
}

const actions = {
  ...commonActions(['setData', 'setUpdatedAt', 'updateAfter']),
  async createShift({ commit }, shift) {
    try {
      const axiosObject = getAxiosObject()
      const res = await axiosObject.post('/shifts', {
        shift: shift
      })
      if (res.status === 200) {
        commit('updateAfter', [res.data.shift])
        return true
      }
    } catch (error) {
      return false
    }
  },
  async updateShift({ commit }, shift) {
    try {
      const axiosObject = getAxiosObject()
      const res = await axiosObject.put('/shifts', {
        shift: shift
      })
      if (res.status === 200) {
        commit('updateAfter', [res.data.shift])
        return true
      }
    } catch (error) {
      if (error.response?.data?.message) {
        if (error.response?.data?.extra?.data) {
          commit('setData', error.response.data.extra.data)
        }
        return error.response.data.message
      } else {
        return 'server error'
      }
    }
  },
  async deleteShift({ commit }, id) {
    try {
      const axiosObject = getAxiosObject()
      const res = await axiosObject.delete('/shifts', { data: { id } })
      if (res.status === 200) {
        commit('updateAfter', [res.data.shift])
        return true
      }
    } catch (error) {
      if (error.response?.data?.message) {
        if (error.response.data.message === 'currently used') {
          return `using ${error.response.data.extra}`
        } else {
          if (error.response?.data?.extra?.data) {
            commit('setData', error.response.data.extra.data)
          }
          return error.response.data.message
        }
      } else {
        return 'server error'
      }
    }
  }
}

const mutations = {
  ...commonMutations(dataName, ['setData', 'updateAfter', 'setUpdatedAt'], {
    order: true,
    indexedById: true
  })
}

export default {
  namespaced: true,
  state,
  mutations,
  getters,
  actions
}
