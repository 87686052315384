<template>
  <div class="popup-footer-two-buttons">
    <div class="two-buttons">
      <base-button-border-orange
        v-if="buttonBorderOrangeFlg"
        @click="cancelClick"
      >
        {{ cancelText }}</base-button-border-orange
      >
      <base-button-medium-white v-else @click="cancelClick">
        {{ cancelText }}</base-button-medium-white
      >
    </div>
    <div class="two-buttons">
      <base-button-small-orange
        v-if="buttonBorderOrangeFlg"
        @click="decisionClick"
        :disabled="disabledFlg || invalid"
        :styles="{ width: '200px' }"
      >
        {{ decisionText }}
      </base-button-small-orange>
      <base-button-medium-orange
        v-else
        @click="decisionClick"
        :disabled="disabledFlg || invalid"
      >
        {{ decisionText }}
      </base-button-medium-orange>
    </div>
  </div>
</template>

<script>
import BaseButtonBorderOrange from '@/components/parts/atoms/BaseButtonBorderOrange'
import BaseButtonMediumWhite from '@/components/parts/atoms/BaseButtonMediumWhite'
import BaseButtonMediumOrange from '@/components/parts/atoms/BaseButtonMediumOrange'
import BaseButtonSmallOrange from '@/components/parts/atoms/BaseButtonSmallOrange'
export default {
  name: 'PopupFooterTwoButtons',
  components: {
    BaseButtonBorderOrange,
    BaseButtonMediumOrange,
    BaseButtonSmallOrange,
    BaseButtonMediumWhite
  },
  props: {
    cancelText: {
      type: String,
      default: '閉じる'
    },
    decisionText: {
      type: String,
      default: '登録'
    },
    buttonBorderOrangeFlg: { type: Boolean, default: false },
    disabledFlg: { type: Boolean, default: false },
    invalid: { type: Boolean, default: false }
  },
  methods: {
    cancelClick() {
      this.$emit('cancel')
    },
    decisionClick() {
      this.$emit('decision')
    }
  }
}
</script>

<style lang="scss" scoped>
.popup-footer-two-buttons {
  display: flex;
  > .two-buttons {
    margin-left: 22px;
    margin-right: 22px;
    ::v-deep .base-button-medium-white button span {
      font-size: 13px;
    }
    ::v-deep .base-button-small-orange button span {
      font-size: 13px;
    }
    ::v-deep .base-button-medium-orange button span {
      font-size: 13px;
    }
  }
}
</style>
