<template>
  <div class="vaccine-check-list-item">
    <div class="radio" @click="checkVaccine">
      <div class="input">
        <div class="outside-circle" :class="{ checked }">
          <div class="inside-circle" v-if="checked"></div>
        </div>
      </div>
      <div class="label">
        <div class="name">{{ displayVaccine.name }}</div>
        <div class="mix-names" v-if="displayVaccine.mixNames.length > 0">
          <div
            v-for="mixName in displayVaccine.mixNames"
            :key="mixName"
            class="mix-name"
          >
            {{ mixName }} <span class="slash">{{ '/' }}</span>
          </div>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
export default {
  name: 'VaccineCheckListItem',

  props: {
    checkedId: { type: Number, default: 0 },
    vaccine: { type: Object, required: true }
  },

  computed: {
    checked() {
      return this.checkedId === this.vaccine.id ? true : false
    },
    displayVaccine() {
      const index = this.vaccine.mixNames.indexOf('犬レプトスピラ症')
      const leptospirosis =
        index !== -1 ? this.vaccine.mixNames.slice(index) : ''
      const mixNames = (index !== -1
        ? this.vaccine.mixNames.slice(0, index - 1)
        : this.vaccine.mixNames
      )
        .split(',')
        .concat(leptospirosis)
        .filter(v => v.length > 0)
      return { ...this.vaccine, mixNames }
    }
  },

  methods: {
    checkVaccine() {
      this.$emit('check', this.vaccine.id)
    }
  }
}
</script>

<style lang="scss" scoped>
$border-color: #{$gray_c};
$outside-size: 18px;
$inside-size: ($outside-size * 0.8);
@mixin center {
  display: flex;
  justify-content: center;
  align-items: center;
}
.vaccine-check-list-item {
  > .radio {
    box-sizing: border-box;
    display: flex;
    width: 100%;
    background-color: #{$white};
    cursor: pointer;
    > .input {
      @include center;
      width: 40px;
      border: 1px solid $border-color;
      background-color: #{$white_f7};
      > .outside-circle {
        @include center;
        width: $outside-size;
        height: $outside-size;
        border-radius: 50%;
        border: solid 2px $border-color;
        background-color: #{$white};
        &.checked {
          border-color: #{$pumpkin};
        }
        > .inside-circle {
          width: $inside-size;
          height: $inside-size;
          border-radius: 50%;
          background-color: #{$pumpkin};
        }
      }
    }
    > .label {
      @include center;
      flex-direction: column;
      width: 460px;
      border: 1px solid $border-color;
      padding: 15px 15px;
      > .name {
        width: 100%;
        text-align: left;
        font-size: 15px;
        font-weight: bold;
      }
      > .mix-names {
        display: flex;
        flex-wrap: wrap;
        width: 100%;
        padding-top: 10px;
        border-top: 2px solid $border-color;
        font-size: 13px;
        > .mix-name {
          line-height: 24px;
          > .slash {
            margin: 0 5px;
          }
          &:last-child {
            > .slash {
              display: none;
            }
          }
        }
      }
      > .name + .mix-names {
        margin-top: 10px;
      }
    }
    > .input + .label {
      border-left: 0;
      border-right: 1px solid $border-color;
    }
  }
}
</style>
