import { makePriceDetail } from '@/utils/price_calculation'
import { makeClinicText, makeFooter, makePageSize } from '@/utils/print_utils'
import { createPdf } from 'pdfmake/build/pdfmake.min.js'
import { removeWeekday } from '@/utils/convert_string.js'
import '@/utils/vfs_fonts.js'
import _ from 'lodash'

const makeOwnerPatientText = (owner, patient) => {
  return {
    table: {
      widths: ['auto'],
      headerRows: 0,
      body: [
        [
          {
            text: owner.lastName + ' ' + owner.firstName + '様',
            border: [true, true, true, false],
            margin: [5, 5, 5, 0]
          }
        ],
        [
          {
            text: patient.name + '様',
            border: [true, false, true, true],
            margin: [5, 0, 5, 5]
          }
        ]
      ]
    }
  }
}

const makeTreatmentRows = treatmentItems => {
  return treatmentItems.map(v => {
    const subtotal = [
      { text: `¥${v.subtotal.toLocaleString()}`, style: 'cellRight' }
    ]
    if (v.discountRate !== 0) {
      subtotal.push({ text: `割引 ${v.discountRate}%`, style: 'tinyRight' })
    }
    if (v.discountPrice !== 0) {
      subtotal.push({ text: `値引 ¥${v.discountPrice}`, style: 'tinyRight' })
    }
    return [
      {
        text: `${v.name}${v.taxExemptFlg ? '（非課税）' : ''}`,
        style: 'cellLeft'
      },
      { text: v.insuranceFlg ? '〇' : '', style: 'cellCenter' },
      { text: `¥${v.unitPrice.toLocaleString()}`, style: 'cellRight' },
      { text: v.amount, style: 'cellRight' },
      subtotal
    ]
  })
}

const makePriceDetailRows = (estimate, ratio) => {
  const priceDetail = makePriceDetail(
    estimate,
    estimate.surgeryFlg, //← 0 or 1
    1,
    estimate.treatmentItems
  )
  const noBorder = { border: [false, false, false, false], alignment: 'right' }
  const underline = { border: [false, false, false, true], alignment: 'right' }
  let rows = [
    [
      { text: '小計', ...noBorder },
      { text: `¥${priceDetail.totalPrice}`, ...underline }
    ]
  ]
  if (priceDetail.discountRate !== '0') {
    rows.push(
      estimate.insuranceType === 'ipet-docomo'
        ? [
            {
              text: `全体割引 ${priceDetail.discountRate}%`,
              ...noBorder,
              alignment: 'left'
            },
            { text: '', ...underline }
          ]
        : [
            {
              text: `全体割引 ${priceDetail.discountRate}%\t割引適用額`,
              ...noBorder
            },
            { text: `¥${priceDetail.rateDiscountedPrice}`, ...underline }
          ]
    )
  }
  if (
    priceDetail.discountPrice !== '0' ||
    (estimate.insuranceType === 'ipet-docomo' &&
      priceDetail.discountRate !== '0')
  ) {
    const discountText =
      priceDetail.discountPrice === '0'
        ? ''
        : `全体値引 ¥${priceDetail.discountPrice}`
    rows.push([
      {
        text: `${discountText}\t値引適用額`,
        ...noBorder
      },
      { text: `¥${priceDetail.fixedDiscountedPrice}`, ...underline }
    ])
  }
  return rows.concat([
    [
      {
        text: `消費税（10%）`,
        ...noBorder
      },
      { text: `¥${priceDetail.consumptionTax}`, ...underline }
    ],
    [
      { text: '合計', ...noBorder },
      { text: `¥${priceDetail.taxIncludedPrice}`, ...underline }
    ],
    [
      { text: '保険負担額', ...noBorder },
      { text: `¥${priceDetail.insurancePrice}`, ...underline }
    ],
    [
      { text: '自己負担額', ...noBorder, fontSize: 14 * ratio, color: 'black' },
      {
        text: `¥${priceDetail.ownerBill}`,
        ...underline,
        fontSize: 14 * ratio,
        color: 'black'
      }
    ]
  ])
}

export const makeDocDefinition = ({
  printOptions,
  clinic,
  clinicImage,
  owner,
  patient,
  estimate
}) => {
  const paperSize = printOptions.paperSize
  const ratio = paperSize === 'A4' ? 1 : paperSize === 'B5' ? 0.84 : 0.7
  //↓テーブル内の診療内容のwidthは、A4の値を基準にratioで値を算出するとB5の時に紙からはみ出てしまうため下記の値を使用
  const treatmentItemNameWidth =
    paperSize === 'A4' ? 266 : paperSize === 'B5' ? 226 : 163
  const ownerPatientText = makeOwnerPatientText(owner, patient)
  const clinicText = makeClinicText({
    ratio,
    clinic,
    clinicImage,
    showClinicName: printOptions.showClinicName
  })
  const treatmentRows = makeTreatmentRows(estimate.treatmentItems)
  const priceDetailRows = makePriceDetailRows(estimate, ratio)

  const contentHeader = {
    columns: [
      {
        stack: [
          {
            //右端の医院情報のどれかの文字数が長い時に「見積書」の文字が改行されないようwidthを設定。
            //widthはcolumnsと組み合わせて使用しないと反映されないようなので下記のような書き方をしている。
            columns: [
              { text: '見積書', fontSize: 40 * ratio, width: 260 * ratio }
            ]
          },
          ownerPatientText
        ],
        width: '*'
      },
      { stack: clinicText, width: 'auto', margin: [10, 0, 0, 0] }
    ]
  }

  const clinicReceiptContentHeader = {
    pageBreak: 'before',
    columns: [
      {
        stack: [
          {
            columns: [
              { text: '見積書(控え)', fontSize: 40 * ratio, width: 260 * ratio }
            ]
          },
          ownerPatientText
        ],
        width: '*'
      },
      { stack: clinicText, width: 'auto', margin: [10, 0, 0, 0] }
    ]
  }

  let contentBody = [
    // 診断名
    { text: estimate.diseaseName, margin: [0, 10 * ratio, 0, 0] },
    // 見積日
    {
      text: `見積日 ${removeWeekday(estimate.date)}`,
      alignment: 'right',
      margin: [0, 5, 0, 5]
    },
    // 診療項目内容のテーブル
    {
      layout: 'treatmentItemsTable',
      table: {
        headerRows: 1,
        widths: [
          treatmentItemNameWidth,
          25 * ratio,
          84 * ratio,
          50 * ratio,
          84 * ratio
        ],
        body: [
          [
            { text: '診療内容', alignment: 'center' },
            { text: '保険', alignment: 'center' },
            { text: '単価 (¥)', alignment: 'center' },
            { text: '数量', alignment: 'center' },
            { text: '小計 (¥)', alignment: 'center' }
          ],
          ...treatmentRows
        ]
      }
    },
    // 合計
    {
      margin: [0, 5, 0, 0],
      columns: [
        { width: '*', text: '' },
        {
          width: 'auto',
          layout: 'priceDetailTable',
          table: {
            headerRows: 0,
            widths: ['auto', 100 * ratio],
            body: priceDetailRows
          }
        }
      ]
    }
  ]

  // 備考
  if (estimate.applyFlg === 1 || estimate.memo) {
    const memoStack = [{ text: '備考', color: 'black', margin: [0, 10, 0, 0] }]
    if (estimate.applyFlg === 1) {
      memoStack.push({
        text: '保険請求額、飼主請求額は変更になる可能性があります。',
        fontSize: 11 * ratio
      })
    }
    if (estimate.memo) {
      memoStack.push({ text: estimate.memo, fontSize: 11 * ratio })
    }
    const lineCount = (estimate.memo.match(/\n/g) || []).length
    //unbreakable: trueにすると、備考の箇所だけで1ページの縦幅を超えた時に備考が丸ごと表示されなくなる不具合が発生する。
    //https://github.com/bpampuch/pdfmake/issues/207
    //そのため、行数と文字数を確認して1ページを超えそうな場合はunbreakableを設定しないようにして対処。
    //改行の位置によって縦幅が変わり、1ページに収まる備考の行数・文字数が正確にはわからなかったので、余裕を持たせた数字にしている。
    if (lineCount > 20 || estimate.memo.length > 1000) {
      contentBody.push({ stack: memoStack })
    } else {
      contentBody.push({ stack: memoStack, unbreakable: true })
    }
  }

  let content
  if (printOptions.printCopy) {
    content = [
      contentHeader,
      ...contentBody,
      _.cloneDeep(clinicReceiptContentHeader),
      ..._.cloneDeep(contentBody)
    ]
  } else {
    content = [contentHeader, ...contentBody]
  }

  return {
    content,
    footer: (currentPage, pageCount) =>
      makeFooter({
        currentPage,
        pageCount,
        printCopy: printOptions.printCopy,
        fontSize: 11 * ratio
      }),
    styles: {
      cellLeft: { fontSize: 11 * ratio, alignment: 'left' },
      cellCenter: { fontSize: 11 * ratio, alignment: 'center' },
      cellRight: { fontSize: 11 * ratio, alignment: 'right' },
      tinyRight: { fontSize: 8 * ratio, alignment: 'right' }
    },
    defaultStyle: {
      font: 'NotoSansMono',
      fontSize: 12 * ratio,
      color: '#3e3e3e',
      preserveLeadingSpaces: true
    },
    pageSize: makePageSize(printOptions.paperSize),
    pageMargins: [20, 20, 20, 30 + 11 * ratio],
    pageOrientation: 'portrait'
  }
}

// args = {
//   printOptions,
//   clinic,
//   clinicImage,
//   owner,
//   patient,
//   estimate
// }
export const printEstimate = args => {
  const docDefinition = makeDocDefinition(args)
  const fonts = {
    NotoSansMono: {
      normal: 'NotoSansMonoCJKJPRegular.otf',
      bold: 'NotoSansMonoCJKJPRegular.otf',
      italics: 'NotoSansMonoCJKJPRegular.otf',
      bolditalics: 'NotoSansMonoCJKJPRegular.otf'
    }
  }
  const tableLayouts = {
    treatmentItemsTable: {
      hLineColor: '#3e3e3e',
      vLineColor: '#3e3e3e',
      fillColor: rowIndex => (rowIndex === 0 ? '#f0f0f0' : null)
    },
    priceDetailTable: {
      hLineWidth: () => 0,
      hLineColor: '#3e3e3e',
      vLineColor: '#3e3e3e',
      fillColor: rowIndex => (rowIndex % 2 === 0 ? '#f0f0f0' : null)
    }
  }
  createPdf(docDefinition, tableLayouts, fonts).open()
}
