<template>
  <div class="base-button-large-white">
    <button type="button" :disabled="disabled" @click="handleClick">
      <span><slot /> </span>
    </button>
  </div>
</template>

<script>
export default {
  name: 'BaseButtonLargeWhite',

  props: {
    disabled: {
      type: Boolean,
      default: false
    }
  },

  methods: {
    handleClick() {
      this.$emit('click')
    }
  }
}
</script>

<style lang="scss" scoped>
.base-button-large-white {
  > button {
    height: 60px;
    width: 335px;
    border-radius: 6px;
    border: solid 2px #{$light-grey};
    background-color: #{$white};
    cursor: pointer;
    &:disabled {
      pointer-events: none;
      background-color: #{$white_f7};
      color: #{$light-grey};
      border: none;
    }
    &:hover {
      opacity: 0.8;
    }
    &:active {
      margin: 2px auto 0 auto;
      height: 58px;
    }
    > span {
      height: 29px;
      font-size: 15px;
      font-weight: bold;
      line-height: 1.45;
    }
  }
}
</style>
