<template>
  <div class="base-button-border-orange">
    <button
      type="button"
      :disabled="disabled"
      :style="styles"
      @click="handleClick"
      data-e2e="base-button-border-orange"
    >
      <span :class="{ alert: alertFlg }"><slot /> </span>
    </button>
  </div>
</template>

<script>
export default {
  name: 'BaseButtonBorderOrange',

  props: {
    disabled: {
      type: Boolean,
      default: false
    },
    styles: {
      type: Object,
      validator: obj => {
        return Object.values(obj).every(v => typeof v === 'string')
      }
    },
    alertFlg: {
      type: Boolean,
      default: false
    }
  },

  methods: {
    handleClick(e) {
      this.$emit('click', e)
    }
  }
}
</script>

<style lang="scss" scoped>
.base-button-border-orange {
  > button {
    height: 33px;
    width: 200px;
    font-size: 13px;
    border-radius: 6px;
    border: solid 2px #{$pumpkin};
    color: #{$pumpkin};
    background-color: #{$white};
    cursor: pointer;
    &:disabled {
      pointer-events: none;
      background-color: #{$white_f7};
      color: #{$light-grey};
      border: solid 2px #{$light-grey};
    }
    &:hover {
      opacity: 0.8;
    }
    &:active {
      margin: 2px auto 0 auto;
      height: 31px;
    }
    > span {
      display: inline-block;
      font-weight: bold;
      &.alert {
        margin-left: -20px;
        &::before {
          content: '!';
          display: inline-block;
          margin-right: 10px;
          background: #{$tomato};
          width: 20px;
          height: 20px;
          line-height: 20px;
          color: white;
          border-radius: 50%;
        }
      }
    }
  }
}
</style>
