<template>
  <div class="base-text-box">
    <input
      class="text-box"
      :class="{
        pointer: pointer,
        'disabled-color-white': disabledColorWhite,
        'placeholder-small-font': placeholderSmallFont
      }"
      :type="type"
      :placeholder="placeholder"
      :maxlength="maxlength"
      :max="max"
      :min="min"
      :disabled="disabled"
      :style="styles"
      v-model="inputText"
      @blur="$emit('blur')"
      @dblclick="$emit('dblclick')"
      @keyup.enter="$emit('keyup')"
      data-e2e="base-text-box"
    />
  </div>
</template>

<script>
import { convertFullWidthNumbersToHalfWidthNumbers } from '@/utils/convert_string'

export default {
  name: 'BaseTextBox',

  props: {
    value: { type: [String, Number] },
    type: { type: String, default: 'text' },
    placeholder: { type: String },
    placeholderSmallFont: { type: Boolean, default: false },
    maxlength: { type: Number, default: 255 },
    max: { type: Number },
    min: { type: Number },
    disabled: { type: Boolean, default: false },
    disabledColorWhite: { type: Boolean, default: false },
    pointer: { type: Boolean, default: false },
    styles: {
      type: Object,
      validator: function(obj) {
        return Object.values(obj).every(v => typeof v === 'string')
      }
    },
    fullWidthNumbersToHalfWidthNumbers: { type: Boolean, default: false }
  },

  computed: {
    inputText: {
      get() {
        return this.value
      },
      set(val) {
        this.$emit('input', this.convertValue(val))
      }
    }
  },

  methods: {
    convertValue(value) {
      return this.fullWidthNumbersToHalfWidthNumbers
        ? convertFullWidthNumbersToHalfWidthNumbers(value)
        : value
    }
  }
}
</script>

<style lang="scss" scoped>
.base-text-box {
  > .text-box {
    width: 265px;
    height: 33px;
    border-radius: 4px;
    border: solid 2px #{$light-grey};
    background-color: #{$white};
    padding: 2px 8px;
    font-size: 15px;
    text-align: left;
    box-sizing: border-box;
    &:disabled {
      background-color: #{$light-grey};
      color: #{$brown-gray};
      opacity: 0.7;
    }
    &::placeholder {
      color: #{$brown-gray};
    }
    &::-ms-clear {
      display: none;
    }
    &::-ms-reveal {
      display: none;
    }
  }
  > .placeholder-small-font::placeholder {
    font-size: 12px;
  }
  > .pointer {
    cursor: pointer;
  }
  > .disabled-color-white {
    &:disabled {
      background-color: #{$white};
      color: #{$greyish-brown};
      opacity: 1;
    }
  }
}
</style>
