<template>
  <div class="data-table">
    <div class="table" ref="table">
      <div class="table-header" :class="{ stripe: stripeFlg }">
        <div class="header-row" :style="{ width: `${tableBodyWidth}px` }">
          <div
            class="cell"
            v-for="(item, i) in headers"
            :key="item"
            :style="headerCellStyles[i]"
          >
            <div class="text-wrap">
              <div class="text">{{ item }}</div>
            </div>
          </div>
        </div>
      </div>
      <div
        class="table-body"
        ref="tableBody"
        v-if="items.length > 0"
        :class="{ stripe: stripeFlg, scroll: scrollFlg }"
        :style="{ maxHeight: bodyMaxHeight }"
      >
        <div
          class="body-row"
          v-for="item in items"
          :key="item.id"
          :class="{ stripe: stripeFlg }"
          :style="{ width: `${tableBodyWidth}px` }"
        >
          <div class="row-inside">
            <div
              class="cell"
              v-for="(key, i) in keys"
              :key="i"
              :class="{
                stripe: stripeFlg,
                disabled: disabled && key !== 'totalPrice'
              }"
              :style="bodyCellStyles[i]"
              data-test="cell"
            >
              <div class="text-wrap">
                <div class="text" :title="item[key]">
                  {{ item[key] }}
                </div>
                <div
                  v-if="key === 'name' && item.taxExemptFlg"
                  class="tax-exempt"
                  :data-test="`tax-exempt-${item.id}`"
                >
                  （非課税）
                </div>
              </div>
            </div>
          </div>
          <div class="row-inside prescription" v-if="existsPrescription(item)">
            <div class="cell prescription" :class="{ stripe: stripeFlg }">
              <div class="icon">
                <div class="L-shaped-icon">
                  <div class="line vertical"></div>
                  <div class="line horizontal"></div>
                </div>
              </div>
              <div class="label">処方：</div>
              <div class="value">
                <div class="text-wrap">
                  <div class="text" :title="item.prescription">
                    {{ item.prescription }}
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
export default {
  name: 'DataTable',

  props: {
    keys: { type: Array, default: () => [] },
    headers: { type: Array, default: () => [] },
    items: { type: Array, default: () => [] },
    headerCellStyles: { type: Array, default: () => [] },
    bodyCellStyles: { type: Array, default: () => [] },
    bodyMaxHeight: { type: String, default: '' },
    stripeFlg: { type: Boolean, default: false },
    scrollFlg: { type: Boolean, default: false },
    disabled: { type: Boolean, default: false }
  },

  data() {
    return {
      observer: null,
      tableBodyWidth: ''
    }
  },

  mounted() {
    if (this.scrollFlg) {
      this.observer = new ResizeObserver(() => {
        if (this.items.length > 0) {
          const { clientWidth } = this.$refs.tableBody
          this.tableBodyWidth = clientWidth
        }
      })
      this.observer.observe(this.$refs.table)
    }
  },

  beforeDestroy() {
    if (this.scrollFlg) {
      this.observer.disconnect()
    }
  },

  methods: {
    existsPrescription(item) {
      return item.prescription?.length > 0
    }
  }
}
</script>

<style lang="scss" scoped>
$border-color: #{$light-grey};
$border-color-stripe: #{$very-light-grey};
@mixin text-wrap {
  > .text-wrap {
    display: flex;
    > .text {
      flex: 1;
      width: 0;
      white-space: nowrap;
      overflow: hidden;
      text-overflow: ellipsis;
      font-feature-settings: 'palt';
    }
    > .tax-exempt {
      font-weight: bold;
      font-feature-settings: 'palt';
    }
  }
}
@mixin cell {
  box-sizing: border-box;
  width: 100%;
  line-height: 29px;
  padding: 0 8px;
  font-size: 10px;
  text-align: center;
  @include text-wrap;
}
.data-table {
  width: 100%;
  > .table {
    width: 100%;
    > .table-header {
      width: 100%;
      box-sizing: border-box;
      display: flex;
      height: 30px;
      border: solid $border-color;
      border-width: 1px 1px 0 1px;
      background-color: #{$gray_f0};
      &.stripe {
        background-color: transparent;
        border-color: transparent;
        > .header-row {
          width: 100%;
          > .cell ~ .cell {
            border-color: transparent;
          }
        }
      }
      > .header-row {
        width: 100%;
        display: flex;
        > .cell {
          @include cell;
          padding: 0;
          font-weight: bold;
        }
      }
    }
    > .table-body {
      box-sizing: border-box;
      border: 1px solid $border-color;
      &.stripe {
        border-color: $border-color-stripe;
      }
      &.scroll {
        overflow-y: scroll;
        overflow-x: hidden;
      }
      > .body-row {
        background-color: #{$white};
        &.stripe:nth-child(odd) {
          background-color: #{$gray_fc};
        }
        > .row-inside {
          display: flex;
          > .cell {
            @include cell;
            font-size: 13px;
            text-align: right;
            &.prescription {
              box-sizing: border-box;
              display: flex;
              line-height: 28px;
              border-top: 1px solid $border-color;
              padding: 0;
              margin: 0 10px;
              font-size: 11px;
              text-align: left;
              &.stripe {
                border-color: $border-color-stripe;
              }
              > .icon {
                width: 8px;
                margin: 0 5px 0 10px;
                > .L-shaped-icon {
                  position: relative;
                  > .line {
                    position: absolute;
                    background-color: #{$greyish-brown};
                    &.vertical {
                      top: 6px;
                      left: 0px;
                      width: 1px;
                      height: 8px;
                    }
                    &.horizontal {
                      top: 14px;
                      left: 0px;
                      width: 8px;
                      height: 1px;
                    }
                  }
                }
              }
              > .label {
                width: 35px;
              }
              > .value {
                flex: 1;
                @include text-wrap;
              }
            }
          }
          .disabled {
            background-color: #{$gray_fc};
          }
        }
      }
    }
  }
}
.header ~ .body,
.body-row ~ .body-row {
  border-top: 1px solid $border-color;
  &.stripe {
    border-color: $border-color-stripe;
  }
}
.cell ~ .cell {
  border-left: 1px solid $border-color;
  &.stripe {
    border-color: $border-color-stripe;
  }
}
</style>
