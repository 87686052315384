<template>
  <div class="search-area" :style="searchAreaStyles">
    <validation-observer class="validation-observer" v-slot="{ invalid }">
      <div class="left">
        <div class="area text-box-area" v-if="textBoxLabel !== ''">
          <span data-test="text-box-label">{{ textBoxLabel }}</span>
          <validation-provider
            class="validate"
            :rules="{ notSurrogatePair: true }"
            v-slot="{ errors }"
          >
            <base-text-box
              class="text-box"
              @input="inputText"
              @keyup="search(invalid)"
              :value="textValue"
              :placeholder="placeholder"
              :placeholderSmallFont="placeholderSmallFont"
              :styles="textBoxStyles"
              :disabled="disabled"
            />
            <div class="error">
              {{ errors[0] }}
            </div>
          </validation-provider>
        </div>
        <div class="area date-box-area" v-if="dateFlg">
          <span
            class="date-label"
            :class="{ 'no-text': dateLabel.length === 0 }"
            data-test="date-label"
            >{{ dateLabel }}</span
          >
          <v-date-picker
            v-model="date"
            @input="inputDate"
            :input-props="{
              class: 'v-date-picker',
              readonly: true
            }"
            data-test="v-date-picker"
            is-required
          />
          <base-button-small-white class="clear" @click="clearDate"
            >クリア</base-button-small-white
          >
        </div>
        <div class="area select-box-area" v-if="selectBoxLabel !== ''">
          <span data-test="select-box-label">{{ selectBoxLabel }}</span>
          <base-select-box
            class="select-box"
            :value="selectValue"
            :styles="selectBoxStyles || styles"
            :selectData="selectBoxData"
            :disabled="disabled"
            @input="select"
          />
          <base-select-box
            class="select-box"
            v-if="secondFlg"
            :value="selectValue2"
            :styles="selectBoxStyles2 || styles"
            :selectData="selectBoxData2"
            :disabled="disabled"
            @input="select2"
          />
        </div>
        <div class="area select-box-area" v-if="selectBoxLabel2 !== ''">
          <span data-test="select-box-label">{{ selectBoxLabel2 }}</span>
          <base-select-box
            class="select-box"
            v-if="thirdFlg"
            :value="selectValue3"
            :styles="selectBoxStyles3 || styles"
            :selectData="selectBoxData3"
            :disabled="disabled"
            @input="select3"
          />
        </div>
        <div class="area select-box-area" v-if="fourthFlg">
          <span v-if="selectBoxLabel4 !== ''" data-test="select-box-label">{{
            selectBoxLabel4
          }}</span>
          <base-select-box
            class="select-box"
            :value="selectValue4"
            :styles="selectBoxStyles4 || styles"
            :selectData="selectBoxData4"
            :disabled="disabled"
            @input="select4"
          />
        </div>
        <div class="area period-area" v-if="periodFlg">
          <span
            class="period-label"
            :class="{ 'no-text': periodLabel.length === 0 }"
            data-test="period-label"
            >{{ periodLabel }}</span
          >
          <validation-provider
            class="validate"
            :rules="`startDateRule:${endDate}`"
            v-slot="{ errors }"
          >
            <v-date-picker
              v-model="startDate"
              :input-props="{
                class: 'v-date-picker start',
                readonly: true
              }"
              :max-date="maxStartDate"
              @input="inputStartDate"
              data-test="v-date-picker-start"
              is-required
            />
            <div class="error">
              {{ errors[0] }}
            </div>
          </validation-provider>
          <div class="wavy-line">～</div>
          <v-date-picker
            v-model="endDate"
            :input-props="{
              class: 'v-date-picker end',
              readonly: true
            }"
            :max-date="maxEndDate"
            @input="inputEndDate"
            data-test="v-date-picker-end"
            is-required
          />
          <base-button-small-white
            class="clear"
            v-if="periodClearFlg"
            @click="clearDate"
            >クリア</base-button-small-white
          >
        </div>
        <div class="area select-box-area" v-if="selectBoxLabel5 !== ''">
          <span data-test="select-box-label">{{ selectBoxLabel5 }}</span>
          <base-select-box
            class="select-box"
            v-if="fifthFlg"
            :value="selectValue5"
            :styles="selectBoxStyles5"
            :selectData="selectBoxData5"
            :disabled="disabled"
            @input="select5"
          />
        </div>
        <div class="area radio-button-area" v-if="radioButtonLabel !== ''">
          <span data-test="radio-button-label">{{ radioButtonLabel }}</span>
          <base-radio-button
            class="radio-button"
            v-for="datum in radioButtonData"
            :key="datum.id"
            :option="datum"
            :value="radioButtonValue"
            @input="selectRadioData"
          />
        </div>
      </div>
      <div class="right">
        <div class="detail-search-button">
          <base-button-small-white
            @click="showDetail"
            v-if="toggleDetailSearchFlg"
            :styles="buttonWidth"
            >詳細検索</base-button-small-white
          >
        </div>
        <div class="search-button" v-if="searchButtonFlg">
          <base-button-small-orange
            :styles="buttonWidth"
            :disabled="waitFlg || invalid"
            @click="search"
            >{{ searchButtonLabel }}</base-button-small-orange
          >
        </div>
      </div>
    </validation-observer>
  </div>
</template>

<script>
import BaseTextBox from '@/components/parts/atoms/BaseTextBox'
import BaseButtonSmallWhite from '@/components/parts/atoms/BaseButtonSmallWhite'
import BaseButtonSmallOrange from '@/components/parts/atoms/BaseButtonSmallOrange'
import BaseSelectBox from '@/components/parts/atoms/BaseSelectBox'
import BaseRadioButton from '@/components/parts/atoms/BaseRadioButton'
import moment from 'moment'
import DetectWindowSize from '@/components/mixins/DetectWindowSize'
import { ValidationObserver, ValidationProvider } from 'vee-validate'
import '@/utils/validation_rules'

export default {
  name: 'SearchArea',

  components: {
    BaseTextBox,
    BaseButtonSmallWhite,
    BaseButtonSmallOrange,
    BaseSelectBox,
    BaseRadioButton,
    ValidationObserver,
    ValidationProvider
  },

  mixins: [DetectWindowSize],

  props: {
    searchAreaStyles: { type: Object },
    textBoxLabel: { type: String, default: '' },
    textValue: { type: String, default: '' },
    placeholder: { type: String, default: '' },
    placeholderSmallFont: { type: Boolean, default: false },
    textBoxStyles: { type: Object },
    dateLabel: { type: String, default: '' },
    dateFlg: { type: Boolean, default: false },
    selectBoxLabel: { type: String, default: '' },
    selectBoxLabel2: { type: String, default: '' },
    selectBoxLabel4: { type: String, default: '' },
    selectBoxLabel5: { type: String, default: '' },
    selectValue: { type: Number, default: 0 },
    selectBoxData: {
      type: Array,
      default: () => {
        return []
      }
    },
    secondFlg: { type: Boolean, default: false },
    selectValue2: { type: Number, default: 0 },
    selectBoxData2: {
      type: Array,
      default: () => {
        return []
      }
    },
    thirdFlg: { type: Boolean, default: false },
    selectValue3: { type: Number, default: 0 },
    selectBoxData3: {
      type: Array,
      default: () => {
        return []
      }
    },
    fourthFlg: { type: Boolean, default: false },
    selectValue4: { type: Number, default: 0 },
    selectBoxData4: {
      type: Array,
      default: () => {
        return []
      }
    },
    fifthFlg: { type: Boolean, default: false },
    selectValue5: { type: Number, default: 0 },
    selectBoxData5: {
      type: Array,
      default: () => {
        return []
      }
    },
    selectBoxStyles: { type: Object, default: () => undefined },
    selectBoxStyles2: { type: Object, default: () => undefined },
    selectBoxStyles3: { type: Object, default: () => undefined },
    selectBoxStyles4: { type: Object, default: () => undefined },
    selectBoxStyles5: { type: Object, default: () => undefined },
    radioButtonLabel: { type: String, default: '' },
    radioButtonValue: { type: [Number, String], default: 1 },
    radioButtonData: {
      type: Array, // [ {id: 1, eachValue: 1 or '値', labelName: '有効' } ]
      default: () => {
        return []
      },
      validator: function(arr) {
        return arr.every(v => {
          return (
            Number.isInteger(v.id) &&
            (Number.isInteger(v.eachValue) ||
              typeof v.eachValue === 'string') &&
            typeof v.labelName === 'string' &&
            v.id !== null &&
            v.eachValue !== null &&
            v.labelName !== null
          )
        })
      }
    },
    disabled: { type: Boolean, default: false },
    toggleDetailSearchFlg: { type: Boolean, default: false },
    searchButtonFlg: { type: Boolean, default: false },
    searchButtonLabel: { type: String, default: '検索' },
    waitFlg: { type: Boolean, default: false },
    periodFlg: { type: Boolean, default: false },
    periodLabel: { type: String, default: '' },
    periodClearFlg: { type: Boolean, default: true },
    defaultStartDate: { type: Date, default: null },
    defaultEndDate: { type: Date, default: null },
    maxStartDate: { type: Date, default: null },
    maxEndDate: { type: Date, default: null }
  },

  data() {
    return {
      styles: { width: '77px' },
      selectData: [{ id: 0, name: '全て' }],
      selectRadioButtonId: 1,
      date: null,
      startDate: null,
      endDate: null
    }
  },

  computed: {
    buttonWidth() {
      return this.mixinWindowWidth < 1024
        ? { width: '70px' }
        : { width: '120px' }
    }
  },

  created() {
    if (this.defaultStartDate) {
      this.startDate = this.defaultStartDate
    }
    if (this.defaultEndDate) {
      this.endDate = this.defaultEndDate
    }
  },
  methods: {
    inputText(text) {
      this.$emit('input', text)
    },
    inputDate(val) {
      if (this.date !== null) {
        this.$emit('input-date', moment(val).format('YYYYMMDD'))
      }
    },
    clearDate() {
      this.date = null
      this.startDate = null
      this.endDate = null
      this.$emit('clear')
    },
    select(id) {
      this.$emit('select', id)
    },
    select2(id) {
      this.$emit('select2', id)
    },
    select3(id) {
      this.$emit('select3', id)
    },
    select4(id) {
      this.$emit('select4', id)
    },
    select5(id) {
      this.$emit('select5', id)
    },
    selectRadioData(id) {
      this.$emit('select-radio-data', id)
    },
    showDetail() {
      this.$emit('show-detail')
    },
    search(invalid) {
      if (!invalid) this.$emit('search')
    },
    inputStartDate(val) {
      if (this.startDate !== null) {
        this.$emit('input-start-date', moment(val).format('YYYYMMDD'))
      }
    },
    inputEndDate(val) {
      if (this.endDate !== null) {
        this.$emit('input-end-date', moment(val).format('YYYYMMDD'))
      }
    }
  }
}
</script>

<style lang="scss" scoped>
.search-area {
  width: 100%;
  height: 73px;
  border: solid 1px #{$light-grey};
  background-color: #{$white_f7};
  box-sizing: border-box;
  font-size: 13px;
  > .validation-observer {
    padding: 0 20px;
    justify-content: space-between;
    align-items: center;
    display: flex;
    > .left {
      padding-top: 20px;
      display: flex;
      align-items: center;
      > .area {
        margin-left: 20px;
        &:first-child {
          margin-left: 0;
        }
      }
      > .text-box-area {
        display: flex;
        align-items: center;
        @include validate-message();
        > span {
          margin-right: 5px;
        }
      }
      > .date-box-area,
      .period-area {
        display: flex;
        align-items: center;
        @include validate-message();
        > .date-label,
        .period-label {
          margin-right: 5px;
          &.no-text {
            margin-right: 0;
          }
        }
        ::v-deep {
          > span {
            .v-date-picker {
              text-align: right;
              padding-right: 12px;
              width: 140px;
              height: 33px;
              border-radius: 5px;
              border: solid 2px #{$light-grey};
              text-indent: 5px;
              cursor: pointer;
              background-image: url('../../../assets/images/calendar.png');
              background-size: 18px 18px;
              background-repeat: no-repeat;
              background-position: 5px center;
            }
          }
        }
        > .wavy-line {
          margin: 0 10px;
        }
        ::v-deep .base-button-small-white button {
          margin-left: 5px;
          width: 70px;
        }
      }
      > .select-box-area {
        display: flex;
        align-items: center;
        > span {
          margin-right: 5px;
        }
        > .select-box + .select-box {
          margin-left: 5px;
        }
      }
      > .radio-button-area {
        display: flex;
        align-items: center;
        > span {
          margin-right: 5px;
        }
        > .radio-button {
          margin: 5px 0 5px 15px;
          ::v-deep {
            > label {
              padding-left: 15px;
            }
          }
        }
      }
    }
    > .right {
      display: flex;
      align-items: center;
      margin-left: 10px;
      padding-top: 20px;
      > .search-button {
        margin-left: 10px;
      }
    }
  }
}
</style>
