import { getAxiosObject } from '@/utils/library'
import {
  commonGetters,
  commonMutations,
  commonActions
} from '@/store/store_utils'

const dataName = 'treatmentLargeClasses'

const state = {
  treatmentLargeClasses: [],
  dataIndexedById: {},
  dataIndexedByOriginalId: {},
  updatedAt: '20101010101010'
}

const getters = {
  ...commonGetters(
    dataName,
    [
      'getData',
      'getDataById',
      'getDataByOriginalId',
      'getUpdatedAt',
      'selectDataZero'
    ],
    { filterDel: true, selectZeroText: '新規分類名' }
  )
}

const actions = {
  ...commonActions(['setData', 'setUpdatedAt', 'updateAfter']),
  async delete({ commit }, id) {
    try {
      const axiosObject = getAxiosObject()
      const res = await axiosObject.delete('/treatment-large-classes', {
        data: { id }
      })
      if (res.status === 200) {
        commit('updateAfter', [res.data.treatmentLargeClass])
        commit('treatmentClasses/updateAfter', res.data.treatmentClasses, {
          root: true
        })
        commit('treatments/updateAfter', res.data.treatments, {
          root: true
        })
        return true
      }
    } catch (error) {
      if (error.response?.data?.extra) {
        if (error.response.data.extra.data) {
          commit('setData', error.response.data.extra.data)
        } else {
          return error.response.data.extra
        }
        return error.response.data.message
      } else {
        return 'server error'
      }
    }
  },
  async update({ commit }, treatmentLargeClass) {
    try {
      const axiosObject = getAxiosObject()
      const res = await axiosObject.put('/treatment-large-classes', {
        treatmentLargeClass
      })
      if (res.status === 200) {
        commit('updateAfter', res.data.treatmentLargeClasses)
        commit('treatmentClasses/updateAfter', res.data.treatmentClasses, {
          root: true
        })
        commit('treatments/updateAfter', res.data.treatments, { root: true })
        return true
      }
    } catch (error) {
      if (error.response?.data?.message) {
        if (error.response.data.message === 'no data in clinic') {
          commit('setData', error.response.data.extra.data)
          return 'no data in clinic'
        }
        if (error.response.data.message === 'no data') {
          return error.response.data.extra ===
            'treatmentLargeClass already edited'
            ? 'already edited'
            : 'already deleted'
        } else {
          return error.response.data.message
        }
      } else {
        return 'server error'
      }
    }
  },
  async order({ commit }, { largeClassesOrderIds, classesOrderIds, orderIds }) {
    try {
      const axiosObject = getAxiosObject()
      const res = await axiosObject.put('/treatment-large-classes/order', {
        largeClassesOrderIds,
        classesOrderIds,
        orderIds
      })
      if (res.status === 200) {
        commit('updateAfter', res.data.treatmentLargeClasses)
        commit('treatmentClasses/updateAfter', res.data.treatmentClasses, {
          root: true
        })
        commit('treatments/updateAfter', res.data.treatments, {
          root: true
        })
        return true
      }
    } catch (error) {
      return false
    }
  }
}

const mutations = {
  ...commonMutations(dataName, ['setData', 'setUpdatedAt', 'updateAfter'], {
    order: true,
    indexedById: true,
    indexedByOriginalId: true
  })
}

export default {
  namespaced: true,
  state,
  getters,
  actions,
  mutations
}
