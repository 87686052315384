import store from '@/store/store'
import _ from 'lodash'
import moment from 'moment'

export const returnLeftAlignMessageStaffWorkingDates = () => {
  const result = calculateDaysStatus()
  let leftAlignMessage = ''
  let closedDayWarning = ''
  let noReservationPurposesWarning = ''
  result.forEach(v => {
    const day = moment(v.day, 'YYYYMMDD').format('M/D(dd)')
    if (v.isClosedDay && v.swdStaffIds.length > 0) {
      closedDayWarning += `${day}、`
    }
    if (
      !v.isClosedDay &&
      _.intersection(v.swdStaffIds, v.rpStaffIds).length === 0
    ) {
      noReservationPurposesWarning += `${day}、`
    }
  })
  if (closedDayWarning.length > 0 || noReservationPurposesWarning.length > 0) {
    leftAlignMessage += '※注意\n'
    if (closedDayWarning.length > 0) {
      closedDayWarning = closedDayWarning.slice(0, -1)
      leftAlignMessage += `${closedDayWarning}は休診日となっています。\n`
    }
    if (noReservationPurposesWarning.length > 0) {
      noReservationPurposesWarning = noReservationPurposesWarning.slice(0, -1)
      leftAlignMessage += `${noReservationPurposesWarning}は利用可能な来院目的が設定されていません。`
    }
    leftAlignMessage += '\n必要に応じて診療日・来院目的の設定をお願いします。'
  }
  return leftAlignMessage
}

export const returnLeftAlignMessageOpenTimes = () => {
  const result = calculateDaysStatus()
  let leftAlignMessage = ''
  let noWorkingStaffWarning = ''
  let noReservationPurposesWarning = ''
  result.forEach(v => {
    const day = moment(v.day, 'YYYYMMDD').format('M/D(dd)')
    if (!v.isClosedDay && v.swdStaffIds.length === 0) {
      noWorkingStaffWarning += `${day}、`
    }
    if (!v.isClosedDay && v.rpStaffIds.length === 0) {
      noReservationPurposesWarning += `${day}、`
    }
  })
  if (
    noWorkingStaffWarning.length > 0 ||
    noReservationPurposesWarning.length > 0
  ) {
    leftAlignMessage += '※注意\n'
    if (noWorkingStaffWarning.length > 0) {
      noWorkingStaffWarning = noWorkingStaffWarning.slice(0, -1)
      leftAlignMessage += `${noWorkingStaffWarning}は対応可能な勤務スタッフが設定されていません。\n`
    }
    if (noReservationPurposesWarning.length > 0) {
      noReservationPurposesWarning = noReservationPurposesWarning.slice(0, -1)
      leftAlignMessage += `${noReservationPurposesWarning}は利用可能な来院目的が設定されていません。`
    }
    leftAlignMessage += '\n必要に応じて診療日・来院目的の設定をお願いします。'
  }
  return leftAlignMessage
}

export const returnLeftAlignMessageReservationPurposes = obj => {
  const specialReservationAvailablePatternDayObj = Object.fromEntries(
    obj.specialReservationAvailablePatterns.map(v => {
      const closedDayFlg = store.getters[
        'reservationAvailablePatterns/getDataById'
      ](v.reservationAvailablePatternId).closedDayFlg
      return [v.date, closedDayFlg]
    })
  )
  const rpStaffIds = obj.reservationPurpose.staffsIds
    .split(',')
    .map(v => Number(v))
  const result = calculateDaysStatus()
  let leftAlignMessage = ''
  let closedDayWarning = ''
  let noWorkingStaffWarning = ''
  result.forEach(v => {
    const day = moment(v.day, 'YYYYMMDD').format('M/D(dd)')
    const specialDay = specialReservationAvailablePatternDayObj[v.day]
    // 休診日なのに来院目的で特別に診療日にした場合
    if (v.isClosedDay && specialDay === 0) {
      closedDayWarning += `${day}、`
    }
    // 特別診療日に設定したのに、勤務表で対応スタッフが出勤していない場合
    if (
      _.intersection(v.swdStaffIds, rpStaffIds).length === 0 &&
      specialDay === 0
    ) {
      noWorkingStaffWarning += `${day}、`
    }
  })
  if (closedDayWarning.length > 0 || noWorkingStaffWarning.length > 0) {
    leftAlignMessage += '※注意\n'
    if (closedDayWarning.length > 0) {
      closedDayWarning = closedDayWarning.slice(0, -1)
      leftAlignMessage += `${closedDayWarning}は休診日となっています。\n`
    }
    if (noWorkingStaffWarning.length > 0) {
      noWorkingStaffWarning = noWorkingStaffWarning.slice(0, -1)
      leftAlignMessage += `${noWorkingStaffWarning}は設定されたスタッフが出勤設定ではありません。`
    }
    leftAlignMessage += '\n必要に応じて診療日・勤務表の設定をお願いします。'
  }
  // 曜日チェック
  const baseCloseDays = calculateBaseCloseDays(obj.reservationPurpose)
  if (baseCloseDays.length > 0) {
    let baseDaysWarning =
      closedDayWarning.length === 0 && noWorkingStaffWarning.length === 0
        ? '※注意\n'
        : '\nまた、'
    const dayOfWeekArray = ['日', '月', '火', '水', '木', '金', '土', '祝']
    baseCloseDays.forEach(dayOfWeek => {
      baseDaysWarning += `${dayOfWeekArray[dayOfWeek]}曜日、`
    })
    baseDaysWarning = baseDaysWarning.slice(0, -1)
    leftAlignMessage += `${baseDaysWarning}は診療日の基本設定で休診日に設定されています。確認をお願いします。`
  }
  return leftAlignMessage
}

export const returnLeftAlignMessageReservationSetting = () => {
  const endDay = calculateEndDay()
  const staffWorkingDatesObj = makeStaffWorkingDatesObj()
  const result = staffWorkingDatesObj[moment(endDay).format('YYYYMMDD')]
  return result === undefined
    ? `※注意\n${moment(endDay).format(
        'M/D(dd)'
      )}までの勤務表が設定されていません。\n必要に応じて勤務表の設定をお願いします。`
    : ''
}

const calculateDaysStatus = () => {
  const dayArray = makeDayArray()
  const openTimesObj = makeOpenTimesObj()
  const staffWorkingDatesObj = makeStaffWorkingDatesObj()
  const openDayShiftIds = makeOpenDayShiftIds()
  const reservationPurposesObj = makeReservationPurposesObj()
  const result = dayArray.map(day => {
    const isClosedDay = calculateClosedDay(day, openTimesObj)
    // 勤務表で出勤しているstaffIds
    const swdStaffIds = calculateStaffWorkingDaysStaffIds(
      day,
      staffWorkingDatesObj,
      openDayShiftIds
    )
    // 来院目的に設定されているstaffIds
    const rpStaffIds = calculateReservationPurposesStaffIds(
      day,
      reservationPurposesObj
    )
    return { day, isClosedDay, swdStaffIds, rpStaffIds }
  })
  return result
}

const makeDayArray = () => {
  const startDay = moment().startOf('day')
  const endDay = calculateEndDay()
  const diff = endDay.diff(startDay, 'days')
  const dayArray = []
  let day = startDay
  for (let i = 0; i <= diff; i++) {
    dayArray.push(moment(day).format('YYYYMMDD'))
    day = moment(day).add(1, 'd')
  }
  return dayArray
}

const calculateEndDay = () => {
  const reservationSetting = store.getters['reservationSetting/getData']
  let endDay
  if (reservationSetting.reservationAvailablePeriodType === 1) {
    if (reservationSetting.reservationEndPeriodUnit === 1) {
      endDay = reservationSetting.reservationEndPeriod
    } else {
      if (reservationSetting.reservationStartAfterUnit === 1) {
        endDay = moment()
          .add(reservationSetting.reservationStartAfterNumber, 'd')
          .add(reservationSetting.reservationEndAfterNumber, 'M')
          .format('YYYYMMDD')
      } else if (reservationSetting.reservationStartAfterUnit === 2) {
        endDay = moment()
          .add(reservationSetting.reservationEndAfterNumber, 'M')
          .format('YYYYMMDD')
      }
    }
  } else {
    endDay = reservationSetting.reservationEndDate
  }
  return moment(endDay, 'YYYYMMDD')
}

const makeOpenTimesObj = () => {
  const openTimes = store.getters['openTimes/getData']
  const closedDaysOfTheWeek = openTimes
    .filter(v => v.closeFlg === 1)
    .map(v => v.type - 1)
  const specialOpenTimes = store.getters['specialOpenTimes/getData']
  const specialClosedDays = []
  const specialOpenDays = []
  specialOpenTimes.forEach(sot => {
    const reservationAvailablePattern = store.getters[
      'reservationAvailablePatterns/getDataById'
    ](sot.reservationAvailablePatternId)
    reservationAvailablePattern.closedDayFlg === 1
      ? specialClosedDays.push(sot.date)
      : specialOpenDays.push(sot.date)
  })
  return { specialClosedDays, specialOpenDays, closedDaysOfTheWeek }
}

const makeStaffWorkingDatesObj = () => {
  const staffWorkingDates = store.getters['staffWorkingDates/getData']
  const defaultShiftId = store.getters['shifts/getData'].filter(
    v => v.defaultFlg === 1
  )[0].id
  const thisMonth = moment().format('YYYYMM')
  return _.groupBy(
    staffWorkingDates
      .filter(
        v =>
          v.yearMonth >= thisMonth && v.shiftIds.some(v => v !== defaultShiftId)
      )
      .map(swd => {
        return swd.shiftIds.map((shiftId, index) => {
          return {
            day: swd.yearMonth + `${index + 1}`.padStart(2, '0'),
            staffId: swd.staffId,
            shiftId: Number(shiftId)
          }
        })
      })
      .flat(),
    'day'
  )
}

const makeOpenDayShiftIds = () => {
  const shifts = store.getters['shifts/getData']
  return shifts.filter(v => v.endTime !== '0000').map(v => v.id)
}

const makeReservationPurposesObj = () => {
  const reservationPurposes = store.getters['reservationPurposes/getData']
  const commonReservationPurposes = reservationPurposes.filter(
    v => v.customFlg === 0
  )
  let alwaysStaffIds = []
  if (commonReservationPurposes.length > 0) {
    alwaysStaffIds = commonReservationPurposes
      .map(v => v.staffsIds)
      .flat()
      .filter((x, i, self) => self.indexOf(x) === i)
      .map(v => Number(v))
  }
  const customReservationPurposes = reservationPurposes.filter(
    v => v.customFlg === 1
  )
  return { alwaysStaffIds, customReservationPurposes }
}

const calculateClosedDay = (day, openTimesObj) => {
  const publicHolidays = store.getters['master/getPublicHolidays']
  let result
  if (openTimesObj.specialClosedDays.includes(day)) {
    result = true
  } else if (openTimesObj.specialOpenDays.includes(day)) {
    result = false
  } else if (publicHolidays.includes(day)) {
    result = openTimesObj.closedDaysOfTheWeek.includes(7) ? true : false
  } else {
    const dayOfWeek = Number(moment(day, 'YYYYMMDD').format('d'))
    result = openTimesObj.closedDaysOfTheWeek.includes(dayOfWeek) ? true : false
  }
  return result
}

const calculateStaffWorkingDaysStaffIds = (
  day,
  staffWorkingDatesObj,
  openDayShiftIds
) => {
  const dayGroupArray = staffWorkingDatesObj[day]
  const staffIds = []
  if (dayGroupArray === undefined) return staffIds
  staffWorkingDatesObj[day].forEach(v => {
    if (openDayShiftIds.includes(v.shiftId)) staffIds.push(v.staffId)
  })
  return staffIds
}

// 来院目的検証はその日、予約を受けれるスタッフ達とカスタムされた日がopenDayかcloseDayか
const calculateReservationPurposesStaffIds = (day, reservationPurposesObj) => {
  let staffIds = reservationPurposesObj.alwaysStaffIds
  const specialReservationAvailablePatterns = store.getters[
    'specialReservationAvailablePatterns/getData'
  ].filter(v => v.date === day)
  const usedReservationPurposeIds = []
  if (specialReservationAvailablePatterns.length > 0) {
    specialReservationAvailablePatterns.forEach(
      specialReservationAvailablePattern => {
        const reservationAvailablePattern = store.getters[
          'reservationAvailablePatterns/getDataById'
        ](specialReservationAvailablePattern.reservationAvailablePatternId)
        usedReservationPurposeIds.push(
          specialReservationAvailablePattern.reservationPurposeId
        )
        if (reservationAvailablePattern.closedDayFlg === 0) {
          const reservationPurpose = store.getters[
            'reservationPurposes/getDataById'
          ](specialReservationAvailablePattern.reservationPurposeId)
          staffIds = staffIds
            .concat(reservationPurpose.staffsIds)
            .filter((x, i, self) => self.indexOf(x) === i)
        }
      }
    )
  }
  const publicHolidays = store.getters['master/getPublicHolidays']
  const customReservationPurposes = reservationPurposesObj.customReservationPurposes.filter(
    v => !usedReservationPurposeIds.includes(v.id)
  )
  customReservationPurposes.forEach(reservationPurpose => {
    if (publicHolidays.includes(day)) {
      staffIds = calculateCustomReservationPurposesStaffIds(
        staffIds,
        reservationPurpose,
        reservationPurpose.reservationAvailablePatternsIds[7]
      )
    } else {
      const dayOfWeek = Number(moment(day, 'YYYYMMDD').format('d'))
      staffIds = calculateCustomReservationPurposesStaffIds(
        staffIds,
        reservationPurpose,
        reservationPurpose.reservationAvailablePatternsIds[dayOfWeek]
      )
    }
  })
  return staffIds
}

const calculateCustomReservationPurposesStaffIds = (
  staffIds,
  reservationPurpose,
  reservationAvailablePatternId
) => {
  const reservationAvailablePattern = store.getters[
    'reservationAvailablePatterns/getDataById'
  ](reservationAvailablePatternId)
  return reservationAvailablePattern.closedDayFlg === 0
    ? staffIds
        .concat(reservationPurpose.staffsIds)
        .filter((x, i, self) => self.indexOf(x) === i)
    : staffIds
}

const calculateBaseCloseDays = reservationPurpose => {
  const reservationAvailablePatterns =
    store.getters['reservationAvailablePatterns/getData']
  const closedDayId = reservationAvailablePatterns.filter(
    v => v.closedDayFlg === 1
  )[0].id
  const baseTimeDayId = reservationAvailablePatterns.filter(
    v => v.baseTimeFlg === 1
  )[0].id
  const rpIntentionalWorkingDays = reservationPurpose.reservationAvailablePatternsIds
    .split(',')
    .map(v =>
      Number(v) !== closedDayId && Number(v) !== baseTimeDayId ? true : false
    )
  const otCloseDays = store.getters['openTimes/getData'].map(v =>
    v.closeFlg === 1 ? true : false
  )
  otCloseDays.shift()
  const result = []
  rpIntentionalWorkingDays.forEach((rpIntentionalWorkingDay, index) => {
    if (rpIntentionalWorkingDay === true && otCloseDays[index] === true) {
      result.push(index)
    }
  })
  return result
}
