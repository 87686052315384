export const achromaticColors = [
  '#ffffff',
  '#e6e6e6',
  '#cccccc',
  '#b3b3b3',
  '#999999',
  '#808080',
  '#666666',
  '#4d4d4d',
  '#333333',
  '#191919'
]

export const reds = [
  '#fdede4',
  '#f8c5ac',
  '#f29b76',
  '#ed6d46',
  '#e83820',
  '#d7000f',
  '#b60005',
  '#910000',
  '#680000',
  '#3b0001'
]

export const oranges = [
  '#fff6e9',
  '#fce2ba',
  '#facd89',
  '#f8b856',
  '#f5a21b',
  '#e48e00',
  '#c07700',
  '#985d00',
  '#6d3f00',
  '#3d1a00'
]

export const yellows = [
  '#fffeee',
  '#fffbc7',
  '#fff799',
  '#fff462',
  '#fff200',
  '#f3e100',
  '#cbbe00',
  '#a19600',
  '#736b00',
  '#403900'
]

export const yellowGreens = [
  '#f6faed',
  '#e2eec5',
  '#cce198',
  '#b6d56a',
  '#9dc93a',
  '#86b81b',
  '#709c12',
  '#567c03',
  '#3a5800',
  '#152e00'
]

export const greens = [
  '#ebf5ec',
  '#bedfc2',
  '#89c997',
  '#3eb370',
  '#00a051',
  '#009140',
  '#007c36',
  '#006428',
  '#004714',
  '#002300'
]

export const turquoises = [
  '#ebf6f5',
  '#bce1df',
  '#84ccc9',
  '#2bb7b3',
  '#00a59f',
  '#00958d',
  '#007f78',
  '#006660',
  '#004844',
  '#00241f'
]

export const lightBlues = [
  '#eaf6fd',
  '#bae3f9',
  '#7ecef4',
  '#00b9ef',
  '#00a7ea',
  '#0097db',
  '#0082ba',
  '#006896',
  '#00496d',
  '#00233d'
]

export const blues = [
  '#eaeff9',
  '#bbcce9',
  '#88abda',
  '#4c8dcb',
  '#0073bd',
  '#0062ac',
  '#005293',
  '#004077',
  '#00264d',
  '#00042d'
]

export const navyBlues = [
  '#e9e6f3',
  '#bbb3d8',
  '#8f82bc',
  '#6356a3',
  '#36318f',
  '#1b1c80',
  '#14126f',
  '#080059',
  '#00003f',
  '#00011d'
]

export const purples = [
  '#f3ebf4',
  '#dbbeda',
  '#c490bf',
  '#b062a3',
  '#9c308d',
  '#8a017c',
  '#750069',
  '#5d0053',
  '#420039',
  '#1c0018'
]

export const pinks = [
  '#fdeff5',
  '#f7c9dd',
  '#f19ec2',
  '#eb6ea5',
  '#e62e8b',
  '#d60077',
  '#b60066',
  '#91004f',
  '#6a0035',
  '#3d0010'
]

export const redPinks = [
  '#fdeeed',
  '#f7c7c6',
  '#f29c9f',
  '#ec6d7b',
  '#e7355c',
  '#d7004a',
  '#b6003d',
  '#91002c',
  '#690016',
  '#3c0001'
]

export const richTextEditorColorSet = [
  ['#ffffff', '#000000', '#999999', '#e83820', '#f5a21b'],
  ['#fff200', '#9dc93a', '#00a051', '#00a59f', '#00a7ea'],
  ['#0073bd', '#36318f', '#9c308d', '#e62e8b', '#e7355c']
]

export const schemaPopupColorSets = [
  [
    '#ffffff',
    '#88abda',
    '#7ecef4',
    '#89c997',
    '#cce198',
    '#fff799',
    '#facd89',
    '#f29b76',
    '#f19ec2'
  ],
  [
    '#999999',
    '#0073bd',
    '#00a7ea',
    '#00a051',
    '#9dc93a',
    '#fff200',
    '#f5a21b',
    '#e83820',
    '#e62e8b'
  ],
  [
    '#808080',
    '#0062ac',
    '#0097db',
    '#009140',
    '#86b81b',
    '#f3e100',
    '#e48e00',
    '#d7000f',
    '#d60077'
  ],
  [
    '#4d4d4d',
    '#004077',
    '#006896',
    '#006428',
    '#567c03',
    '#a19600',
    '#985d00',
    '#910000',
    '#91004f'
  ],
  [
    '#000000',
    '#00042d',
    '#00233d',
    '#002300',
    '#152e00',
    '#403900',
    '#3d1a00',
    '#3b0001',
    '#3d0010'
  ]
]

export const defaultColorSets = [
  achromaticColors,
  reds,
  oranges,
  yellows,
  yellowGreens,
  greens,
  turquoises,
  lightBlues,
  blues,
  navyBlues,
  purples,
  pinks,
  redPinks
]
