import { getAxiosObject } from '@/utils/library'
import {
  commonGetters,
  commonMutations,
  commonActions
} from '@/store/store_utils'
import { toStateName } from '@/utils/convert_string'

const dataName = 'antibodies'
const keys = ['patientId']

const state = {
  antibodies: [],
  dataIndexedById: {},
  updatedAt: '20101010101010'
}
keys.forEach(key => {
  state[toStateName(key)] = null
})

const getters = {
  ...commonGetters(dataName, ['getUpdatedAt', 'getDataById'], {
    filterDel: true,
    getDataBy: keys
  })
}

const actions = {
  ...commonActions(['setData', 'setUpdatedAt', 'updateAfter']),
  async create({ commit }, { antibody, antibodyTypes }) {
    try {
      const axiosObject = getAxiosObject()
      const res = await axiosObject.post('/antibodies', {
        antibody,
        antibodyTypes
      })
      if (res.status === 200) {
        commit('updateAfter', [res.data.antibody])
        commit('antibodyTypes/updateAfter', res.data.antibodyTypes, {
          root: true
        })
      }
      return true
    } catch (error) {
      if (error.response?.data?.message) {
        return error.response.data.message === 'no data'
          ? `no ${error.response.data.extra}`
          : error.response.data.message
      } else {
        return 'server error'
      }
    }
  },
  async update({ commit }, { antibody, antibodyTypes }) {
    try {
      const axiosObject = getAxiosObject()
      const res = await axiosObject.put('/antibodies', {
        antibody,
        antibodyTypes
      })
      if (res.status === 200) {
        commit('updateAfter', [res.data.antibody])
        commit('antibodyTypes/updateAfter', res.data.antibodyTypes, {
          root: true
        })
      }
      return true
    } catch (error) {
      if (error.response?.data?.message) {
        if (error.response.data.message === 'no data') {
          return `no ${error.response.data.extra}`
        } else {
          if (error.response.data.extra?.data) {
            commit('setData', error.response.data.extra.data)
          }
          return error.response.data.message
        }
      } else {
        return 'server error'
      }
    }
  },

  async delete({ commit }, id) {
    try {
      const axiosObject = getAxiosObject()
      const res = await axiosObject.delete('/antibodies', {
        data: { id }
      })
      if (res.status === 200) {
        commit('updateAfter', [res.data.antibody])
        commit('antibodyTypes/updateAfter', res.data.antibodyTypes, {
          root: true
        })
      }
      return true
    } catch (error) {
      if (error.response?.data?.extra?.data) {
        commit('setData', error.response.data.extra.data)
      }
      return error.response?.data?.message || 'server error'
    }
  }
}

const mutations = {
  ...commonMutations(dataName, ['setData', 'setUpdatedAt', 'updateAfter'], {
    setDataBy: keys,
    indexedById: true
  })
}

export default {
  namespaced: true,
  state,
  getters,
  actions,
  mutations
}
