<template>
  <div class="base-select-box">
    <div class="select-wrapper" :class="{ disabled: disabled }">
      <select
        class="select-box"
        :class="{ 'disabled-color-white': disabledColorWhite }"
        data-test="select-box"
        data-e2e="select-box"
        :value="value"
        :disabled="disabled"
        :style="styles"
        @change="selectValue"
        @blur="$emit('blur')"
      >
        <option v-for="datum in selectData" :value="datum.id" :key="datum.id">
          <span v-if="hasSpace(datum.name)" class="hasSpace">
            <span v-for="(char, i) in Array.from(datum.name)" :key="i">
              <span v-if="char === ' '" class="space">&nbsp;</span>
              <span v-else>{{ char }}</span>
            </span>
          </span>
          <span v-else>{{ datum.name }}</span>
        </option>
        <option value="" class="placeholder" disabled selected>{{
          placeholder
        }}</option>
      </select>
    </div>
  </div>
</template>

<script>
export default {
  name: 'BaseSelectBox',

  props: {
    value: { type: [String, Number] },
    selectData: {
      type: Array, //[{id:int,name:string}]or[{id:int,name:number}]
      required: true,
      validator: arr => {
        return arr.every(v => {
          return (
            (Number.isInteger(v.id) &&
              typeof v.name === 'string' &&
              v.id !== null &&
              v.name !== null) ||
            (Number.isInteger(v.id) &&
              typeof v.name === 'number' &&
              v.id !== null &&
              v.name !== null)
          )
        })
      }
    },
    disabled: { type: Boolean, default: false },
    disabledColorWhite: { type: Boolean, default: false },
    styles: {
      type: Object,
      validator: obj => {
        return Object.values(obj).every(v => typeof v === 'string')
      }
    },
    placeholder: { type: String, default: '' }
  },
  methods: {
    selectValue(e) {
      this.$emit('input', parseInt(e.target.value))
    },
    hasSpace(val) {
      return typeof val === 'string' && val.includes(' ')
    }
  }
}
</script>

<style lang="scss" scoped>
.base-select-box {
  > .select-wrapper {
    display: inline-block;
    position: relative;
    background-color: #{$white};
    border-radius: 5px;
    &:after {
      content: '';
      position: absolute;
      top: 50%;
      right: 8px;
      width: 6px;
      height: 6px;
      border: 0px;
      border-bottom: solid 2px #{$pumpkin};
      border-right: solid 2px #{$pumpkin};
      -ms-transform: rotate(45deg);
      -webkit-transform: rotate(45deg);
      transform: rotate(45deg);
      margin-top: -6px;
    }
    &.disabled {
      &:after {
        border-bottom: solid 2px #{$brown-gray};
        border-right: solid 2px #{$brown-gray};
      }
    }
    > .select-box {
      cursor: pointer;
      -webkit-appearance: none;
      -moz-appearance: none;
      appearance: none;
      display: block;
      position: relative;
      z-index: 1;
      height: 33px;
      width: 100%;
      padding: 0 20px 0 8px;
      background: transparent;
      border: solid 2px #{$light-grey};
      border-radius: 5px;
      font-size: 13px;
      > .option-default-none {
        display: none;
      }
      > .placeholder {
        display: none;
      }
      &:disabled {
        pointer-events: none;
        background-color: #{$light-grey};
      }
      &.disabled-color-white {
        &:disabled {
          opacity: 1;
          background-color: #{$white};
          color: #{$greyish-brown};
        }
      }
    }
    > .select-box::-ms-expand {
      display: none;
    }
  }
}
</style>
