<template>
  <div class="header-login-staff">
    <div class="image-name" @click="openPopup">
      <img
        v-if="headerStaff.image === null"
        src="@/assets/images/no_image.png"
        class="staff-image"
        width="36px"
        height="36px"
        alt="no-image"
      />
      <img
        v-else
        :src="headerStaff.image"
        class="staff-image"
        width="36px"
        height="36px"
        alt="staff-image"
      />
      <div class="staff-name" data-e2e="header-login-staff">
        {{ headerStaff.name }}
      </div>
    </div>
  </div>
</template>

<script>
export default {
  name: 'HeaderLoginStaff',
  props: {
    headerStaff: {
      type: Object,
      validator: function(obj) {
        return typeof obj.name === 'string'
      }
    }
  },
  methods: {
    openPopup() {
      this.$emit('popup')
    }
  }
}
</script>

<style lang="scss" scoped>
.header-login-staff {
  max-width: 170px;
  margin-top: 14px;
  float: left;
  align-items: center;
  > .image-name {
    display: flex;
    &:hover {
      opacity: 0.8;
      cursor: pointer;
    }
    > .staff-image {
      width: 36px;
      height: 36px;
      object-fit: cover;
      border-radius: 50%;
    }
    .staff-name {
      float: left;
      margin-left: 10px;
      margin-top: 5px;
      font-size: 17px;
      font-weight: normal;
      font-stretch: normal;
      font-style: normal;
      letter-spacing: normal;
      text-align: left;
      color: #{$greyish-brown};
      overflow: hidden;
      white-space: nowrap;
      text-overflow: ellipsis;
    }
  }
}
@media (max-width: $horizontal-tablet-width) {
  .header-login-staff {
    .image-name {
      .staff-name {
        font-size: 13px;
        margin-top: 7px;
      }
    }
  }
}
</style>
