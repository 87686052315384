export const csvToArray = (value, arrayValueType = 'number') => {
  return typeof value === 'string'
    ? value !== ''
      ? arrayValueType === 'string'
        ? value.split(',')
        : value.split(',').map(Number)
      : []
    : value
}

const datumCsvToArray = (columns, arrayValueTypes, datum) => {
  columns.forEach((column, i) => {
    const arrayValueType = arrayValueTypes[i]
    datum[column] = arrayValueType
      ? csvToArray(datum[column], arrayValueType)
      : csvToArray(datum[column])
  })
  return datum
}

export const dataCsvToArray = (
  data = [],
  columns = [],
  arrayValueTypes = []
) => {
  if (Array.isArray(data)) {
    return data.map(datum => datumCsvToArray(columns, arrayValueTypes, datum))
  } else {
    return datumCsvToArray(columns, arrayValueTypes, data)
  }
}

export default { csvToArray, dataCsvToArray }
