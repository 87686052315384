import { getAxiosObject } from '@/utils/library'
import {
  commonGetters,
  commonMutations,
  commonActions
} from '@/store/store_utils'
import { toStateName } from '@/utils/convert_string'

const dataName = 'diseases'
const keys = ['diseaseClassOriginalId']

const state = {
  diseases: [],
  dataIndexedById: {},
  dataIndexedByOriginalId: {},
  updatedAt: '20101010101010'
}
keys.forEach(key => {
  state[toStateName(key)] = null
})

const getters = {
  ...commonGetters(
    dataName,
    ['getData', 'getDataById', 'getDataByOriginalId', 'getUpdatedAt'],
    { filterDel: true, getDataBy: keys }
  )
}

const actions = {
  ...commonActions(['setData', 'setUpdatedAt', 'updateAfter']),
  async create({ commit }, newData) {
    try {
      const axiosObject = getAxiosObject()
      const res = await axiosObject.post('/' + dataName, newData)
      if (res.status === 200) {
        commit('updateAfter', [res.data.disease])
        commit('diseaseClasses/updateAfter', [res.data.diseaseClass], {
          root: true
        })
        return true
      }
    } catch (error) {
      if (error.response?.data?.message) {
        if (error.response.data.message === 'no data in clinic') {
          if (error.response.data.extra.dataName === 'diseaseClass') {
            commit('diseaseClasses/setData', error.response.data.extra.data, {
              root: true
            })
          }
          return 'no data in clinic'
        } else if (error.response.data.message === 'no data') {
          return `no ${error.response.data.extra}`
        } else {
          return error.response.data.message
        }
      } else {
        return 'server error'
      }
    }
  },
  async delete({ commit }, id) {
    try {
      const axiosObject = getAxiosObject()
      const res = await axiosObject.delete('/' + dataName, { data: { id } })
      if (res.status === 200) {
        commit('updateAfter', [res.data.disease])
        return true
      }
    } catch (error) {
      if (error.response?.data?.extra?.data) {
        commit('setData', error.response.data.extra.data)
        return error.response.data.message
      } else {
        return 'server error'
      }
    }
  },
  async update({ commit }, data) {
    try {
      const axiosObject = getAxiosObject()
      const res = await axiosObject.put('/' + dataName, {
        disease: data.disease
      })
      if (res.status === 200) {
        commit('updateAfter', res.data.diseases)
        if (Object.keys(res.data.diseaseClass).length > 0) {
          commit('diseaseClasses/updateAfter', [res.data.diseaseClass], {
            root: true
          })
        }
        return true
      }
    } catch (error) {
      if (error.response?.data?.message) {
        if (error.response.data.message === 'no data in clinic') {
          if (error.response.data.extra.dataName === 'disease') {
            commit('setData', error.response.data.extra.data)
          } else if (error.response.data.extra.dataName === 'diseaseClass') {
            commit('diseaseClasses/setData', error.response.data.extra.data, {
              root: true
            })
          }
          return 'no data in clinic'
        }
        if (error.response.data.message === 'no data') {
          return error.response.data.extra === 'disease already edited'
            ? 'already edited'
            : 'already deleted'
        } else {
          return error.response.data.message
        }
      } else {
        return 'server error'
      }
    }
  }
}

const mutations = {
  ...commonMutations(dataName, ['setData', 'setUpdatedAt', 'updateAfter'], {
    order: true,
    indexedById: true,
    indexedByOriginalId: true,
    setDataBy: keys
  })
}

export default {
  namespaced: true,
  state,
  getters,
  actions,
  mutations
}
