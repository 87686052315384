import store from '@/store/store'

export default {
  data() {
    return {
      mixinWindowWidth: window.innerWidth
    }
  },
  mounted() {
    window.addEventListener('resize', this.mixinHandleResize)
    this.mixinHandleResize()
  },
  beforeDestroy() {
    window.removeEventListener('resize', this.mixinHandleResize)
  },
  methods: {
    mixinHandleResize() {
      this.mixinWindowWidth = window.innerWidth
      if (this.mixinWindowWidth <= 1206) {
        store.dispatch('petorelu/narrowLeftMenu')
        store.dispatch('petorelu/hideToggleButton')
      } else {
        store.dispatch('petorelu/showToggleButton')
      }
    }
  }
}
