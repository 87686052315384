import { getAxiosObject } from '@/utils/library'
import {
  commonGetters,
  commonActions,
  commonMutations
} from '@/store/store_utils'

const dataName = 'reservationColumnGroups'

const state = {
  reservationColumnGroups: [],
  dataIndexedById: {},
  updatedAt: '20101010101010'
}

const getters = {
  ...commonGetters(
    dataName,
    [
      'getData',
      'getDataIncludeDel',
      'getDataById',
      'getUpdatedAt',
      'selectData'
    ],
    { filterDel: true }
  )
}

const actions = {
  ...commonActions(['setData', 'setUpdatedAt', 'updateAfter']),
  async create({ commit }, reservationColumnGroup) {
    try {
      const axiosObject = getAxiosObject()
      const res = await axiosObject.post('/reservation-column-groups', {
        reservationColumnGroup: reservationColumnGroup
      })
      if (res.status === 200) {
        commit('updateAfter', [res.data.reservationColumnGroup])
        return true
      }
    } catch (error) {
      return error.response?.data?.message
        ? error.response.data.message === 'no data'
          ? `no ${error.response.data.extra}`
          : error.response.data.message
        : 'server error'
    }
  },
  async update({ commit }, reservationColumnGroup) {
    try {
      const axiosObject = getAxiosObject()
      const res = await axiosObject.put('/reservation-column-groups', {
        reservationColumnGroup
      })
      if (res.status === 200) {
        commit('updateAfter', [res.data.reservationColumnGroup])
        return true
      }
    } catch (error) {
      if (error.response?.data?.message) {
        if (error.response.data.extra?.data) {
          commit('setData', error.response.data.extra.data)
        }
        return error.response.data.message
      } else {
        return 'server error'
      }
    }
  },
  async delete({ commit }, id) {
    try {
      const axiosObject = getAxiosObject()
      const res = await axiosObject.delete('/reservation-column-groups', {
        data: { id }
      })
      if (res.status === 200) {
        commit('updateAfter', [res.data.reservationColumnGroup])
        return true
      }
    } catch (error) {
      if (error.response?.data?.message) {
        if (error.response.data.message === 'currently used') {
          return `used in ${error.response.data.extra}`
        } else {
          if (error.response.data.extra?.data) {
            commit('setData', error.response.data.extra.data)
          }
          return error.response.data.message
        }
      } else {
        return 'server error'
      }
    }
  }
}

const mutations = {
  ...commonMutations(dataName, ['setData', 'setUpdatedAt', 'updateAfter'], {
    indexedById: true
  })
}

export default {
  namespaced: true,
  state,
  mutations,
  getters,
  actions
}
