import { getAxiosObject } from '@/utils/library'
import store from '@/store/store'
import moment from 'moment'

export async function mixinDataUpdate(data) {
  const tokenIssuingTime = moment(
    store.getters['auth/tokenIssuingTime'],
    'YYYYMMDDHHmmss'
  )
  const after2hourTime = tokenIssuingTime.add(
    process.env.VUE_APP_EXPIRE_IN,
    'h'
  ) // 10, 's'
  if (moment().isAfter(after2hourTime)) return
  const axiosObject = getAxiosObject()
  try {
    const useStore = process.env.NODE_ENV === 'test' ? this.$store : store
    const serverTime = data.updatedAt
    const oneStoreTables = [
      'clinics',
      'commonSettings',
      'displays',
      'mailSettings',
      'previousMails',
      'reservationSettings',
      'shiftSettings',
      'printSettings'
    ]
    const isOneStore = table => {
      return oneStoreTables.includes(table) ? true : false
    }
    const storeTimes = data.tables
      .map(table => {
        const store = isOneStore(table) ? table.slice(0, -1) : table
        return { table, updatedAt: useStore.getters[`${store}/getUpdatedAt`] }
      })
      .filter(storeTime => storeTime.updatedAt <= serverTime)

    if (storeTimes.length > 0) {
      const response = await axiosObject.post('/data-update', { storeTimes })
      if (response.status === 200) {
        storeTimes.forEach(storeTime => {
          const table = storeTime.table
          const resData = response.data[table]
          if (resData.length > 0) {
            const data = isOneStore(table) ? resData[0] : resData
            const store = isOneStore(table) ? table.slice(0, -1) : table
            useStore.dispatch(`${store}/updateAfter`, data)
            const latestUpdatedAt = resData.reduce((latest, current) => {
              const currentUpdatedAt = moment(current.updatedAt).format(
                'YYYYMMDDHHmmss'
              )
              return latest > currentUpdatedAt ? latest : currentUpdatedAt
            }, storeTime.updatedAt)
            useStore.dispatch(`${store}/setUpdatedAt`, latestUpdatedAt)
            const notifications = [
              'notices',
              'receivedMails',
              'reservationRequests',
              'reservations'
            ]
            if (notifications.includes(table)) {
              useStore.dispatch(
                `${store}/setCount`,
                response.data[`${table}Count`]
              )
            }
          }
        })
      }
    }
    return true
  } catch (error) {
    return false
  }
}

export default {
  methods: {
    mixinDataUpdate
  }
}
