<template>
  <node-view-wrapper
    class="rich-text-editor-examination-table"
    :style="hoverStyles"
  >
    <div class="examination-result-group-table-area" @click="handleClick">
      <table>
        <tr>
          <th class="w30">登録日</th>
          <td class="w70" data-test="date">
            {{
              displayExaminationResults[0]
                ? displayExaminationResults[0].date
                : ''
            }}
          </td>
        </tr>
        <tr>
          <th
            class="w30"
            v-html="$sanitize(escapeTextExceptHighLight('検査名', true))"
          ></th>
          <td
            class="w70"
            v-html="
              $sanitize(
                escapeTextExceptHighLight(
                  displayExaminationResults[0]
                    ? displayExaminationResults[0].treatmentName
                    : ''
                )
              )
            "
            data-test="treatmentName"
          ></td>
        </tr>
        <tr>
          <th
            class="w30"
            v-html="$sanitize(escapeTextExceptHighLight('表示種別', true))"
          ></th>
          <td
            class="w70"
            v-html="
              $sanitize(
                escapeTextExceptHighLight(
                  displayExaminationResults[0]
                    ? displayExaminationResults[0].speciesName
                    : ''
                )
              )
            "
            data-test="speciesName"
          ></td>
        </tr>
      </table>

      <table>
        <tr>
          <th
            class="w15"
            v-html="$sanitize(escapeTextExceptHighLight('項目区分名', true))"
          ></th>
          <th
            class="w15"
            v-html="$sanitize(escapeTextExceptHighLight('項目名', true))"
          ></th>
          <th
            class="w15"
            v-html="$sanitize(escapeTextExceptHighLight('結果(単位)', true))"
          ></th>
          <th class="w15">基準値</th>
          <th
            class="w40"
            v-html="$sanitize(escapeTextExceptHighLight('コメント', true))"
          ></th>
        </tr>
        <template
          v-for="results in Object.values(resultsGroupedByTopicClassId)"
        >
          <tr
            v-for="(result, n) in results"
            :key="results[0].examinationTopicClassId + '_' + n"
          >
            <td
              class="w15"
              v-if="n === 0"
              :rowspan="results.length"
              v-html="
                $sanitize(escapeTextExceptHighLight(result.topicClassName))
              "
              data-test="topicClassName"
            ></td>
            <td
              class="w15"
              v-html="$sanitize(escapeTextExceptHighLight(result.topicName))"
              data-test="topicName"
            ></td>
            <td
              class="w15"
              v-html="$sanitize(escapeTextExceptHighLight(result.resultUnit))"
              data-test="resultUnit"
            ></td>
            <td class="w15" data-test="reference">{{ result.reference }}</td>
            <td
              class="w40"
              v-html="$sanitize(escapeTextExceptHighLight(result.comment))"
              data-test="comment"
            ></td>
          </tr>
        </template>
      </table>
    </div>
  </node-view-wrapper>
</template>

<script>
import { NodeViewWrapper, nodeViewProps } from '@tiptap/vue-2'
import { mapGetters } from 'vuex'
import moment from 'moment'
import _ from 'lodash'
import { highLightText } from '@/utils/high_light'
import { escapeHtml } from '@/utils/escape'
import { unescapeHighLightHtml } from '@/utils/unescape'

export default {
  name: 'RichTextEditorExaminationTable',

  components: {
    NodeViewWrapper
  },

  props: nodeViewProps,

  computed: {
    ...mapGetters({
      getExaminationResultsByPatientIdIncludeDel:
        'examinationResults/getDataByPatientIdIncludeDel',
      newExaminationResultGroups:
        'examinationResults/getNewExaminationResultGroups',
      getTreatment: 'treatments/getDataById',
      getFormat: 'examinationFormats/getDataById',
      getTopicClass: 'examinationTopicClasses/getDataById',
      getTopic: 'examinationTopics/getDataById',
      getExaminationSpecies: 'examinationSpecies/getDataById',
      getSpecies: 'species/getDataById'
    }),
    hoverStyles() {
      return this.extension.options.richTextEditorShowFlg
        ? { '--cursor': 'auto', '--opacity': '1' }
        : { '--cursor': 'pointer', '--opacity': '0.8' }
    },
    examinationResults() {
      let results = []
      if (
        !this.extension.options.richTextEditorShowFlg &&
        Object.keys(this.newExaminationResultGroups).length > 0
      ) {
        //カルテ登録・編集画面での表示
        results = this.newExaminationResultGroups[
          this.node.attrs.examinationResultGroupId
        ]
          ? this.newExaminationResultGroups[
              this.node.attrs.examinationResultGroupId
            ]
          : []
      } else if (
        this.extension.options.richTextEditorShowFlg &&
        this.extension.options.patientId > 0 &&
        this.extension.options.medicalContentId > 0
      ) {
        //カルテの履歴部分での表示
        results = this.getExaminationResultsByPatientIdIncludeDel(
          this.extension.options.patientId
        ).filter(
          v =>
            v.medicalContentId === this.extension.options.medicalContentId &&
            v.examinationResultGroupId ===
              this.node.attrs.examinationResultGroupId
        )
      }
      return results
    },
    displayExaminationResults() {
      return this.examinationResults.map(result => {
        const treatment = this.getTreatment(result.treatmentId)
        const format = this.getFormat(result.examinationFormatId)
        const topicClass = this.getTopicClass(result.examinationTopicClassId)
        const topic = this.getTopic(result.examinationTopicId)
        const examinationSpecies = this.getExaminationSpecies(
          result.examinationSpeciesId
        )
        const speciesName = examinationSpecies
          ? this.getSpecies(examinationSpecies.speciesId).name || ''
          : ''
        const reference = examinationSpecies?.reference || ''
        const numFlg = topic.examinationType === '数値' && reference !== ''
        const min = numFlg ? reference.split(',')[0] : ''
        const max = numFlg ? reference.split(',')[1] : ''
        return {
          ...result,
          treatmentName: treatment.name,
          topicClassName: topicClass.name,
          topicName: topic.name,
          speciesName,
          resultUnit: topic.unit
            ? result.result + '(' + topic.unit + ')'
            : result.result,
          reference: min === '' && max === '' ? reference : `${min} – ${max}`,
          date: moment(result.date, 'YYYYMMDD').format('YYYY年MM月DD日（dd）'),
          treatment,
          format
        }
      })
    },
    resultsGroupedByTopicClassId() {
      return _.groupBy(
        this.displayExaminationResults,
        'examinationTopicClassId'
      )
    }
  },

  methods: {
    escapeTextExceptHighLight(text, headerNameFlg = false) {
      if (
        this.extension.options.richTextEditorShowFlg &&
        this.extension.options.searchRegExp &&
        text.length > 0
      ) {
        const highLightedText = highLightText(
          text,
          this.extension.options.searchRegExp
        )
        if (headerNameFlg) {
          return highLightedText
        } else {
          const escapedContent = escapeHtml(highLightedText)
          return unescapeHighLightHtml(escapedContent)
        }
      } else {
        return escapeHtml(text)
      }
    },
    handleClick() {
      if (!this.extension.options.richTextEditorShowFlg) {
        this.$parent.$emit('click-examination-result-table', {
          examinationResultGroupId: this.node.attrs.examinationResultGroupId,
          treatment: this.displayExaminationResults[0].treatment,
          format: this.displayExaminationResults[0].format,
          examinationTableCursorPosition: this.getPos()
        })
      }
    }
  }
}
</script>

<style lang="scss" scoped>
.rich-text-editor-examination-table {
  &:hover {
    cursor: var(--cursor);
    opacity: var(--opacity);
  }
  > div {
    table {
      border-collapse: collapse;
      table-layout: fixed;
      width: 100%;
      margin: 0 auto;
      overflow: hidden;
      &:first-child {
        margin-bottom: 10px;
      }

      td,
      th {
        min-width: 1em;
        border: 1px solid #{$light-grey};
        padding: 3px 5px;
        vertical-align: top;
        box-sizing: border-box;
        position: relative;
        > * {
          margin-bottom: 0;
        }
      }

      th {
        box-sizing: border-box;
        height: 24px;
        line-height: 24px;
        font-weight: bold;
        font-size: 13px;
        text-align: left;
        background-color: #{$gray_f0};
      }

      td {
        height: 24px;
        line-height: 24px;
        font-size: 13px;
      }

      > tr {
        .w15 {
          width: 15%;
        }
        .w20 {
          width: 20%;
        }
        .w30 {
          width: 30%;
        }
        .w40 {
          width: 40%;
        }
        .w55 {
          width: 55%;
        }
        .w70 {
          width: 70%;
        }
      }
    }
  }
}
</style>
