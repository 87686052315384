<template>
  <focus-trap :escapeDeactivates="false" :clickDeactivates="false">
    <div
      class="examination-list-popup"
      @keyup.esc.stop="closePopup"
      tabindex="-1"
    >
      <popup
        :buttons="['閉じる']"
        @close="closePopup"
        @click-close-mark="closePopup"
      >
        <template v-slot:content>
          <div class="content">
            <div class="title">検査登録</div>
            <div class="search-area-component">
              <search-area
                v-model="searchWord"
                :placeholder="''"
                :textBoxLabel="'検索単語'"
                :textValue="searchWord"
                :searchAreaStyles="{ minWidth: '590px', width: '590px' }"
              />
            </div>
            <div class="sub-title">検査内容</div>
            <accordion-list
              v-show="searchWord.length === 0"
              :data="allList"
              :styles="{ height: '300px' }"
              @click-row="openExaminationPopup"
            />
            <accordion-list
              v-show="searchWord.length !== 0"
              :data="filteredList"
              :keyIndexFlg="true"
              :styles="{ height: '300px' }"
              @click-row="openExaminationPopup"
            />
          </div>
        </template>
      </popup>
      <examination-popup
        v-if="examinationPopupFlg"
        :treatment="treatment"
        :format="format"
        :examinationResultGroups="examinationResultGroups"
        :examinationResultGroupsIncludeDel="examinationResultGroupsIncludeDel"
        :patientId="patientId"
        :medicalRecordDate="medicalRecordDate"
        @close="closeExaminationPopup"
        @copy="copy"
      ></examination-popup>
      <!-- <announce-popup
        v-if="alertFlg"
        @cancel="alertFlg = false"
        @decision="goToExaminationRegistration"
        :title="'注意'"
        :buttons="['閉じる', '基準値設定']"
        :type="'alert'"
        >検査基準値が未登録となっています。検査基準値の登録を行いますか？</announce-popup
      > -->
    </div>
  </focus-trap>
</template>

<script>
import AccordionList from '@/components/parts/organisms/AccordionList'
// import AnnouncePopup from '@/components/popups/AnnouncePopup'
import ExaminationPopup from '@/components/popups/ExaminationPopup'
import Popup from '@/components/popups/Popup'
import SearchArea from '@/components/parts/molecules/SearchArea'
import { FocusTrap } from 'focus-trap-vue'
import { mapGetters } from 'vuex'

export default {
  name: 'ExaminationListPopup',

  components: {
    AccordionList,
    // AnnouncePopup,
    ExaminationPopup,
    FocusTrap,
    Popup,
    SearchArea
  },

  props: {
    examinationResultGroups: { type: Object },
    examinationResultGroupsIncludeDel: { type: Object },
    patientId: { type: Number },
    medicalRecordDate: { type: String }
  },

  data() {
    return {
      alertFlg: false,
      examinationFormat: {},
      examinationPopupFlg: false,
      searchWord: '',
      treatment: {},
      format: {}
    }
  },

  computed: {
    ...mapGetters({
      treatmentLargeClasses: 'treatmentLargeClasses/getData',
      treatmentClassesByLargeOriginalId:
        'treatmentClasses/getDataByTreatmentLargeClassOriginalId',
      treatmentsByClassOriginalId:
        'treatments/getDataByTreatmentClassOriginalId',
      formatsByTreatmentOriginalId:
        'examinationFormats/getDataByTreatmentOriginalId'
    }),
    treatmentClasses() {
      const examinationLargeClass = this.treatmentLargeClasses.find(
        t => t.examinationFlg === 1
      )
      return this.treatmentClassesByLargeOriginalId(
        examinationLargeClass.originalId
      )
    },
    treatments() {
      return this.treatmentClasses.reduce(
        (map, treatmentClass) =>
          map.concat(
            this.treatmentsByClassOriginalId(treatmentClass.originalId)
          ),
        []
      )
    },
    allList() {
      return this.treatmentClasses
        ? this.treatmentClasses
            .filter(mediumClass => mediumClass.showFlg)
            .map(mediumClass => {
              const treatments = this.treatmentsByClassOriginalId(
                mediumClass.originalId
              )
              return {
                ...mediumClass,
                children: treatments
                  ? treatments
                      .filter(treatment => treatment.showFlg)
                      .map(treatment => {
                        return {
                          ...treatment,
                          layerNumber: 2,
                          label: this.formatsByTreatmentOriginalId(
                            treatment.originalId
                          )
                            ? ''
                            : '検査基準値未登録'
                        }
                      })
                  : [],
                layerNumber: 1
              }
            })
        : []
    },
    filteredList() {
      return this.filteredTreatmentClasses.concat(this.filteredTreatments)
    },
    filteredTreatmentClasses() {
      if (this.searchWord.length === 0) return []
      return this.treatmentClasses
        .filter(
          v =>
            v.showFlg &&
            v.name.toLowerCase().includes(this.searchWord.toLowerCase().trim())
        )
        .map(mediumClass => {
          const treatments = this.treatmentsByClassOriginalId(
            mediumClass.originalId
          )
          return {
            ...mediumClass,
            name: '診・分類 / ' + mediumClass.name,
            children: treatments
              ? treatments
                  .filter(treatment => treatment.showFlg)
                  .map(treatment => {
                    return {
                      ...treatment,
                      layerNumber: 2,
                      label: this.formatsByTreatmentOriginalId(
                        treatment.originalId
                      )
                        ? ''
                        : '検査基準値未登録'
                    }
                  })
              : [],
            layerNumber: 1
          }
        })
    },
    filteredTreatments() {
      if (this.searchWord.length === 0) return []
      return this.treatments
        .filter(
          v =>
            v.showFlg &&
            v.name.toLowerCase().includes(this.searchWord.toLowerCase().trim())
        )
        .map(treatment => {
          return {
            ...treatment,
            name: treatment.name,
            layerNumber: 1,
            label: this.formatsByTreatmentOriginalId(treatment.originalId)
              ? ''
              : '検査基準値未登録'
          }
        })
    }
  },

  methods: {
    copy(examinationResultGroup) {
      this.$emit('copy', examinationResultGroup)
      this.examinationPopupFlg = false
    },
    closePopup() {
      this.$emit('close')
    },
    openExaminationPopup(treatment) {
      const examinationFormats = this.formatsByTreatmentOriginalId(
        treatment.originalId
      )
      this.treatment = treatment
      if (examinationFormats?.length > 0) {
        this.format = examinationFormats[0]
        this.examinationPopupFlg = true
        // } else {
        //   this.alertFlg = true
      }
    },
    closeExaminationPopup() {
      this.examinationPopupFlg = false
      this.treatment = {}
      this.format = {}
    }
    // goToExaminationRegistration() {
    //   this.$router.push({
    //     name: 'examination-new',
    //     params: { originalId: this.treatment.originalId }
    //   })
    // }
  }
}
</script>

<style lang="scss" scoped>
.examination-list-popup {
  position: absolute;
  top: 0;
  left: 0;
  .content {
    box-sizing: border-box;
    .title {
      margin-bottom: 15px;
      font-size: 16px;
      font-weight: bold;
      text-align: left;
    }
    .sub-title {
      margin: 20px 0 5px 0;
      font-size: 14px;
      font-weight: bold;
      text-align: left;
    }
  }
}
</style>
