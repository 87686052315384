export const decodeBase64fromBuffer = data => {
  const decode = obj => {
    const imageAttributes = ['image', 'overImage', 'stamp', 'thumbnailImage']
    imageAttributes.forEach(imageAttribute => {
      const image = obj[imageAttribute]
      if (
        image !== undefined &&
        image !== null &&
        !(typeof image === 'string' && image.startsWith('data:image/'))
      ) {
        obj[imageAttribute] = Buffer.from(image, 'base64').toString()
      }
    })
    return obj
  }
  return Array.isArray(data) ? data.map(v => decode(v)) : decode(data)
}
