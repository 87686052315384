<template>
  <div class="base-button-small-orange">
    <button
      type="button"
      @click="handleClick"
      :disabled="disabled || preventContinuousClickFlg"
      :style="styles"
      data-e2e="base-button-small-orange"
    >
      <span><slot /></span>
    </button>
  </div>
</template>

<script>
export default {
  name: 'BaseButtonSmallOrange',

  props: {
    disabled: { type: Boolean, default: false },
    styles: {
      type: Object,
      validator: function(obj) {
        return Object.values(obj).every(v => typeof v === 'string')
      }
    }
  },

  data() {
    return {
      preventContinuousClickFlg: false,
      id: null
    }
  },

  beforeDestroy() {
    clearTimeout(this.id)
  },

  methods: {
    handleClick() {
      this.preventContinuousClickFlg = true
      this.$emit('click')
      this.id = setTimeout(() => {
        this.preventContinuousClickFlg = false
      }, 500)
    }
  }
}
</script>

<style lang="scss" scoped>
.base-button-small-orange {
  > button {
    width: 135px;
    height: 33px;
    border-radius: 6px;
    border: solid 2px #{$pumpkin};
    background-color: #{$pumpkin};
    cursor: pointer;
    &:hover {
      opacity: 0.8;
      background-color: #{$pumpkin};
    }
    &:active {
      margin: 2px auto 0 auto;
      height: 31px;
    }
    > span {
      height: 22px;
      font-size: 15px;
      font-weight: bold;
      text-align: left;
      color: #{$white};
      display: inline-block;
      vertical-align: middle;
    }
  }
  > button:disabled {
    background-color: #{$light-grey};
    border: solid 2px #{$light-grey};
    cursor: default;
    > span {
      color: #{$brown-gray};
    }
    &:hover {
      opacity: 1;
    }
    &:active {
      margin: 0 auto 0 auto;
      height: 33px;
    }
  }
}
@media (max-width: $tablet-width) {
  .base-button-small-orange {
    > button {
      > span {
        font-size: 12px;
        line-height: 22px;
      }
    }
  }
}
</style>
