import { render, staticRenderFns } from "./BaseButtonRegister.vue?vue&type=template&id=5cef7760&scoped=true&"
import script from "./BaseButtonRegister.vue?vue&type=script&lang=js&"
export * from "./BaseButtonRegister.vue?vue&type=script&lang=js&"
import style0 from "./BaseButtonRegister.vue?vue&type=style&index=0&id=5cef7760&lang=scss&scoped=true&"


/* normalize component */
import normalizer from "!../../../../node_modules/vue-loader/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  "5cef7760",
  null
  
)

export default component.exports