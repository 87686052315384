const state = {
  backClick: false,
  preventLeaveFlg: false,
  popupPreventLeaveFlg: false,
  leaveDialogShow: false,
  nextFunction: null,
  to: null,
  leftWideFlg: true,
  rightWideFlg: true,
  toggleButtonFlg: true,
  browser: window.navigator.userAgent.toLowerCase(),
  clinicVersionUpFlg: false,
  socket: null,
  showKarteLockPopupFlg: false,
  tryPatientId: 0,
  tryMedicalRecordOriginalId: 0,
  tryHospitalizationId: 0,
  isTryingDelete: false,
  globalPopup: {
    show: false,
    type: '',
    title: '',
    layerNumber: 1,
    buttons: [],
    message: '',
    close: () => {},
    cancel: () => {},
    decision: () => {}
  }
}

const getters = {
  get(state) {
    return state
  }
}

const actions = {
  toggleLeft({ commit }) {
    commit('toggleLeft')
  },
  toggleRight({ commit }) {
    commit('toggleRight')
  },
  goBack({ commit }) {
    commit('goBack')
  },
  cancelBack({ commit }) {
    commit('cancelBack')
  },
  okLeave({ commit }) {
    commit('okLeave')
  },
  ngLeave({ commit }) {
    commit('ngLeave')
  },
  popupOkLeave({ commit }) {
    commit('popupOkLeave')
  },
  popupNgLeave({ commit }) {
    commit('popupNgLeave')
  },
  showLeaveDialog({ commit }) {
    commit('showLeaveDialog')
  },
  hideLeaveDialog({ commit }) {
    commit('hideLeaveDialog')
  },
  saveNextFunction({ commit }, nextFunction) {
    commit('saveNextFunction', nextFunction)
  },
  saveTo({ commit }, to) {
    commit('saveTo', to)
  },
  leaveReset({ commit }) {
    commit('leaveReset')
  },
  narrowLeftMenu({ commit }) {
    commit('narrowLeftMenu')
  },
  hideToggleButton({ commit }) {
    commit('hideToggleButton')
  },
  showToggleButton({ commit }) {
    commit('showToggleButton')
  },
  setClinicVersionUpFlgToTrue({ commit }) {
    commit('setClinicVersionUpFlgToTrue')
  },
  setClinicVersionUpFlgToFalse({ commit }) {
    commit('setClinicVersionUpFlgToFalse')
  },
  setSocket({ commit }, socket) {
    commit('setSocket', socket)
  },
  showKarteLockPopup({ commit }, data) {
    commit('showKarteLockPopup', data)
  },
  hideKarteLockPopup({ commit }) {
    commit('hideKarteLockPopup')
  },
  showGlobalPopup({ commit }, popup) {
    commit('showGlobalPopup', popup)
  },
  hideGlobalPopup({ commit }) {
    commit('hideGlobalPopup')
  }
}

const mutations = {
  toggleLeft(state) {
    state.leftWideFlg = !state.leftWideFlg
  },
  toggleRight(state) {
    state.rightWideFlg = !state.rightWideFlg
  },
  goBack(state) {
    state.backClick = true
  },
  cancelBack(state) {
    state.backClick = false
  },
  ngLeave(state) {
    state.preventLeaveFlg = true
  },
  okLeave(state) {
    state.preventLeaveFlg = false
  },
  popupNgLeave(state) {
    state.popupPreventLeaveFlg = true
  },
  popupOkLeave(state) {
    state.popupPreventLeaveFlg = false
  },
  showLeaveDialog(state) {
    state.leaveDialogShow = true
  },
  hideLeaveDialog(state) {
    state.leaveDialogShow = false
  },
  saveNextFunction(state, nextFunction) {
    state.nextFunction = nextFunction
  },
  saveTo(state, to) {
    state.to = to
  },
  leaveReset(state) {
    state.preventLeaveFlg = false
    state.popupPreventLeaveFlg = false
    state.leaveDialogShow = false
    state.nextFunction = null
    state.to = null
  },
  narrowLeftMenu(state) {
    state.leftWideFlg = false
  },
  hideToggleButton(state) {
    state.toggleButtonFlg = false
  },
  showToggleButton(state) {
    state.toggleButtonFlg = true
  },
  setClinicVersionUpFlgToTrue(state) {
    state.clinicVersionUpFlg = true
  },
  setClinicVersionUpFlgToFalse(state) {
    state.clinicVersionUpFlg = false
  },
  setSocket(state, socket) {
    state.socket = socket
  },
  showKarteLockPopup(
    state,
    {
      patientId,
      medicalRecordOriginalId,
      hospitalizationId,
      isTryingDelete = false
    }
  ) {
    state.tryPatientId = patientId
    state.tryMedicalRecordOriginalId = medicalRecordOriginalId
    state.tryHospitalizationId = hospitalizationId
    state.isTryingDelete = isTryingDelete
    state.showKarteLockPopupFlg = true
  },
  hideKarteLockPopup(state) {
    state.showKarteLockPopupFlg = false
    state.tryPatientId = 0
    state.tryMedicalRecordOriginalId = 0
    state.tryHospitalizationId = 0
    state.isTryingDelete = false
  },
  showGlobalPopup(state, popup) {
    const emptyFunction = () => {}
    state.globalPopup = {
      show: true,
      type: popup.type || '',
      title: popup.title || '',
      buttons: popup.buttons || [],
      layerNumber: popup.layerNumber || 1,
      message: popup.message || '',
      close: popup.close || emptyFunction,
      cancel: popup.cancel || emptyFunction,
      decision: popup.decision || emptyFunction
    }
  },
  hideGlobalPopup(state) {
    state.globalPopup = {
      show: false,
      type: '',
      title: '',
      buttons: [],
      layerNumber: 1,
      message: '',
      close: () => {},
      cancel: () => {},
      decision: () => {}
    }
  }
}

export default {
  namespaced: true,
  state,
  mutations,
  getters,
  actions
}
