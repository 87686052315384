import { getAxiosObject } from '@/utils/library'
import {
  commonGetters,
  commonActions,
  commonMutations
} from '@/store/store_utils'
import { toStateName } from '@/utils/convert_string'

const dataName = 'problems'
const keys = ['patientId']

const state = {
  problems: [],
  dataIndexedById: {},
  updatedAt: '20101010101010'
}
keys.forEach(key => {
  state[toStateName(key)] = null
})

const getters = {
  ...commonGetters(dataName, ['getData', 'getDataById', 'getUpdatedAt'], {
    filterDel: true,
    getDataBy: keys
  })
}

const actions = {
  ...commonActions(['setData', 'setUpdatedAt', 'updateAfter']),
  async create({ commit }, problem) {
    try {
      const axiosObject = getAxiosObject()
      const res = await axiosObject.post('/' + dataName, { problem })
      if (res.status === 200) {
        commit('updateAfter', res.data.problems)
        return true
      }
    } catch (error) {
      return error.response?.data?.message || 'server error'
    }
  },
  async delete({ commit }, id) {
    try {
      const axiosObject = getAxiosObject()
      const res = await axiosObject.delete('/' + dataName, { data: { id } })
      if (res.status === 200) {
        commit('updateAfter', [res.data.problem])
        return true
      }
    } catch (error) {
      if (error.response?.data?.extra?.data) {
        commit('setData', error.response.data.extra.data)
        return error.response.data.message
      } else {
        return 'server error'
      }
    }
  },
  async update({ commit }, problem) {
    try {
      const axiosObject = getAxiosObject()
      const res = await axiosObject.put('/' + dataName, { problem })
      if (res.status === 200) {
        commit('updateAfter', [res.data.problem])
        return true
      }
    } catch (error) {
      if (error.response?.data?.extra?.data) {
        commit('setData', error.response.data.extra.data)
        return error.response.data.message
      } else {
        return 'server error'
      }
    }
  },
  async order({ commit }, { orderIds, patientId }) {
    try {
      const axiosObject = getAxiosObject()
      const res = await axiosObject.put('/' + dataName + '/order', {
        orderIds,
        patientId
      })
      if (res.status === 200) {
        commit('updateAfter', res.data.problems)
        return true
      }
    } catch (error) {
      return false
    }
  }
}

const mutations = {
  ...commonMutations(dataName, ['setUpdatedAt', 'setData', 'updateAfter'], {
    order: true,
    indexedById: true,
    setDataBy: keys
  })
}

export default {
  namespaced: true,
  state,
  mutations,
  getters,
  actions
}
