<template>
  <focus-trap :escapeDeactivates="false" :clickDeactivates="false">
    <div
      class="schema-image-select-popup"
      @keyup.esc.stop="$emit('close')"
      tabindex="-1"
    >
      <popup
        :buttons="['閉じる']"
        :layerNumber="2"
        @close="$emit('close')"
        @click-close-mark="$emit('close')"
      >
        <template v-slot:content>
          <div class="content">
            <div class="title">シェーマ画像選択</div>
            <div class="buttons">
              <base-button-schema-header
                v-for="imageClass in imageClasses"
                :key="imageClass.id"
                :id="imageClass.id"
                :text="imageClass.name"
                :styles="{ 'font-size': '13px' }"
                :active="selectedImageClassId === imageClass.id"
                @click="selectImageClass"
              />
            </div>
            <div class="images">
              <div
                class="image-wrapper"
                data-test="image-wrapper"
                v-for="(displayImage, i) in displayImages"
                :key="displayImage.id"
                @click="$emit('decide', displayImage)"
              >
                <img
                  class="image"
                  :data-test="`image-${i + 1}`"
                  :src="displayImage.thumbnailImage || displayImage.image"
                />
                <span v-if="displayImage.name" class="image-name">{{
                  displayImage.name
                }}</span>
              </div>
            </div>
          </div>
        </template>
      </popup>
    </div>
  </focus-trap>
</template>

<script>
import Popup from '@/components/popups/Popup'
import BaseButtonSchemaHeader from '@/components/parts/atoms/BaseButtonSchemaHeader'
import { FocusTrap } from 'focus-trap-vue'
import { mapGetters } from 'vuex'

export default {
  name: 'SchemaImageSelectPopup',

  components: {
    Popup,
    BaseButtonSchemaHeader,
    FocusTrap
  },

  props: {
    patientId: { type: Number, default: 0 }
  },

  data() {
    return {
      selectedImageClassId: 1,
      imageClasses: [
        { id: 1, name: '犬' },
        { id: 2, name: '猫' },
        { id: 3, name: 'その他' },
        { id: 4, name: '部位' },
        { id: 5, name: 'バイタル' },
        { id: 6, name: '過去画像' }
      ]
    }
  },

  computed: {
    ...mapGetters({
      getMedicalContentsByPatientId: 'medicalContents/getDataByPatientId',
      karteUploadImages: 'uploadImages/getKarteUploadImages'
    }),
    displayImages() {
      return this.imagesPerClassId[this.selectedImageClassId]
    },
    imagesPerClassId() {
      return {
        1: this.dogSchemaImages,
        2: this.catSchemaImages,
        3: this.otherSchemaImages,
        4: this.partSchemaImages,
        5: this.vitalSchemaImages,
        6: this.patientKarteImages
      }
    },
    schemaImages() {
      const schemaImages = this.$store.getters['schemaImages/getData']
      return schemaImages.map(v => {
        return { ...v, image: this.getSchemaImage(v.id) }
      })
    },
    dogSchemaImages() {
      return this.schemaImages.filter(v => v.schemaImageClassId === 1)
    },
    catSchemaImages() {
      return this.schemaImages.filter(v => v.schemaImageClassId === 2)
    },
    otherSchemaImages() {
      return this.schemaImages.filter(v => v.schemaImageClassId === 3)
    },
    partSchemaImages() {
      return this.schemaImages.filter(v => v.schemaImageClassId === 4)
    },
    vitalSchemaImages() {
      return this.schemaImages.filter(v => v.schemaImageClassId === 5)
    },
    patientKarteImages() {
      let patientKarteImages = []
      const medicalContents = this.patientMedicalContents
      if (medicalContents.length) {
        const medicalContentOriginalIds = medicalContents.map(
          mc => mc.originalId
        )
        patientKarteImages = Object.values(this.karteUploadImages).filter(
          kui => {
            const isKarteImage =
              medicalContentOriginalIds.includes(
                kui.medicalContentOriginalId
              ) && kui.delFlg === 0
            return isKarteImage
          }
        )
      }
      return patientKarteImages
    },
    patientMedicalContents() {
      return this.getMedicalContentsByPatientId(this.patientId) || []
    }
  },

  methods: {
    getSchemaImage(id) {
      return require(`@/assets/images/schemas/${id}.jpg`)
    },
    selectImageClass(id) {
      this.selectedImageClassId = id
    }
  }
}
</script>

<style lang="scss" scoped>
.schema-image-select-popup {
  .content {
    height: 550px;
    > .title {
      font-size: 16px;
      font-weight: bold;
      color: #{$greyish-brown};
      text-align: left;
    }
    > .buttons {
      margin-top: 5px;
      display: flex;
      gap: 0 5px;
    }
    > .images {
      margin-top: 10px;
      display: flex;
      flex-wrap: wrap;
      gap: 4px;
      overflow: auto;
      width: 644px;
      max-height: 468px;
      > .image-wrapper {
        @include hover();
        width: 150px;
        height: 150px;
        display: flex;
        justify-content: center;
        align-items: center;
        position: relative;
        border: 1px solid #{$light-grey};
        > .image {
          max-width: 150px;
          max-height: 150px;
        }
        > .image-name {
          cursor: pointer;
          background: #{$white};
          position: absolute;
          top: 115px;
          left: 5px;
          width: 80px;
          height: 26px;
          line-height: 26px;
          font-size: 10px;
          color: #{$pumpkin};
          border: 1px solid #{$pumpkin};
          border-radius: 50px;
          z-index: 10;
          text-align: center; /* 左右中央 */
          vertical-align: middle; /* 上下中央 */
        }
      }
    }
  }
}
</style>
