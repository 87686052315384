import { makeClinicText, makeFooter, makePageSize } from '@/utils/print_utils'
import { createPdf } from 'pdfmake/build/pdfmake.min.js'
import '@/utils/vfs_fonts' // この行がないとテストが通らない

const makePatientText = (patient, speciesName) => {
  return {
    table: {
      widths: ['auto'],
      headerRows: 0,
      body: [
        [
          {
            text: patient.name + '様',
            border: [true, true, true, false],
            margin: [5, 5, 5, 0]
          }
        ],
        [
          {
            text: '（' + speciesName + '）',
            border: [true, false, true, true],
            margin: [5, 0, 5, 5]
          }
        ]
      ]
    }
  }
}

const makeTopicClassRows = topicItems => {
  return topicItems.map((item, i) => {
    const classNameCell =
      i === 0
        ? {
            text: item.topicClassName,
            rowSpan: topicItems.length,
            style: 'cell'
          }
        : ''
    return [
      classNameCell,
      { text: item.topicName, style: 'cell' },
      { text: item.resultUnit, style: 'cell' },
      { text: item.reference, style: 'cell' },
      { text: item.comment, style: 'cell' }
    ]
  })
}

export const makeDocDefinition = ({
  printOptions,
  clinic,
  clinicImage,
  patient,
  examinationItems,
  examinationDate
}) => {
  const paperSize = printOptions.paperSize
  const ratio = paperSize === 'A4' ? 1 : paperSize === 'B5' ? 0.84 : 0.7
  const patientText = makePatientText(
    patient,
    examinationItems[0][0].speciesName
  )
  const clinicText = makeClinicText({
    ratio,
    clinic,
    clinicImage,
    showClinicName: printOptions.showClinicName
  })
  const contentHeader = {
    columns: [
      {
        stack: [
          {
            //右端の医院情報のどれかの文字数が長い時に「検査結果」の文字が改行されないようwidthを設定。
            //widthはcolumnsと組み合わせて使用しないと反映されないようなので下記のような書き方をしている。
            columns: [
              { text: '検査結果', fontSize: 40 * ratio, width: 260 * ratio }
            ]
          },
          patientText
        ],
        width: '*'
      },
      { stack: clinicText, width: 'auto', margin: [10, 0, 0, 0] }
    ]
  }
  const examinationNameAndDateRow = {
    width: 530 * ratio,
    margin: [0, 15, 0, 10],
    columns: [
      { text: examinationItems[0][0].treatmentName },
      { text: '', width: '*' },
      { text: examinationDate, alignment: 'right' }
    ]
  }
  let examinationTableRows = []
  examinationItems.forEach(topicClassItem => {
    examinationTableRows = examinationTableRows.concat(
      makeTopicClassRows(topicClassItem)
    )
  })
  const content = [
    contentHeader,
    examinationNameAndDateRow,
    {
      width: 'auto',
      layout: 'examinationTable',
      table: {
        headerRows: 1,
        widths: ['20%', '20%', '20%', '20%', '20%'],
        body: [
          [
            { text: '項目区分名', style: 'cell' },
            { text: '項目名', style: 'cell' },
            { text: '結果（単位）', style: 'cell' },
            { text: '基準値', style: 'cell' },
            { text: 'コメント', style: 'cell' }
          ],
          ...examinationTableRows
        ]
      }
    }
  ]
  return {
    content,
    footer: (currentPage, pageCount) =>
      makeFooter({
        currentPage,
        pageCount,
        fontSize: 11 * ratio
      }),
    styles: {
      patientStyle: {
        alignment: 'left',
        fontSize: 16 * ratio,
        margin: [5, 5, 5, 5]
      },
      cell: { alignment: 'center', fontSize: 10 * ratio }
    },
    defaultStyle: {
      font: 'NotoSansMono',
      fontSize: 12 * ratio,
      color: '#3e3e3e',
      preserveLeadingSpaces: true
    },
    pageSize: makePageSize(printOptions.paperSize),
    pageMargins: [20, 20, 20, 30 + 11 * ratio],
    pageOrientation: 'portrait'
  }
}

// args = {
//   printOptions,
//   clinic,
//   clinicImage,
//   patient,
//   examinationDate,
//   examinationItems
// }
export const printExamination = args => {
  const docDefinition = makeDocDefinition(args)
  const fonts = {
    NotoSansMono: {
      normal: 'NotoSansMonoCJKJPRegular.otf',
      bold: 'NotoSansMonoCJKJPRegular.otf',
      italics: 'NotoSansMonoCJKJPRegular.otf',
      bolditalics: 'NotoSansMonoCJKJPRegular.otf'
    }
  }
  const tableLayouts = {
    examinationTable: {
      hLineColor: '#3e3e3e',
      vLineColor: '#3e3e3e',
      fillColor: (rowIndex, node, colIndex) =>
        rowIndex === 0 ? '#f0f0f0' : null
    }
  }
  createPdf(docDefinition, tableLayouts, fonts).open()
}
