//ハイライト表示のタグ部分だけアンエスケープする（エスケープ文字を元に戻す）
//v-htmlを使用している箇所で、ハイライト表示だけhtml・cssを適用するために使用
//参考サイト
//https://www.javadrive.jp/javascript/regexp/index14.html
//肯定の後読み
export const unescapeHighLightHtml = string => {
  return string
    .replace(
      /&lt;span style=&quot;background-color:#fce1cc; font-weight:bold&quot;&gt;/gi,
      '<span style="background-color:#fce1cc; font-weight:bold">'
    )
    .replace(/&lt;\/span&gt;/gi, '</span>')
}
