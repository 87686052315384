import { PAPER_SIZES } from '@/utils/define'

export const mmToPt = mm => {
  // In the pdfmake source code, in pt -> A4: [595.28, 841.89]
  // in mm -> A4: [210, 297]
  const pointsInMillimeter = 2.8346
  return mm * pointsInMillimeter
}

export const makePageSize = paperSize => {
  // input: paperSize < 'A4' | 'B5' | 'A5' >
  // pdfmakeで'B5'の用紙サイズと指定するとISO B5になります。
  // JIS B5を指定するために直接 width と height の値を渡します。
  return paperSize === 'B5'
    ? {
        width: mmToPt(PAPER_SIZES[paperSize].x),
        height: mmToPt(PAPER_SIZES[paperSize].y)
      }
    : paperSize
}

export const makeClinicText = ({
  ratio,
  clinic,
  clinicImage,
  showClinicName,
  showClinicTel = true,
  showClinicEmail = true,
  showClinicAddress = true,
  showInvoiceNumber = false
}) => {
  const rowStyle = { fontSize: 11 * ratio }
  const marginSize =
    clinic.name.length < 12 ? 25 : clinic.name.length < 24 ? 12 : 0
  const clinicName = showClinicName ? clinic.name : ''
  //医院名・メールアドレス・住所が半角英数字で文字数が長い場合、紙の枠からはみ出てしまうことがあるためwidthを設定。
  const rows = clinicImage
    ? [
        {
          columns: [
            {
              image: clinicImage,
              fit: [showClinicName ? 100 * ratio : 250 * ratio, 75 * ratio],
              height: 'auto',
              width: 'auto'
            },
            {
              text: clinicName,
              fontSize: 16 * ratio,
              width: clinic.name.length > 10 ? 150 * ratio : '*',
              margin: [5 * ratio, marginSize, 0, 0]
            }
          ]
        }
      ]
    : [
        {
          //widthはcolumnsと組み合わせて使用しないと反映されないようなので下記のような書き方をしている。
          columns: [
            {
              text: clinicName,
              fontSize: 16 * ratio,
              width: clinic.name.length > 25 ? 270 * ratio : '*'
            }
          ]
        }
      ]
  if (showClinicTel && clinic.tel) {
    rows.push({ text: 'TEL ' + clinic.tel, ...rowStyle })
  }
  if (showClinicEmail && clinic.email) {
    if (clinic.email.length > 40) {
      rows.push({
        columns: [{ text: clinic.email, ...rowStyle, width: 270 * ratio }]
      })
    } else {
      rows.push({ text: clinic.email, ...rowStyle })
    }
  }
  if (showClinicAddress) {
    if (clinic.postalCode)
      rows.push({ text: `〒${clinic.postalCode}`, ...rowStyle })
    if (clinic.prefectureId || clinic.address) {
      const address = clinic.prefectureName + clinic.address
      if (address.length > 40) {
        rows.push({
          columns: [{ text: address, ...rowStyle, width: 260 * ratio }]
        })
      } else {
        rows.push({ text: address, ...rowStyle })
      }
    }
    if (clinic.building) {
      if (clinic.building.length > 40) {
        rows.push({
          columns: [{ text: clinic.building, ...rowStyle, width: 260 * ratio }]
        })
      } else {
        rows.push({ text: clinic.building, ...rowStyle })
      }
    }
  }
  if (showInvoiceNumber && clinic.invoiceNumber) {
    rows.push({ text: `登録番号 ${clinic.invoiceNumber}`, ...rowStyle })
  }
  return rows
}

export const makeFooter = ({
  currentPage,
  pageCount,
  printCopy = false,
  fontSize
}) => {
  if (pageCount === 1 || (printCopy && pageCount === 2)) {
    return { text: '' }
  } else if (printCopy) {
    const halfPageCount = pageCount / 2
    return {
      text:
        currentPage <= halfPageCount
          ? currentPage.toString() + '/' + halfPageCount
          : (currentPage - halfPageCount).toString() + '/' + halfPageCount,
      alignment: 'right',
      margin: [0, 10, 20, 20],
      fontSize
    }
  } else {
    return {
      text: currentPage.toString() + '/' + pageCount,
      alignment: 'right',
      margin: [0, 10, 20, 20],
      fontSize
    }
  }
}
