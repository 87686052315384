import { mutationUpdateAfter } from '@/utils/update_after'
import { dataCsvToArray } from '@/utils/comma_string'
import { decodeBase64fromBuffer } from '@/utils/base64'
import { toStateName } from '@/utils/convert_string'
import moment from 'moment'
import _ from 'lodash'

export const setIndexedData = data => {
  return Object.assign({}, ...data.map(datum => ({ [datum.id]: datum })))
}

export const setIndexedByOriginalId = data => {
  return Object.assign(
    {},
    ...data.map(datum => {
      if (datum.delFlg === 0) {
        return { [datum.originalId]: datum }
      }
    })
  )
}

export const setIndexedData2 = (state, dataName) => {
  state[dataName].forEach(datum => {
    state.dataIndexedById[datum.id] = datum
    const datumUpdatedAt = moment(datum.updatedAt).format('YYYYMMDDHHmmss')
    state.updatedAt =
      datumUpdatedAt > state.updatedAt ? datumUpdatedAt : state.updatedAt
  })
}

export const setIndexedByOriginalId2 = (state, dataName) => {
  state[dataName].forEach(datum => {
    if (datum.delFlg === 0) {
      state.dataIndexedByOriginalId[datum.originalId] = datum
    }
    const datumUpdatedAt = moment(datum.updatedAt).format('YYYYMMDDHHmmss')
    state.updatedAt =
      datumUpdatedAt > state.updatedAt ? datumUpdatedAt : state.updatedAt
  })
}

export const updateIndexedData = (state, data, dataName) => {
  if (Array.isArray(data) && data.length !== 0) {
    if (Object.keys(state.dataIndexedById).length === 0) {
      setIndexedData2(state, dataName)
    } else {
      data.forEach(datum => {
        const id = datum.id
        const updatedAt = moment(datum.updatedAt).toISOString()
        if (
          state.dataIndexedById[id] === undefined ||
          state.dataIndexedById[id].updatedAt <= updatedAt
        ) {
          state.dataIndexedById[id] = datum
        }
      })
    }
  }
}

export const updateIndexedByOriginalId = (state, data, dataName) => {
  if (Array.isArray(data) && data.length !== 0) {
    if (Object.keys(state.dataIndexedByOriginalId).length === 0) {
      setIndexedByOriginalId2(state, dataName)
    } else {
      data.forEach(datum => {
        const id = datum.originalId
        const updatedAt = moment(datum.updatedAt).toISOString()
        if (
          state.dataIndexedByOriginalId[id] === undefined ||
          state.dataIndexedByOriginalId[id].updatedAt <= updatedAt
        ) {
          state.dataIndexedByOriginalId[id] = datum
        }
      })
    }
  }
}

export const setDataByPatientId = (state, dataName) => {
  state.dataByPatientId = _.groupBy(state[dataName], 'patientId')
}

export const datumParseJson = (datum, columns) => {
  columns.forEach(column => {
    datum[column] = JSON.parse(datum[column])
  })
  return datum
}

export const dataParseJson = (data, columns) => {
  return Array.isArray(data)
    ? data.map(datum => datumParseJson(datum, columns))
    : datumParseJson(data, columns)
}

export const commonGetters = (
  dataName,
  gettersList,
  {
    filterDel = false,
    selectZeroText = '',
    getDataBy = [],
    getDataByIncludeDel = []
  }
) => {
  const commonGetters = {
    getData(state) {
      let data = state[dataName]
      if (filterDel) data = data.filter(v => v.delFlg === 0)
      return data
    },
    getDataIncludeDel(state) {
      return state[dataName]
    },
    getDataById(state) {
      if (Object.keys(state.dataIndexedById).length === 0) {
        state.dataIndexedById = setIndexedData(state[dataName])
      }
      return id => state.dataIndexedById[id]
    },
    getDataByOriginalId(state) {
      if (Object.keys(state.dataIndexedByOriginalId).length === 0) {
        state.dataIndexedByOriginalId = setIndexedByOriginalId(state[dataName])
      }
      return id => state.dataIndexedByOriginalId[id]
    },
    selectData(state) {
      let data = state[dataName]
      if (filterDel) data = data.filter(v => v.delFlg === 0)
      return data.map(v => {
        return { id: v.id, name: v.name }
      })
    },
    selectDataZero(state) {
      let data = state[dataName]
      if (filterDel) data = data.filter(v => v.delFlg === 0)
      const selectData = data.map(v => {
        return { id: v.id, name: v.name }
      })
      return [{ id: 0, name: selectZeroText }].concat(selectData)
    },
    getUpdatedAt(state) {
      return state.updatedAt
    },
    getDataByPatientIdIncludeDel(state) {
      if (state.dataByPatientId === null) {
        setDataByPatientId(state, dataName)
      }
      return patientId => state.dataByPatientId[patientId]
    },
    getCount(state) {
      return state.count
    }
  }

  const oneToManyGetters = {}
  if (getDataBy.length !== 0) {
    getDataBy.forEach(key => {
      const stateName = toStateName(key)
      const functionName = 'getDataBy' + key[0].toUpperCase() + key.substring(1)
      const tempFunction = state => {
        if (state[stateName] === null) {
          state[stateName] = _.groupBy(state[dataName], key)
        }
        return val => state[stateName][val]?.filter(v => v.delFlg === 0)
      }
      oneToManyGetters[functionName] = tempFunction
    })
  }
  if (getDataByIncludeDel.length !== 0) {
    getDataByIncludeDel.forEach(key => {
      const stateName = toStateName(key)
      const functionName =
        'getDataBy' + key[0].toUpperCase() + key.substring(1) + 'IncludeDel'
      const tempFunction = state => {
        if (state[stateName] === null) {
          state[stateName] = _.groupBy(state[dataName], key)
        }
        return val => state[stateName][val]
      }
      oneToManyGetters[functionName] = tempFunction
    })
  }

  return gettersList.reduce(
    (map, getter) => ({ ...map, [getter]: commonGetters[getter] }),
    { ...oneToManyGetters }
  )
}

export const commonActions = actionsList => {
  const commonActions = {
    setData({ commit }, data) {
      commit('setData', data)
    },
    setUpdatedAt({ commit }, updatedAt) {
      commit('setUpdatedAt', updatedAt)
    },
    updateAfter({ commit }, data) {
      commit('updateAfter', data)
    },
    setCount({ commit }, count) {
      commit('setCount', count)
    }
  }
  return actionsList.reduce(
    (map, action) => ({ ...map, [action]: commonActions[action] }),
    {}
  )
}

export const commonMutations = (
  dataName,
  mutationsList,
  {
    order = false,
    indexedById = false,
    indexedByOriginalId = false,
    indexedByPatientId = false,
    csvColumns = [],
    csvTypes = [],
    base64 = false,
    setDataBy = [],
    lastUsed = false,
    jsonColumns = []
  }
) => {
  const updateAfter = (state, data) => {
    if (csvColumns.length !== 0) {
      data = dataCsvToArray(data, csvColumns, csvTypes)
    }
    if (base64) data = decodeBase64fromBuffer(data)
    if (jsonColumns.length !== 0) data = dataParseJson(data, jsonColumns)
    mutationUpdateAfter(state, data, dataName)
    if (order) state[dataName].sort((a, b) => a.order - b.order)
    if (indexedById) updateIndexedData(state, data, dataName)
    if (indexedByOriginalId) updateIndexedByOriginalId(state, data, dataName)
    if (indexedByPatientId) setDataByPatientId(state, dataName)
    if (setDataBy.length !== 0) {
      setDataBy.forEach(key => {
        state[toStateName(key)] = _.groupBy(state[dataName], key)
      })
    }
    if (lastUsed)
      state[dataName].sort((a, b) => (a.lastUsed > b.lastUsed ? -1 : 1))
  }

  const commonMutations = {
    setData(state, data) {
      if (csvColumns.length !== 0) {
        data = dataCsvToArray(data, csvColumns, csvTypes)
      }
      if (base64) data = decodeBase64fromBuffer(data)
      if (order) data.sort((a, b) => a.order - b.order)
      state[dataName] = data
      if (indexedById) state.dataIndexedById = {}
      if (indexedByOriginalId) state.dataIndexedByOriginalId = {}
      if (indexedByPatientId) state.dataByPatientId = null
      if (setDataBy.length !== 0) {
        setDataBy.forEach(key => {
          state[toStateName(key)] = null
        })
      }
      if (lastUsed) data.sort((a, b) => (a.lastUsed > b.lastUsed ? -1 : 1))
      if (jsonColumns.length !== 0) data = dataParseJson(data, jsonColumns)
    },
    setUpdatedAt(state, updatedAt) {
      state.updatedAt = updatedAt
    },
    updateAfter,
    //↓お知らせの各一覧画面はリアクティブにするため、mutationのupdateAfterを監視しています。
    //検索apiでもupdateAfterを使用していて、上記のリアクティブにする処理が動きバグになるため、
    //下記のupdateAfterSearchを使用しています
    updateAfterSearch: updateAfter,
    read(state, readIds) {
      if (Object.keys(state.dataIndexedById).length === 0) {
        setIndexedData2(state, dataName)
      }
      readIds.forEach(readId => {
        state.dataIndexedById[readId].readFlg = 1
      })
    },
    delete(state, id) {
      if (Object.keys(state.dataIndexedById).length === 0) {
        setIndexedData2(state, dataName)
      }
      state.dataIndexedById[id].delFlg = 1
    },
    setCount(state, count) {
      state.count = count
    }
  }
  return mutationsList.reduce(
    (map, mutation) => ({ ...map, [mutation]: commonMutations[mutation] }),
    {}
  )
}

export const updateUploadImages = (commit, rootGetters, resUploadImages) => {
  const karteUploadImages = rootGetters['uploadImages/getKarteUploadImages']
  const uploadImages = resUploadImages.map(v => {
    const karteUploadImage = karteUploadImages[v.id]
    if (karteUploadImage) {
      v.image = karteUploadImage.image
      v.thumbnailImage = karteUploadImage.thumbnailImage
    }
    return v
  })
  const mutations = [
    'setKarteUploadImages',
    'updateAfter',
    'resetResizedUploadImages'
  ]
  mutations.forEach(mutation => {
    commit(`uploadImages/${mutation}`, uploadImages, { root: true })
  })
}
