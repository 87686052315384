<template>
  <div class="notice-new">
    <template v-if="token">
      <div class="notice-title">お知らせ登録</div>
      <validation-observer
        class="validation-observer"
        ref="observer"
        v-slot="{ invalid }"
      >
        <notice-input-form
          :notice="notice"
          :selectedSendType="selectedSendType"
          :clinicData="clinicData"
          :bulkCheckFlg="bulkCheckFlg"
          @input-selected-send-type="inputSelectedSendType"
          @bulk-check="bulkCheck"
        />
        <div class="button-wrapper">
          <base-button-medium-white
            class="button"
            @click="$router.push({ path: '/customer-login-management' })"
            >戻る</base-button-medium-white
          >
          <base-button-medium-orange
            class="button"
            :disabled="
              invalid ||
                waitFlg ||
                (selectedSendType === 2 && sendClinicIds.length === 0)
            "
            @click="post"
            >送信</base-button-medium-orange
          >
        </div>
        <announce-popup
          v-if="alertFlg"
          @close="closeAlert"
          :title="title"
          :buttons="buttons"
          :type="type"
          >{{ popupMessage }}</announce-popup
        >
        <unsaved-leave-popup />
      </validation-observer>
    </template>
  </div>
</template>

<script>
import NoticeInputForm from '@/components/parts/organisms/NoticeInputForm'
import BaseButtonMediumWhite from '@/components/parts/atoms/BaseButtonMediumWhite'
import BaseButtonMediumOrange from '@/components/parts/atoms/BaseButtonMediumOrange'
import AnnouncePopup from '@/components/popups/AnnouncePopup'
import UnsavedLeavePopup from '@/components/popups/UnsavedLeavePopup'
import { mapGetters } from 'vuex'
import { ValidationObserver } from 'vee-validate'

export default {
  name: 'NoticeNew',
  components: {
    NoticeInputForm,
    BaseButtonMediumWhite,
    BaseButtonMediumOrange,
    AnnouncePopup,
    UnsavedLeavePopup,
    ValidationObserver
  },
  data() {
    return {
      waitFlg: false,
      notice: { noticeCategoryId: 1, title: '', message: '' },
      selectedSendType: 1,
      clinicData: [],
      bulkCheckFlg: false,
      alertFlg: false,
      title: '',
      buttons: ['閉じる'],
      type: '',
      popupMessage: ''
    }
  },
  computed: {
    ...mapGetters({
      token: 'pyxosManage/token',
      clinics: 'pyxosManage/clinics'
    }),
    sendClinicIds() {
      return this.clinicData.filter(v => v.checkedFlg).map(v => v.id)
    }
  },
  created() {
    this.setData()
  },
  methods: {
    setData() {
      this.clinicData = this.clinics
        .filter(v => v.delFlg === 0)
        .map(v => {
          return { ...v, checkedFlg: false }
        })
    },
    inputSelectedSendType(selectedSendType) {
      this.selectedSendType = selectedSendType
    },
    bulkCheck(bulkCheckFlg) {
      this.bulkCheckFlg = bulkCheckFlg
      this.clinicData = this.clinicData.map(v => {
        v.checkedFlg = this.bulkCheckFlg ? true : false
        return v
      })
    },
    async post() {
      this.waitFlg = true
      const allSendFlg = this.selectedSendType === 1 ? true : false
      const res = await this.$store.dispatch('notices/create', {
        notice: this.notice,
        allSendFlg,
        sendClinicIds: this.sendClinicIds
      })
      if (res === true) {
        this.type = 'success'
        this.title = '完了'
        this.popupMessage = 'お知らせを登録（送信）しました'
      } else {
        this.type = 'failure'
        this.title = '失敗'
        this.popupMessage = 'お知らせの登録（送信）に失敗しました'
      }
      this.alertFlg = true
      this.waitFlg = false
    },
    closeAlert() {
      this.alertFlg = false
      if (this.type === 'success') {
        this.$router.push({ path: '/customer-login-management' })
      }
    }
  }
}
</script>

<style lang="scss" scoped>
.notice-new {
  margin: 0 auto;
  width: 1000px;
  > .notice-title {
    margin: 20px 0;
    text-align: left;
    font-size: 21px;
    font-weight: bold;
  }
  > .validation-observer {
    > .button-wrapper {
      display: flex;
      justify-content: center;
      margin: 30px 0;
      > .button {
        margin: 0 16px;
      }
    }
  }
}
</style>
