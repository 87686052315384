import { getAxiosObject } from '@/utils/library'
import {
  commonGetters,
  commonActions,
  commonMutations
} from '@/store/store_utils'

const dataName = 'reservationColumns'

const state = {
  reservationColumns: [],
  dataIndexedById: {},
  updatedAt: '20101010101010'
}

const getters = {
  ...commonGetters(
    dataName,
    ['getData', 'getDataById', 'getUpdatedAt', 'selectData'],
    { filterDel: true }
  )
}

const actions = {
  ...commonActions(['setData', 'setUpdatedAt', 'updateAfter']),
  async create({ commit }, reservationColumn) {
    try {
      const axiosObject = getAxiosObject()
      const res = await axiosObject.post('/reservation-columns', {
        reservationColumn: reservationColumn
      })
      if (res.status === 200) {
        commit('updateAfter', [res.data.reservationColumn])
        commit(
          'staffReservationColumns/updateAfter',
          res.data.staffReservationColumns,
          { root: true }
        )
        return true
      }
    } catch (error) {
      if (error.response?.data?.message === 'no data') {
        return `no ${error.response.data.extra}`
      } else if (error.response?.data?.message) {
        return error.response.data.message
      } else {
        return 'server error'
      }
    }
  },
  async delete({ commit }, id) {
    try {
      const axiosObject = getAxiosObject()
      const res = await axiosObject.delete('/reservation-columns', {
        data: { id }
      })
      if (res.status === 200) {
        commit('updateAfter', [res.data.reservationColumn])
        commit(
          'staffReservationColumns/updateAfter',
          [res.data.staffReservationColumn],
          { root: true }
        )
        return true
      }
    } catch (error) {
      if (error.response?.data?.message) {
        if (error.response.data.message === 'currently used') {
          return `used in ${error.response.data.extra}`
        } else {
          if (error.response.data.extra?.data) {
            commit('setData', error.response.data.extra.data)
          }
          return error.response.data.message
        }
      } else {
        return 'server error'
      }
    }
  },
  async update({ commit }, reservationColumn) {
    try {
      const axiosObject = getAxiosObject()
      const res = await axiosObject.put('/reservation-columns', {
        reservationColumn
      })
      if (res.status === 200) {
        commit('updateAfter', [res.data.reservationColumn])
        commit(
          'staffReservationColumns/updateAfter',
          res.data.staffReservationColumns,
          { root: true }
        )
        return true
      }
    } catch (error) {
      if (error.response?.data?.message) {
        if (error.response.data.message === 'no data') {
          return `no ${error.response.data.extra}`
        } else {
          if (error.response.data.extra?.data) {
            commit('setData', error.response.data.extra.data)
          }
          return error.response.data.message
        }
      } else {
        return 'server error'
      }
    }
  }
}

const mutations = {
  ...commonMutations(dataName, ['setData', 'setUpdatedAt', 'updateAfter'], {
    indexedById: true
  })
}

export default {
  namespaced: true,
  state,
  getters,
  actions,
  mutations
}
