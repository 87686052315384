import { getAxiosObject } from '@/utils/library'
import {
  commonGetters,
  commonActions,
  commonMutations
} from '@/store/store_utils'

const dataName = 'imageTags'

const state = {
  imageTags: [],
  dataIndexedById: {},
  updatedAt: '20101010101010'
}

const getters = {
  ...commonGetters(
    dataName,
    ['getData', 'getDataIncludeDel', 'getDataById', 'getUpdatedAt'],
    { filterDel: true }
  ),
  getDataIndexedById(state) {
    return state.dataIndexedById
  }
}

const actions = {
  ...commonActions(['setData', 'setUpdatedAt', 'updateAfter']),
  async create({ commit }, imageTag) {
    try {
      const axiosObject = getAxiosObject()
      const res = await axiosObject.post('/image-tags', { imageTag })
      if (res.status === 200) {
        commit('updateAfter', [res.data.imageTag])
        return { result: true, tag: res.data.imageTag }
      }
    } catch (error) {
      return error.response?.data?.message || 'server error'
    }
  },
  async update({ commit }, imageTag) {
    try {
      const axiosObject = getAxiosObject()
      const res = await axiosObject.put('/image-tags', { imageTag })
      if (res.status === 200) {
        commit('updateAfter', [res.data.imageTag])
        return { result: true, tag: res.data.imageTag }
      }
    } catch (error) {
      return error.response?.data?.message || 'server error'
    }
  },
  async delete({ commit }, imageTag) {
    try {
      const axiosObject = getAxiosObject()
      const res = await axiosObject.delete('/image-tags', {
        data: { imageTag }
      })
      if (res.status === 200) {
        commit('updateAfter', [res.data.imageTag])
        return { result: true, tag: res.data.imageTag }
      }
    } catch (error) {
      return error.response?.data?.message || 'server error'
    }
  }
}

const mutations = {
  ...commonMutations(dataName, ['setData', 'setUpdatedAt', 'updateAfter'], {
    indexedById: true,
    lastUsed: true
  })
}

export default {
  namespaced: true,
  state,
  mutations,
  getters,
  actions
}
