import { getAxiosObject } from '@/utils/library'
import { returnLeftAlignMessageReservationSetting } from '@/utils/check_reservable_days'
import {
  commonGetters,
  commonActions,
  commonMutations
} from '@/store/store_utils'

const dataName = 'reservationSetting'

const state = {
  reservationSetting: {},
  updatedAt: '20101010101010'
}

const getters = {
  ...commonGetters(dataName, ['getData', 'getUpdatedAt'], {})
}

const actions = {
  ...commonActions(['setData', 'setUpdatedAt', 'updateAfter']),
  async update({ commit }, reservationSetting) {
    try {
      const axiosObject = getAxiosObject()
      const res = await axiosObject.put('/reservation-setting', {
        reservationSetting
      })
      if (res.status === 200) {
        commit('updateAfter', res.data.reservationSetting)
        commit(`staffWorkingDates/updateAfter`, res.data.staffWorkingDates, {
          root: true
        })
        const leftAlignMessage = returnLeftAlignMessageReservationSetting()
        return { result: true, leftAlignMessage }
      }
    } catch (error) {
      return { result: false }
    }
  },
  async updateMailText({ commit }, reservationSetting) {
    try {
      const axiosObject = getAxiosObject()
      const res = await axiosObject.put('/reservation-setting/mail', {
        reservationSetting
      })
      if (res.status === 200) {
        commit('updateAfter', res.data.reservationSetting)
        return true
      }
    } catch (error) {
      return error.response?.data?.message
        ? error.response.data.message === 'no data'
          ? `no ${error.response.data.extra}`
          : error.response.data.message
        : 'server error'
    }
  }
}

const mutations = {
  ...commonMutations(dataName, ['setData', 'updateAfter', 'setUpdatedAt'], {})
}

export default {
  namespaced: true,
  state,
  mutations,
  getters,
  actions
}
