import { getAxiosObject } from '@/utils/library'
import {
  commonGetters,
  commonActions,
  commonMutations
} from '@/store/store_utils'

const dataName = 'sendMailTemplates'

const state = {
  sendMailTemplates: [],
  dataIndexedById: {},
  updatedAt: '20101010101010'
}

const getters = {
  ...commonGetters(dataName, ['getData', 'getDataById', 'getUpdatedAt'], {
    filterDel: true
  })
}

const actions = {
  ...commonActions(['setData', 'setUpdatedAt', 'updateAfter']),
  async post({ commit }, sendMailTemplate) {
    try {
      const axiosObject = getAxiosObject()
      const res = await axiosObject.post('/send-mail-templates', {
        sendMailTemplate
      })
      commit('updateAfter', [res.data.sendMailTemplate])
      return true
    } catch (error) {
      return error.response?.data?.message || 'server error'
    }
  },
  async put({ commit }, sendMailTemplate) {
    try {
      const axiosObject = getAxiosObject()
      const res = await axiosObject.put('/send-mail-templates', {
        sendMailTemplate
      })
      commit('updateAfter', [res.data.sendMailTemplate])
      return true
    } catch (error) {
      if (error.response?.data?.message) {
        if (error.response?.data?.extra?.data) {
          commit('setData', error.response.data.extra.data)
        }
        return error.response.data.message
      } else {
        return 'server error'
      }
    }
  },
  async delete({ commit }, id) {
    try {
      const axiosObject = getAxiosObject()
      const res = await axiosObject.delete('/send-mail-templates', {
        data: { id }
      })
      commit('updateAfter', [res.data.sendMailTemplate])
      return true
    } catch (error) {
      if (error.response?.data?.extra?.data) {
        commit('setData', error.response.data.extra.data)
        return error.response.data.message
      } else {
        return 'server error'
      }
    }
  }
}

const mutations = {
  ...commonMutations(dataName, ['setData', 'updateAfter', 'setUpdatedAt'], {
    order: true,
    indexedById: true
  })
}

export default {
  namespaced: true,
  state,
  mutations,
  getters,
  actions
}
