<template>
  <focus-trap :escapeDeactivates="false" :clickDeactivates="false">
    <div
      class="upload-image-popup"
      @keyup.esc.self.stop="checkDiff"
      tabindex="-1"
    >
      <validation-observer class="validation-observer" v-slot="{ invalid }">
        <popup
          :invalid="invalid || uploadImage.image === null"
          :disabledFlg="waitFlg"
          @cancel="checkDiff"
          @decision="decideRegister"
          @click-close-mark="checkDiff"
        >
          <template v-slot:content>
            <div class="content">
              <div class="title">画像登録</div>
              <div class="input-form">
                <div class="left">
                  <medical-record-image-uploader
                    v-if="uploadImage.image === null"
                    :image="uploadImage.image"
                    @input="inputImage"
                  ></medical-record-image-uploader>
                  <div v-else class="image-wrap">
                    <img :src="uploadImage.image" class="image" />
                    <div class="cancel-mark" @click="cancelImage">
                      <div class="cross"></div>
                    </div>
                  </div>
                </div>
                <div class="right">
                  <div class="input title">
                    <validation-provider
                      :rules="{ notSurrogatePair: true }"
                      v-slot="{ errors }"
                    >
                      <base-text-box
                        :placeholder="'タイトル'"
                        :maxlength="30"
                        :styles="textBoxStyle"
                        v-model="uploadImage.title"
                      />
                      <div class="error">{{ errors[0] }}</div>
                    </validation-provider>
                  </div>
                  <image-tag-area
                    :newTags="newTags"
                    :tagIds="uploadImage.imageTagsIds"
                    :tagNames="uploadImage.tagNames"
                    :styles="textBoxStyle"
                    :height="{ 'max-height': '294px' }"
                    @set="setNewTag"
                    @delete="deleteTag"
                  />
                </div>
              </div>
            </div>
          </template>
        </popup>
        <announce-popup
          v-if="announcePopup.popupFlg"
          v-bind="announcePopup"
          @close="closeAnnouncePopup"
          @cancel="closeAnnouncePopup"
          @decision="closePopup"
          >{{ announcePopup.content }}</announce-popup
        >
      </validation-observer>
    </div>
  </focus-trap>
</template>

<script>
import Popup from '@/components/popups/Popup'
import MedicalRecordImageUploader from '@/components/parts/atoms/MedicalRecordImageUploader'
import BaseTextBox from '@/components/parts/atoms/BaseTextBox'
import ImageTagArea from '@/components/parts/molecules/ImageTagArea'
import AnnouncePopup from '@/components/popups/AnnouncePopup'
import CheckPopupInputDifference from '@/components/mixins/CheckPopupInputDifference'
import { FocusTrap } from 'focus-trap-vue'
import { ValidationProvider, ValidationObserver } from 'vee-validate'
import _ from 'lodash'
import { getAxiosObject } from '@/utils/library'

export default {
  name: 'UploadImagePopup',

  components: {
    Popup,
    MedicalRecordImageUploader,
    BaseTextBox,
    ImageTagArea,
    AnnouncePopup,
    FocusTrap,
    ValidationProvider,
    ValidationObserver
  },

  mixins: [CheckPopupInputDifference],

  props: {
    patientId: { type: Number, default: 0 }
  },

  data() {
    return {
      uploadImage: {
        title: '',
        image: null,
        thumbnailImage: null,
        imageTagsIds: [],
        tagNames: [],
        width: 0,
        height: 0,
        widthInKarte: 0,
        heightInKarte: 0,
        patientId: this.patientId
      },
      initialData: {},
      textBoxStyle: { width: '225px', height: '33px' },
      announcePopup: {
        popupFlg: false,
        type: 'alert',
        title: '確認',
        buttons: ['キャンセル', '閉じる'],
        layerNumber: 2,
        content: '入力内容を保存せずに閉じようとしています。\nよろしいですか？'
      },
      newTags: [],
      waitFlg: false
    }
  },

  computed: {
    diffFlg() {
      return !_.isEqual(this.initialData, this.uploadImage)
    }
  },

  created() {
    this.initialData = _.cloneDeep(this.uploadImage)
  },

  methods: {
    inputImage(obj) {
      this.uploadImage.image = obj.image
      this.uploadImage.thumbnailImage = obj.thumbnailImage
      this.uploadImage.width = obj.width
      this.uploadImage.height = obj.height
      this.uploadImage.widthInKarte = obj.width
      this.uploadImage.heightInKarte = obj.height
      this.uploadImage.title = obj.name
    },
    setNewTag(obj) {
      this.uploadImage.tagNames.push(obj.tagName)
      if (obj.newTag) this.newTags.push(obj.newTag)
      if (obj.imageTagId) this.uploadImage.imageTagsIds.push(obj.imageTagId)
    },
    deleteTag(obj) {
      this.uploadImage.tagNames = this.uploadImage.tagNames.filter(
        v => v !== obj.tagName
      )
      this.uploadImage.imageTagsIds = this.uploadImage.imageTagsIds.filter(
        v => v !== obj.imageTagId
      )
      this.newTags = this.newTags.filter(v => v !== obj.tagName.substring(2))
    },
    cancelImage() {
      this.uploadImage = {
        ...this.uploadImage,
        image: null,
        thumbnailImage: null,
        title: '',
        height: 0,
        width: 0,
        heightInKarte: 0,
        widthInKarte: 0,
        imageTagsIds: [],
        tagNames: []
      }
    },
    checkDiff() {
      this.diffFlg ? this.openUnsavedPopup() : this.closePopup()
    },
    openUnsavedPopup() {
      this.announcePopup = {
        popupFlg: true,
        type: 'alert',
        title: '確認',
        buttons: ['キャンセル', '閉じる'],
        layerNumber: 2,
        content: '入力内容を保存せずに閉じようとしています。\nよろしいですか？'
      }
    },
    closeAnnouncePopup() {
      this.announcePopup = {
        popupFlg: false,
        type: '',
        title: '',
        buttons: [],
        layerNumber: 2,
        content: ''
      }
    },
    closePopup() {
      this.$emit('close')
    },
    async decideRegister() {
      try {
        this.waitFlg = true
        const axiosObject = getAxiosObject()
        const res = await axiosObject.post('/upload-images', {
          image: this.uploadImage,
          newTags: this.newTags
        })
        if (res.status === 200) {
          this.$store.dispatch('uploadImages/setKarteUploadImages', [
            res.data.uploadImage
          ])
          this.$store.dispatch('imageTags/updateAfter', res.data.imageTags)
          this.$emit('decide', res.data.uploadImage.id)
        }
      } catch (error) {
        this.announcePopup = {
          popupFlg: true,
          type: 'alert',
          title: '失敗',
          buttons: ['閉じる'],
          layerNumber: 2,
          content: '新規作成に失敗しました'
        }
      } finally {
        this.waitFlg = false
      }
    }
  }
}
</script>

<style lang="scss" scoped>
.upload-image-popup {
  position: absolute;
  top: 0;
  left: 0;
  .content {
    > .title {
      font-size: 16px;
      font-weight: bold;
      color: #{$greyish-brown};
      text-align: left;
    }
    > .input-form {
      margin-top: 15px;
      display: flex;
      > .left {
        > .image-wrap {
          position: relative;
          display: table-cell;
          text-align: center;
          vertical-align: middle;
          width: 400px;
          height: 400px;
          max-width: 400px;
          max-height: 400px;
          border: solid 1px #{$light-grey};
          box-sizing: border-box;
          > .image {
            max-width: 398px;
            max-height: 398px;
            vertical-align: top;
          }
          > .cancel-mark {
            position: absolute;
            top: 10px;
            right: 10px;
            width: 30px;
            height: 30px;
            border-radius: 50%;
            box-shadow: 0 0 6px;
            background-color: #{$white};
            opacity: 0.8;
            cursor: pointer;
            transition: 0.2s;
            &:hover {
              opacity: 1;
            }
            > .cross {
              position: relative;
              top: 14px;
              right: -5px;
              width: 20px;
              &::before,
              &::after {
                position: absolute;
                top: 0;
                left: 0;
                content: '';
                width: 100%;
                height: 2px;
                background-color: #{$dark-grey};
                border-radius: 2px;
              }
              &::before {
                transform: rotate(45deg);
              }
              &::after {
                transform: rotate(-45deg);
              }
            }
          }
        }
      }
      > .left + .right {
        margin-left: 15px;
      }
      > .right {
        > .title {
          margin-bottom: 20px;
        }
      }
    }
  }
}
.input {
  @include validate-message();
}
</style>
