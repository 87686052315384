import {
  commonGetters,
  commonActions,
  commonMutations
} from '@/store/store_utils'
import { getAxiosObject } from '@/utils/library'

const dataName = 'display'

const state = { display: {}, updatedAt: '20101010101010' }

const getters = {
  ...commonGetters(dataName, ['getData', 'getUpdatedAt'], {}),
  getColumnSquareHeaderHeight: state =>
    45 + (state.display.timeTableUnitHeight - 140) / 2,
  // ReservationColumn.vue class="column-name"のheight 26px + class="column-square-header"のheight 46px
  getTimetableBodyHeaderHeight: state =>
    72 + (state.display.timeTableUnitHeight - 140) / 2
}

const actions = {
  ...commonActions(['setData', 'setUpdatedAt', 'updateAfter']),
  async update({ commit }, display) {
    try {
      const axiosObject = getAxiosObject()
      const res = await axiosObject.put('/display', { display })
      if (res.status === 200) {
        commit('updateAfter', res.data.display)
        return true
      }
    } catch (error) {
      return false
    }
  }
}

const mutations = {
  ...commonMutations(dataName, ['setData', 'setUpdatedAt', 'updateAfter'], {
    csvColumns: ['colorOrder'],
    csvTypes: ['string']
  })
}

export default { namespaced: true, state, getters, actions, mutations }
