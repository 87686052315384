import { getAxiosObject } from '@/utils/library'
import {
  commonGetters,
  commonActions,
  commonMutations
} from '@/store/store_utils'

const dataName = 'ownerTags'

const state = {
  ownerTags: [],
  dataIndexedById: {},
  updatedAt: '20101010101010'
}

const getters = {
  ...commonGetters(
    dataName,
    ['getData', 'getDataById', 'getUpdatedAt', 'selectData'],
    { filterDel: true }
  )
}

const actions = {
  ...commonActions(['setData', 'setUpdatedAt', 'updateAfter']),
  async create({ commit }, ownerTag) {
    try {
      const axiosObject = getAxiosObject()
      const res = await axiosObject.post('/owner-tags', { ownerTag })
      if (res.status === 200) {
        commit('updateAfter', [res.data.ownerTag])
        return true
      }
    } catch (error) {
      return error.response?.data?.message === 'invalid data'
        ? 'blank ownerTag name'
        : error.response?.data?.message === 'already used'
        ? `used ${error.response.data.extra}`
        : 'server error'
    }
  },
  async update({ commit }, ownerTag) {
    try {
      const axiosObject = getAxiosObject()
      const res = await axiosObject.put('/owner-tags', { ownerTag })
      if (res.status === 200) {
        commit('updateAfter', [res.data.ownerTag])
        return true
      }
    } catch (error) {
      if (error.response?.data?.message === 'invalid data') {
        return 'blank ownerTag name'
      } else if (error.response?.data?.message === 'already used') {
        return `used ${error.response.data.extra}`
      } else if (error.response?.data?.message) {
        if (error.response.data.extra?.data) {
          commit('setData', error.response.data.extra.data)
        }
        return error.response.data.message
      } else {
        return 'server error'
      }
    }
  },
  async delete({ commit }, id) {
    try {
      const axiosObject = getAxiosObject()
      const res = await axiosObject.delete('/owner-tags', { data: { id } })
      if (res.status === 200) {
        commit('updateAfter', [res.data.ownerTag])
        return true
      }
    } catch (error) {
      if (error.response?.data?.extra?.data) {
        commit('setData', error.response.data.extra.data)
        return error.response.data.message
      } else {
        return 'server error'
      }
    }
  }
}

const mutations = {
  ...commonMutations(dataName, ['setData', 'setUpdatedAt', 'updateAfter'], {
    indexedById: true
  })
}

export default {
  namespaced: true,
  state,
  getters,
  actions,
  mutations
}
