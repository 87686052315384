<template>
  <focus-trap :escapeDeactivates="false" :clickDeactivates="false">
    <div
      class="medical-content-templates-popup"
      @keyup.esc.self.stop="$emit('close')"
      tabindex="-1"
    >
      <popup
        :buttons="['閉じる']"
        :contentStyle="{}"
        @close="$emit('close')"
        @click-close-mark="$emit('close')"
      >
        <template v-slot:content>
          <div class="content">
            <div class="header">
              <div class="title">テンプレートリスト</div>
              <base-button-register
                class="new-button"
                :text="'新規登録'"
                @click="openNewPopup"
              />
            </div>
            <div class="template-list">
              <div class="list-header">
                <div class="cell w45">テンプレート</div>
                <div class="cell w55">内容</div>
              </div>
              <div class="list-body">
                <div
                  class="row"
                  v-for="template in displayMedicalContentTemplates"
                  :key="template.id"
                  @click="set(template.content)"
                >
                  <div class="cell template-name" :title="template.name">
                    {{ template.name }}
                  </div>
                  <div
                    class="cell template-content"
                    :title="template.displayContent"
                  >
                    {{ template.displayContent }}
                  </div>
                  <div class="cell button">
                    <base-button-small-white
                      :styles="{
                        width: '40px',
                        height: '25px',
                        border: '1px solid #cecece'
                      }"
                      @click.stop="openEditPopup(template.id)"
                      >編集</base-button-small-white
                    >
                  </div>
                </div>
              </div>
            </div>
          </div>
        </template>
      </popup>
      <medical-content-template-new-popup
        v-if="newPopupFlg"
        @close="newPopupFlg = false"
      />
      <medical-content-template-edit-popup
        v-if="editPopupFlg"
        :id="targetTemplateId"
        @close="closeEditPopup"
      />
    </div>
  </focus-trap>
</template>

<script>
import Popup from '@/components/popups/Popup'
import BaseButtonRegister from '@/components/parts/atoms/BaseButtonRegister'
import BaseButtonSmallWhite from '@/components/parts/atoms/BaseButtonSmallWhite'
import MedicalContentTemplateNewPopup from '@/components/popups/MedicalContentTemplateNewPopup'
import MedicalContentTemplateEditPopup from '@/components/popups/MedicalContentTemplateEditPopup'
import { mapGetters } from 'vuex'
import { FocusTrap } from 'focus-trap-vue'
import { removeHtmlTag } from '@/utils/convert_string'

export default {
  name: 'MedicalContentTemplatesPopup',

  components: {
    FocusTrap,
    Popup,
    BaseButtonRegister,
    BaseButtonSmallWhite,
    MedicalContentTemplateNewPopup,
    MedicalContentTemplateEditPopup
  },

  data() {
    return {
      newPopupFlg: false,
      editPopupFlg: false,
      targetTemplateId: null
    }
  },

  computed: {
    ...mapGetters({
      medicalContentTemplates: 'medicalContentTemplates/getData'
    }),
    displayMedicalContentTemplates() {
      return this.medicalContentTemplates.map(v => {
        return {
          ...v,
          displayContent: removeHtmlTag(v.content)
        }
      })
    }
  },

  methods: {
    openNewPopup() {
      this.newPopupFlg = true
    },
    set(content) {
      this.$emit('set', content)
      this.$emit('close')
    },
    openEditPopup(id) {
      this.targetTemplateId = id
      this.editPopupFlg = true
    },
    closeEditPopup() {
      this.editPopupFlg = false
      this.targetTemplateId = null
    }
  }
}
</script>

<style lang="scss" scoped>
.medical-content-templates-popup {
  position: absolute;
  top: 0;
  left: 0;
  .content {
    width: 600px;
    > .header {
      display: flex;
      > .title {
        font-size: 16px;
        font-weight: bold;
        color: #{$greyish-brown};
        text-align: left;
      }
      > .new-button {
        margin: 0 0 0 auto;
      }
    }
    > .template-list {
      margin-top: 20px;
      min-height: 226px;
      text-align: left;
      > .list-header {
        box-sizing: border-box;
        display: flex;
        height: 25px;
        font-size: 13px;
        font-weight: bold;
        border: 1px solid transparent;
        > .cell {
          box-sizing: border-box;
          padding: 0 5px;
          & + .cell {
            border-left: 1px solid transparent;
          }
        }
        > .w45 {
          width: 210px;
        }
        > .w55 {
          flex: 1;
        }
      }
      > .list-body {
        box-sizing: border-box;
        max-height: 401px;
        border: 1px solid #{$very-light-grey};
        overflow-y: auto;
        > .row {
          box-sizing: border-box;
          display: flex;
          height: 40px;
          background-color: #{$gray_fc};
          font-size: 13px;
          @include hover();
          & ~ .row {
            border-top: 1px solid #{$very-light-grey};
          }
          &:first-child {
            margin-top: -1px;
            border-top: 1px solid transparent;
          }
          &:nth-child(even) {
            background-color: #{$white};
          }
          > .cell {
            box-sizing: border-box;
            line-height: 39px;
            padding: 0 5px;
          }
          > .template-name {
            width: 210px;
            border-right: 1px solid #{$very-light-grey};
            overflow: hidden;
            text-overflow: ellipsis;
            white-space: nowrap;
          }
          > .template-content {
            flex: 1;
            overflow: hidden;
            text-overflow: ellipsis;
            white-space: nowrap;
          }
          > .button {
            width: 50px;
            justify-content: center;
            border-left: 1px solid transparent;
            ::v-deep .text {
              font-size: 12px;
              font-weight: normal;
              color: #{$black_6};
            }
          }
        }
      }
    }
  }
}
</style>
