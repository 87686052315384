<template>
  <div class="mail-change-authorization">
    <main-header :logined-flg="false" />
    <div class="text" data-test="text">{{ text }}</div>
    <base-check-mark v-if="checkMarkFlg" />
    <base-button-medium-orange
      v-if="!clinicVersionUpNotReloadFlg"
      @click="pushToLogin"
      class="button"
      >ログイントップへ</base-button-medium-orange
    >
  </div>
</template>

<script>
import MainHeader from '@/components/parts/organisms/MainHeader'
import BaseCheckMark from '@/components/parts/atoms/BaseCheckMark.vue'
import BaseButtonMediumOrange from '@/components/parts/atoms/BaseButtonMediumOrange'
import axios from 'axios'
import { getApiUrl } from '@/utils/library'

export default {
  name: 'MailChangeAuthorization',

  components: {
    MainHeader,
    BaseCheckMark,
    BaseButtonMediumOrange
  },

  data() {
    return {
      text: '',
      checkMarkFlg: false,
      clinicVersionUpNotReloadFlg: false
    }
  },

  async created() {
    try {
      const axiosObject = axios.create({
        baseURL: getApiUrl(),
        headers: {
          Authorization: 'Bearer ' + this.$route.query.token,
          'Content-Type': 'application/json',
          'Clinic-Version': process.env.VERSION
        }
      })
      const res = await axiosObject.put('/staffs/mail-change-authorization')
      if (res.status === 200) {
        this.text = 'メールアドレス変更認証が完了しました。'
        this.checkMarkFlg = true
      }
    } catch (error) {
      if (
        error.response?.status === 400 &&
        error.response?.data.extra === 'not the same as required version'
      ) {
        this.text =
          '現在PETORELU+をアップデートしております。暫くお待ちになってから再度お試し下さい。'
        this.clinicVersionUpNotReloadFlg = true
      } else if (
        error.response?.data?.message === 'invalid data' &&
        error.response?.data?.extra === 'already authenticated'
      ) {
        this.text = '既にメールアドレス変更認証が完了しています。'
      } else if (
        error.response?.data?.message === 'invalid data' &&
        error.response?.data?.extra === 'email already used'
      ) {
        this.text =
          'メールアドレス変更認証に失敗しました。このメールアドレスは医院内で既に使用されています。'
      } else if (
        error.response?.data?.message === 'invalid data' &&
        error.response?.data?.extra === 'email and password already used'
      ) {
        this.text = `メールアドレス変更認証に失敗しました。同じメールアドレスとパスワードの組み合わせのスタッフが既に存在します。\nパスワードの再設定画面でパスワードを変更してから\nスタッフ編集画面で再度メールアドレスの変更をお願いします。`
      } else {
        this.text =
          'メールアドレス変更認証に失敗しました。再度メールアドレス変更をお願いします。'
      }
    }
  },

  methods: {
    pushToLogin() {
      this.$router.push({ name: 'login' })
    }
  }
}
</script>

<style lang="scss" scoped>
.mail-change-authorization {
  > .text {
    margin: 67px 0 39px;
    font-size: 20px;
    font-weight: bold;
  }
  > .button {
    margin-top: 39px;
  }
}
</style>
