<template>
  <div class="base-header-help">
    <div class="help-mark">
      ？
    </div>
    <div class="help-text">
      ヘルプ
    </div>
  </div>
</template>

<script>
export default {
  name: 'BaseHeaderHelp'
}
</script>

<style lang="scss" scoped>
.base-header-help {
  margin-top: 16px;
  float: left;
  display: flex;
  align-items: center;
  &:hover {
    cursor: pointer;
    opacity: 0.8;
  }
  .help-mark {
    float: left;
    width: 14px;
    height: 29px;
    font-family: MicrosoftSansSerif;
    font-size: 26px;
    font-weight: normal;
    font-stretch: normal;
    font-style: normal;
    line-height: 32px;
    letter-spacing: normal;
    text-align: left;
    color: #{$pumpkin};
  }
  .help-text {
    float: left;
    margin-left: 15px;
    width: 51px;
    height: 25px;
    font-size: 17px;
    font-weight: normal;
    font-stretch: normal;
    font-style: normal;
    letter-spacing: normal;
    text-align: left;
    color: #{$greyish-brown};
  }
}
@media (max-width: $horizontal-tablet-width) {
  .base-header-help {
    .help-text {
      font-size: 13px;
      margin-top: 5px;
    }
  }
}
</style>
