var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('div',{staticClass:"password-reset"},[_c('main-header',{attrs:{"logined-flg":false}}),(!_vm.clinicVersionUpNotReloadFlg)?_c('validation-observer',{staticClass:"observer",scopedSlots:_vm._u([{key:"default",fn:function(ref){
var invalid = ref.invalid;
return [_c('div',{staticClass:"page-title"},[_vm._v(" パスワードの再設定 ")]),_c('div',{staticClass:"password-reset-progress-bar"},[_c('div',{staticClass:"progress-bar-list-text orange"},[_vm._v(" メールアドレス入力 ")]),_c('div',{staticClass:"arrow orange"}),_c('div',{staticClass:"progress-bar-list-text orange"},[_vm._v(" 本人確認 ")]),_c('div',{staticClass:"arrow orange"}),_c('div',{staticClass:"progress-bar-list-text orange"},[_vm._v(" パスワード設定 ")]),_c('div',{staticClass:"arrow"}),_c('div',{staticClass:"progress-bar-list-text"},[_vm._v(" 完了 ")])]),_c('div',{staticClass:"password-reset-text"},[_vm._v(" 新しいパスワードを設定してください。 ")]),_c('div',{staticClass:"password-reset-input"},[_c('div',{staticClass:"password-reset-input-label"},[_vm._v(" パスワード"),_c('span',{staticClass:"red"},[_vm._v(" ※必須")])]),_c('validation-provider',{staticClass:"provider",attrs:{"rules":{
          requiredRule: true,
          regexHalfWidthAlphaNumeric: _vm.validPasswordRegex,
          minPassRule: 8
        }},scopedSlots:_vm._u([{key:"default",fn:function(ref){
        var errors = ref.errors;
return [_c('base-text-input-password',_vm._b({attrs:{"newFlg":true},model:{value:(_vm.password),callback:function ($$v) {_vm.password=$$v},expression:"password"}},'base-text-input-password',_vm.passwordBox,false)),_c('div',{staticClass:"error"},[_vm._v(" "+_vm._s(errors[0])+" ")])]}}],null,true)})],1),_c('div',{staticClass:"password-reset-input re-enter"},[_c('div',{staticClass:"password-reset-input-label re-enter"},[_vm._v(" パスワード（再入力）"),_c('span',{staticClass:"red"},[_vm._v(" ※必須")])]),_c('validation-provider',{staticClass:"provider",attrs:{"rules":{
          requiredRule: true,
          regexHalfWidthAlphaNumeric: _vm.validPasswordRegex,
          minPassRule: 8,
          isRePassRule: _vm.password
        }},scopedSlots:_vm._u([{key:"default",fn:function(ref){
        var errors = ref.errors;
return [_c('base-text-input-password',_vm._b({attrs:{"newFlg":true},model:{value:(_vm.reEnterPassword),callback:function ($$v) {_vm.reEnterPassword=$$v},expression:"reEnterPassword"}},'base-text-input-password',_vm.passwordBox,false)),_c('div',{staticClass:"error"},[_vm._v(" "+_vm._s(errors[0])+" ")])]}}],null,true)})],1),_c('div',{staticClass:"submit-button"},[_c('base-button-large-orange',{attrs:{"disabled":invalid || _vm.waitFlg},on:{"click":_vm.handleUpdatePassword}},[_vm._v("次へ")])],1)]}}],null,false,711568996)}):_c('div',{staticClass:"version-uploading-error"},[_vm._v(" 現在PETORELU+をアップデートしております。暫くお待ちになってから再度お試し下さい。 ")]),(_vm.alertFlg)?_c('announce-popup',{attrs:{"title":_vm.title,"buttons":_vm.buttons,"type":_vm.type},on:{"close":_vm.closeAlert}},[_vm._v(_vm._s(_vm.popupMessage))]):_vm._e()],1)}
var staticRenderFns = []

export { render, staticRenderFns }