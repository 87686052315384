import { mutationUpdateAfter } from '@/utils/update_after'
import { getAxiosObject } from '@/utils/library'
import { returnLeftAlignMessageOpenTimes } from '@/utils/check_reservable_days'
import {
  commonGetters,
  commonActions,
  commonMutations
} from '@/store/store_utils'

const dataName = 'openTimes'

const state = {
  openTimes: [],
  updatedAt: '20101010101010'
}

const getters = {
  ...commonGetters(dataName, ['getData', 'getUpdatedAt'], { filterDel: true })
}

const actions = {
  ...commonActions(['setData', 'setUpdatedAt', 'updateAfter']),
  async update({ commit }, obj) {
    try {
      const axiosObject = getAxiosObject()
      const res = await axiosObject.put('/open-times', obj)
      const stores = [
        'openTimes',
        'specialOpenTimes',
        'shifts',
        'reservationPurposes',
        'reservationSetting',
        'specialReservationAvailablePatterns',
        'reservationAvailablePatterns',
        'shiftSetting',
        'staffWorkingDates'
      ]
      stores.forEach(store => {
        commit(`${store}/updateAfter`, res.data[store], { root: true })
      })
      const leftAlignMessage = returnLeftAlignMessageOpenTimes()
      return { status: res.status, leftAlignMessage }
    } catch (err) {
      return err.response?.data?.message
        ? { status: err.response.status, message: err.response.data.message }
        : { status: 500, message: 'server error' }
    }
  }
}

const mutations = {
  ...commonMutations(dataName, ['setUpdatedAt'], {}),
  setData(state, openTimes) {
    state.openTimes = openTimes.sort((a, b) => a.type - b.type)
  },
  updateAfter(state, data) {
    mutationUpdateAfter(state, data, dataName)
    state.openTimes.sort((a, b) => a.type - b.type)
  }
}

export default {
  namespaced: true,
  state,
  mutations,
  getters,
  actions
}
