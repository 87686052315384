import { Extension } from '@tiptap/core'
import { Node, mergeAttributes } from '@tiptap/core'
import { VueNodeViewRenderer } from '@tiptap/vue-2'
import RichTextEditorExaminationTable from '@/components/parts/organisms/RichTextEditorExaminationTable.vue'
import RichTextEditorImage from '@/components/parts/organisms/RichTextEditorImage.vue'
import RichTextEditorMedicalContentImage from '@/components/parts/organisms/RichTextEditorMedicalContentImage.vue'
import StarterKit from '@tiptap/starter-kit'

export const configureStarterKit = () => {
  return StarterKit.configure({
    //https://tiptap.dev/api/extensions/starter-kit
    //下記の拡張機能は使用していないので入れない。
    //※機能を入れる場合、現状だと診療内容の登録をした時にmedicalContentのsearchContentカラムには
    //pタグ内のtextしか保存しておらず、下記のタグ内に書いてあるtextは保存しない処理になっており、
    //処理を変更する必要があるので注意。
    //箇所⇒https://github.com/shinshinshin/plus_back/blob/karte_data/app/clinic_routes/medical_contents.js
    //      calculateSearchContent関数内 if (v.type === 'paragraph' && v.content)の処理部分
    heading: false, // <h1>,<h2>,<h3>,<h4>,<h5>,<h6>
    codeBlock: false, // <pre>,<code>
    blockquote: false, // <blockquote>
    bulletList: false, // <ul>
    orderedList: false, // <ol>
    listItem: false, // <li>
    hardBreak: false, // <br>
    horizontalRule: false, // <hr>
    mention: false // @ この機能はあっても挙動が特に変わらないが、使用しないのではずしておく
  })
}

export const FontSize = Extension.create({
  name: 'fontSize',

  addOptions: {
    types: ['textStyle']
  },

  addGlobalAttributes() {
    return [
      {
        types: this.options.types,
        attributes: {
          fontSize: {
            default: null,
            renderHTML: attributes => {
              return { style: `font-size: ${attributes.fontSize}` }
            },
            parseHTML: element => element.style.fontSize || null
          }
        }
      }
    ]
  },

  addCommands() {
    return {
      setFontSize: fontSize => ({ chain }) => {
        return chain()
          .setMark('textStyle', { fontSize })
          .run()
      }
    }
  }
})

export const BackgroundColor = Extension.create({
  name: 'backgroundColor',

  addOptions: {
    types: ['textStyle']
  },

  addGlobalAttributes() {
    return [
      {
        types: this.options.types,
        attributes: {
          backgroundColor: {
            default: null,
            renderHTML: attributes => {
              return {
                style: `background-color: ${attributes.backgroundColor}`
              }
            },
            parseHTML: element => element.style.backgroundColor || null
          }
        }
      }
    ]
  },

  addCommands() {
    return {
      setBackgroundColor: backgroundColor => ({ chain }) => {
        return chain()
          .setMark('textStyle', { backgroundColor })
          .run()
      }
    }
  }
})

export const createVueRichTextEditorExaminationTable = ({
  patientId = 0,
  medicalContentId = 0,
  richTextEditorShowFlg = false,
  searchRegExp = null
}) => {
  const vueRichTextEditorExaminationTable = Node.create({
    name: 'VueRichTextEditorExaminationTable',

    group: 'block',

    atom: true,

    addOptions: {
      patientId,
      medicalContentId,
      richTextEditorShowFlg,
      searchRegExp
    },

    addAttributes() {
      return {
        examinationResultGroupId: { default: 0 }
      }
    },

    parseHTML() {
      return [{ tag: 'vue-rich-text-editor-examination-table' }]
    },

    renderHTML({ HTMLAttributes }) {
      return [
        'vue-rich-text-editor-examination-table',
        mergeAttributes(HTMLAttributes)
      ]
    },

    addNodeView() {
      return VueNodeViewRenderer(RichTextEditorExaminationTable)
    }
  })
  return vueRichTextEditorExaminationTable
}

export const createVueRichTextEditorImage = ({
  richTextEditorShowFlg = false,
  isMedicalContentsHistoryDetail = false
}) => {
  const vueRichTextEditorImage = Node.create({
    name: 'VueRichTextEditorImage',

    group: 'block',

    atom: true,

    addOptions: { richTextEditorShowFlg, isMedicalContentsHistoryDetail },

    addAttributes() {
      return {
        uploadImageId: { default: 0 }
      }
    },

    parseHTML() {
      return [{ tag: 'vue-rich-text-editor-image' }]
    },

    renderHTML({ HTMLAttributes }) {
      return ['vue-rich-text-editor-image', mergeAttributes(HTMLAttributes)]
    },

    addNodeView() {
      return VueNodeViewRenderer(RichTextEditorImage)
    }
  })
  return vueRichTextEditorImage
}

export const createVueRichTextEditorMedicalContentImage = ({
  richTextEditorShowFlg = false,
  searchRegExp = null,
  isMedicalContentsHistoryDetail = false
}) => {
  const vueRichTextEditorMedicalContentImage = Node.create({
    name: 'VueRichTextEditorMedicalContentImage',

    group: 'block',

    atom: true,

    addOptions: {
      richTextEditorShowFlg,
      searchRegExp,
      isMedicalContentsHistoryDetail
    },

    addAttributes() {
      return {
        medicalContentImageId: { default: 0 },
        uploadImageId: { default: 0 }
      }
    },

    parseHTML() {
      return [{ tag: 'vue-rich-text-editor-medical-content-image' }]
    },

    renderHTML({ HTMLAttributes }) {
      return [
        'vue-rich-text-editor-medical-content-image',
        mergeAttributes(HTMLAttributes)
      ]
    },

    addNodeView() {
      return VueNodeViewRenderer(RichTextEditorMedicalContentImage)
    }
  })
  return vueRichTextEditorMedicalContentImage
}
