import { getAxiosObject } from '@/utils/library'
import {
  commonGetters,
  commonMutations,
  commonActions,
  updateUploadImages
} from '@/store/store_utils'
import { toStateName } from '@/utils/convert_string'

const dataName = 'medicalPayments'
const keys = ['medicalRecordOriginalId', 'patientId', 'originalId']

const state = {
  medicalPayments: [],
  dataIndexedById: {},
  dataIndexedByOriginalId: {},
  updatedAt: '20101010101010'
}
keys.forEach(key => {
  state[toStateName(key)] = null
})

const getters = {
  ...commonGetters(
    dataName,
    ['getData', 'getDataById', 'getDataByOriginalId', 'getUpdatedAt'],
    {
      filterDel: true,
      getDataBy: ['medicalRecordOriginalId', 'patientId'],
      getDataByIncludeDel: ['patientId', 'originalId']
    }
  )
}

const actions = {
  ...commonActions(['setData', 'setUpdatedAt', 'updateAfter']),
  async create(
    { commit, rootGetters },
    {
      medicalRecord,
      medicalContent,
      contentJsonObj,
      examinationResultGroups,
      resizedUploadImages,
      resizedMedicalContentImages,
      deleteExaminationResults,
      medicalPayment,
      medicalTreatmentItems,
      anicomReport,
      mailFlg
    }
  ) {
    try {
      const axiosObject = getAxiosObject()
      const res = await axiosObject.post('/medical-payments', {
        medicalRecord,
        medicalContent,
        contentJsonObj,
        examinationResultGroups,
        resizedUploadImages,
        resizedMedicalContentImages,
        deleteExaminationResults,
        medicalPayment,
        medicalTreatmentItems,
        anicomReport,
        mailFlg
      })
      if (res.status === 200) {
        commit('updateAfter', [res.data.medicalPayment])
        if (Object.keys(res.data.reservation).length > 0) {
          commit('reservations/updateAfter', [res.data.reservation], {
            root: true
          })
        }
        commit('medicalRecords/updateAfter', res.data.medicalRecords, {
          root: true
        })
        if (res.data.medicalTreatmentItems.length > 0) {
          commit(
            'medicalTreatmentItems/updateAfter',
            res.data.medicalTreatmentItems,
            { root: true }
          )
        }
        if (res.data.sendMail) {
          commit('sendMails/updateAfter', [res.data.sendMail], { root: true })
        }
        if (res.data.patientVaccines.length > 0) {
          commit('patientVaccines/updateAfter', res.data.patientVaccines, {
            root: true
          })
        }
        if (res.data.antibody) {
          commit('antibodies/updateAfter', [res.data.antibody], { root: true })
        }
        if (res.data.antibodyTypes.length > 0) {
          commit('antibodyTypes/updateAfter', res.data.antibodyTypes, {
            root: true
          })
        }
        if (res.data.hospitalization) {
          commit('hospitalizations/updateAfter', [res.data.hospitalization], {
            root: true
          })
        }
        if (res.data.examinationResults.length > 0) {
          commit(
            'examinationResults/updateAfter',
            res.data.examinationResults,
            { root: true }
          )
        }
        if (res.data.uploadImages.length > 0) {
          updateUploadImages(commit, rootGetters, res.data.uploadImages)
        }
        if (res.data.medicalContentImages.length > 0) {
          const actions = [
            'setKarteMedicalContentImages',
            'updateAfter',
            'resetResizedMedicalContentImages'
          ]
          actions.forEach(a => {
            commit(`medicalContentImages/${a}`, res.data.medicalContentImages, {
              root: true
            })
          })
        }
        commit('medicalContents/updateAfter', res.data.medicalContents, {
          root: true
        })
        return {
          result: true,
          reservation: res.data.reservation,
          medicalRecord:
            res.data.medicalRecords[res.data.medicalRecords.length - 1],
          medicalPayment: res.data.medicalPayment,
          medicalTreatmentItems: res.data.medicalTreatmentItems,
          sendMailFlg: Boolean(res.data.sendMail),
          medicalContent:
            res.data.medicalContents.length > 0
              ? res.data.medicalContents[res.data.medicalContents.length - 1]
              : null,
          examinationResults: res.data.examinationResults.filter(
            v => v.delFlg === 0
          )
        }
      }
    } catch (error) {
      if (error.response?.data?.message === 'no data') {
        if (error.response.data.extra.dataName === 'medicalRecord') {
          commit('medicalRecords/setData', error.response.data.extra.data, {
            root: true
          })
          return `no ${error.response.data.extra.dataName}`
        } else {
          return `no ${error.response.data.extra}`
        }
      } else if (error.response?.data?.message === 'no data in clinic') {
        const moduleName = dataName => {
          const moduleName =
            dataName === 'diseaseClass' ? 'diseaseClasses' : `${dataName}s`
          return moduleName
        }
        const dataName = error.response.data.extra.dataName
        commit(
          `${moduleName(dataName)}/setData`,
          error.response.data.extra.data,
          { root: true }
        )
        return `no ${dataName}`
      } else if (error.response?.data?.message === 'invalid data') {
        return error.response?.data?.extra
      } else if (error.response?.data?.message) {
        return error.response.data.message
      } else {
        return 'server error'
      }
    }
  },
  async update(
    { commit, rootGetters },
    {
      medicalRecord,
      medicalContent,
      contentJsonObj,
      examinationResultGroups,
      resizedUploadImages,
      resizedMedicalContentImages,
      deleteExaminationResults,
      medicalPayment,
      medicalTreatmentItems,
      anicomReport,
      mailFlg
    }
  ) {
    try {
      const axiosObject = getAxiosObject()
      const res = await axiosObject.put('/medical-payments', {
        medicalRecord,
        medicalContent,
        contentJsonObj,
        examinationResultGroups,
        resizedUploadImages,
        resizedMedicalContentImages,
        deleteExaminationResults,
        medicalPayment,
        medicalTreatmentItems,
        anicomReport,
        mailFlg
      })
      if (res.status === 200) {
        commit('updateAfter', res.data.medicalPayments)
        if (Object.keys(res.data.reservation).length > 0) {
          commit('reservations/updateAfter', [res.data.reservation], {
            root: true
          })
        }
        commit('medicalRecords/updateAfter', res.data.medicalRecords, {
          root: true
        })
        if (res.data.medicalTreatmentItems.length > 0) {
          commit(
            'medicalTreatmentItems/updateAfter',
            res.data.medicalTreatmentItems,
            { root: true }
          )
        }
        if (res.data.sendMail) {
          commit('sendMails/updateAfter', [res.data.sendMail], { root: true })
        }
        if (Object.keys(res.data.anicomReport).length > 0) {
          commit('anicomReports/updateAfter', [res.data.anicomReport], {
            root: true
          })
        }
        if (res.data.patientVaccines.length > 0) {
          commit('patientVaccines/updateAfter', res.data.patientVaccines, {
            root: true
          })
        }
        if (res.data.antibodies.length) {
          commit('antibodies/updateAfter', res.data.antibodies, {
            root: true
          })
        }
        if (res.data.antibodyTypes.length > 0) {
          commit('antibodyTypes/updateAfter', res.data.antibodyTypes, {
            root: true
          })
        }
        if (res.data.hospitalization) {
          commit('hospitalizations/updateAfter', [res.data.hospitalization], {
            root: true
          })
        }
        if (res.data.examinationResults.length > 0) {
          commit(
            'examinationResults/updateAfter',
            res.data.examinationResults,
            { root: true }
          )
        }
        if (res.data.uploadImages.length > 0) {
          updateUploadImages(commit, rootGetters, res.data.uploadImages)
        }
        if (res.data.medicalContentImages.length > 0) {
          const actions = [
            'setKarteMedicalContentImages',
            'updateAfter',
            'resetResizedMedicalContentImages'
          ]
          actions.forEach(a => {
            commit(`medicalContentImages/${a}`, res.data.medicalContentImages, {
              root: true
            })
          })
        }
        commit('medicalContents/updateAfter', res.data.medicalContents, {
          root: true
        })
        return {
          result: true,
          reservation: res.data.reservation,
          medicalRecord:
            res.data.medicalRecords[res.data.medicalRecords.length - 1],
          medicalPayment: res.data.medicalPayments[1],
          medicalTreatmentItems: res.data.medicalTreatmentItems.filter(
            v => v.medicalPaymentId === res.data.medicalPayments[1].id
          ),
          sendMailFlg: Boolean(res.data.sendMail),
          medicalContent:
            res.data.medicalContents.length > 0
              ? res.data.medicalContents[res.data.medicalContents.length - 1]
              : null,
          examinationResults: res.data.examinationResults.filter(
            v => v.delFlg === 0
          )
        }
      }
    } catch (error) {
      if (error.response?.data?.message === 'no data') {
        if (error.response.data.extra.dataName === 'medicalRecord') {
          commit('medicalRecords/setData', error.response.data.extra.data, {
            root: true
          })
          return `no ${error.response.data.extra.dataName}`
        } else {
          return `no ${error.response.data.extra}`
        }
      } else if (error.response?.data?.message === 'no data in clinic') {
        const moduleName = dataName => {
          const moduleName =
            dataName === 'diseaseClass' ? 'diseaseClasses' : `${dataName}s`
          return moduleName
        }
        const dataName = error.response.data.extra.dataName
        commit(
          `${moduleName(dataName)}/setData`,
          error.response.data.extra.data,
          { root: true }
        )
        return `no ${dataName}`
      } else if (error.response?.data?.message === 'invalid data') {
        return error.response?.data?.extra
      } else if (error.response?.data?.message) {
        return error.response.data.message
      } else {
        return 'server error'
      }
    }
  },
  async delete({ commit, rootGetters }, id) {
    try {
      const axiosObject = getAxiosObject()
      const res = await axiosObject.delete('/medical-payments', {
        data: { id }
      })
      if (res.status === 200) {
        commit('updateAfter', [res.data.medicalPayment])
        if (res.data.medicalRecord) {
          commit('medicalRecords/updateAfter', [res.data.medicalRecord], {
            root: true
          })
          if (
            rootGetters['medicalRecords/getOriginalIdSetInTab'] ===
            res.data.medicalRecord.originalId
          )
            commit('medicalRecords/resetOriginalIdSetInTab', null, {
              root: true
            })
        }
        if (res.data.reservation) {
          commit('reservations/updateAfter', [res.data.reservation], {
            root: true
          })
        }
        if (res.data.medicalTreatmentItems.length > 0) {
          commit(
            'medicalTreatmentItems/updateAfter',
            res.data.medicalTreatmentItems,
            { root: true }
          )
        }
        if (res.data.payments.length > 0) {
          commit('payments/updateAfter', res.data.payments, { root: true })
        }
        if (res.data.antibody) {
          commit('antibodies/updateAfter', [res.data.antibody], { root: true })
        }
        if (res.data.antibodyTypes.length > 0) {
          commit('antibodyTypes/updateAfter', res.data.antibodyTypes, {
            root: true
          })
        }
        if (res.data.hospitalization) {
          commit('hospitalizations/updateAfter', [res.data.hospitalization], {
            root: true
          })
        }
        return {
          result: true,
          deleteMedicalPaymentIds: [res.data.medicalPayment.id]
        }
      }
    } catch (error) {
      if (error.response?.data?.message) {
        if (error.response.data.message === 'no data in clinic') {
          commit('setData', error.response.data.extra.data)
          return error.response.data.message
        } else if (error.response.data.message === 'invalid data') {
          return error.response.data.extra
        } else {
          return error.response.data.message
        }
      } else {
        return 'server error'
      }
    }
  },
  async deleteHospitalization({ commit, rootGetters }, originalId) {
    try {
      const axiosObject = getAxiosObject()
      const res = await axiosObject.delete(
        '/medical-payments/hospitalization',
        {
          data: { originalId }
        }
      )
      if (res.status === 200) {
        commit('updateAfter', res.data.medicalPayments)
        if (res.data.medicalRecords.length > 0) {
          commit('medicalRecords/updateAfter', res.data.medicalRecords, {
            root: true
          })
          const originalIdSetInTab =
            rootGetters['medicalRecords/getOriginalIdSetInTab']
          if (
            res.data.medicalRecords.find(
              v => v.originalId === originalIdSetInTab
            )
          )
            commit('medicalRecords/resetOriginalIdSetInTab', null, {
              root: true
            })
        }
        if (res.data.reservations.length > 0) {
          commit('reservations/updateAfter', res.data.reservations, {
            root: true
          })
        }
        if (res.data.medicalTreatmentItems.length > 0) {
          commit(
            'medicalTreatmentItems/updateAfter',
            res.data.medicalTreatmentItems,
            { root: true }
          )
        }
        if (res.data.payments.length > 0) {
          commit('payments/updateAfter', res.data.payments, { root: true })
        }
        if (res.data.antibodies.length > 0) {
          commit('antibodies/updateAfter', res.data.antibodies, { root: true })
        }
        if (res.data.antibodyTypes.length > 0) {
          commit('antibodyTypes/updateAfter', res.data.antibodyTypes, {
            root: true
          })
        }
        commit('hospitalizations/updateAfter', [res.data.hospitalization], {
          root: true
        })
        return {
          result: true,
          deleteMedicalPaymentIds: res.data.medicalPayments.map(v => v.id)
        }
      }
    } catch (error) {
      if (error.response?.data?.message) {
        if (error.response.data.message === 'no data in clinic') {
          commit('setData', error.response.data.extra.data)
          return error.response.data.message
        } else if (error.response.data.message === 'invalid data') {
          return error.response.data.extra
        } else {
          return error.response.data.message
        }
      } else {
        return 'server error'
      }
    }
  },
  async search(
    { commit },
    {
      detailSearchFlg,
      trimSearchText,
      period,
      trimSearchConditions,
      page,
      order,
      deleteCounts
    }
  ) {
    try {
      const axiosObject = getAxiosObject()
      const searchValues = {
        detailSearchFlg,
        trimSearchText,
        period,
        trimSearchConditions,
        page,
        order,
        deleteCounts
      }
      const res = await axiosObject.get('/medical-payments/search', {
        params: { searchValues }
      })
      if (res.status === 200) {
        if (res.data.medicalPayments.length > 0) {
          commit('updateAfter', res.data.medicalPayments)
        }
        if (res.data.medicalRecords.length > 0) {
          commit('medicalRecords/updateAfter', res.data.medicalRecords, {
            root: true
          })
        }
        if (res.data.medicalTreatmentItems.length > 0) {
          commit(
            'medicalTreatmentItems/updateAfter',
            res.data.medicalTreatmentItems,
            { root: true }
          )
        }
        if (res.data.payments.length > 0) {
          commit('payments/updateAfter', res.data.payments, { root: true })
        }
        return {
          result: true,
          medicalPayments: res.data.medicalPayments,
          hitAllDataCounts: res.data.hitAllDataCounts
        }
      }
    } catch (error) {
      return { result: false }
    }
  },

  async searchAppliedData(
    { commit },
    {
      detailSearchFlg,
      trimSearchText,
      period,
      trimSearchConditions,
      insuranceType,
      order
    }
  ) {
    try {
      const axiosObject = getAxiosObject()
      const searchValues = {
        detailSearchFlg,
        trimSearchText,
        period,
        trimSearchConditions,
        insuranceType,
        order
      }
      const res = await axiosObject.get(
        '/medical-payments/search-applied-data',
        { params: { searchValues } }
      )
      if (res.status === 200) {
        if (res.data.medicalPayments.length > 0) {
          commit('updateAfter', res.data.medicalPayments)
        }
        if (res.data.medicalRecords.length > 0) {
          commit('medicalRecords/updateAfter', res.data.medicalRecords, {
            root: true
          })
        }
        if (res.data.medicalTreatmentItems.length > 0) {
          commit(
            'medicalTreatmentItems/updateAfter',
            res.data.medicalTreatmentItems,
            { root: true }
          )
        }
        if (res.data.hospitalizations.length > 0) {
          commit('hospitalizations/updateAfter', res.data.hospitalizations, {
            root: true
          })
        }
        return {
          result: true,
          sortedHitDisplayMedicalPayments:
            res.data.sortedHitDisplayMedicalPayments,
          //↓検索にヒットした診療明細に紐づいたテーブルデータ。ヒットしたのが退院の明細の場合は紐づいた入院開始・入院中のデータも入れています。
          // 診療明細書一括印刷、アイペット保険金請求書印刷で必要な情報を算出する時に使用して下さい。
          medicalPayments: res.data.medicalPayments,
          medicalRecords: res.data.medicalRecords,
          medicalTreatmentItems: res.data.medicalTreatmentItems,
          hospitalizations: res.data.hospitalizations
        }
      }
    } catch (error) {
      return { result: false }
    }
  },
  async fetchRelatedById({ commit }, medicalPaymentId) {
    try {
      const axiosObject = getAxiosObject()
      const res = await axiosObject.get('/medical-payments', {
        params: { medicalPaymentId }
      })
      const stores = [
        'medicalPayments',
        'anicomReports',
        'medicalRecords',
        'medicalTreatmentItems',
        'hospitalizations',
        'anicomCIdChecks',
        'payments'
      ]
      stores.forEach(store => {
        commit(`${store}/updateAfter`, res.data[store], { root: true })
      })
      return 'success'
    } catch (error) {
      if (error.response?.data?.message === 'no data') {
        return 'no data'
      } else {
        return 'server error'
      }
    }
  }
}

const mutations = {
  ...commonMutations(dataName, ['setData', 'setUpdatedAt', 'updateAfter'], {
    indexedById: true,
    indexedByOriginalId: true,
    setDataBy: keys
  })
}

export default {
  namespaced: true,
  state,
  getters,
  actions,
  mutations
}
