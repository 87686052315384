<template>
  <div class="medical-record-image-uploader">
    <div
      class="input-area"
      :style="styles"
      @drop.prevent="onImageChange($event, 'drop')"
    >
      <input
        type="file"
        accept="image/png,image/jpeg,image/gif,image/bmp"
        :key="inputCounts"
        @change="onImageChange"
      />
      <div class="guide">
        <img src="@/assets/images/cloud_upload.png" class="icon cloud_upload" />
        <div class="texts">
          <div class="caption">画像を配置</div>
          <div class="explain">
            ここにファイルをドラッグ＆ドロップしてください。<br />
            またはクリックして画像を選択してください。
          </div>
          <div class="notes">
            ※画像ファイル[bmp,jpg,jpeg,gif,png]を登録してください。
          </div>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import imageCompression from 'browser-image-compression'

export default {
  name: 'MedicalRecordImageUploader',

  props: {
    image: { type: [String, null], default: null },
    styles: {
      type: Object,
      default: () => ({}),
      validator: obj => Object.values(obj).every(v => typeof v === 'string')
    }
  },

  data() {
    return {
      inputCounts: 0
    }
  },

  methods: {
    async onImageChange(e, inputType = 'change') {
      const file =
        inputType === 'drop' ? e.dataTransfer.files[0] : e.target.files[0]

      /*
        2024/1/9
        高解像度で画像を取り込めるようにするため、画像の横幅・縦幅を400以下に圧縮する設定を2000以下に変更した。
        （maxWidthOrHeight: 400を2000に変更）
        1MB以下に圧縮する設定（maxSizeMB: 1）は必ず1MB以下になるわけではなく、
        数十MBの大きいサイズの画像の場合（試したのは32.9MBのbmpの画像）に1MBを大きく超えることがあるため、幅での圧縮の設定を残した。
        https://github.com/shinshinshin/plus_clinic/pull/1980/files
        該当のカード https://trello.com/c/zIRmWhd8
      */
      const image = await this.encodeBase64(await this.resizeImage(file, 2000))

      /*
        2024/2/7
        画像一覧画面や診療内容内容一覧画面では画像を複数表示するので、
        処理が重くならないよう画像幅を縮小したサイズの小さい画像(サムネイル画像)の方を表示する。
        下記で画像幅250以下に圧縮したサムネイル画像を作成する。
      */
      const thumbnailImage = await this.encodeBase64(
        await this.resizeImage(file, 250)
      )

      const imageStyle = await this.getImageStyle(image)
      const { width, height } = this.calculateReducedWidthHeight(
        imageStyle.width,
        imageStyle.height
      )
      this.$emit('input', {
        image,
        thumbnailImage,
        width,
        height,
        name: file.name.substring(0, 30)
      })
      this.inputCounts += 1
    },
    async resizeImage(file, maxWidthOrHeight) {
      return await imageCompression(file, {
        maxSizeMB: 1,
        maxWidthOrHeight
      })
    },
    encodeBase64(file) {
      return new Promise(resolve => {
        const reader = new FileReader()
        reader.readAsDataURL(file)
        reader.onload = () => resolve(reader.result)
      })
    },
    getImageStyle(file) {
      return new Promise(resolved => {
        const image = new Image()
        image.onload = () => {
          resolved({ width: image.width, height: image.height })
        }
        image.src = file
      })
    },
    /*
      2024/2/7
      ↓画像の幅を縦横比率を維持して400以下にした場合の横幅・縦幅を算出する
       例 width:1000, height:750 (1 : 0.75) → width:400, height:300 で返す
       目的
       →uploadImagesのwidth,height,widthInKarte,heightInKarteやmedicalContentImagesのwidthInKarte,heightInKarte
        に400以下にした場合の横幅・縦幅を記録するため。
        カルテ登録画面の診療内容フォーム内で幅400で画像を表示する等のために上記の値を使用している。
        上記カラムに元の画像の横・縦幅（または幅を2000以下に圧縮した時の横・縦幅）を記録すると画像が大きい場合に、
        値を使用している画面で元の画像のサイズのまま表示されるので表示が崩れてしまう。
        (カルテ登録画面/診療フォームの枠から画像がはみ出したり、画像一覧画面で他の画像に重なったりする)
        以前は画像を圧縮する時に400以下に縮小し（imageCompression関数にmaxWidthOrHeight: 400を設定）その画像サイズを使用して記録していたが、
        400ではなく2000(高解像度の画像)と250(サムネイル画像)に縮小する仕様に変わったため、代わりにこの関数で算出するようにした。
    */
    calculateReducedWidthHeight(width, height) {
      const maxWidthOrHeight = 400
      if (width > maxWidthOrHeight || height > maxWidthOrHeight) {
        if (width > height) {
          return {
            width: maxWidthOrHeight,
            height: Math.round((height / width) * maxWidthOrHeight)
          }
        } else {
          return {
            height: maxWidthOrHeight,
            width: Math.round((width / height) * maxWidthOrHeight)
          }
        }
      } else {
        return { width, height }
      }
    }
  }
}
</script>

<style lang="scss" scoped>
@mixin center {
  display: flex;
  justify-content: center;
  align-items: center;
}
.medical-record-image-uploader {
  > .input-area {
    position: relative;
    width: 400px;
    height: 400px;
    box-sizing: border-box;
    border: dashed 2px #{$gray};
    @include center;
    > input {
      position: absolute;
      width: 100%;
      height: 100%;
      top: 0;
      left: 0;
      opacity: 0;
      cursor: pointer;
    }
    > .guide {
      @include center;
      > .icon {
        width: 38px;
      }
      > .texts {
        margin-left: 15px;
        font-size: 11px;
        line-height: 1.27;
        text-align: left;
        color: #{$gray};
        > .caption {
          font-weight: bold;
        }
        > .notes {
          font-size: 8px;
          color: #{$tomato};
        }
      }
    }
  }
}
</style>
