import store from '@/store/store'

export const getDevice = () => {
  const ua = store?.getters['petorelu/get']?.browser
  if (
    ua.indexOf('iphone') > 0 ||
    ua.indexOf('ipod') > 0 ||
    (ua.indexOf('android') > 0 && ua.indexOf('mobile') > 0)
  ) {
    return 'sp'
  } else if (
    ua.indexOf('ipad') > 0 ||
    (ua.indexOf('macintosh') > 0 && 'ontouchend' in document) ||
    ua.indexOf('android') > 0
  ) {
    return 'tab'
  } else {
    return 'pc'
  }
}

// 参考サイト https://www.qam-web.com/?p=15115
export const isPcModeOnIpad = () => {
  const ua = store.getters['petorelu/get'].browser
  return ua.indexOf('macintosh') > 0 &&
    'ontouchend' in document &&
    (ua.includes('firefox') || ua.includes('safari'))
    ? true
    : false
}
