import {
  commonGetters,
  commonActions,
  commonMutations
} from '@/store/store_utils'

const dataName = 'specialReservationAvailablePatterns'

const state = {
  specialReservationAvailablePatterns: [],
  updatedAt: '20101010101010'
}

const getters = {
  ...commonGetters(dataName, ['getData', 'getUpdatedAt'], { filterDel: true })
}

const actions = {
  ...commonActions(['setData', 'setUpdatedAt', 'updateAfter'])
}

const mutations = {
  ...commonMutations(dataName, ['setData', 'updateAfter', 'setUpdatedAt'], {})
}

export default {
  namespaced: true,
  state,
  mutations,
  getters,
  actions
}
