<template>
  <div class="base-radio-button" :style="styles">
    <input
      type="radio"
      class="radio-input"
      :class="{ disabled: disabled }"
      :value="option.eachValue"
      :id="`radio-button-${option.id}`"
      v-model="internalValue"
      :disabled="disabled"
    />
    <label
      :class="{ disabled: disabled }"
      :for="`radio-button-${option.id}`"
      :style="inputStyles"
      :title="option.title"
      data-test="radio-button"
      data-e2e="base-radio-button"
      >{{ option.labelName }}</label
    >
  </div>
</template>

<script>
export default {
  name: 'BaseRadioButton',
  props: {
    option: {
      type: Object, // {id: 1, eachValue: 1 or '値', labelName: '有効', title(任意で設定): '値' }
      required: true,
      validator: function(v) {
        const isValidId = v.id !== null && Number.isInteger(v.id)
        const isNumber = Number.isInteger(v.eachValue)
        const isString = typeof v.eachValue === 'string'
        const isBoolean = typeof v.eachValue === 'boolean'
        const isValidValue = isNumber || isString || isBoolean
        const isValidLabel =
          v.labelName !== null && typeof v.labelName === 'string'
        return isValidId && isValidValue && isValidLabel
      }
    },
    value: {
      type: [String, Number, Boolean],
      required: true
    },
    styles: {
      marginLeft: { type: String },
      marginRight: { type: String }
    },
    inputStyles: { type: Object },
    disabled: { type: Boolean, default: false }
  },
  computed: {
    internalValue: {
      get() {
        return this.value
      },
      set(val) {
        this.$emit('input', val)
      }
    }
  }
}
</script>

<style lang="scss" scoped>
.base-radio-button {
  margin-left: 10px;
  margin-right: 30px;
  display: flex;
  align-items: center;
  .radio-input {
    display: none;
  }
  .radio-input + label {
    padding-left: 30px;
    position: relative;
    cursor: pointer;
    &::before {
      background-color: #{$white};
      content: '';
      position: absolute;
      top: 50%;
      left: -10px;
      width: 20px;
      height: 20px;
      margin-top: -10px;
      border: solid 2px #{$light-grey};
      border-radius: 50%;
      box-sizing: border-box;
    }
    &.disabled {
      pointer-events: none;
      cursor: default;
      &::before {
        background-color: #{$light-grey};
        opacity: 0.7;
      }
    }
  }
  .radio-input {
    &:checked + label {
      &::after {
        content: '';
        position: absolute;
        top: 50%;
        left: -4px;
        width: 8px;
        height: 8px;
        margin-top: -4px;
        background-color: #{$pumpkin};
        border-radius: 50%;
      }
      &.disabled {
        &::after {
          background-color: #{$brown-gray};
          opacity: 0.7;
        }
      }
    }
  }
}
</style>
