import { getAxiosObject } from '@/utils/library'
import {
  commonGetters,
  commonActions,
  commonMutations
} from '@/store/store_utils'

const dataName = 'waitingStatuses'
const apiUrl = '/waiting-statuses'

const state = {
  waitingStatuses: [],
  dataIndexedById: {},
  updatedAt: '20101010101010'
}

const getters = {
  ...commonGetters(
    dataName,
    [
      'getData',
      'getDataIncludeDel',
      'getDataById',
      'selectData',
      'getUpdatedAt'
    ],
    { filterDel: true }
  )
}

const actions = {
  ...commonActions(['setData', 'setUpdatedAt', 'updateAfter']),
  async update(
    { commit },
    { waitingStatusesBeforeInput, waitingStatusesAfterInput }
  ) {
    try {
      const axiosObject = getAxiosObject()
      const res = await axiosObject.put(apiUrl, {
        waitingStatusesBeforeInput,
        waitingStatusesAfterInput
      })
      if (res.status === 200) {
        commit('updateAfter', res.data.waitingStatuses)
        return 'success'
      }
    } catch (error) {
      if (error.response?.data?.extra) {
        return error.response.data.extra
      } else if (error.response?.data?.message) {
        return error.response.data.message
      } else {
        return 'server error'
      }
    }
  }
}

const mutations = {
  ...commonMutations(dataName, ['setData', 'updateAfter', 'setUpdatedAt'], {
    indexedById: true,
    order: true
  })
}

export default {
  namespaced: true,
  state,
  getters,
  actions,
  mutations
}
