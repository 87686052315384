<template>
  <button @click="$emit('click')">
    <div class="text">シェーマ画像選択</div>
    <img src="@/assets/images/select.png" />
  </button>
</template>

<script>
export default {
  name: 'BaseButtonSchemaSelect'
}
</script>

<style lang="scss" scoped>
button {
  width: 130px;
  height: 33px;
  padding: 0;
  border-radius: 6px;
  border: solid 2px #{$light-grey};
  background-color: #{$white};
  cursor: pointer;
  position: relative;
  &:hover {
    opacity: 0.8;
  }
  > .text {
    text-align: left;
    padding-left: 3px;
    font-size: 10px;
    color: #{$light-grey};
  }
  > img {
    position: absolute;
    right: 3px;
    top: 7px;
  }
}
</style>
