import {
  commonGetters,
  commonActions,
  commonMutations
} from '@/store/store_utils'

const dataName = 'schemaImages'

const state = {
  uploadImages: [],
  dataIndexedById: {},
  updatedAt: '20101010101010'
}

const getters = {
  ...commonGetters(dataName, ['getData', 'getDataById'], { filterDel: true })
}

const actions = {
  ...commonActions(['setData', 'setUpdatedAt', 'updateAfter'])
}

const mutations = {
  ...commonMutations(dataName, ['setData', 'setUpdatedAt', 'updateAfter'], {
    indexedById: true
  })
}

export default {
  namespaced: true,
  state,
  mutations,
  getters,
  actions
}
