export default {
  methods: {
    mixinCalculateBright(color) {
      const r = parseInt(color.substr(1, 2), 16)
      const g = parseInt(color.substr(3, 2), 16)
      const b = parseInt(color.substr(5, 2), 16)
      const brightness = (r * 299 + g * 587 + b * 114) / 1000
      return brightness
    },
    mixinAdaptTextColor(color) {
      const brightness = this.mixinCalculateBright(color)
      const black = '#3e3e3e'
      const white = '#ffffff'
      return brightness > 154 ? black : white
    }
  }
}
