import { getAxiosObject } from '@/utils/library'
import {
  commonGetters,
  commonMutations,
  commonActions,
  updateUploadImages
} from '@/store/store_utils'
import { toStateName } from '@/utils/convert_string'

const dataName = 'medicalContents'
const keys = ['medicalRecordOriginalId', 'patientId', 'originalId']

const state = {
  medicalContents: [],
  dataIndexedByOriginalId: {},
  updatedAt: '20101010101010'
}
keys.forEach(key => {
  state[toStateName(key)] = null
})

const getters = {
  ...commonGetters(
    dataName,
    ['getData', 'getDataByOriginalId', 'getUpdatedAt'],
    {
      filterDel: true,
      getDataBy: ['medicalRecordOriginalId', 'patientId'],
      getDataByIncludeDel: ['patientId', 'originalId']
    }
  )
}

const actions = {
  ...commonActions(['setData', 'setUpdatedAt', 'updateAfter']),
  async create(
    { commit, rootGetters },
    {
      medicalRecord,
      medicalContent,
      contentJsonObj,
      examinationResultGroups,
      resizedUploadImages,
      resizedMedicalContentImages,
      deleteExaminationResults,
      medicalPayment,
      medicalTreatmentItems,
      anicomReport,
      mailFlg
    }
  ) {
    try {
      const axiosObject = getAxiosObject()
      const res = await axiosObject.post('/medical-contents', {
        medicalRecord,
        medicalContent,
        contentJsonObj,
        examinationResultGroups,
        resizedUploadImages,
        resizedMedicalContentImages,
        deleteExaminationResults,
        medicalPayment,
        medicalTreatmentItems,
        anicomReport,
        mailFlg
      })
      if (res.status === 200) {
        commit('updateAfter', [res.data.medicalContent])
        commit('medicalRecords/updateAfter', res.data.medicalRecords, {
          root: true
        })
        if (Object.keys(res.data.reservation).length > 0) {
          commit('reservations/updateAfter', [res.data.reservation], {
            root: true
          })
        }
        if (res.data.examinationResults.length > 0) {
          commit(
            'examinationResults/updateAfter',
            res.data.examinationResults,
            { root: true }
          )
        }
        if (res.data.uploadImages.length > 0) {
          updateUploadImages(commit, rootGetters, res.data.uploadImages)
        }
        if (res.data.medicalContentImages.length > 0) {
          const actions = [
            'setKarteMedicalContentImages',
            'updateAfter',
            'resetResizedMedicalContentImages'
          ]
          actions.forEach(a => {
            commit(`medicalContentImages/${a}`, res.data.medicalContentImages, {
              root: true
            })
          })
        }
        if (res.data.sendMail) {
          commit('sendMails/updateAfter', [res.data.sendMail], {
            root: true
          })
        }
        if (res.data.medicalPayments.length > 0) {
          commit('medicalPayments/updateAfter', res.data.medicalPayments, {
            root: true
          })
        }
        if (res.data.medicalTreatmentItems.length > 0) {
          commit(
            'medicalTreatmentItems/updateAfter',
            res.data.medicalTreatmentItems,
            { root: true }
          )
        }
        if (res.data.patientVaccines.length > 0) {
          commit('patientVaccines/updateAfter', res.data.patientVaccines, {
            root: true
          })
        }
        if (res.data.antibodies.length > 0) {
          commit('antibodies/updateAfter', res.data.antibodies, { root: true })
        }
        if (res.data.antibodyTypes.length > 0) {
          commit('antibodyTypes/updateAfter', res.data.antibodyTypes, {
            root: true
          })
        }
        if (res.data.hospitalization) {
          commit('hospitalizations/updateAfter', [res.data.hospitalization], {
            root: true
          })
        }
        return {
          result: true,
          medicalRecord:
            res.data.medicalRecords.length > 1
              ? res.data.medicalRecords[1]
              : res.data.medicalRecords[0],
          medicalContent: res.data.medicalContent,
          reservation: res.data.reservation,
          examinationResults: res.data.examinationResults,
          sendMailFlg: Boolean(res.data.sendMail),
          medicalPayment:
            res.data.medicalPayments.length > 0
              ? res.data.medicalPayments[res.data.medicalPayments.length - 1]
              : null,
          medicalTreatmentItems: res.data.medicalTreatmentItems.filter(
            v => v.medicalPaymentId === res.data.medicalPayments[1].id
          )
        }
      }
    } catch (error) {
      if (error.response?.data?.message === 'no data') {
        return `no ${error.response.data.extra}`
      } else if (error.response?.data?.message === 'no data in clinic') {
        return `no ${error.response.data.extra.dataName}`
      } else if (error.response?.data?.message === 'invalid data') {
        return error.response?.data?.extra
      } else if (error.response?.data?.message) {
        return error.response.data.message
      } else {
        return 'server error'
      }
    }
  },
  async update(
    { commit, rootGetters },
    {
      medicalRecord,
      medicalContent,
      contentJsonObj,
      examinationResultGroups,
      resizedUploadImages,
      resizedMedicalContentImages,
      deleteExaminationResults,
      medicalPayment,
      medicalTreatmentItems,
      anicomReport,
      mailFlg
    }
  ) {
    try {
      const axiosObject = getAxiosObject()
      const res = await axiosObject.put('/medical-contents', {
        medicalRecord,
        medicalContent,
        contentJsonObj,
        examinationResultGroups,
        resizedUploadImages,
        resizedMedicalContentImages,
        deleteExaminationResults,
        medicalPayment,
        medicalTreatmentItems,
        anicomReport,
        mailFlg
      })
      if (res.status === 200) {
        commit('medicalRecords/updateAfter', res.data.medicalRecords, {
          root: true
        })
        commit('updateAfter', res.data.medicalContents)
        if (Object.keys(res.data.reservation).length > 0) {
          commit('reservations/updateAfter', [res.data.reservation], {
            root: true
          })
        }
        if (res.data.examinationResults.length > 0) {
          commit(
            'examinationResults/updateAfter',
            res.data.examinationResults,
            { root: true }
          )
        }
        if (res.data.uploadImages.length > 0) {
          updateUploadImages(commit, rootGetters, res.data.uploadImages)
        }
        if (res.data.medicalContentImages.length > 0) {
          const actions = [
            'setKarteMedicalContentImages',
            'updateAfter',
            'resetResizedMedicalContentImages'
          ]
          actions.forEach(a => {
            commit(`medicalContentImages/${a}`, res.data.medicalContentImages, {
              root: true
            })
          })
        }
        if (res.data.sendMail) {
          commit('sendMails/updateAfter', [res.data.sendMail], {
            root: true
          })
        }
        if (res.data.medicalPayments.length > 0) {
          commit('medicalPayments/updateAfter', res.data.medicalPayments, {
            root: true
          })
        }
        if (res.data.medicalTreatmentItems.length > 0) {
          commit(
            'medicalTreatmentItems/updateAfter',
            res.data.medicalTreatmentItems,
            { root: true }
          )
        }
        if (res.data.patientVaccines.length > 0) {
          commit('patientVaccines/updateAfter', res.data.patientVaccines, {
            root: true
          })
        }
        if (res.data.antibodies.length > 0) {
          commit('antibodies/updateAfter', res.data.antibodies, { root: true })
        }
        if (res.data.antibodyTypes.length > 0) {
          commit('antibodyTypes/updateAfter', res.data.antibodyTypes, {
            root: true
          })
        }
        if (res.data.hospitalization) {
          commit('hospitalizations/updateAfter', [res.data.hospitalization], {
            root: true
          })
        }
        return {
          result: true,
          medicalRecord: res.data.medicalRecords[1],
          medicalContent: res.data.medicalContents[1],
          reservation: res.data.reservation,
          examinationResults: res.data.examinationResults.filter(
            v => v.delFlg === 0
          ),
          sendMailFlg: Boolean(res.data.sendMail),
          medicalPayment:
            res.data.medicalPayments.length > 0
              ? res.data.medicalPayments[res.data.medicalPayments.length - 1]
              : null,
          medicalTreatmentItems: res.data.medicalTreatmentItems.filter(
            v => v.medicalPaymentId === res.data.medicalPayments[1].id
          )
        }
      }
    } catch (error) {
      if (error.response?.data?.message === 'no data') {
        return error.response.data.extra === 'reservation' ||
          error.response.data.extra === 'patient'
          ? `no ${error.response.data.extra}`
          : `no ${error.response.data.extra.dataName}`
      } else if (error.response?.data?.message === 'no data in clinic') {
        return `no ${error.response.data.extra.dataName}`
      } else if (error.response?.data?.message === 'invalid data') {
        return error.response?.data?.extra
      } else if (error.response?.data?.message) {
        return error.response.data.message
      } else {
        return 'server error'
      }
    }
  },
  async delete({ commit, rootGetters }, id) {
    try {
      const axiosObject = getAxiosObject()
      const res = await axiosObject.delete('/medical-contents', {
        data: { id }
      })
      if (res.status === 200) {
        commit('updateAfter', [res.data.medicalContent])
        if (Object.keys(res.data.medicalRecord).length > 0) {
          commit('medicalRecords/updateAfter', [res.data.medicalRecord], {
            root: true
          })
          if (
            rootGetters['medicalRecords/getOriginalIdSetInTab'] ===
            res.data.medicalRecord.originalId
          )
            commit('medicalRecords/resetOriginalIdSetInTab', null, {
              root: true
            })
        }
        if (Object.keys(res.data.reservation).length > 0) {
          commit('reservations/updateAfter', [res.data.reservation], {
            root: true
          })
        }
        if (res.data.examinationResults.length > 0) {
          commit(
            'examinationResults/updateAfter',
            res.data.examinationResults,
            { root: true }
          )
        }
        if (res.data.uploadImages.length > 0) {
          commit('uploadImages/updateAfter', res.data.uploadImages, {
            root: true
          })
        }
        if (res.data.medicalContentImages.length > 0) {
          commit(
            'medicalContentImages/updateAfter',
            res.data.medicalContentImages,
            { root: true }
          )
        }
        return {
          result: true,
          deleteMedicalContentId: res.data.medicalContent.id
        }
      }
    } catch (error) {
      if (error.response?.data?.extra?.data) {
        commit('setData', error.response.data.extra.data)
        return error.response.data.message
      } else {
        return 'server error'
      }
    }
  },
  async search(
    { commit },
    {
      detailSearchFlg,
      trimSearchText,
      period,
      trimSearchConditions,
      page,
      order,
      deleteCounts
    }
  ) {
    try {
      const axiosObject = getAxiosObject()
      const searchValues = {
        detailSearchFlg,
        trimSearchText,
        period,
        trimSearchConditions,
        page,
        order,
        deleteCounts
      }
      const res = await axiosObject.get('/medical-contents/search', {
        params: { searchValues }
      })
      if (res.status === 200) {
        if (res.data.medicalContents.length > 0) {
          commit('updateAfter', res.data.medicalContents)
        }
        if (res.data.medicalRecords.length > 0) {
          commit('medicalRecords/updateAfter', res.data.medicalRecords, {
            root: true
          })
        }
        return {
          result: true,
          medicalContents: res.data.medicalContents,
          hitAllDataCounts: res.data.hitAllDataCounts
        }
      }
    } catch (error) {
      return { result: false }
    }
  }
}

const mutations = {
  ...commonMutations(dataName, ['setData', 'setUpdatedAt', 'updateAfter'], {
    indexedByOriginalId: true,
    setDataBy: keys
  })
}

export default {
  namespaced: true,
  state,
  getters,
  actions,
  mutations
}
