<template>
  <div class="base-button-medium-white">
    <button
      type="button"
      :disabled="disabled"
      @click="handleClick"
      data-e2e="base-button-medium-white"
    >
      <span><slot /> </span>
    </button>
  </div>
</template>

<script>
export default {
  name: 'BaseButtonMediumWhite',

  props: {
    disabled: {
      type: Boolean,
      default: false
    }
  },

  methods: {
    handleClick() {
      this.$emit('click')
    }
  }
}
</script>

<style lang="scss" scoped>
.base-button-medium-white {
  > button {
    height: 44px;
    width: 200px;
    border-radius: 6px;
    border: solid 2px #{$light-grey};
    background-color: #{$white};
    cursor: pointer;
    &:disabled {
      pointer-events: none;
      background-color: #{$white_f7};
      color: #{$light-grey};
      border: none;
    }
    &:hover {
      opacity: 0.8;
    }
    &:active {
      margin: 2px auto 0 auto;
      height: 42px;
    }
    > span {
      height: 29px;
      font-size: 15px;
      font-weight: bold;
      line-height: 1.45;
    }
  }
}
</style>
