<template>
  <div id="app">
    <div id="all-screen-wrapper">
      <div ref="clinicVersion" hidden>clinicVersion:{{ version }}</div>
      <div ref="backVersion" hidden>backVersion:{{ backVersion }}</div>
      <div id="main-view">
        <router-view />
      </div>
      <announce-popup
        v-if="clinicVersionUpFlg"
        :type="'alert'"
        :title="'画面の再読み込みのお願い'"
        :buttons="['閉じる', '再読み込み']"
        :layerNumber="3"
        @cancel="setClinicVersionUpFlgFalse"
        @decision="reload"
        >{{ popupMessage }}</announce-popup
      >
      <announce-popup
        v-if="globalPopup.show"
        :type="globalPopup.type"
        :title="globalPopup.title"
        :buttons="globalPopup.buttons"
        :layerNumber="globalPopup.layerNumber"
        @close="globalPopup.close"
        @cancel="globalPopup.cancel"
        @decision="globalPopup.decision"
        >{{ globalPopup.message }}</announce-popup
      >
      <announce-popup
        v-if="resizeFlg"
        :type="'alert'"
        :title="'サイズ・ズーム変更のお願い'"
        :buttons="['OK']"
        @close="resizeFalse"
      >
        {{ zoomMessage }}
      </announce-popup>
      <karte-lock-popup></karte-lock-popup>
      <logout-popup></logout-popup>
    </div>
  </div>
</template>
<script>
import 'normalize.css'
import 'vue-virtual-scroller/dist/vue-virtual-scroller.css'
import AnnouncePopup from '@/components/popups/AnnouncePopup'
import KarteLockPopup from '@/components/popups/KarteLockPopup'
import LogoutPopup from '@/components/popups/LogoutPopup'
import moment from 'moment'
import { getDevice } from '@/utils/get_device'
import { getAxiosObject } from '@/utils/library'
moment.locale('ja')

export default {
  head: {
    meta: function() {
      return [{ name: 'robots', content: 'noindex' }]
    }
  },
  name: 'App',
  components: {
    AnnouncePopup,
    KarteLockPopup,
    LogoutPopup
  },
  data() {
    return {
      version: process.env.VERSION,
      popupMessage:
        'いつもPETORELU+をご利用いただきありがとうございます。\nPETORELU+のアップデートに伴い、現在の操作が中断されました。\nお手数ですが、画面の再読み込みをお願い致します。',
      resizeFlg: true,
      zoomMessage:
        'ブラウザのサイズやズームの変更をお願いします。\n現在の設定では快適な閲覧・操作に支障がある可能性があります。',
      id: null,
      firstFlg: true
    }
  },
  watch: {
    loginStaff(loginStaff) {
      if (loginStaff.delFlg === 1) {
        this.$router.push({ name: 'login' })
      } else if (loginStaff && this.firstFlg) {
        //同時にログインするとログイン数を正確に数えられないことが有るため、遅れてもう一度チェックする
        this.firstFlg = false
        window.setTimeout(this.checkLoginCount, 1 * 1000)
      }
    },
    tokenIssuingTime(time) {
      if (time !== '20101010101010') {
        window.setTimeout(() => {
          window.addEventListener('click', this.refreshToken)
        }, 30 * 60 * 1000)

        clearTimeout(this.id)
        this.id = window.setTimeout(() => {
          window.removeEventListener('click', this.refreshToken)
          this.logoutAnnounce(
            'ログインの有効期限が切れました。\n再度ログインし直して下さい。'
          )
        }, process.env.VUE_APP_EXPIRE_IN * 60 * 60 * 1000)
      }
    }
  },
  computed: {
    backVersion() {
      return this.$store.getters['auth/backVersion']
    },
    clinicVersionUpFlg() {
      return this.$store.getters['petorelu/get'].clinicVersionUpFlg
    },
    loginStaff() {
      const loginStaffId = this.$store.getters['auth/loginStaffId']
      return this.$store.getters['staffs/getDataById'](loginStaffId)
    },
    globalPopup() {
      return this.$store.getters['petorelu/get'].globalPopup
    },
    tokenIssuingTime() {
      return this.$store.getters['auth/tokenIssuingTime']
    }
  },
  created() {
    this.resizeFlg = !this.checkBrowserSize()
  },
  methods: {
    async checkLoginCount() {
      const axiosObject = getAxiosObject()
      const localKey = localStorage.getItem('localKey')
      const res = await axiosObject.post('/login-count', {
        localKey
      })
      if (!res.data || res.data.limitCheck !== true) {
        this.logoutAnnounce('同時接続数の制限によりログアウトします。')
      }
    },
    async refreshToken() {
      window.removeEventListener('click', this.refreshToken)
      const result = await this.$store.dispatch('auth/refreshToken')
      if (result === 'failure') {
        this.$store.dispatch('petorelu/showGlobalPopup', {
          type: 'failure',
          title: '失敗',
          buttons: ['閉じる'],
          message: '通信エラーが発生しました。',
          close: () => this.$store.dispatch('petorelu/hideGlobalPopup')
        })
      }
    },
    logoutAnnounce(message) {
      this.$store.dispatch('petorelu/showGlobalPopup', {
        type: 'alert',
        title: '警告',
        buttons: ['ログイン画面へ'],
        message,
        close: () => {
          this.$store.dispatch('petorelu/okLeave')
          this.$store.dispatch('petorelu/hideGlobalPopup')
          this.$router.push('/login')
          clearTimeout(this.id)
        }
      })
    },
    setClinicVersionUpFlgFalse() {
      this.$store.dispatch('petorelu/setClinicVersionUpFlgToFalse')
    },
    reload() {
      location.reload()
    },
    checkBrowserSize() {
      if (getDevice() === 'pc') {
        const browserWidth = window?.innerWidth
        const browserHeight = window?.innerHeight
        if (browserHeight && browserWidth) {
          return browserWidth >= 1580 && browserHeight >= 700
        } else {
          return true
        }
      } else {
        return true
      }
    },
    resizeFalse() {
      this.resizeFlg = false
    }
  }
}
</script>

<style lang="scss">
html {
  height: 100%;
}
body {
  height: 100%;
}
#app {
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
  font-family: 'Noto Sans JP', sans-serif;
  font-size: 18px;
  font-weight: normal;
  font-stretch: normal;
  font-style: normal;
  line-height: 1.47;
  letter-spacing: normal;
  text-align: center;
  color: #{$greyish-brown};
  height: 100%;
  position: relative;
  > #all-screen-wrapper {
    width: 100%;
    height: 100%;
    > #main-view {
      width: 100%;
      height: 100%;
      margin: auto;
    }
  }
}
a {
  text-decoration: none;
  color: #{$greyish-brown};
}
</style>
