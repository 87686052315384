<template>
  <div class="multiple-text-input-form">
    <div class="heading">
      <div class="heading-title">
        <div class="text"><slot></slot></div>
        <div class="required" v-if="requiredFlg">必須</div>
      </div>
      <slot name="title-supplement" />
    </div>
    <div class="body">
      <validation-provider
        class="validate"
        :rules="{
          ...validationRules,
          requiredRule: requiredFlg,
          notSurrogatePair: true
        }"
        v-slot="{ errors }"
      >
        <base-multiple-lines-text-box
          :maxlength="maxlength"
          :placeholder="placeholder"
          :styles="styles"
          v-model="input"
        />
        <div class="error" data-test="err">{{ errors[0] }}</div>
      </validation-provider>
    </div>
  </div>
</template>

<script>
import BaseMultipleLinesTextBox from '@/components/parts/atoms/BaseMultipleLinesTextBox'
import { ValidationProvider } from 'vee-validate'
import '@/utils/validation_rules'

export default {
  name: 'MultipleTextInputForm',

  components: {
    BaseMultipleLinesTextBox,
    ValidationProvider
  },

  props: {
    requiredFlg: { type: Boolean, default: false },
    validationRules: { type: Object },
    maxlength: { type: Number },
    placeholder: { type: String, default: '' },
    styles: { height: { type: String }, width: { type: String } },
    value: { type: [String, Number], default: '' }
  },

  computed: {
    input: {
      get() {
        return this.value
      },
      set(val) {
        this.$emit('input', val)
      }
    }
  }
}
</script>

<style lang="scss" scoped>
.multiple-text-input-form {
  display: flex;
  align-items: flex-start;
  > .heading {
    width: 40%;
    > .heading-title {
      display: flex;
      align-items: center;
      height: 33px;
      font-size: 15px;
      > .required {
        margin-left: 10px;
        font-size: 13px;
        color: #{$tomato};
      }
    }
  }
  > .body {
    width: 60%;
    @include validate-message();
  }
}
</style>
