<template>
  <div
    :class="{
      'medical-content-image-text-box': true,
      'no-event': canvasMode !== 'text',
      'text-move': isMove
    }"
    :style="overallStyle"
    data-test="medical-content-image-text-box"
  >
    <div class="content">
      <div
        class="icon"
        v-if="canvasMode === 'text'"
        @pointerdown="moveText"
        data-test="move"
      >
        <font-awesome-icon class="arrow" :icon="arrowIcon" />
      </div>
      <div
        class="icon"
        v-if="canvasMode === 'text'"
        @click="trashText"
        data-test="trash"
      >
        <font-awesome-icon class="trash" :icon="trashIcon" />
      </div>
      <div
        :style="textBoxStyle"
        contenteditable="true"
        spellcheck="false"
        @input="inputText"
        @focus="$emit('focus')"
        @blur="blurTextBox"
        data-test="text-box"
      >
        {{ innerText }}
      </div>
    </div>
  </div>
</template>

<script>
import { FontAwesomeIcon } from '@fortawesome/vue-fontawesome'
import { faTrashAlt, faArrowsAlt } from '@fortawesome/free-solid-svg-icons'
export default {
  name: 'MedicalContentImageTextBox',

  components: {
    FontAwesomeIcon
  },

  props: {
    keyId: { type: Number, default: 0 },
    text: { type: String, default: '' },
    top: { type: Number, default: 0 },
    left: { type: Number, default: 0 },
    color: { type: String, default: '#000000' },
    size: { type: Number, default: 15 },
    canvasMode: { type: String, default: 'pen' },
    isMove: { type: Boolean, default: false },
    canvasWidth: { type: Number, default: 400 },
    canvasHeight: { type: Number, default: 400 }
  },

  data() {
    return {
      innerText: '',
      arrowIcon: faArrowsAlt,
      trashIcon: faTrashAlt
    }
  },

  computed: {
    overallStyle() {
      const border =
        this.canvasMode === 'text'
          ? '1px dashed #cecece'
          : 'transparent 1px solid'
      return {
        position: 'absolute',
        top: `${this.top}px`,
        left: `${this.left}px`,
        color: this.color,
        'font-size': `${this.size}px`,
        border,
        padding: '5px 3px',
        'box-sizing': 'content-box'
      }
    },
    textBoxStyle() {
      const widthSpace = 14
      const heightSpace = 12
      return {
        'max-width': this.canvasWidth - this.left - widthSpace + 'px',
        'max-height': this.canvasHeight - this.top - heightSpace + 'px',
        'min-width': '27px',
        overflow: 'hidden',
        'text-align': 'left',
        padding: '0 2px'
      }
    }
  },

  mounted() {
    this.innerText = this.text
  },

  methods: {
    moveText(e) {
      if (e.pointerType === 'mouse' && e.button !== 0) return
      this.$emit('move', e, this.keyId)
    },
    trashText() {
      this.$emit('trash', this.keyId)
    },
    inputText(e) {
      this.$emit('input', e.target.innerText)
    },
    blurTextBox(e) {
      this.$emit('blur', e, this.keyId)
    }
  }
}
</script>

<style lang="scss" scoped>
.medical-content-image-text-box {
  background-color: rgba(0, 0, 0, 0);
  position: absolute;
  top: 0px;
  left: 0px;
  > .content {
    > .icon {
      position: relative;
      font-size: 15px;
      color: #{$dark-grey};
      > .arrow {
        position: absolute;
        cursor: move;
        top: -22px;
        left: -7px;
        &:hover {
          opacity: 0.8;
        }
      }
      > .trash {
        position: absolute;
        @include hover();
        top: -22px;
        left: 20px;
      }
    }
  }
}
.no-event {
  pointer-events: none;
}
.text-move {
  cursor: move;
}
</style>
