import { getAxiosObject } from '@/utils/library'
import {
  commonGetters,
  commonActions,
  commonMutations
} from '@/store/store_utils'
const dataName = 'reservationRequests'

const state = {
  reservationRequests: [],
  dataIndexedById: {},
  updatedAt: '20101010101010',
  count: 0
}

const getters = {
  ...commonGetters(
    dataName,
    ['getData', 'getDataById', 'getUpdatedAt', 'getCount'],
    { filterDel: true }
  )
}

const actions = {
  ...commonActions(['setData', 'setUpdatedAt', 'updateAfter', 'setCount']),
  async readAll({ commit }, readIds) {
    try {
      const axiosObject = getAxiosObject()
      const res = await axiosObject.put('/reservation-requests/all', {
        readIds
      })
      if (res.status === 200) {
        commit('setData', res.data[dataName])
        return true
      }
    } catch (error) {
      return false
    }
  },
  async read({ commit }, id) {
    try {
      const axiosObject = getAxiosObject()
      const res = await axiosObject.put('/reservation-requests', { id })
      if (res.status === 200) {
        commit('readReservationRequest', res.data.id)
        return true
      }
    } catch (error) {
      if (error.response?.data?.message) {
        if (error.response.data.extra?.data) {
          commit('setData', error.response.data.extra.data)
        }
        return error.response.data.message
      } else {
        return 'server error'
      }
    }
  },
  async deleteReservationRequest({ commit }, id) {
    try {
      const axiosObject = getAxiosObject()
      const res = await axiosObject.delete('/reservation-requests', {
        data: { id }
      })
      if (res.status === 200) {
        commit('updateAfter', [res.data.reservationRequest])
        if (res.data.sendMail) {
          commit('sendMails/updateAfter', [res.data.sendMail], {
            root: true
          })
        }
        commit('setCount', res.data.reservationRequestsCount)
        return { result: 'success', sendMailFlg: Boolean(res.data.sendMail) }
      }
    } catch (error) {
      if (error.response?.data?.extra?.data) {
        commit('setData', error.response.data.extra.data)
        return error.response.data.message
      } else {
        return 'server error'
      }
    }
  },
  async search(
    { commit },
    { startDate, endDate, sortType, order, filter, page }
  ) {
    try {
      const axiosObject = getAxiosObject()
      const res = await axiosObject.get('/reservation-requests', {
        params: { startDate, endDate, sortType, order, filter, page }
      })
      commit('updateAfterSearch', res.data.reservationRequests)
      commit('setCount', res.data.reservationRequestsCount)
      return {
        result: 'success',
        hitAllDataCounts: res.data.hitAllDataCounts,
        reservationRequests: res.data.reservationRequests
      }
    } catch (error) {
      return { result: 'failure' }
    }
  }
}

const mutations = {
  ...commonMutations(
    dataName,
    ['setData', 'setUpdatedAt', 'updateAfter', 'updateAfterSearch', 'setCount'],
    { indexedById: true }
  ),
  readReservationRequest(state, reservationRequestId) {
    const target = state[dataName].find(r => r.id === reservationRequestId)
    target.readFlg = 1
  }
}
export default {
  namespaced: true,
  state,
  mutations,
  getters,
  actions
}
