<template>
  <focus-trap :escapeDeactivates="false" :clickDeactivates="false">
    <div class="schema-popup" @keyup.esc.self.stop="closePopup" tabindex="-1">
      <base-loading :waitFlg="waitFlg" />
      <validation-observer class="validation-observer" v-slot="{ invalid }">
        <popup
          :invalid="invalid || lookOnlyFlg === 1"
          :disabledFlg="waitFlg"
          @click-close-mark="closePopup"
          @decision="update"
          @cancel="closePopup"
        >
          <template v-slot:content>
            <div class="content">
              <div class="header">
                <div class="left">
                  <div class="title">シェーマ</div>
                  <div class="buttons">
                    <base-button-schema-header
                      :text="'undo'"
                      :disabled="undoDataStack.length === 0"
                      @click="undo"
                    />
                    <base-button-schema-header
                      class="button-margin"
                      :text="'redo'"
                      :disabled="redoDataStack.length === 0"
                      @click="redo"
                    />
                    <base-button-schema-header
                      :text="'clear'"
                      :disabled="!clearAble"
                      @click="clear"
                    />
                  </div>
                </div>
                <medical-record-image-uploader
                  class="image-uploader"
                  :styles="{ width: '390px', height: '70px' }"
                  @input="insertUnderImageFromImageUploader"
                />
              </div>
              <div class="input-form">
                <div class="left">
                  <div class="image-area" ref="imageArea">
                    <img
                      class="under-image"
                      :src="medicalContentImage.underImage"
                    />
                    <canvas
                      ref="schemaCanvas"
                      width="400px"
                      height="400px"
                      :style="{
                        position: 'absolute',
                        top: '0px',
                        left: '0px',
                        border: '1px solid #cecece',
                        width: '400px',
                        height: '400px',
                        'box-sizing': 'border-box'
                      }"
                      :class="{
                        eraser: canvasMode === 'eraser',
                        text: canvasMode === 'text' && !isMove
                      }"
                      @pointerdown="drawStart"
                      @pointermove="draw"
                      @pointerup="drawEnd"
                      @click="insertTextBox"
                      data-test="canvas"
                    />
                    <medical-content-image-text-box
                      v-for="(textObj,
                      index) in medicalContentImage.texts.filter(
                        t => t.delFlg === 0
                      )"
                      :key="textObj.key"
                      :keyId="textObj.key"
                      :text="textObj.text"
                      :top="textObj.top"
                      :left="textObj.left"
                      :color="textObj.color"
                      :size="textObj.size"
                      :canvasMode="canvasMode"
                      :isMove="isMove"
                      :canvasWidth="canvas.width"
                      :canvasHeight="canvas.height"
                      v-model="textObj.text"
                      @move="moveText"
                      @trash="deleteText"
                      @blur="focusoutTextBox"
                      @focus="focusTextIndex = index"
                      data-target="textBox"
                    />
                  </div>
                </div>
                <div class="right">
                  <div class="right-top">
                    <div class="buttons">
                      <base-button-schema-select
                        class="left-button"
                        @click="schemaImageSelectPopupFlg = true"
                      />
                      <base-button-schema-icon
                        class="trash-button"
                        :styles="{ width: '49px', height: '33px' }"
                        :icon="'trash'"
                        @click="ejectUnderImage"
                      />
                      <base-select-box
                        class="left-button"
                        :styles="{ width: '86px', height: '33px' }"
                        :selectData="lineWidths"
                        v-model="lineWidth"
                        @input="changeFontSize"
                      />
                      <base-button-schema-icon
                        class="right-button"
                        :text="'Text'"
                        :active="canvasMode === 'text'"
                        @click="canvasMode = 'text'"
                      />
                      <base-button-schema-icon
                        class="left-button"
                        :icon="'pen'"
                        :active="canvasMode === 'pen'"
                        @click="canvasMode = 'pen'"
                      />
                      <base-button-schema-icon
                        class="right-button"
                        :icon="'eraser'"
                        :active="canvasMode === 'eraser'"
                        @click="canvasMode = 'eraser'"
                      />
                      <base-button-schema-icon
                        class="left-button"
                        :icon="'line'"
                        :active="canvasMode === 'line'"
                        @click="canvasMode = 'line'"
                      />
                      <base-button-schema-icon
                        class="right-button"
                        :icon="'circle'"
                        :active="canvasMode === 'circle'"
                        @click="canvasMode = 'circle'"
                      />
                      <base-button-schema-icon
                        class="left-button"
                        :icon="'arrow'"
                        :active="canvasMode === 'arrow'"
                        @click="canvasMode = 'arrow'"
                      />
                      <base-button-schema-icon
                        class="right-button"
                        :icon="'palette'"
                        @click="colorChartFlg = !colorChartFlg"
                      />
                    </div>
                    <base-color-chart
                      v-if="colorChartFlg"
                      class="color-chart"
                      :value="strokeStyle"
                      :colorSets="schemaPopupColorSets"
                      @click="changeColor"
                    />
                    <div
                      v-if="colorChartFlg"
                      class="cover"
                      @click="colorChartFlg = false"
                      data-test="cover"
                    ></div>
                  </div>
                  <div class="right-bottom">
                    <div class="title">
                      <validation-provider
                        :rules="{ notSurrogatePair: true }"
                        v-slot="{ errors }"
                      >
                        <base-text-box
                          v-model="medicalContentImage.title"
                          :placeholder="'タイトル'"
                          :maxlength="30"
                          :styles="{ width: '173px', height: '33px' }"
                        />
                        <div class="error">{{ errors[0] }}</div>
                      </validation-provider>
                      <base-button-icon
                        class="calendar-button"
                        :text="'+'"
                        :img="'calendar.png'"
                        @click="inputDate"
                      />
                    </div>
                    <div class="tag">
                      <validation-provider
                        :rules="{ notSurrogatePair: true }"
                        v-slot="{ errors }"
                      >
                        <base-data-list
                          v-model="newTag"
                          :placeholder="'# タグ'"
                          :maxlength="15"
                          :styles="{ width: '173px', height: '33px' }"
                          :selectData="imageTags"
                          @decide="setNewTag"
                        />
                        <div class="error">{{ errors[0] }}</div>
                      </validation-provider>
                    </div>
                    <div class="tags">
                      <div
                        class="tag"
                        v-for="(tagName, i) in medicalContentImage.tagNames"
                        :key="i"
                      >
                        {{ tagName
                        }}<span
                          class="delete-button"
                          @click="
                            deleteTag(
                              tagName,
                              medicalContentImage.imageTagsIds[i]
                            )
                          "
                          data-test="delete-button"
                        >
                          ×
                        </span>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </template>
        </popup>
        <announce-popup
          v-if="popup.popupFlg"
          v-bind="popup"
          @close="popup.popupFlg = false"
          @cancel="popup.popupFlg = false"
          @decision="$emit('close')"
          >{{ popup.content }}</announce-popup
        >
        <schema-image-select-popup
          v-if="schemaImageSelectPopupFlg"
          :patientId="patientId"
          @decide="insertUnderImageFromSchemaPopup"
          @close="schemaImageSelectPopupFlg = false"
        />
      </validation-observer>
    </div>
  </focus-trap>
</template>

<script>
import Popup from '@/components/popups/Popup'
import BaseLoading from '@/components/parts/atoms/BaseLoading'
import BaseButtonSchemaHeader from '@/components/parts/atoms/BaseButtonSchemaHeader'
import MedicalRecordImageUploader from '@/components/parts/atoms/MedicalRecordImageUploader'
import MedicalContentImageTextBox from '@/components/parts/molecules/MedicalContentImageTextBox'
import BaseButtonSchemaSelect from '@/components/parts/atoms/BaseButtonSchemaSelect'
import BaseButtonSchemaIcon from '@/components/parts/atoms/BaseButtonSchemaIcon'
import BaseSelectBox from '@/components/parts/atoms/BaseSelectBox'
import BaseColorChart from '@/components/parts/atoms/BaseColorChart'
import BaseTextBox from '@/components/parts/atoms/BaseTextBox'
import BaseButtonIcon from '@/components/parts/atoms/BaseButtonIcon'
import BaseDataList from '@/components/parts/atoms/BaseDataList'
import { schemaPopupColorSets } from '@/utils/color_library'
import AnnouncePopup from '@/components/popups/AnnouncePopup'
import SchemaImageSelectPopup from '@/components/popups/SchemaImageSelectPopup'
import CheckPopupInputDifference from '@/components/mixins/CheckPopupInputDifference'
import { FocusTrap } from 'focus-trap-vue'
import { ValidationProvider, ValidationObserver } from 'vee-validate'
import { mapGetters } from 'vuex'
import moment from 'moment'
import _ from 'lodash'
import { VALID_SURROGATE_PAIR_REGEX } from '@/utils/define'
import { getAxiosObject } from '@/utils/library'
import '@/utils/validation_rules'

export default {
  name: 'SchemaPopup',

  components: {
    Popup,
    BaseLoading,
    BaseButtonSchemaHeader,
    MedicalRecordImageUploader,
    MedicalContentImageTextBox,
    BaseButtonSchemaSelect,
    BaseButtonSchemaIcon,
    BaseSelectBox,
    BaseColorChart,
    BaseTextBox,
    BaseButtonIcon,
    BaseDataList,
    AnnouncePopup,
    SchemaImageSelectPopup,
    FocusTrap,
    ValidationProvider,
    ValidationObserver
  },

  mixins: [CheckPopupInputDifference],

  props: {
    patientId: { type: Number, default: 0 },
    medicalContentImageId: { type: Number, default: 0 }
  },

  data() {
    return {
      medicalContentImage: {
        medicalContentOriginalId: 0,
        title: '',
        imageTagsIds: [],
        tagNames: [],
        texts: [],
        underImage: null,
        overImage: null,
        underImageInfo: {},
        schemaImageId: 0,
        uploadImageId: 0,
        widthInKarte: 400,
        heightInKarte: 400
      },
      textKey: 1,
      focusTextIndex: null,
      initialData: {},
      initialRGBASummary: 0,
      currentRGBASummary: 0,
      canvas: null,
      context: null,
      isDrag: false,
      isMove: false,
      canvasMode: 'pen',
      undoDataStack: [],
      redoDataStack: [],
      clearAble: false,
      startX: null,
      startY: null,
      strokeStyle: '#000000',
      schemaPopupColorSets: schemaPopupColorSets,
      lineWidth: 3,
      newTag: '',
      newTags: [],
      popup: {
        popupFlg: false,
        type: 'alert',
        title: '確認',
        buttons: [],
        layerNumber: 2,
        content: ''
      },
      schemaImageSelectPopupFlg: false,
      colorChartFlg: false,
      waitFlg: false,
      textBox: '',
      areaX: '',
      areaY: '',
      index: ''
    }
  },

  computed: {
    ...mapGetters({
      imageTags: 'imageTags/getData',
      getImageTag: 'imageTags/getDataById',
      getKarteMedicalContentImage:
        'medicalContentImages/getKarteMedicalContentImage',
      getKarteUploadImage: 'uploadImages/getKarteUploadImage',
      getImageTextsByMedicalContentImageId:
        'medicalContentImageTexts/getDataByMedicalContentImageId',
      getResizedMedicalContentImageByOriginalId:
        'medicalContentImages/getResizedMedicalContentImageByOriginalId',
      lookOnlyFlg: 'auth/lookOnlyFlg'
    }),
    diffFlg() {
      return (
        !_.isEqual(this.initialData, {
          ...this.medicalContentImage,
          texts: this.medicalContentImage.texts.filter(
            text => text.delFlg === 0
          )
        }) || this.initialRGBASummary !== this.currentRGBASummary
      )
    },
    lineWidths() {
      return this.canvasMode === 'text'
        ? [
            { id: 1, name: '10px' },
            { id: 2, name: '15px' },
            { id: 3, name: '20px' },
            { id: 4, name: '25px' },
            { id: 5, name: '30px' }
          ]
        : [
            { id: 1, name: '1px' },
            { id: 2, name: '2px' },
            { id: 3, name: '3px' },
            { id: 4, name: '4px' },
            { id: 5, name: '5px' }
          ]
    }
  },

  watch: {
    canvasMode: function() {
      if (this.canvasMode !== 'text') {
        this.focusTextIndex = null
      }
    },
    undoDataStack() {
      this.currentRGBASummary = this.getRGBASummary()
    },
    redoDataStack() {
      this.currentRGBASummary = this.getRGBASummary()
    },
    isDrag() {
      this.currentRGBASummary = this.getRGBASummary()
    }
  },

  mounted() {
    const vc = this
    vc.canvas = vc.$refs.schemaCanvas
    vc.context = vc.canvas.getContext('2d')
    vc.context.lineCap = 'square'
    vc.context.lineJoin = 'square'
    if (vc.medicalContentImageId !== 0) vc.setMedicalContentImage()
    if (vc.medicalContentImage.overImage !== null) {
      const img = new Image()
      img.src = vc.medicalContentImage.overImage
      img.onload = () => vc.context.drawImage(img, 0, 0, 400, 400)
    }
    vc.initialData = _.cloneDeep(vc.medicalContentImage)
    setTimeout(() => {
      vc.currentRGBASummary = vc.getRGBASummary()
      vc.initialRGBASummary = vc.getRGBASummary()
    }, 500)
  },

  methods: {
    setMedicalContentImage() {
      const medicalContentImage = _.cloneDeep(
        this.getKarteMedicalContentImage(this.medicalContentImageId)
      )
      const imageTagsIds = Array.isArray(medicalContentImage.imageTagsIds)
        ? medicalContentImage.imageTagsIds
        : medicalContentImage.imageTagsIds !== ''
        ? medicalContentImage.imageTagsIds.split(',')
        : []
      const tagNames =
        imageTagsIds.length > 0
          ? imageTagsIds.map(id => '# ' + this.getImageTag(Number(id)).name)
          : []
      const medicalContentImageTexts = this.getImageTextsByMedicalContentImageId(
        medicalContentImage.id
      )
      const texts = medicalContentImageTexts
        ? medicalContentImageTexts.map((v, i) => {
            return { ...v, key: i + 1 }
          })
        : []
      this.textKey = texts.length + 1
      const underImage = medicalContentImage.uploadImageId
        ? this.getKarteUploadImage(medicalContentImage.uploadImageId).image
        : medicalContentImage.schemaImageId
        ? require(`@/assets/images/schemas/${medicalContentImage.schemaImageId}.jpg`)
        : null
      const underImageInfo = medicalContentImage.uploadImageId
        ? { id: medicalContentImage.uploadImageId, type: 'existingUpload' }
        : medicalContentImage.schemaImageId
        ? { id: medicalContentImage.schemaImageId, type: 'schema' }
        : {}

      this.medicalContentImage = {
        id: medicalContentImage.id,
        originalId: medicalContentImage.originalId,
        medicalContentOriginalId: medicalContentImage.medicalContentOriginalId,
        title: medicalContentImage.title,
        imageTagsIds,
        tagNames,
        texts,
        underImage,
        overImage: medicalContentImage.overImage,
        underImageInfo,
        schemaImageId: medicalContentImage.schemaImageId,
        uploadImageId: medicalContentImage.uploadImageId,
        widthInKarte: medicalContentImage.widthInKarte,
        heightInKarte: medicalContentImage.heightInKarte
      }
    },
    drawStart(e) {
      if (e.pointerType === 'mouse' && e.button !== 0) return
      if (this.canvasMode === 'text') return
      // 書き始めにredoDataStackは空にする
      this.redoDataStack = []
      // 書き始めの状態を保存する
      this.saveUndoData()
      this.startX = e.offsetX
      this.startY = e.offsetY
      this.isDrag = true
      // 描画の太さ
      this.context.lineWidth = this.lineWidth
      // 描画の色
      this.context.strokeStyle = this.strokeStyle
      // destination-out = 消しゴムモード
      this.context.globalCompositeOperation =
        this.canvasMode === 'eraser' ? 'destination-out' : 'source-over'
      // 描画開始の宣言
      this.context.beginPath()
      this.context.lineTo(this.startX, this.startY)
      this.context.stroke()
    },
    draw(e) {
      if (!this.isDrag) return
      const startX = this.startX
      const startY = this.startY
      const moveX = e.offsetX
      const moveY = e.offsetY
      // 一度真っ白にして書き始めの状態を再描画する事で滑らかに描ける
      this.context.clearRect(0, 0, this.canvas.width, this.canvas.height)
      this.context.putImageData(this.undoDataStack[0], 0, 0)

      // canvasMode毎に処理を変える
      if (this.canvasMode === 'pen') {
        this.context.lineTo(moveX, moveY)
      } else if (this.canvasMode === 'eraser') {
        this.context.lineWidth = 30
        this.context.lineTo(moveX, moveY)
      } else if (this.canvasMode === 'line') {
        this.context.beginPath()
        this.context.moveTo(startX, startY)
        this.context.lineTo(moveX, moveY)
      } else if (this.canvasMode === 'circle') {
        this.context.beginPath()
        moveX > startX
          ? this.context.arc(
              startX,
              startY,
              moveX - startX,
              0,
              2 * Math.PI,
              false
            )
          : this.context.arc(
              startX,
              startY,
              startX - moveX,
              0,
              2 * Math.PI,
              false
            )
      } else if (this.canvasMode === 'arrow') {
        this.context.beginPath()
        const headlen = 15
        const angle = Math.atan2(moveY - startY, moveX - startX)
        this.context.moveTo(startX, startY)
        this.context.lineTo(moveX, moveY)
        this.context.lineTo(
          moveX - headlen * Math.cos(angle - Math.PI / 6),
          moveY - headlen * Math.sin(angle - Math.PI / 6)
        )
        this.context.moveTo(moveX, moveY)
        this.context.lineTo(
          moveX - headlen * Math.cos(angle + Math.PI / 6),
          moveY - headlen * Math.sin(angle + Math.PI / 6)
        )
      }
      this.context.stroke()
    },
    drawEnd() {
      this.isDrag = false
      // 描画終了の宣言
      this.context.closePath()
      this.clearAble = this.getRGBASummary() !== 0 ? true : false
    },
    saveUndoData() {
      const stackMaxSize = 5
      if (this.undoDataStack.length >= stackMaxSize) this.undoDataStack.pop()
      this.undoDataStack.unshift(
        this.context.getImageData(0, 0, this.canvas.width, this.canvas.height)
      )
    },
    saveRedoData() {
      this.redoDataStack.unshift(
        this.context.getImageData(0, 0, this.canvas.width, this.canvas.height)
      )
    },
    undo() {
      if (this.undoDataStack.length <= 0) return
      this.saveRedoData()
      const imageData = this.undoDataStack.shift()
      this.context.putImageData(imageData, 0, 0)
      this.clearAble = this.getRGBASummary() !== 0 ? true : false
    },
    redo() {
      if (this.redoDataStack.length <= 0) return
      this.saveUndoData()
      const imageData = this.redoDataStack.shift()
      this.context.putImageData(imageData, 0, 0)
      this.clearAble = this.getRGBASummary() !== 0 ? true : false
    },
    getRGBASummary() {
      // canvas内のRGBA情報を集計。透明だと0になる。
      return this.context
        .getImageData(0, 0, this.canvas.width, this.canvas.height)
        .data.reduce((prev, current) => prev + current)
    },
    clear() {
      this.clearAble = false
      this.saveUndoData()
      this.context.clearRect(0, 0, this.canvas.width, this.canvas.height)
    },
    insertUnderImageFromImageUploader(image) {
      this.medicalContentImage.underImage = image.image
      this.medicalContentImage.schemaImageId = 0
      this.medicalContentImage.uploadImageId = 0
      this.medicalContentImage.underImageInfo = {
        thumbnailImage: image.thumbnailImage,
        width: image.width,
        height: image.height,
        widthInKarte: image.width,
        heightInKarte: image.height,
        title: image.name,
        type: 'newUpload'
      }
    },
    async insertUnderImageFromSchemaPopup(imageObj) {
      const isSchemaImage = imageObj.schemaImageClassId
      if (isSchemaImage) {
        this.medicalContentImage.underImage = imageObj.image
        this.medicalContentImage.schemaImageId = imageObj.id
        this.medicalContentImage.uploadImageId = 0
        this.medicalContentImage.underImageInfo = {
          id: imageObj.id,
          type: 'schema'
        }
      } else {
        const underImage = await this.getImageOfUploadImage(imageObj.id)
        this.medicalContentImage.underImage = underImage
        this.medicalContentImage.schemaImageId = 0
        this.medicalContentImage.uploadImageId = imageObj.id
        this.medicalContentImage.underImageInfo = {
          id: imageObj.id,
          type: 'existingUpload'
        }
      }
      this.schemaImageSelectPopupFlg = false
    },
    async getImageOfUploadImage(uploadImageId) {
      this.waitFlg = true
      let image
      const karteUploadImages = this.$store.getters[
        'uploadImages/getKarteUploadImages'
      ]
      image = karteUploadImages[uploadImageId]?.image
      if (!image) {
        const uploadImage = await this.$store.dispatch(
          'uploadImages/get',
          uploadImageId
        )
        if (uploadImage) {
          image = uploadImage.image
        } else {
          image = null
          this.popup = {
            popupFlg: true,
            layerNumber: 2,
            type: 'alert',
            title: '失敗',
            content: '画像の取得に失敗しました',
            buttons: ['閉じる']
          }
        }
      }
      this.waitFlg = false
      return image
    },
    ejectUnderImage() {
      this.medicalContentImage.underImage = null
      this.medicalContentImage.underImageInfo = {}
      this.medicalContentImage.uploadImageId = 0
      this.medicalContentImage.schemaImageId = 0
    },
    insertTextBox(e) {
      if (this.canvasMode !== 'text') return
      const initialWidth = 39
      const initialHeight = 41
      const extraWidth = this.canvas.width - initialWidth
      const extraHeight = this.canvas.height - initialHeight
      this.medicalContentImage.texts.push({
        key: this.textKey,
        text: '',
        // はみ出るのを防ぐ
        top: extraHeight < e.offsetY ? extraHeight : e.offsetY,
        left: extraWidth < e.offsetX ? extraWidth : e.offsetX,
        width: initialWidth,
        height: initialHeight,
        color: this.strokeStyle,
        size: Number(
          this.lineWidths
            .find(v => v.id === this.lineWidth)
            .name.substring(0, 2)
        ),
        delFlg: 0
      })
      this.textKey += 1
    },
    deleteText(keyId) {
      const index = this.medicalContentImage.texts.findIndex(
        v => v.key === keyId
      )
      this.medicalContentImage.texts[index].delFlg = 1
    },
    moveText(e, keyId) {
      this.index = this.medicalContentImage.texts.findIndex(
        v => v.key === keyId
      )
      this.isMove = true
      this.textBox = e
        .composedPath()
        .find(v => v.dataset && v.dataset.target === 'textBox')
      const area = this.$refs.imageArea
      this.areaX = area.getBoundingClientRect().left
      this.areaY = area.getBoundingClientRect().top
      this.handleMove(e)
      document.addEventListener('pointermove', this.handleMove)
      document.addEventListener('pointerup', this.handleMoveEnd)
    },
    handleMove(e) {
      const space = 2
      const canvasWidth = this.canvas.width
      const canvasHeight = this.canvas.height
      const x = e.pageX - this.areaX - space
      const maxX = canvasWidth - this.textBox.clientWidth - space
      this.textBox.style.left =
        x < 0 ? '0px' : 0 < x && x < maxX ? x + 'px' : maxX + 'px'
      const y = e.pageY - this.areaY - space
      const maxY = canvasHeight - this.textBox.clientHeight - space
      this.textBox.style.top =
        y < 0 ? '0px' : 0 < y && y < maxY ? y + 'px' : maxY + 'px'
    },
    handleMoveEnd() {
      document.removeEventListener('pointermove', this.handleMove)
      document.removeEventListener('pointerup', this.handleMoveEnd)
      this.medicalContentImage.texts[this.index].left = Number(
        this.textBox.style.left.slice(0, -2)
      )
      this.medicalContentImage.texts[this.index].top = Number(
        this.textBox.style.top.slice(0, -2)
      )
      this.isMove = false
    },
    focusoutTextBox(e, keyId) {
      const index = this.medicalContentImage.texts.findIndex(
        v => v.key === keyId
      )
      const textBox = e
        .composedPath()
        .find(v => v.dataset && v.dataset.target === 'textBox')
      this.medicalContentImage.texts[
        index
      ].width = textBox.getBoundingClientRect().width
      this.medicalContentImage.texts[
        index
      ].height = textBox.getBoundingClientRect().height
    },
    changeFontSize(value) {
      this.lineWidth = value
      if (this.canvasMode === 'text' && this.focusTextIndex !== null) {
        this.medicalContentImage.texts[this.focusTextIndex].size = Number(
          this.lineWidths
            .find(v => v.id === this.lineWidth)
            .name.substring(0, 2)
        )
      }
    },
    changeColor(color) {
      this.strokeStyle = color
      if (this.canvasMode === 'text' && this.focusTextIndex !== null) {
        this.medicalContentImage.texts[this.focusTextIndex].color = color
      }
      this.colorChartFlg = false
    },
    inputDate() {
      if (this.medicalContentImage.title.length > 19) {
        this.popup.popupFlg = true
        this.popup.buttons = ['閉じる']
        this.popup.content = '30文字以上になる為、日付を入力できません'
        return
      }
      this.medicalContentImage.title += moment().format('-YYYY-MM-DD')
    },
    deleteTag(tagName, imageTagId) {
      this.medicalContentImage.tagNames = this.medicalContentImage.tagNames.filter(
        v => v !== tagName
      )
      this.medicalContentImage.imageTagsIds = this.medicalContentImage.imageTagsIds.filter(
        v => v !== imageTagId
      )
      this.newTags = this.newTags.filter(v => v !== tagName.substring(2))
    },
    setNewTag(tagName) {
      this.newTag = ''
      const guardClause =
        VALID_SURROGATE_PAIR_REGEX.test(tagName) ||
        this.medicalContentImage.tagNames.some(v => v === `# ${tagName}`) ||
        tagName === ''
      if (guardClause) return

      this.medicalContentImage.tagNames.push(`# ${tagName}`)
      if (!this.imageTags.some(v => v.name === tagName)) {
        this.newTags.push(tagName)
      } else {
        const imageTagId = this.imageTags.find(v => v.name === tagName).id
        this.medicalContentImage.imageTagsIds.push(String(imageTagId))
      }
    },
    async update() {
      try {
        this.waitFlg = true
        this.medicalContentImage.overImage = this.canvas.toDataURL()
        const axiosObject = getAxiosObject()
        const res = await axiosObject.put(
          '/medical-content-images/schema-popup',
          {
            image: this.medicalContentImage,
            newTags: this.newTags,
            patientId: this.patientId
          }
        )
        if (res.status === 200) {
          this.$store.dispatch('uploadImages/setKarteUploadImages', [
            res.data.uploadImage
          ])
          this.$store.dispatch(
            'medicalContentImageTexts/updateAfter',
            res.data.medicalContentImageTexts
          )
          this.$store.dispatch('imageTags/updateAfter', res.data.imageTags)
          this.$store.dispatch(
            'medicalContentImages/setKarteMedicalContentImages',
            [res.data.medicalContentImage]
          )

          // サイズ変更済みの場合、resizedMedicalContentImageをセットする
          const resizedMedicalContentImage = this.getResizedMedicalContentImageByOriginalId(
            res.data.medicalContentImage.originalId
          )
          if (resizedMedicalContentImage) {
            this.$store.dispatch(
              'medicalContentImages/setResizedMedicalContentImage',
              {
                ...res.data.medicalContentImage,
                widthInKarte: resizedMedicalContentImage.widthInKarte,
                heightInKarte: resizedMedicalContentImage.heightInKarte
              }
            )
          }

          const medicalContentImageId = res.data.medicalContentImage.id
          const uploadImageId = res.data.uploadImage.id
            ? res.data.uploadImage.id
            : 0
          this.$emit('decide', { medicalContentImageId, uploadImageId })
        }
      } catch (error) {
        this.popup = {
          popupFlg: true,
          type: 'alert',
          title: '失敗',
          buttons: ['閉じる'],
          layerNumber: 2,
          content: '作成に失敗しました'
        }
      } finally {
        this.waitFlg = false
      }
    },
    closePopup() {
      const RGBASummary = this.getRGBASummary()
      if (this.initialRGBASummary === RGBASummary && !this.diffFlg) {
        this.$emit('close')
      } else {
        this.popup.popupFlg = true
        this.popup.buttons = ['キャンセル', '閉じる']
        this.popup.content =
          '入力内容を保存せずに閉じようとしています。\nよろしいですか？'
      }
    }
  }
}
</script>

<style lang="scss" scoped>
.schema-popup {
  position: absolute;
  top: 0;
  left: 0;
  .content {
    > .header {
      display: flex;
      > .left {
        width: 39%;
        > .title {
          font-size: 16px;
          font-weight: bold;
          color: #{$greyish-brown};
          text-align: left;
        }
        > .buttons {
          margin-top: 13px;
          display: flex;
          > .button-margin {
            margin: 0 10px;
          }
        }
      }
      > .image-uploader {
        width: 61%;
      }
    }
    > .input-form {
      margin-top: 10px;
      display: flex;
      > .left {
        width: 400px;
        height: 400px;
        > .image-area {
          position: relative;
          display: table-cell;
          text-align: center;
          vertical-align: middle;
          width: 400px;
          height: 400px;
          touch-action: none;
          > .under-image {
            vertical-align: top;
            max-width: 400px;
            max-height: 400px;
          }
          > .eraser {
            cursor: url('../../assets/images/eraser.png') 15 15, auto;
          }
          > .text {
            cursor: crosshair;
          }
        }
      }
      > .right {
        width: 218px;
        > .right-top {
          padding: 5px 0 0;
          background: #{$pale};
          > .buttons {
            display: flex;
            flex-wrap: wrap;
            > .left-button {
              margin: 0px 7.6px 5px 15.3px;
            }
            > .right-button {
              margin: 0px 15.3px 5px 7.6px;
            }
          }
          > .color-chart {
            position: absolute;
            right: 40px;
            z-index: 1100;
          }
          > .cover {
            position: fixed;
            top: 0;
            left: 0;
            width: 100vw;
            height: 100vh;
            opacity: 1;
            z-index: 1000;
          }
        }
        > .right-bottom {
          > .title {
            margin-top: 20px;
            display: flex;
            @include validate-message();
            > .calendar-button {
              margin-left: 5px;
            }
          }
          > .tag {
            margin-top: 20px;
            display: flex;
            @include validate-message();
          }
          > .tags {
            margin-top: 20px;
            height: 77px;
            width: 240px;
            display: flex;
            flex-wrap: wrap;
            overflow-y: auto;
            > .tag {
              height: 18px;
              line-height: 18px;
              font-size: 12px;
              color: #{$gray};
              border: 1px solid #{$gray};
              padding: 0 3px;
              border-radius: 50px;
              margin-right: 5px;
              margin-bottom: 5px;
              animation: zoomIn 1.5s cubic-bezier(0.25, 1, 0.5, 1) 1 forwards;
              > .delete-button {
                @include hover();
              }
            }
          }
        }
      }
      > .left + .right {
        margin-left: 15px;
      }
    }
  }
}
.input {
  @include validate-message();
}
</style>
