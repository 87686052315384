import { getAxiosObject } from '@/utils/library'
import {
  commonGetters,
  commonActions,
  commonMutations
} from '@/store/store_utils'

const dataName = 'staffs'

const state = { staffs: [], dataIndexedById: {}, updatedAt: '20101010101010' }

const getters = {
  ...commonGetters(dataName, ['getData', 'getDataById', 'getUpdatedAt'], {
    filterDel: true
  }),
  getStaffs(state) {
    return state.staffs.filter(
      v => v.delFlg === 0 && v.retireFlg === 0 && v.supportFlg === 0
    )
  },
  selectData(state, getters) {
    return getters.getStaffs.map(v => {
      return { id: v.id, name: `${v.lastName} ${v.firstName}`, jobId: v.jobId }
    })
  },
  selectDataZero(state, getters) {
    return [{ id: 0, name: '未設定', jobId: 0 }].concat(getters.selectData)
  }
}

const errorAction = (commit, error) => {
  if (error.response?.data?.message) {
    if (error.response.data.extra) {
      if (error.response.data.extra.data) {
        commit('setData', error.response.data.extra.data)
        return error.response.data.message
      } else {
        return error.response.data.extra
      }
    } else {
      return error.response.data.message
    }
  } else {
    return 'server error'
  }
}

const actions = {
  ...commonActions(['setData', 'setUpdatedAt', 'updateAfter']),
  async create({ commit }, obj) {
    try {
      const axiosObject = getAxiosObject()
      const res = await axiosObject.post('/staffs', obj)
      commit('updateAfter', [res.data.staff])
      commit('staffWorkingDates/updateAfter', res.data.staffWorkingDates, {
        root: true
      })
      return 'success'
    } catch (error) {
      return error.response?.data?.extra || 'server error'
    }
  },
  async updateStaff({ commit }, staff) {
    try {
      const axiosObject = getAxiosObject()
      const res = await axiosObject.put('/staffs', { staff: staff })
      if (res.status === 200) {
        commit('updateAfter', [res.data.staff])
        return 'success'
      }
    } catch (error) {
      return errorAction(commit, error)
    }
  },
  async delete({ commit }, id) {
    try {
      const axiosObject = getAxiosObject()
      const res = await axiosObject.delete('/staffs', { data: { id } })
      commit('updateAfter', [res.data.staff])
      commit('staffWorkingDates/updateAfter', res.data.staffWorkingDates, {
        root: true
      })
      return 'success'
    } catch (error) {
      return errorAction(commit, error)
    }
  },
  async updateSupportStaff({ commit }, { start, end, lookOnlyFlg }) {
    try {
      const axiosObject = getAxiosObject()
      const res = await axiosObject.put('/contacts', {
        start,
        end,
        lookOnlyFlg
      })
      if (res.status === 200) {
        commit('updateAfter', [res.data.supportStaff])
        return 'success'
      }
    } catch (error) {
      return error.response?.data?.message
        ? error.response.data.message
        : 'server error'
    }
  }
}

const mutations = {
  ...commonMutations(dataName, ['setData', 'setUpdatedAt', 'updateAfter'], {
    order: true,
    indexedById: true,
    base64: true
  })
}

export default {
  namespaced: true,
  state,
  mutations,
  getters,
  actions
}
