<template>
  <div class="base-button-small-red">
    <button
      type="button"
      @click="handleClick"
      :disabled="disabled"
      :style="styles"
      data-e2e="base-button-small-red"
    >
      <span><slot /></span>
    </button>
  </div>
</template>

<script>
export default {
  name: 'BaseButtonSmallRed',

  props: {
    disabled: { type: Boolean, default: false },
    styles: {
      type: Object,
      validator: obj => {
        return Object.values(obj).every(v => typeof v === 'string')
      }
    }
  },

  methods: {
    handleClick() {
      this.$emit('click')
    }
  }
}
</script>

<style lang="scss" scoped>
.base-button-small-red {
  > button {
    width: 135px;
    height: 33px;
    border-radius: 6px;
    border: solid 2px #{$tomato};
    background-color: #{$tomato};
    cursor: pointer;
    &:hover {
      opacity: 0.8;
      background-color: #{$tomato};
    }
    &:active {
      margin: 2px auto 0 auto;
      height: 31px;
    }
    > span {
      height: 22px;
      font-size: 15px;
      font-weight: bold;
      text-align: left;
      color: #{$white};
      display: inline-block;
      vertical-align: middle;
    }
  }
  > button:disabled {
    background-color: #{$light-grey};
    border: solid 2px #{$light-grey};
    cursor: default;
    > span {
      color: #{$greyish-brown};
    }
    &:hover {
      opacity: 1;
    }
    &:active {
      margin: 0 auto 0 auto;
      height: 33px;
    }
  }
}
@media (max-width: $tablet-width) {
  .base-button-small-red {
    > button {
      > span {
        font-size: 12px;
        line-height: 22px;
      }
    }
  }
}
</style>
