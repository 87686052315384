<template>
  <div class="base-text-input-password">
    <input
      type="password"
      class="base-text-input-password-class"
      maxlength="100"
      :autocomplete="newFlg ? 'new-password' : 'on'"
      :value="value"
      :placeholder="placeholder"
      :style="styles"
      @input="inputText"
      data-e2e="base-text-input-password"
    />
  </div>
</template>

<script>
export default {
  name: 'BaseTextInputPassword',

  props: {
    value: {
      type: String
    },
    placeholder: {
      type: String
    },
    newFlg: { type: Boolean, default: false },
    styles: {
      type: Object,
      validator: function(obj) {
        return Object.values(obj).every(v => typeof v === 'string')
      }
    }
  },

  methods: {
    inputText(e) {
      this.$emit('input', e.target.value)
    }
  }
}
</script>

<style lang="scss" scoped>
.base-text-input-password {
  > .base-text-input-password-class {
    width: 299px;
    height: 44px;
    border-radius: 4px;
    border: solid 2px #{$light-grey};
    background-color: #{$white};
    padding: 2px 15px;
    font-size: 15px;
    text-align: left;
    box-sizing: border-box;
    &::placeholder {
      color: #{$brown-gray};
    }
  }
}
</style>
