<template>
  <div class="vrainers-settings">
    <base-loading :waitFlg="waitFlg" />
    <h2>ブレイナーズデータ変換</h2>
    <div>
      <div>018_MDRUGGRPH</div>
      <input type="file" @change="uploadCsv($event, 'drugGroupHospital')" />
      <!--
      <div>022_MDRUGITEM</div>
      <input type="file" @change="uploadCsv($event, 'drugItem')" />
    -->
      <div>024_MDRUGITEMH</div>
      <input type="file" @change="uploadCsv($event, 'drugItemHospital')" />
      <div>049_MORDERGRPH</div>
      <input type="file" @change="uploadCsv($event, 'orderGroupHospital')" />
      <div>052_MORDERITEMH</div>
      <input type="file" @change="uploadCsv($event, 'orderItemHospital')" />
      <div>062_MSTAFF</div>
      <input type="file" @change="uploadCsv($event, 'staff')" />
      <div>MDIAGGRP</div>
      <input type="file" @change="uploadCsv($event, 'diagramGroup')" />
      <div>MDIAGITEM</div>
      <input type="file" @change="uploadCsv($event, 'diagramItem')" />
      <div>clinicId</div>
      <input type="text" v-model="clinicId" />
      <div>pass</div>
      <input type="password" v-model="pass" />
    </div>
    <base-button-medium-orange class="button" @click="addData"
      >登録</base-button-medium-orange
    >
  </div>
</template>

<script>
import Encoding from 'encoding-japanese'
import BaseButtonMediumOrange from '@/components/parts/atoms/BaseButtonMediumOrange'
import BaseLoading from '@/components/parts/atoms/BaseLoading'
import { getAxiosObject } from '@/utils/library'
export default {
  name: 'VrainersSettings',

  components: {
    BaseButtonMediumOrange,
    BaseLoading
  },

  data() {
    return {
      uploadFile: {},
      waitFlg: false,
      fileData: null,
      timeOut: null,
      drugItem: null,
      drugGroupHospital: null,
      drugItemHospital: null,
      orderGroupHospital: null,
      orderItemHospital: null,
      staff: null,
      diagramGroup: null,
      diagramItem: null,
      clinicId: 0,
      pass: ''
    }
  },

  computed: {},

  watch: {},

  methods: {
    async addData() {
      try {
        const axiosObject = getAxiosObject()
        const res = await axiosObject.post('/vrainers/settings', {
          clinicId: this.clinicId,
          pass: this.pass,
          drugItem: this.drugItem,
          drugGroupHospital: this.drugGroupHospital,
          drugItemHospital: this.drugItemHospital,
          orderGroupHospital: this.orderGroupHospital,
          orderItemHospital: this.orderItemHospital,
          staff: this.staff,
          diagramGroup: this.diagramGroup,
          diagramItem: this.diagramItem
        })
      } catch (err) {
        //
      }
    },
    uploadCsv(e, dataName) {
      this.waitFlg = true
      this.timeOut = setTimeout(async () => {
        const res = await this.readCsv(e)
        if (res[0]) {
          this[dataName] = res[1]
        }
        this.waitFlg = false
      }, 1000)
    },
    readCsv(e) {
      return new Promise((resolve, reject) => {
        const file = e.target.files[0]
        const reader = new FileReader()
        file ? reader.readAsArrayBuffer(file) : (this.waitFlg = false)
        reader.onload = () => {
          const binary = new Uint8Array(reader.result)
          let csvStr
          switch (Encoding.detect(binary)) {
            case 'UTF8':
              csvStr = new TextDecoder().decode(binary)
              break
            case 'SJIS': {
              const utf8Array = Encoding.convert(binary, {
                to: 'UNICODE',
                from: 'SJIS'
              })
              csvStr = Encoding.codeToString(utf8Array)
              break
            }
            default: {
              csvStr = new TextDecoder().decode(binary)
              break
            }
          }
          //console.log(csvStr)
          resolve([true, csvStr])
        }
        reader.onerror = () => reject([false])
      })
    }
  }
}
</script>

<style lang="scss" scoped>
.apply-form {
  display: flex;
  flex-direction: column;
  > .validation-observer {
    > img {
      margin-top: 20px;
    }
    > .button {
      margin: 40px 0 100px;
    }
  }
}
</style>
