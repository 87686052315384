<template>
  <div class="base-button-icon">
    <button
      class="button"
      type="button"
      :style="styles"
      @click="$emit('click')"
    >
      <div class="icon-text">
        <div v-if="text" class="button-text">{{ text }}</div>
        <img
          v-if="img"
          class="icon"
          :src="require(`@/assets/images/${img}`)"
          alt="`${img}`"
          data-test="img"
        />
      </div>
    </button>
  </div>
</template>

<script>
export default {
  name: 'BaseButtonIcon',

  props: {
    img: { type: String, default: '' },
    text: { type: String, default: '' },
    styles: {
      type: Object,
      validator: function(obj) {
        return Object.values(obj).every(v => typeof v === 'string')
      }
    }
  }
}
</script>

<style lang="scss" scoped>
.base-button-icon {
  height: 33px;
  background-color: #{$white};
  > .button {
    background-color: #{$white};
    height: 33px;
    padding: 0 3px;
    box-sizing: border-box;
    border: 1px solid #{$pumpkin};
    border-radius: 6px;
    cursor: pointer;
    &:hover {
      opacity: 0.8;
    }
    &:active {
      height: 31px;
      margin: 2px auto 0 auto;
    }
    > .icon-text {
      color: #{$pumpkin};
      display: flex;
      > .icon {
        width: 20px;
      }
      > .button-text {
        margin-right: 3px;
        font-size: 15px;
        font-weight: bold;
      }
    }
  }
}
</style>
