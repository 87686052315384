<template>
  <div class="full-name-input-form">
    <div class="heading" :style="{ height: headingHeight }">
      <div class="text"><slot></slot></div>
      <div class="required" v-if="requiredFlg">必須</div>
    </div>
    <div class="body">
      <div class="name last">
        <validation-provider
          class="validate"
          :rules="{
            requiredRule: requiredFlg,
            regexKana: kanaFlg,
            nameRule: true,
            ...validationRules
          }"
          v-slot="{ errors }"
        >
          <div class="label-field">
            <label>{{ kanaFlg ? 'セイ' : '姓' }}</label>
            <base-text-box
              data-test="last-name"
              :styles="styles"
              :maxlength="15"
              v-model="InputLastName"
            />
          </div>
          <div v-if="errorShowFlg" class="error" data-test="last-name-err">
            <div v-for="(error, index) in errors" :key="index">{{ error }}</div>
          </div>
        </validation-provider>
      </div>
      <div class="name first">
        <validation-provider
          class="validate"
          :rules="{
            requiredRule: requiredFlg,
            regexKana: kanaFlg,
            nameRule: true,
            ...validationRules
          }"
          v-slot="{ errors }"
        >
          <div class="label-field">
            <label>{{ kanaFlg ? 'メイ' : '名' }}</label>
            <base-text-box
              data-test="first-name"
              :styles="styles"
              :maxlength="15"
              v-model="InputFirstName"
            />
          </div>
          <div v-if="errorShowFlg" class="error" data-test="first-name-err">
            <div v-for="(error, index) in errors" :key="index">{{ error }}</div>
          </div>
        </validation-provider>
      </div>
    </div>
  </div>
</template>

<script>
import BaseTextBox from '@/components/parts/atoms/BaseTextBox'
import { ValidationProvider } from 'vee-validate'
import '@/utils/validation_rules'
import { hiraToKata } from '@/utils/convert_string'

export default {
  name: 'FullNameInputForm',

  components: {
    BaseTextBox,
    ValidationProvider
  },

  props: {
    kanaFlg: { type: Boolean, default: false },
    requiredFlg: { type: Boolean, default: false },
    validationRules: { type: Object },
    styles: {
      type: Object,
      default: () => ({ height: '33px', width: '93px' })
    },
    lastName: { type: String },
    firstName: { type: String },
    errorShowFlg: { type: Boolean, default: true }
  },

  computed: {
    InputLastName: {
      get() {
        return this.lastName
      },
      set(val) {
        this.kanaFlg
          ? this.$emit('input-last-name', hiraToKata(val), 'lastNameKana')
          : this.$emit('input-last-name', val, 'lastName')
      }
    },
    InputFirstName: {
      get() {
        return this.firstName
      },
      set(val) {
        this.kanaFlg
          ? this.$emit('input-first-name', hiraToKata(val), 'firstNameKana')
          : this.$emit('input-first-name', val, 'firstName')
      }
    },
    headingHeight() {
      return this.styles ? (this.styles.height ? this.styles.height : '') : ''
    }
  }
}
</script>

<style lang="scss" scoped>
.full-name-input-form {
  display: flex;
  align-items: flex-start;
  > .heading {
    display: flex;
    align-items: center;
    width: 40%;
    font-size: 15px;
    > .required {
      margin-left: 10px;
      font-size: 13px;
      color: #{$tomato};
    }
  }
  > .body {
    display: flex;
    gap: 0 15px;
    width: 60%;
    font-size: 13px;
    > .name {
      @include validate-message();
      > .validate {
        position: relative;
        > .label-field {
          display: flex;
          align-items: center;
          > label {
            width: 30px;
            margin-right: 5px;
          }
        }
      }
    }
  }
}
</style>
