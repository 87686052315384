<template>
  <div class="password-reset-registered">
    <main-header :logined-flg="false" />
    <div class="page-title">
      パスワードの設定完了
    </div>
    <div class="password-reset-progress-bar">
      <div class="progress-bar-list-text">
        メールアドレス入力
      </div>
      <div class="arrow"></div>
      <div class="progress-bar-list-text">
        本人確認
      </div>
      <div class="arrow"></div>
      <div class="progress-bar-list-text">
        パスワード設定
      </div>
      <div class="arrow"></div>
      <div class="progress-bar-list-text">
        完了
      </div>
    </div>
    <div class="check-mark">
      <base-check-mark />
    </div>
    <div class="message">
      パスワードの再設定が完了しました。
    </div>
    <div class="next-button">
      <base-button-large-orange @click="pushToLogin"
        >ログイントップへ</base-button-large-orange
      >
    </div>
  </div>
</template>

<script>
import MainHeader from '@/components/parts/organisms/MainHeader'
import BaseCheckMark from '@/components/parts/atoms/BaseCheckMark.vue'
import BaseButtonLargeOrange from '@/components/parts/atoms/BaseButtonLargeOrange'
import PreventBack from '@/components/mixins/PreventBack'

export default {
  name: 'PasswordResetRegistered',

  components: {
    MainHeader,
    BaseCheckMark,
    BaseButtonLargeOrange
  },

  mixins: [PreventBack],

  methods: {
    pushToLogin() {
      this.$router.push({ path: '/login' })
    }
  }
}
</script>

<style lang="scss" scoped>
.password-reset-registered {
  > .page-title {
    margin-top: 67px;
    font-size: 30px;
    font-weight: bold;
  }
  > .password-reset-progress-bar {
    margin: 27px auto 0;
    width: 630px;
    height: 45px;
    border: solid 2px #{$pumpkin};
    border-radius: 50px;
    box-sizing: border-box;
    display: flex;
    justify-content: space-around;
    align-items: center;
    > .arrow {
      width: 8px;
      height: 8px;
      border-top: 2px solid #{$pumpkin};
      border-right: 2px solid #{$pumpkin};
      transform: rotate(45deg);
    }
    > .progress-bar-list-text {
      font-size: 15px;
      font-weight: bold;
      color: #{$pumpkin};
    }
  }
  > .check-mark {
    margin-top: 39px;
  }
  > .message {
    margin-top: 50px;
    font-size: 18px;
    font-weight: bold;
  }
  > .next-button {
    margin-top: 78px;
  }
}
</style>
