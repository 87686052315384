<template>
  <div class="base-data-list">
    <input
      class="text-box"
      :class="{ pointer: pointer, 'disabled-color-white': disabledColorWhite }"
      :type="type"
      :placeholder="placeholder"
      :maxlength="maxlength"
      :max="max"
      :min="min"
      :disabled="disabled"
      :style="styles"
      v-model="inputText"
      @blur="$emit('blur')"
      @keydown.enter="setName"
      data-e2e="base-data-list"
      list="list"
      @change="setName"
    />
    <datalist id="list" data-test="datalist">
      <option v-for="datum in selectData" :key="datum.id">
        {{ datum.name }}
      </option>
    </datalist>
  </div>
</template>

<script>
export default {
  name: 'BaseDataList',

  props: {
    value: { type: [String, Number] },
    type: { type: String, default: 'text' },
    placeholder: { type: String },
    maxlength: { type: Number, default: 255 },
    max: { type: Number },
    min: { type: Number },
    disabled: { type: Boolean, default: false },
    disabledColorWhite: { type: Boolean, default: false },
    pointer: { type: Boolean, default: false },
    styles: {
      type: Object,
      validator: function(obj) {
        return Object.values(obj).every(v => typeof v === 'string')
      }
    },
    selectData: {
      type: Array, //[{id:int,name:string}]or[{id:int,name:number}]
      required: true,
      validator: arr => {
        return arr.every(v => {
          return (
            (Number.isInteger(v.id) &&
              typeof v.name === 'string' &&
              v.id !== null &&
              v.name !== null) ||
            (Number.isInteger(v.id) &&
              typeof v.name === 'number' &&
              v.id !== null &&
              v.name !== null)
          )
        })
      }
    }
  },

  computed: {
    inputText: {
      get() {
        return this.value
      },
      set(val) {
        this.$emit('input', val)
      }
    }
  },

  methods: {
    setName(e) {
      this.$emit('decide', e.target.value)
    }
  }
}
</script>

<style lang="scss" scoped>
.base-data-list {
  > .text-box {
    width: 265px;
    height: 33px;
    border-radius: 4px;
    border: solid 2px #{$light-grey};
    background-color: #{$white};
    padding: 2px 8px;
    font-size: 15px;
    text-align: left;
    box-sizing: border-box;
    &:disabled {
      background-color: #{$light-grey};
      color: #{$brown-gray};
      opacity: 0.7;
    }
    &::placeholder {
      color: #{$brown-gray};
    }
    &::-ms-clear {
      display: none;
    }
    &::-ms-reveal {
      display: none;
    }
  }
  > .pointer {
    cursor: pointer;
  }
  > .disabled-color-white {
    &:disabled {
      background-color: #{$white};
      color: #{$greyish-brown};
      opacity: 1;
    }
  }
}
</style>
