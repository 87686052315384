import {
  commonGetters,
  commonMutations,
  commonActions
} from '@/store/store_utils'
import { toStateName } from '@/utils/convert_string'
import Vue from 'vue'

const dataName = 'examinationResults'
const keys = ['patientId']

const state = {
  examinationResults: [],
  updatedAt: '20101010101010',
  newExaminationResultGroups: {}
}
keys.forEach(key => {
  state[toStateName(key)] = null
})

const getters = {
  ...commonGetters(dataName, ['getUpdatedAt'], {
    getDataBy: keys,
    getDataByIncludeDel: ['patientId']
  }),
  getNewExaminationResultGroups(state) {
    return state.newExaminationResultGroups
  }
}

const actions = {
  ...commonActions(['setData', 'setUpdatedAt', 'updateAfter']),
  setNewExaminationResultGroups({ commit }, examinationResultGroups) {
    commit('setNewExaminationResultGroups', examinationResultGroups)
  },
  changeNewExaminationResultGroup({ commit }, examinationResultGroup) {
    commit('changeNewExaminationResultGroup', examinationResultGroup)
  },
  resetNewExaminationResultGroups({ commit }) {
    commit('resetNewExaminationResultGroups')
  }
}

const mutations = {
  ...commonMutations(dataName, ['setUpdatedAt', 'setData', 'updateAfter'], {
    setDataBy: keys
  }),
  setNewExaminationResultGroups(state, examinationResultGroups) {
    state.newExaminationResultGroups = examinationResultGroups
  },
  changeNewExaminationResultGroup(state, examinationResultGroup) {
    Vue.set(
      state.newExaminationResultGroups,
      examinationResultGroup[0].examinationResultGroupId,
      examinationResultGroup
    )
  },
  resetNewExaminationResultGroups(state) {
    state.newExaminationResultGroups = {}
  }
}

export default {
  namespaced: true,
  state,
  getters,
  actions,
  mutations
}
