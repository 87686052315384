import { getAxiosObject } from '@/utils/library'
import {
  commonGetters,
  commonMutations,
  commonActions
} from '@/store/store_utils'

const dataName = 'medicalContentTemplates'

const state = {
  medicalContentTemplates: [],
  dataIndexedById: {},
  updatedAt: '20101010101010'
}

const getters = {
  ...commonGetters(dataName, ['getData', 'getDataById', 'getUpdatedAt'], {
    filterDel: true
  })
}

const actions = {
  ...commonActions(['setData', 'setUpdatedAt', 'updateAfter']),
  async create({ commit }, medicalContentTemplate) {
    try {
      const axiosObject = getAxiosObject()
      const res = await axiosObject.post('/medical-content-templates', {
        medicalContentTemplate
      })
      if (res.status === 200) {
        commit('updateAfter', [res.data.medicalContentTemplate])
        return true
      }
    } catch (error) {
      return error.response?.data?.message || 'server error'
    }
  },
  async delete({ commit }, id) {
    try {
      const axiosObject = getAxiosObject()
      const res = await axiosObject.delete('/medical-content-templates', {
        data: { id }
      })
      if (res.status === 200) {
        commit('updateAfter', [res.data.medicalContentTemplate])
        return true
      }
    } catch (error) {
      if (error.response?.data?.extra?.data) {
        commit('setData', error.response.data.extra.data)
        return error.response.data.message
      } else {
        return 'server error'
      }
    }
  },
  async update({ commit }, medicalContentTemplate) {
    try {
      const axiosObject = getAxiosObject()
      const res = await axiosObject.put('/medical-content-templates', {
        medicalContentTemplate
      })
      if (res.status === 200) {
        commit('updateAfter', [res.data.medicalContentTemplate])
        return true
      }
    } catch (error) {
      if (error.response?.data?.message) {
        if (error.response.data.message === 'no data in clinic') {
          commit('setData', error.response.data.extra.data)
        }
        return error.response.data.message
      } else {
        return 'server error'
      }
    }
  }
}

const mutations = {
  ...commonMutations(dataName, ['setData', 'setUpdatedAt', 'updateAfter'], {
    order: true,
    indexedById: true
  })
}

export default {
  namespaced: true,
  state,
  getters,
  actions,
  mutations
}
