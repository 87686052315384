<template>
  <div class="image-form">
    <div class="heading">
      <div class="text"><slot></slot></div>
      <div class="required" v-if="requiredFlg" data-test="required">必須</div>
    </div>
    <div class="body">
      <label
        class="image-area"
        :class="{ border: image === null }"
        :style="imageAreaStyles"
        data-test="image-area"
      >
        <img :src="image" v-if="image !== null" :style="styles" alt="image" />
        <div class="mount" v-else :style="styles" data-test="mount">
          画像を選択してください
        </div>
        <input
          ref="inputTag"
          type="file"
          @change="onImageChange"
          accept="image/png,image/jpeg,image/gif,image/bmp"
        />
      </label>
      <base-button-small-white
        v-if="!requiredFlg"
        class="delete-button"
        @click="deleteImage"
        >画像削除</base-button-small-white
      >
    </div>
  </div>
</template>

<script>
import imageCompression from 'browser-image-compression'
import BaseButtonSmallWhite from '@/components/parts/atoms/BaseButtonSmallWhite'

export default {
  name: 'ImageForm',

  components: {
    BaseButtonSmallWhite
  },

  props: {
    requiredFlg: { type: Boolean, default: false },
    image: { type: [String, null], default: null },
    maxWidthOrHeight: { type: Number, default: 1000 },
    imageAreaStyles: {
      type: Object,
      validator: obj => {
        return Object.values(obj).every(v => typeof v === 'string')
      },
      //↓このコンポーネント全体のwidthが600pxの場合（お問い合わせ画面、申し込みフォーム画面）
      // に合わせた値をデフォルト値にしております。
      default: () => ({ height: '180px', width: '360px' })
    },
    styles: {
      type: Object,
      validator: obj => {
        return Object.values(obj).every(v => typeof v === 'string')
      },
      default: () => ({
        maxHeight: '180px',
        maxWidth: '360px',
        lineHeight: '180px'
      })
    }
  },

  methods: {
    async onImageChange(e) {
      if (e.target.files.length !== 0) {
        const image = await this.encodeBase64(
          await this.resizeImage(e.target.files[0])
        )
        this.$emit('input-image', image)
      }
    },
    async resizeImage(file) {
      return await imageCompression(file, {
        maxSizeMB: 0.5,
        maxWidthOrHeight: this.maxWidthOrHeight
      })
    },
    encodeBase64(file) {
      return new Promise(resolve => {
        const reader = new FileReader()
        reader.readAsDataURL(file)
        reader.onload = () => resolve(reader.result)
      })
    },
    deleteImage() {
      this.$emit('delete-image')
    }
  }
}
</script>

<style lang="scss" scoped>
.image-form {
  display: flex;
  align-items: flex-start;
  > .heading {
    display: flex;
    align-items: center;
    height: 33px;
    width: 40%;
    font-size: 15px;
    > .required {
      margin-left: 10px;
      font-size: 13px;
      color: #{$tomato};
    }
  }
  > .body {
    width: 60%;
    text-align: left;
    > label {
      display: flex;
      justify-content: center;
      align-items: center;
      cursor: pointer;
      box-sizing: border-box;
      &.border {
        border: dashed 2px #{$light-grey};
        border-radius: 5px;
      }
      > .mount {
        color: #{$light-grey};
        font-size: 20px;
      }
      > input {
        display: none;
      }
    }
    > .delete-button {
      margin-top: 10px;
    }
  }
}
</style>
