<template>
  <div class="apply-form">
    <validation-observer class="validation-observer" v-slot="{ invalid }">
      <img
        src="@/assets/images/login_logo.png"
        width="315px"
        height="54px"
        alt="login-logo"
      />
      <h2>PETORELU<SUP>+</SUP>申込みフォーム</h2>
      <apply-input-form
        :data="data"
        :prefectures="mixinPrefectures"
        :flags="flags"
        :plans="plans"
        :apiResultPostalCode="mixinApiResultPostalCode"
        @input-staff-last-name="inputStaffLastName"
        @input-staff-first-name="inputStaffFirstName"
        @input-postal-code="inputPostalCode"
        @auto-input-address="mixinInputAddress('data')"
        @input-image="inputImage"
      />
      <base-button-large-orange
        class="button"
        :disabled="invalid || imageEmptyFlg || !isChecked || waitFlg"
        @click="apply"
        >申し込む</base-button-large-orange
      >
      <announce-popup
        v-if="alertFlg"
        :title="'失敗'"
        :buttons="['閉じる']"
        :type="'failure'"
        @close="alertFlg = false"
        >{{ alertMsg }}</announce-popup
      >
    </validation-observer>
  </div>
</template>

<script>
import ApplyInputForm from '@/components/parts/organisms/ApplyInputForm'
import BaseButtonLargeOrange from '@/components/parts/atoms/BaseButtonLargeOrange'
import AnnouncePopup from '@/components/popups/AnnouncePopup'
import { getAxiosObject } from '@/utils/library'
import { ValidationObserver } from 'vee-validate'
import AutoInputPrefecture from '@/components/mixins/AutoInputPrefecture'
export default {
  name: 'ApplyForm',

  components: {
    ApplyInputForm,
    BaseButtonLargeOrange,
    AnnouncePopup,
    ValidationObserver
  },

  mixins: [AutoInputPrefecture],

  data() {
    return {
      data: {
        planId: 1,
        staffLastName: '',
        staffFirstName: '',
        postalCode: '',
        prefectureId: '',
        address: '',
        building: '',
        tel: '',
        clinicName: '',
        clinicEmail: '',
        staffEmail: '',
        anicomHCd: '',
        vhCode: '',
        memo: '',
        image: null
      },
      plans: {
        title: '選択プラン',
        radioButtonData: [
          {
            id: 1,
            eachValue: 1,
            labelName: '予約プラン'
          },
          {
            id: 2,
            eachValue: 2,
            labelName: '電子カルテAプラン'
          },
          {
            id: 3,
            eachValue: 3,
            labelName: '電子カルテBプラン'
          }
        ]
      },
      alertFlg: false,
      alertMsg: '',
      flags: {
        acceptTermsOfUse: false,
        acceptPrivacyPolicy: false
      },
      isChecked: false,
      waitFlg: false
    }
  },

  computed: {
    imageEmptyFlg() {
      return this.data.image === null ? true : false
    }
  },

  watch: {
    flags: {
      handler: function() {
        this.isChecked =
          this.flags.acceptTermsOfUse && this.flags.acceptPrivacyPolicy
      },
      deep: true
    }
  },

  methods: {
    inputStaffLastName(val) {
      this.data.staffLastName = val
    },
    inputStaffFirstName(val) {
      this.data.staffFirstName = val
    },
    inputPostalCode(val) {
      if (
        !this.mixinApiResultPostalCode?.existingPostalCodeFlg &&
        val.length !== 7
      ) {
        this.mixinApiResultPostalCode = null
      }
      this.data.postalCode = val
    },
    inputImage(image) {
      this.data.image = image
    },
    async apply() {
      this.waitFlg = true
      try {
        await getAxiosObject().post('/admin/clinics/new', { data: this.data })
        this.$router.push({ name: 'apply-registered' })
      } catch (error) {
        if (error.response?.data?.message === 'already used') {
          error.response.data.extra === 'clinic email' &&
            (this.alertMsg = '医院のメールアドレスは既に使用されています。')
          error.response.data.extra === 'staff email' &&
            (this.alertMsg = 'ユーザーのメールアドレスは既に使用されています。')
        } else {
          this.alertMsg = `申し込みに失敗しました。\n時間を置いて再度お試し下さい。`
        }
        this.waitFlg = false
        this.alertFlg = true
      }
    }
  }
}
</script>

<style lang="scss" scoped>
.apply-form {
  display: flex;
  flex-direction: column;
  > .validation-observer {
    > img {
      margin-top: 20px;
    }
    > .button {
      margin: 40px 0 100px;
    }
  }
}
</style>
