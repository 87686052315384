import {
  commonGetters,
  commonActions,
  commonMutations
} from '@/store/store_utils'
import { getAxiosObject } from '@/utils/library'
import { decodeBase64fromBuffer } from '@/utils/base64'
import { dataCsvToArray } from '@/utils/comma_string'
import { mutationUpdateAfter } from '@/utils/update_after'

const dataName = 'uploadImages'

const state = {
  uploadImages: [],
  updatedAt: '20101010101010',
  // 操作中のカルテに使用されている画像を一時保存するObject
  karteUploadImages: {},
  // サイズ変更したデータを診療内容登録まで一時保存するObject
  resizedUploadImages: {},
  // 既に高解像度の画像(uploadImageのimageカラム)を取得しているカルテを記録
  acquiredMedicalRecordOriginalIds: new Set()
}

const getters = {
  ...commonGetters(dataName, ['getData', 'getDataIncludeDel', 'getUpdatedAt'], {
    filterDel: true
  }),
  getKarteUploadImages(state) {
    return state.karteUploadImages
  },
  getKarteUploadImage: state => uploadImageId => {
    return state.karteUploadImages[uploadImageId]
  },
  getResizedUploadImages(state) {
    return state.resizedUploadImages
  },
  getResizedUploadImage: state => uploadImageId => {
    return state.resizedUploadImages[uploadImageId]
  },
  getAcquiredMedicalRecordOriginalIds(state) {
    return state.acquiredMedicalRecordOriginalIds
  }
}

const actions = {
  ...commonActions(['setData', 'setUpdatedAt', 'updateAfter']),
  async update({ commit }, obj) {
    try {
      const axiosObject = getAxiosObject()
      const res = await axiosObject.put('/upload-images', obj)
      if (res.status === 200) {
        commit('updateAfter', [res.data.uploadImage])
        commit('imageTags/updateAfter', res.data.imageTags, { root: true })
        return 'success'
      }
    } catch (err) {
      return err.response?.data?.message === 'no data'
        ? `no ${err.response.data.extra}`
        : 'server error'
    }
  },
  setKarteUploadImages({ commit }, images) {
    commit('setKarteUploadImages', images)
  },
  setResizedUploadImage({ commit }, resizedUploadImage) {
    commit('setResizedUploadImage', resizedUploadImage)
  },
  resetResizedUploadImages({ commit }) {
    commit('resetResizedUploadImages')
  },
  insertAcquiredMedicalRecordOriginalIds({ commit }, medicalRecordOriginalIds) {
    commit('insertAcquiredMedicalRecordOriginalIds', medicalRecordOriginalIds)
  },
  async get({ commit }, id) {
    try {
      const axiosObject = getAxiosObject()
      const res = await axiosObject.get('/upload-images', { params: { id } })
      if (res.status === 200) {
        commit('updateAfter', [res.data.uploadImage])
        return res.data.uploadImage
      }
    } catch (ex) {
      return null
    }
  },
  async getUploadImageOfOpenedKarte({ commit }, id) {
    try {
      const axiosObject = getAxiosObject()
      const res = await axiosObject.get('/upload-images', { params: { id } })
      if (res.status === 200) {
        if (res.data.uploadImage) {
          commit('setKarteUploadImages', [res.data.uploadImage])
          //↑画像取得後に診療内容フォーム内に画像を表示させるために必要。画像コンポーネントで上記のmutationを監視し、動いたら画像表示の処理が再度動くようにしている。
          commit('updateAfter', [res.data.uploadImage])
        }
        return 'success'
      }
    } catch (ex) {
      return 'failure'
    }
  }
}

const mutations = {
  ...commonMutations(dataName, ['setData', 'setUpdatedAt'], {
    csvColumns: ['imageTagsIds']
  }),
  updateAfter(state, images) {
    const convertImages = dataCsvToArray(images, ['imageTagsIds'])
    const decodedImages = decodeBase64fromBuffer(convertImages)
    mutationUpdateAfter(state, decodedImages, dataName)
    decodedImages.forEach(image => {
      state.karteUploadImages[image.id] = image
    })
  },
  setKarteUploadImages(state, images) {
    const decodedImages = decodeBase64fromBuffer(images)
    decodedImages.forEach(image => {
      state.karteUploadImages[image.id] = image
    })
  },
  setResizedUploadImage(state, resizedUploadImage) {
    state.resizedUploadImages[resizedUploadImage.id] = resizedUploadImage
  },
  resetResizedUploadImages(state) {
    state.resizedUploadImages = {}
  },
  insertAcquiredMedicalRecordOriginalIds(state, medicalRecordOriginalIds) {
    medicalRecordOriginalIds.forEach(v => {
      state.acquiredMedicalRecordOriginalIds.add(v)
    })
  }
}

export default {
  namespaced: true,
  state,
  mutations,
  getters,
  actions
}
