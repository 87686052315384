import { getAxiosObject } from '@/utils/library'
import { mutationUpdateAfter } from '@/utils/update_after'
import { commonGetters, commonActions } from '@/store/store_utils'

const dataName = 'staffReservationColumns'

const state = {
  staffReservationColumns: [],
  dataIndexedByColumnId: {},
  updatedAt: '20101010101010'
}

const getters = {
  ...commonGetters(dataName, ['getData', 'getUpdatedAt'], { filterDel: true }),
  getDataByColumnId(state) {
    if (Object.keys(state.dataIndexedByColumnId).length === 0) {
      mutations.setIndexed(state)
    }
    return reservationColumnId =>
      state.dataIndexedByColumnId[reservationColumnId]
  }
}

const actions = {
  ...commonActions(['setData', 'setUpdatedAt', 'updateAfter']),
  async toggleShowFlg({ commit }, id) {
    try {
      const axiosObject = getAxiosObject()
      const res = await axiosObject.put('/staff-reservation-columns', { id })
      if (res.status === 200) {
        commit('updateAfter', res.data.staffReservationColumns)
        return true
      }
    } catch (error) {
      return error.response?.data?.message
        ? error.response.data.message === 'no data'
          ? `no ${error.response.data.extra}`
          : error.response.data.message
        : 'server error'
    }
  },
  async updateOrder({ commit }, orderIds) {
    try {
      const axiosObject = getAxiosObject()
      const res = await axiosObject.put('/staff-reservation-columns/order', {
        orderIds
      })
      if (res.status === 200) {
        commit('updateAfter', res.data.staffReservationColumns)
        return true
      }
    } catch (error) {
      return error.response?.data?.message
        ? error.response.data.message
        : 'server error'
    }
  }
}

export const setIndexedByColumnIdData = data => {
  return Object.assign(
    {},
    ...data.map(datum => ({ [datum.reservationColumnId]: datum }))
  )
}

export const updateIndexedByColumnIdData = (state, data) => {
  if (Array.isArray(data) && data.length !== 0) {
    if (Object.keys(state.dataIndexedByColumnId).length === 0) {
      state.dataIndexedByColumnId = setIndexedByColumnIdData(state[dataName])
    } else {
      data.forEach(datum => {
        const reservationColumnId = datum.reservationColumnId
        const updatedAt = datum.updatedAt
        if (
          state.dataIndexedByColumnId[reservationColumnId] === undefined ||
          state.dataIndexedByColumnId[reservationColumnId].updatedAt <=
            updatedAt
        ) {
          state.dataIndexedByColumnId[reservationColumnId] = datum
        }
      })
    }
  }
}

const mutations = {
  setData(state, staffReservationColumns) {
    state[dataName] = staffReservationColumns.sort((a, b) => a.order - b.order)
    state.dataIndexedByColumnId = {}
  },
  setUpdatedAt(state, updatedAt) {
    state.updatedAt = updatedAt
  },
  updateAfter(state, data) {
    mutationUpdateAfter(state, data, dataName)
    state[dataName].sort((a, b) => a.order - b.order)
    updateIndexedByColumnIdData(state, data)
  },
  setIndexed(state) {
    state.dataIndexedByColumnId = setIndexedByColumnIdData(state[dataName])
  }
}

export default {
  namespaced: true,
  state,
  getters,
  actions,
  mutations
}
