import { getAxiosObject, axiosObjectForPyxosManage } from '@/utils/library'
import {
  commonGetters,
  commonActions,
  commonMutations
} from '@/store/store_utils'

const dataName = 'notices'

const state = {
  notices: [],
  dataIndexedById: {},
  updatedAt: '20101010101010',
  count: 0
}

const getters = {
  ...commonGetters(
    dataName,
    ['getData', 'getDataById', 'getUpdatedAt', 'getCount'],
    { filterDel: true }
  )
}

const actions = {
  ...commonActions(['setData', 'setUpdatedAt', 'updateAfter', 'setCount']),
  async readAll({ commit }, readIds) {
    try {
      const axiosObject = getAxiosObject()
      const res = await axiosObject.put('/notices/all', { readIds })
      if (res.status === 200) {
        commit('read', res.data.readIds)
        commit('setCount', res.data.noticesNoReadCount)
        return true
      }
    } catch (error) {
      return false
    }
  },
  async read({ commit }, id) {
    try {
      const axiosObject = getAxiosObject()
      const res = await axiosObject.put('/' + dataName, { id })
      if (res.status === 200) {
        commit('read', [res.data.id])
        commit('setCount', res.data.noticesNoReadCount)
        return true
      }
    } catch (error) {
      if (error.response?.data?.message) {
        if (error.response.data.extra?.data) {
          commit('setData', error.response.data.extra.data)
        }
        return error.response.data.message
      } else {
        return 'server error'
      }
    }
  },
  async delete({ commit }, id) {
    try {
      const axiosObject = getAxiosObject()
      const res = await axiosObject.delete('/' + dataName, { data: { id } })
      if (res.status === 200) {
        commit('delete', res.data.id)
        commit('setCount', res.data.noticesNoReadCount)
        return 'success'
      }
    } catch (error) {
      if (error.response?.data?.extra?.data) {
        commit('setData', error.response.data.extra.data)
        return error.response.data.message
      } else {
        return 'server error'
      }
    }
  },
  async search(
    { commit },
    { startDate, endDate, page, sortType, order, filter }
  ) {
    try {
      const axiosObject = getAxiosObject()
      const res = await axiosObject.get('/notices/search', {
        params: { startDate, endDate, page, sortType, order, filter }
      })
      if (res.status === 200) {
        if (res.data.notices.length > 0) {
          commit('updateAfterSearch', res.data.notices)
        }
        commit('setCount', res.data.noticesNoReadCount)
        return {
          result: true,
          notices: res.data.notices,
          hitAllDataCounts: res.data.hitAllDataCounts
        }
      }
    } catch (error) {
      return { result: false }
    }
  },
  // ピクオス側で各医院にお知らせを送る(作成)際に使用するAPI
  async create({ commit }, { notice, allSendFlg, sendClinicIds }) {
    try {
      const axiosObject = axiosObjectForPyxosManage()
      const res = await axiosObject.post('/' + dataName, {
        notice: notice,
        allSendFlg: allSendFlg,
        sendClinicIds: sendClinicIds
      })
      if (res.status === 200) {
        return true
      }
    } catch (error) {
      return false
    }
  }
}

const mutations = {
  ...commonMutations(
    dataName,
    [
      'setData',
      'setUpdatedAt',
      'read',
      'delete',
      'updateAfter',
      'updateAfterSearch',
      'setCount'
    ],
    { indexedById: true }
  )
}

export default {
  namespaced: true,
  state,
  mutations,
  getters,
  actions
}
