<template>
  <div class="auto-input-form">
    <div class="heading" :style="{ height: headingHeight }">
      <div class="text"><slot></slot></div>
      <div class="required" v-if="requiredFlg">必須</div>
    </div>
    <div class="body">
      <validation-provider
        class="validate"
        :rules="{
          ...validationRules,
          requiredRule: requiredFlg,
          notSurrogatePair: true
        }"
        :immediate="immediate"
        v-slot="{ errors }"
      >
        <div class="input-button">
          <base-text-box
            class="input"
            :value="value"
            :maxlength="maxlength"
            :max="max"
            :min="min"
            :placeholder="placeholder"
            :type="type"
            :disabled="disabled"
            :disabledColorWhite="disabledColorWhite"
            :styles="inputStyle"
            :fullWidthNumbersToHalfWidthNumbers="
              fullWidthNumbersToHalfWidthNumbers
            "
            v-model="input"
          />
          <base-button-plus
            v-if="buttonShowFlg"
            class="button"
            :text="buttonText"
            :styles="buttonStyle"
            :disabled="
              buttonText === '住所自動入力' &&
                (value === '' ||
                  errors.includes('7桁の半角数字で入力して下さい'))
            "
            @click="$emit('click', input)"
          />
        </div>
        <div v-if="errorShowFlg" class="error">
          <div v-for="(error, index) in errors" :key="index">{{ error }}</div>
        </div>
      </validation-provider>
    </div>
  </div>
</template>

<script>
import BaseTextBox from '@/components/parts/atoms/BaseTextBox'
import BaseButtonPlus from '@/components/parts/atoms/BaseButtonPlus'
import { ValidationProvider } from 'vee-validate'
import '@/utils/validation_rules'

export default {
  name: 'AutoInputForm',

  components: {
    BaseTextBox,
    BaseButtonPlus,
    ValidationProvider
  },

  props: {
    value: { type: [String, Number], default: '' },
    requiredFlg: { type: Boolean, default: false },
    validationRules: { type: Object },
    immediate: { type: Boolean, default: false },
    maxlength: { type: Number },
    max: { type: Number },
    min: { type: Number },
    placeholder: { type: String, default: '' },
    type: { type: String, default: 'text' },
    disabled: { type: Boolean, default: false },
    disabledColorWhite: { type: Boolean, default: false },
    inputStyle: { type: Object },
    buttonShowFlg: { type: Boolean, default: true },
    buttonText: { type: String, default: '' },
    buttonStyle: { type: Object },
    errorShowFlg: { type: Boolean, default: true },
    fullWidthNumbersToHalfWidthNumbers: { type: Boolean, default: false }
  },

  computed: {
    input: {
      get() {
        return this.value
      },
      set(val) {
        this.$emit('input', val)
      }
    },
    headingHeight() {
      return this.inputStyle
        ? this.inputStyle.height
          ? this.inputStyle.height
          : ''
        : ''
    }
  }
}
</script>

<style lang="scss" scoped>
.auto-input-form {
  display: flex;
  align-items: flex-start;
  > .heading {
    display: flex;
    align-items: center;
    height: 33px;
    width: 40%;
    font-size: 15px;
    > .required {
      width: 30px;
      margin-left: 10px;
      font-size: 13px;
      color: #{$tomato};
    }
  }
  > .body {
    width: 60%;
    @include validate-message();
    > .validate {
      width: 100%;
      > .input-button {
        display: flex;
        align-items: center;
        > .input {
          margin-right: 10px;
        }
      }
    }
  }
}
</style>
