import { getAxiosObject } from '@/utils/library'
import {
  commonGetters,
  commonActions,
  commonMutations
} from '@/store/store_utils'

const dataName = 'sendMails'

const state = {
  sendMails: [],
  dataIndexedById: {},
  updatedAt: '20101010101010'
}

const getters = {
  ...commonGetters(dataName, ['getData', 'getDataById', 'getUpdatedAt'], {
    filterDel: true
  })
}

const actions = {
  ...commonActions(['setData', 'setUpdatedAt', 'updateAfter']),
  async delete({ commit }, id) {
    try {
      const axiosObject = getAxiosObject()
      const res = await axiosObject.delete('/send-mails', { data: { id } })
      if (res.status === 200) {
        commit('delete', res.data.id)
        return true
      }
    } catch (error) {
      if (error.response?.data?.message) {
        if (error.response.data.extra?.data) {
          commit('setData', error.response.data.extra.data)
        }
        return error.response.data.message
      } else {
        return 'server error'
      }
    }
  },
  async create({ commit }, { ownerIds, title, message, category }) {
    try {
      const axiosObject = getAxiosObject()
      const res = await axiosObject.post('/send-mails', {
        ownerIds,
        title,
        message,
        category
      })
      if (res.status === 200) {
        commit('updateAfter', [res.data.sendMail])
        return { result: true, notSendOwnerIds: res.data.notSendOwnerIds }
      }
    } catch (error) {
      return error.response?.data?.message && error.response?.data?.extra
        ? error.response.data.extra
        : 'server error'
    }
  },
  async search(
    { commit },
    { startDate, endDate, sortType, order, filter, page }
  ) {
    try {
      const axiosObject = getAxiosObject()
      const res = await axiosObject.get('/send-mails', {
        params: { startDate, endDate, sortType, order, filter, page }
      })
      commit('updateAfterSearch', res.data.sendMails)
      return {
        result: 'success',
        hitAllDataCounts: res.data.hitAllDataCounts,
        sendMails: res.data.sendMails
      }
    } catch (error) {
      return { result: 'failure' }
    }
  }
}

const mutations = {
  ...commonMutations(
    dataName,
    ['setData', 'setUpdatedAt', 'delete', 'updateAfter', 'updateAfterSearch'],
    { indexedById: true, csvColumns: ['ownerIds'] }
  )
}

export default {
  namespaced: true,
  state,
  mutations,
  getters,
  actions
}
