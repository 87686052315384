import {
  commonGetters,
  commonMutations,
  commonActions
} from '@/store/store_utils'
import { toStateName } from '@/utils/convert_string'

const dataName = 'examinationTopics'
const keys = ['examinationTopicClassOriginalId']

const state = {
  examinationTopics: [],
  dataIndexedById: {},
  dataIndexedByOriginalId: {},
  updatedAt: '20101010101010'
}
keys.forEach(key => {
  state[toStateName(key)] = null
})

const getters = {
  ...commonGetters(
    dataName,
    ['getDataById', 'getDataByOriginalId', 'getUpdatedAt'],
    { getDataBy: keys }
  )
}

const actions = {
  ...commonActions(['setData', 'setUpdatedAt', 'updateAfter'])
}

const mutations = {
  ...commonMutations(dataName, ['setData', 'setUpdatedAt', 'updateAfter'], {
    order: true,
    indexedById: true,
    indexedByOriginalId: true,
    setDataBy: keys
  })
}

export default {
  namespaced: true,
  state,
  getters,
  actions,
  mutations
}
