<template>
  <div class="image-tag-area">
    <div class="tag">
      <validation-provider
        :rules="{ notSurrogatePair: true }"
        v-slot="{ errors }"
      >
        <base-data-list
          v-model="newTag"
          :placeholder="'# タグ'"
          :maxlength="15"
          :styles="styles"
          :selectData="imageTags"
          @decide="setNewTag"
        />
        <div class="error">{{ errors[0] }}</div>
      </validation-provider>
    </div>
    <div class="tags" :style="height">
      <div class="tag" v-for="(tagName, i) in tagNames" :key="i">
        <span>{{ `# ${tagName}` }}</span
        ><span
          class="delete-button"
          @click="deleteTag(tagName, tagIds[i])"
          data-test="delete-button"
          >{{ ` ×` }}</span
        >
      </div>
    </div>
  </div>
</template>

<script>
import BaseDataList from '@/components/parts/atoms/BaseDataList'
import { ValidationProvider } from 'vee-validate'
import { VALID_SURROGATE_PAIR_REGEX } from '@/utils/define'
import '@/utils/validation_rules'
import { mapGetters } from 'vuex'

export default {
  name: 'ImageTagArea',

  components: {
    BaseDataList,
    ValidationProvider
  },

  props: {
    newTags: { type: Array },
    tagIds: { type: Array },
    tagNames: { type: Array },
    styles: {
      type: Object,
      validator: function(obj) {
        return Object.values(obj).every(v => typeof v === 'string')
      }
    },
    height: {
      type: Object,
      validator: function(obj) {
        return Object.values(obj).every(v => typeof v === 'string')
      }
    }
  },

  data() {
    return {
      newTag: ''
    }
  },

  computed: {
    ...mapGetters({
      imageTags: 'imageTags/getData'
    })
  },

  methods: {
    setNewTag(tagName) {
      this.newTag = ''
      const guardClause =
        VALID_SURROGATE_PAIR_REGEX.test(tagName) ||
        this.tagNames.some(v => v === tagName) ||
        tagName === ''
      if (guardClause) return
      if (!this.imageTags.some(v => v.name === tagName)) {
        this.$emit('set', { tagName, newTag: tagName })
      } else {
        const imageTagId = this.imageTags.find(v => v.name === tagName).id
        this.$emit('set', { tagName, imageTagId: String(imageTagId) })
      }
    },
    deleteTag(tagName, imageTagId) {
      this.$emit('delete', { tagName, imageTagId })
    }
  }
}
</script>

<style lang="scss" scoped>
.image-tag-area {
  > .tag {
    display: flex;
    @include validate-message();
  }
  > .tags {
    margin-top: 20px;
    width: 240px;
    display: flex;
    flex-wrap: wrap;
    overflow-y: auto;
    > .tag {
      height: 18px;
      line-height: 18px;
      font-size: 12px;
      color: #{$gray};
      border: 1px solid #{$gray};
      padding: 0 3px;
      border-radius: 50px;
      margin-right: 5px;
      margin-bottom: 5px;
      animation: zoomIn 1.5s cubic-bezier(0.25, 1, 0.5, 1) 1 forwards;
      > .delete-button {
        @include hover();
      }
    }
  }
}
</style>
