<template>
  <div class="base-button-medium-orange">
    <button
      type="button"
      :disabled="disabled || preventContinuousClickFlg"
      @click="handleClick"
      :style="styles"
      data-e2e="base-button-medium-orange"
    >
      <span><slot /> </span>
    </button>
  </div>
</template>

<script>
export default {
  name: 'BaseButtonMediumOrange',

  props: {
    disabled: {
      type: Boolean,
      default: false
    },
    styles: {
      type: Object,
      validator: function(obj) {
        return Object.values(obj).every(v => typeof v === 'string')
      }
    }
  },

  data() {
    return {
      preventContinuousClickFlg: false,
      id: null
    }
  },

  beforeDestroy() {
    clearTimeout(this.id)
  },

  methods: {
    handleClick() {
      this.preventContinuousClickFlg = true
      this.$emit('click')
      this.id = setTimeout(() => {
        this.preventContinuousClickFlg = false
      }, 500)
    }
  }
}
</script>

<style lang="scss" scoped>
.base-button-medium-orange {
  > button {
    height: 44px;
    width: 200px;
    border-radius: 6px;
    background-color: #{$pumpkin};
    cursor: pointer;
    border: none;
    color: #{$white};
    &:disabled {
      pointer-events: none;
      background-color: #{$light-grey};
      color: #{$brown-gray};
    }
    &:hover {
      opacity: 0.8;
    }
    &:active {
      margin: 2px auto 0 auto;
      height: 42px;
    }
    > span {
      height: 29px;
      font-size: 15px;
      font-weight: bold;
      line-height: 1.45;
    }
  }
}
</style>
