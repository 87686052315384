import { getAxiosObject } from '@/utils/library'

const state = {
  token: '',
  tokenIssuingTime: '20101010101010',
  loginStaffId: 0,
  adminFlg: 0,
  lookOnlyFlg: 0,
  supportFlg: 0,
  supportLoginStartTime: null,
  supportLoginEndTime: null,
  backVersion: '',
  karteFlg: 0,
  smsFlg: 0,
  showLogoutPopupFlg: false,
  canGoToLockedPage: false,
  clientKey: '',
  lockingClientKeys: null,
  selfLockParams: {
    patientId: 0,
    medicalRecordOriginalId: 0,
    hospitalizationId: 0
  },
  flashLockParams: {
    patientId: 0,
    medicalRecordOriginalId: 0,
    hospitalizationId: 0
  }
}

const getters = {
  showLogoutPopupFlg(state) {
    return state.showLogoutPopupFlg
  },
  token(state) {
    return state.token
  },
  tokenIssuingTime(state) {
    return state.tokenIssuingTime
  },
  loginStaffId(state) {
    return state.loginStaffId
  },
  adminFlg(state) {
    return state.adminFlg
  },
  lookOnlyFlg(state) {
    return state.lookOnlyFlg
  },
  supportFlg(state) {
    return state.supportFlg
  },
  backVersion(state) {
    return state.backVersion
  },
  karteFlg(state) {
    return state.karteFlg
  },
  smsFlg(state) {
    return state.smsFlg
  },
  canGoToLockedPage(state) {
    return state.canGoToLockedPage
  },
  clientKey(state) {
    return state.clientKey
  },
  lockingClientKeys(state) {
    return state.lockingClientKeys
  },
  selfLockParams(state) {
    return state.selfLockParams
  },
  flashLockParams(state) {
    return state.flashLockParams
  },
  isAnotherClientLocking: state => ({
    patientId,
    medicalRecordOriginalId,
    hospitalizationId
  }) => {
    const selfClientKey = state.clientKey
    const patientClientKey = state.lockingClientKeys.patient[patientId]
    const medicalRecordClientKey =
      state.lockingClientKeys.medicalRecord[medicalRecordOriginalId]
    const hospitalizationClientKey =
      state.lockingClientKeys.hospitalization[hospitalizationId]
    const lockingClientKey =
      patientClientKey ?? medicalRecordClientKey ?? hospitalizationClientKey
    const isAnotherClientLocking = lockingClientKey
      ? lockingClientKey !== selfClientKey
      : false
    return isAnotherClientLocking
  },
  isFlashLocked: state => ({ patientId, medicalRecordOriginalId }) => {
    const flashLockParams = state.flashLockParams
    const isFlashLocked =
      flashLockParams.patientId === patientId &&
      flashLockParams.medicalRecordOriginalId === medicalRecordOriginalId
    return isFlashLocked
  }
}

const actions = {
  authLogin({ commit }, obj) {
    commit('login', obj)
  },
  authLogout({ commit }) {
    commit('logout')
  },
  showLogoutPopup({ commit }) {
    commit('showLogoutPopup')
  },
  async refreshToken({ commit }) {
    try {
      const axiosObject = getAxiosObject()
      const res = await axiosObject.post('/refresh-token', {
        localKey: localStorage.getItem('localKey')
      })
      commit('refreshToken', res.data)
      return 'success'
    } catch {
      return 'failure'
    }
  },
  canGoToLockedPage({ commit }, boolean) {
    commit('canGoToLockedPage', boolean)
  },
  setLockingClientKeys(
    { commit },
    { patient, medicalRecord, hospitalization }
  ) {
    commit('setLockingClientKeys', { patient, medicalRecord, hospitalization })
  },
  setSelfLockParams(
    { commit },
    { patientId, medicalRecordOriginalId, hospitalizationId }
  ) {
    commit('setSelfLockParams', {
      patientId,
      medicalRecordOriginalId,
      hospitalizationId
    })
  },
  setFlashLockParams(
    { commit },
    { patientId, medicalRecordOriginalId, hospitalizationId }
  ) {
    commit('setFlashLockParams', {
      patientId,
      medicalRecordOriginalId,
      hospitalizationId
    })
  },
  lockKarte(
    { commit, rootGetters, state },
    { patientId, medicalRecordOriginalId, hospitalizationId }
  ) {
    const socket = rootGetters['petorelu/get'].socket
    socket.emit('lockKarte', {
      token: state.token,
      patientId,
      medicalRecordOriginalId,
      hospitalizationId
    })
    commit('setSelfLockParams', {
      patientId,
      medicalRecordOriginalId,
      hospitalizationId
    })
  },
  unlockKarte(
    { rootGetters, state },
    { patientId, medicalRecordOriginalId, hospitalizationId }
  ) {
    const socket = rootGetters['petorelu/get'].socket
    socket.emit('unlockKarte', {
      token: state.token,
      patientId,
      medicalRecordOriginalId,
      hospitalizationId
    })
  },
  flashLockKarte(
    { rootGetters, state },
    { patientId, medicalRecordOriginalId, hospitalizationId }
  ) {
    const socket = rootGetters['petorelu/get'].socket
    socket.emit('flashLockKarte', {
      token: state.token,
      patientId,
      medicalRecordOriginalId,
      hospitalizationId
    })
  }
}

const mutations = {
  login(state, obj) {
    state.token = obj.token
    state.tokenIssuingTime = obj.tokenIssuingTime
    state.loginStaffId = obj.staff.id
    state.adminFlg = obj.staff.adminFlg
    state.lookOnlyFlg = obj.staff.lookOnlyFlg
    state.supportFlg = obj.staff.supportFlg
    state.supportLoginStartTime = obj.staff.supportLoginStartTime
    state.supportLoginEndTime = obj.staff.supportLoginEndTime
    state.backVersion = obj.backVersion
    state.karteFlg = obj.karteFlg
    state.smsFlg = obj.smsFlg
    state.clientKey = obj.clientKey
  },
  logout(state) {
    state.token = ''
    state.tokenIssuingTime = '20101010101010'
    state.loginStaffId = 0
    state.showLogoutPopupFlg = false
    state.clientKey = ''
  },
  showLogoutPopup(state) {
    state.showLogoutPopupFlg = true
  },
  refreshToken(state, obj) {
    state.token = obj.token
    state.tokenIssuingTime = obj.tokenIssuingTime
  },
  canGoToLockedPage(state, boolean) {
    state.canGoToLockedPage = boolean
  },
  setLockingClientKeys(state, { patient, medicalRecord, hospitalization }) {
    state.lockingClientKeys = { patient, medicalRecord, hospitalization }
  },
  setSelfLockParams(
    state,
    { patientId, medicalRecordOriginalId, hospitalizationId }
  ) {
    state.selfLockParams = {
      patientId,
      medicalRecordOriginalId,
      hospitalizationId
    }
  },
  setFlashLockParams(
    state,
    { patientId, medicalRecordOriginalId, hospitalizationId }
  ) {
    const isEditingKarte = state.selfLockParams.medicalRecordOriginalId !== 0
    if (isEditingKarte) {
      state.flashLockParams = {
        patientId,
        medicalRecordOriginalId,
        hospitalizationId
      }
    }
  }
}

export default {
  namespaced: true,
  state,
  getters,
  actions,
  mutations
}
