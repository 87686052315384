<template>
  <div class="password-reset-send-mail">
    <validation-observer v-slot="{ invalid }" class="observer">
      <main-header :logined-flg="false" />
      <div class="page-title">
        パスワードの再設定
      </div>
      <div class="password-reset-progress-bar">
        <div class="progress-bar-list-text orange">
          メールアドレス入力
        </div>
        <div class="arrow"></div>
        <div class="progress-bar-list-text">
          本人確認
        </div>
        <div class="arrow"></div>
        <div class="progress-bar-list-text">
          パスワード設定
        </div>
        <div class="arrow"></div>
        <div class="progress-bar-list-text">
          完了
        </div>
      </div>
      <div class="password-reset-text">
        ご登録いただいたメールアドレスを入力してください。
      </div>
      <div class="password-reset-input-mail">
        <div class="password-reset-input-label">
          メールアドレス<span class="red"> ※必須</span>
        </div>
        <validation-provider
          :rules="{ emailRule: true, requiredRule: true }"
          v-slot="{ errors }"
          class="validate"
        >
          <base-text-box
            v-bind="textBox"
            v-model="email"
            name="email"
            :maxlength="254"
          />
          <div class="error" data-test="email-err">{{ errors[0] }}</div>
        </validation-provider>
      </div>
      <div class="submit-button">
        <base-button-large-orange
          @click="handleSendMail"
          :disabled="invalid || waitFlg"
          >送信</base-button-large-orange
        >
      </div>
      <div class="back-button">
        <base-button-large-white @click="backLogin"
          >もどる</base-button-large-white
        >
      </div>
    </validation-observer>
    <announce-popup
      v-if="alertFlg"
      @close="closeAlert"
      :title="title"
      :buttons="buttons"
      :type="type"
      >{{ popupMessage }}</announce-popup
    >
  </div>
</template>

<script>
import MainHeader from '@/components/parts/organisms/MainHeader'
import BaseTextBox from '@/components/parts/atoms/BaseTextBox'
import BaseButtonLargeOrange from '@/components/parts/atoms/BaseButtonLargeOrange'
import BaseButtonLargeWhite from '@/components/parts/atoms/BaseButtonLargeWhite'
import AnnouncePopup from '@/components/popups/AnnouncePopup'
import ApiPasswordReset from '@/components/mixins/ApiPasswordReset'
import { ValidationProvider, ValidationObserver } from 'vee-validate'

export default {
  name: 'PasswordResetSendMail',

  components: {
    MainHeader,
    BaseTextBox,
    BaseButtonLargeOrange,
    BaseButtonLargeWhite,
    AnnouncePopup,
    ValidationProvider,
    ValidationObserver
  },

  mixins: [ApiPasswordReset],

  data() {
    return {
      email: '',
      textBox: {
        type: 'email',
        placeholder: 'test@mail.com',
        styles: {
          width: '410px',
          height: '45px'
        }
      },
      alertFlg: false,
      title: '完了',
      buttons: ['閉じる'],
      type: 'success',
      popupMessage: '',
      waitFlg: false
    }
  },

  methods: {
    async handleSendMail() {
      this.waitFlg = true
      const result = await this.mixinSendMail(this.email)
      if (result === true) {
        this.$router.push({ name: 'password-reset-send-after' })
      } else if (result === 'no staff') {
        this.popupMessage = '入力されたメールアドレスは登録されておりません'
      } else if (result === 'no clinic') {
        this.popupMessage =
          '入力されたスタッフの医院アカウントは現在削除されており、ペトレルプラスをご利用できません'
      } else {
        this.popupMessage = 'メールの送信ができません'
      }
      this.title = '失敗'
      this.type = 'failure'
      this.alertFlg = true
      this.waitFlg = false
    },
    closeAlert() {
      this.alertFlg = false
    },
    backLogin() {
      this.$router.push({ name: 'login' })
    }
  }
}
</script>

<style lang="scss" scoped>
.password-reset-send-mail {
  > .observer {
    > .page-title {
      margin-top: 67px;
      font-size: 30px;
      font-weight: bold;
    }
    > .password-reset-progress-bar {
      margin: 27px auto 0;
      width: 630px;
      height: 45px;
      border: solid 2px #{$pumpkin};
      border-radius: 50px;
      box-sizing: border-box;
      display: flex;
      justify-content: space-around;
      align-items: center;
      > .arrow {
        width: 8px;
        height: 8px;
        border-top: 2px solid #{$light-grey};
        border-right: 2px solid #{$light-grey};
        transform: rotate(45deg);
      }
      > .progress-bar-list-text {
        font-size: 15px;
        font-weight: bold;
        color: #{$light-grey};
        &.orange {
          color: #{$pumpkin};
        }
      }
    }
    > .password-reset-text {
      margin: 39px auto 0;
      font-weight: bold;
    }
    > .password-reset-input-mail {
      margin: 38px auto 0;
      width: 755px;
      display: flex;
      @include validate-message();
      > .password-reset-input-label {
        line-height: 45px;
        font-weight: bold;
        margin-right: 25px;
        > .red {
          color: #{$tomato};
        }
      }
      > span {
        > .error {
          text-align: left;
        }
      }
    }
    > .submit-button {
      margin-top: 79px;
    }
    > .back-button {
      margin-top: 23px;
    }
  }
}
</style>
