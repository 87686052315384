import { getAxiosObject } from '@/utils/library'
import {
  commonGetters,
  commonMutations,
  commonActions
} from '@/store/store_utils'
import { toStateName } from '@/utils/convert_string'

const dataName = 'medicines'
const keys = ['medicineSmallClassOriginalId']

const state = {
  medicines: [],
  dataIndexedById: {},
  dataIndexedByOriginalId: {},
  updatedAt: '20101010101010'
}
keys.forEach(key => {
  state[toStateName(key)] = null
})

const getters = {
  ...commonGetters(
    dataName,
    ['getData', 'getDataById', 'getUpdatedAt', 'getDataByOriginalId'],
    { filterDel: true, getDataBy: keys }
  )
}

const actions = {
  ...commonActions(['setData', 'setUpdatedAt', 'updateAfter']),
  async create(
    { commit },
    { medicineLargeClass, medicineMediumClass, medicineSmallClass, medicine }
  ) {
    try {
      const axiosObject = getAxiosObject()
      const res = await axiosObject.post('/medicines', {
        medicineLargeClass,
        medicineMediumClass,
        medicineSmallClass,
        medicine
      })
      if (res.status === 200) {
        commit('updateAfter', [res.data.medicine])
        commit(
          'medicineLargeClasses/updateAfter',
          [res.data.medicineLargeClass],
          { root: true }
        )
        commit(
          'medicineMediumClasses/updateAfter',
          [res.data.medicineMediumClass],
          { root: true }
        )
        commit(
          'medicineSmallClasses/updateAfter',
          [res.data.medicineSmallClass],
          { root: true }
        )
        return true
      }
    } catch (error) {
      if (error.response?.data?.message) {
        if (error.response.data.message === 'no data in clinic') {
          const dataName = error.response.data.extra.dataName
          if (
            dataName === 'medicineLargeClass' ||
            dataName === 'medicineMediumClass' ||
            dataName === 'medicineSmallClass'
          ) {
            commit(`${dataName}es/setData`, error.response.data.extra.data, {
              root: true
            })
          }
          return 'no data in clinic'
        } else if (error.response.data.message === 'no data') {
          return `no ${error.response.data.extra}`
        } else {
          return error.response.data.message
        }
      } else {
        return 'server error'
      }
    }
  },
  async delete({ commit }, id) {
    try {
      const axiosObject = getAxiosObject()
      const res = await axiosObject.delete('/medicines', { data: { id } })
      if (res.status === 200) {
        commit('updateAfter', [res.data.medicine])
        return true
      }
    } catch (error) {
      if (error.response?.data?.extra) {
        if (error.response.data.extra.data) {
          commit('setData', error.response.data.extra.data)
        } else {
          return error.response.data.extra
        }
        return error.response.data.message
      } else {
        return 'server error'
      }
    }
  },
  async update(
    { commit },
    { medicineLargeClass, medicineMediumClass, medicineSmallClass, medicine }
  ) {
    try {
      const axiosObject = getAxiosObject()
      const res = await axiosObject.put('/medicines', {
        medicineLargeClass,
        medicineMediumClass,
        medicineSmallClass,
        medicine
      })
      if (res.status === 200) {
        if (Object.keys(res.data.medicineLargeClass).length > 0) {
          commit(
            'medicineLargeClasses/updateAfter',
            [res.data.medicineLargeClass],
            { root: true }
          )
        }
        if (Object.keys(res.data.medicineMediumClass).length > 0) {
          commit(
            'medicineMediumClasses/updateAfter',
            [res.data.medicineMediumClass],
            { root: true }
          )
        }
        if (Object.keys(res.data.medicineSmallClass).length > 0) {
          commit(
            'medicineSmallClasses/updateAfter',
            [res.data.medicineSmallClass],
            { root: true }
          )
        }
        commit('updateAfter', res.data.medicines)
        return true
      }
    } catch (error) {
      if (error.response?.data?.message) {
        if (error.response.data.message === 'no data in clinic') {
          const dataName = error.response.data.extra.dataName
          if (
            dataName === 'medicineLargeClass' ||
            dataName === 'medicineMediumClass' ||
            dataName === 'medicineSmallClass'
          ) {
            commit(`${dataName}es/setData`, error.response.data.extra.data, {
              root: true
            })
          } else {
            commit('setData', error.response.data.extra.data)
          }
          return 'no data in clinic'
        } else if (error.response.data.message === 'no data') {
          return error.response.data.extra === 'medicine already edited'
            ? 'already edited'
            : 'already deleted'
        } else {
          return error.response.data.message
        }
      } else {
        return 'server error'
      }
    }
  }
}

const mutations = {
  ...commonMutations(dataName, ['setData', 'setUpdatedAt', 'updateAfter'], {
    order: true,
    indexedById: true,
    indexedByOriginalId: true,
    setDataBy: keys
  })
}

export default {
  namespaced: true,
  state,
  getters,
  actions,
  mutations
}
