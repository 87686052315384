import { getAxiosObject } from '@/utils/library'
import {
  commonGetters,
  commonMutations,
  commonActions
} from '@/store/store_utils'
import { toStateName } from '@/utils/convert_string'

const dataName = 'patientVaccines'
const keys = ['patientId']

const state = {
  patientVaccines: [],
  updatedAt: '20101010101010'
}
keys.forEach(key => {
  state[toStateName(key)] = null
})

const getters = {
  ...commonGetters(dataName, ['getUpdatedAt'], {
    getDataBy: keys,
    getDataByIncludeDel: keys
  })
}

const actions = {
  ...commonActions(['setData', 'setUpdatedAt', 'updateAfter']),
  async create({ commit }, { patientVaccines, patientVaccinePeriodGroups }) {
    try {
      const axiosObject = getAxiosObject()
      const res = await axiosObject.post('/patient-vaccines', {
        patientVaccines,
        patientVaccinePeriodGroups
      })
      if (res.status === 200) {
        commit('updateAfter', res.data.patientVaccines)
        commit(
          'patientVaccinePeriods/updateAfter',
          res.data.patientVaccinePeriods,
          {
            root: true
          }
        )
        return true
      }
    } catch (error) {
      return error.response?.data?.message || 'server error'
    }
  },
  async update({ commit }, { patientVaccines, patientVaccinePeriodGroups }) {
    try {
      const axiosObject = getAxiosObject()
      const res = await axiosObject.put('/patient-vaccines', {
        patientVaccines,
        patientVaccinePeriodGroups
      })
      if (res.status === 200) {
        commit('updateAfter', res.data.patientVaccines)
        commit(
          'patientVaccinePeriods/updateAfter',
          res.data.patientVaccinePeriods,
          {
            root: true
          }
        )
        return true
      }
    } catch (error) {
      if (error.response?.data?.extra?.data) {
        commit('setData', error.response.data.extra.data)
      }
      return error.response?.data?.message || 'server error'
    }
  },
  async delete({ commit }, id) {
    try {
      const axiosObject = getAxiosObject()
      const res = await axiosObject.delete('/patient-vaccines', {
        data: { id }
      })
      if (res.status === 200) {
        commit('updateAfter', [res.data.patientVaccine])
        commit(
          'patientVaccinePeriods/updateAfter',
          res.data.patientVaccinePeriods,
          {
            root: true
          }
        )
        return true
      }
    } catch (error) {
      if (error.response?.data?.extra?.data) {
        commit('setData', error.response.data.extra.data)
      }
      return error.response?.data?.message || 'server error'
    }
  }
}

const mutations = {
  ...commonMutations(dataName, ['setData', 'setUpdatedAt', 'updateAfter'], {
    setDataBy: keys
  })
}

export default {
  namespaced: true,
  state,
  getters,
  actions,
  mutations
}
