import { getAxiosObject } from '@/utils/library'
import {
  commonGetters,
  commonActions,
  commonMutations
} from '@/store/store_utils'

const dataName = 'shiftPatterns'

const state = {
  shiftPatterns: [],
  dataIndexedById: {},
  updatedAt: '20101010101010'
}

const getters = {
  ...commonGetters(
    dataName,
    ['getData', 'getDataById', 'selectData', 'selectDataZero', 'getUpdatedAt'],
    { filterDel: true }
  )
}

const actions = {
  ...commonActions(['setData', 'setUpdatedAt', 'updateAfter']),
  async createShiftPattern({ commit }, shiftPattern) {
    try {
      const axiosObject = getAxiosObject()
      const res = await axiosObject.post('/shift-patterns', {
        shiftPattern: shiftPattern
      })
      if (res.status === 200) {
        commit('updateAfter', [res.data.shiftPattern])
        return true
      }
    } catch (error) {
      return error.response?.data?.message === 'no data'
        ? `no ${error.response?.data?.extra}`
        : 'server error'
    }
  },
  async updateShiftPattern({ commit }, shiftPattern) {
    try {
      const axiosObject = getAxiosObject()
      const res = await axiosObject.put('/shift-patterns', {
        shiftPattern
      })
      if (res.status === 200) {
        commit('updateAfter', [res.data.shiftPattern])
        return true
      }
    } catch (error) {
      if (error.response?.data?.message) {
        if (error.response.data.message === 'no data') {
          return `no ${error.response.data.extra}`
        } else {
          if (error.response.data.extra?.data) {
            commit('setData', error.response.data.extra.data)
          }
          return error.response.data.message
        }
      } else {
        return 'server error'
      }
    }
  },
  async deleteShiftPattern({ commit }, shiftPatternId) {
    try {
      const axiosObject = getAxiosObject()
      const res = await axiosObject.delete('/shift-patterns', {
        data: { id: shiftPatternId }
      })
      if (res.status === 200) {
        commit('updateAfter', [res.data.shiftPattern])
        return true
      }
    } catch (error) {
      if (error.response?.data?.message) {
        if (error.response.data.message === 'currently used') {
          return 'using data'
        } else {
          if (error.response.data.extra?.data) {
            commit('setData', error.response.data.extra.data)
          }
          return error.response.data.message
        }
      } else {
        return 'server error'
      }
    }
  }
}

const mutations = {
  ...commonMutations(dataName, ['setData', 'updateAfter', 'setUpdatedAt'], {
    order: true,
    indexedById: true
  })
}

export default {
  namespaced: true,
  state,
  mutations,
  getters,
  actions
}
