<template>
  <div class="base-color-chart" :style="style" data-test="base-color-chart">
    <div class="color-chart">
      <div class="hue" v-for="hue in hues" :key="hue.id">
        <div
          class="chip"
          v-for="color in hue.colors"
          :key="color"
          :style="{ backgroundColor: color }"
          @click="selectColor(color)"
        >
          <div
            v-if="value === color"
            :class="`check-mark ${checkMarkClass(color)}`"
          ></div>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import ColorMethods from '@/components/mixins/ColorMethods'
import { VALID_COLOR_CODE_REGEX } from '@/utils/define'
import { defaultColorSets } from '@/utils/color_library'

export default {
  name: 'BaseColorChart',

  mixins: [ColorMethods],

  data() {
    return {
      style: {}
    }
  },

  props: {
    value: { type: String, default: '#ef6c00' },
    colorSets: {
      type: Array,
      default: () => defaultColorSets,
      validator: function(arr1) {
        return arr1.every(arr2 => {
          return arr2.every(v => {
            return VALID_COLOR_CODE_REGEX.test(v)
          })
        })
      }
    }
  },

  computed: {
    hues() {
      return this.colorSets.map((v, i) => {
        return { id: i + 1, colors: v }
      })
    }
  },

  mounted() {
    this.style = { width: `${this.hues[0].colors.length * 22}px` }
  },

  methods: {
    selectColor(color) {
      this.$emit('click', color)
    },
    checkMarkClass(color) {
      return this.mixinCalculateBright(color) > 154 ? 'black' : 'white'
    }
  }
}
</script>

<style lang="scss" scoped>
.base-color-chart {
  > .color-chart {
    background-color: #{$white};
    > .hue {
      display: flex;
      > .chip {
        cursor: pointer;
        position: relative;
        width: 20px;
        height: 20px;
        margin: 2px;
        border-radius: 10%;
        box-shadow: 0px 0px 2px rgba(0, 0, 0, 0.8);
        &:hover {
          box-shadow: 0px 0px 6px rgba(0, 0, 0, 0.8);
        }
        > .black:before,
        .black:after,
        .white:before,
        .white:after {
          content: '';
          display: block;
          position: absolute;
          width: 8px;
          height: 4px;
          -webkit-transform: rotate(-45deg);
          transform: rotate(-45deg);
          left: 5px;
          top: 6px;
        }
        > .black:after {
          border-left: 2px solid #3e3e3e;
          border-bottom: 2px solid #3e3e3e;
        }
        > .white:after {
          border-left: 2px solid #ffffff;
          border-bottom: 2px solid #ffffff;
        }
      }
    }
  }
}
</style>
