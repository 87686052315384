<template>
  <div>
    <base-back-ground-cover
      :layerNumber="layerNumber"
      @cover-click="coverClick"
    >
      <div class="popup" :style="{ zIndex }">
        <div v-if="closeMarkFlg" class="close-mark" @click="clickCloseMark">
          ×
        </div>
        <div
          v-if="title"
          class="popup-header"
          v-bind:class="{ 'short-header': shortFlg, orange: headerOrangeFlg }"
          :style="headerStyles"
        >
          <slot name="header">
            <div class="popup-title">{{ title }}</div>
          </slot>
        </div>
        <div class="popup-content" :style="contentStyle">
          <slot name="content"></slot>
        </div>
        <div class="popup-footer" v-bind:class="{ 'short-footer': shortFlg }">
          <slot name="footer">
            <popup-footer-one-button
              v-if="buttons.length === 1"
              :text="buttons[0]"
              :buttonBorderOrangeFlg="buttonBorderOrangeFlg"
              @close="close"
            />
            <popup-footer-two-buttons
              v-if="buttons.length === 2"
              :cancelText="buttons[0]"
              :decisionText="buttons[1]"
              :buttonBorderOrangeFlg="buttonBorderOrangeFlg"
              :disabledFlg="disabledFlg"
              :invalid="invalid"
              @cancel="cancel"
              @decision="decision"
            />
          </slot>
        </div>
      </div>
    </base-back-ground-cover>
  </div>
</template>

<script>
import BaseBackGroundCover from '@/components/parts/atoms/BaseBackGroundCover'
import PopupFooterTwoButtons from '@/components/parts/molecules/PopupFooterTwoButtons'
import PopupFooterOneButton from '@/components/parts/molecules/PopupFooterOneButton'
export default {
  name: 'Popup',
  components: {
    PopupFooterOneButton,
    BaseBackGroundCover,
    PopupFooterTwoButtons
  },
  props: {
    layerNumber: {
      type: Number,
      default: 1
    },
    closeMarkFlg: { type: Boolean, default: true },
    headerStyles: {
      type: Object,
      default: function() {
        return {}
      }
    },
    title: { type: String, default: null },
    headerOrangeFlg: { type: Boolean, default: false },
    buttons: {
      type: Array,
      default: () => ['閉じる', '登録'],
      validator: function(arr) {
        return arr.every(v => typeof v === 'string')
      }
    },
    buttonBorderOrangeFlg: { type: Boolean, default: false },
    disabledFlg: { type: Boolean },
    invalid: { type: Boolean, default: false },
    shortFlg: { type: Boolean, default: false },
    // 見積書一覧以外に必要。見積書一覧ではmargin: 0 autoを消している。
    contentStyle: {
      type: Object,
      default: function() {
        return { margin: '0 auto' }
      }
    }
  },
  computed: {
    zIndex() {
      return this.layerNumber * 1000 + 100
    }
  },
  methods: {
    coverClick() {
      this.$emit('cover-click')
    },
    clickCloseMark() {
      this.$emit('click-close-mark')
    },
    close() {
      this.$emit('close')
    },
    cancel() {
      this.$emit('cancel')
    },
    decision() {
      this.$emit('decision')
    }
  }
}
</script>
<style lang="scss" scoped>
.popup {
  max-height: 98vh;
  max-width: 98vw;
  background-color: white;
  display: flex;
  flex-direction: column;
  justify-content: space-between;
  position: relative;
  > .close-mark {
    position: absolute;
    right: 0;
    top: -6px;
    color: #{$pumpkin};
    font-size: 22px;
    cursor: pointer;
  }
  > .popup-header {
    border-bottom: solid 1px #{$light-grey};
    display: flex;
    height: 35px;
    width: 100%;
    justify-content: center;
    align-items: center;
    &.orange {
      background-color: #{$pumpkin};
      color: #{$white};
    }
    > .popup-title {
      text-align: center;
      width: 100%;
      font-size: 20px;
      font-weight: bold;
    }
  }
  > .popup-content {
    padding: 25px 30px 0 30px;
    overflow: auto;
  }
  > .short-header {
    height: 35px;
  }
  > .popup-footer {
    display: flex;
    margin-top: auto;
    padding: 30px 0;
    justify-content: center;
  }
  > .short-footer {
    padding-top: 5px;
    padding-bottom: 10px;
  }
}
</style>
