import store from '@/store/store'

export default {
  watch: {
    diffFlg: function(diffFlg) {
      diffFlg
        ? store.dispatch('petorelu/popupNgLeave')
        : store.dispatch('petorelu/popupOkLeave')
    }
  },

  beforeDestroy() {
    this.$store.dispatch('petorelu/popupOkLeave')
  }
}
