export const patientRelatedStoreNamesReservationPlan = [
  'anicomPatients',
  'ipetPatients',
  'microchipFacilities',
  'patients',
  'reservationRequests',
  'reservations'
]

export const ownerRelatedStoreNamesReservationPlan = [
  ...patientRelatedStoreNamesReservationPlan,
  'patients'
]

export const ownerRelatedStoreNamesExcludeReservation = ownerRelatedStoreNamesReservationPlan.filter(
  v => v !== 'reservations'
)

// 飼主または患者を削除した時にanicomReportsとanicomReportCancelsは
// 削除してないため入れていない
export const patientRelatedStoreNamesKartePlan = [
  'anicomCIdChecks',
  'anicomPatients',
  'antibodies',
  'antibodyTypes',
  'estimateTreatmentItems',
  'estimates',
  'examinationResults',
  'hospitalizations',
  'ipetPatients',
  'medicalContentImageTexts',
  'medicalContentImages',
  'medicalContents',
  'medicalPayments',
  'medicalRecords',
  'medicalTreatmentItems',
  'microchipFacilities',
  'patientVaccinePeriods',
  'patientVaccines',
  'payments',
  'problems',
  'reservationRequests',
  'reservations',
  'uploadImages',
  'vaccineReminders'
]

export const ownerRelatedStoreNamesKartePlan = [
  ...patientRelatedStoreNamesReservationPlan,
  'patients'
]

export const ownerRelatedStoreNamesExcludeReservationKartePlan = ownerRelatedStoreNamesKartePlan.filter(
  v => v !== 'reservations'
)

export const patientRelatedParentStoreNamesReservationPlan = [
  // 保険
  'anicomPatients',
  'ipetPatients',
  // マイクロチップ
  'microchipFacilities'
]
export const patientRelatedParentStoreNamesKartePlan = [
  // 保険
  'anicomPatients',
  'ipetPatients',
  // 予防接種
  'antibodies',
  'patientVaccines',
  'vaccineReminders',
  // 見積
  'estimates',
  // カルテ
  'medicalRecords',
  // プロブレムリスト
  'problems',
  // マイクロチップ
  'microchipFacilities',
  // 承認番号取得
  'anicomCIdChecks',
  'ipetChecks'
]

export const patientRelatedConvertNames = {
  // 保険
  anicomPatients: 'アニコム保険',
  ipetPatients: 'アイペット保険',
  // 予防接種
  antibodies: '予防接種履歴',
  patientVaccines: '予防接種',
  vaccineReminders: '予防接種連絡履歴',
  // 見積
  estimates: '見積',
  // カルテ
  medicalRecords: 'カルテ',
  // プロブレムリスト
  problems: 'プロブレムリスト',
  // マイクロチップ
  microchipFacilities: 'マイクロチップ',
  // 承認番号取得
  anicomCIdChecks: 'アニコム保険の承認番号',
  ipetChecks: 'アイペット保険の承認番号'
}
