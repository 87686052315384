<template>
  <focus-trap
    v-model="activeTrap"
    :escapeDeactivates="false"
    :clickDeactivates="false"
  >
    <div class="unsaved-leave-popup" @keyup.esc.stop="cancel" tabindex="-1">
      <announce-popup
        v-if="showFlg"
        :title="'ページ移動確認'"
        :type="'alert'"
        :buttons="['キャンセル', '移動']"
        :layerNumber="layerNumber"
        @cancel="cancel"
        @decision="decision"
      >
        {{ message[popupType] }} 移動しますか？
      </announce-popup>
    </div>
  </focus-trap>
</template>

<script>
import AnnouncePopup from '@/components/popups/AnnouncePopup'
import { FocusTrap } from 'focus-trap-vue'

export default {
  name: 'UnsavedLeavePopup',
  components: {
    AnnouncePopup,
    FocusTrap
  },
  props: {
    layerNumber: { type: Number, default: 1 },
    popupType: { type: String, default: 'default' }
  },
  data() {
    return {
      activeTrap: false,
      message: {
        default: '入力内容を保存せずにページ移動しようとしています。\n ',
        print: ''
      }
    }
  },
  computed: {
    showFlg() {
      return this.$store.getters['petorelu/get'].leaveDialogShow
    }
  },
  watch: {
    showFlg: function() {
      this.activeTrap = this.showFlg
    }
  },
  beforeDestroy() {
    this.$store.dispatch('petorelu/leaveReset')
  },
  methods: {
    cancel() {
      this.$store.dispatch('petorelu/hideLeaveDialog')
      this.$store.dispatch('petorelu/cancelBack')
      this.$emit('cancel')
    },
    decision() {
      this.$store.dispatch('petorelu/hideLeaveDialog')
      this.$emit('decision')
      this.$store.dispatch('petorelu/okLeave')
      this.$store.dispatch('petorelu/popupOkLeave')
      const to = this.$store.getters['petorelu/get'].to
      if (to) this.$router.push(to) // to がないことはあり得ないが、テストの時出るエラーを防ぐため
    }
  }
}
</script>
<style lang="scss" scoped>
.unsaved-leave-popup {
  position: absolute;
  top: 0;
  left: 0;
}
</style>
