<template>
  <div class="vrainers">
    <base-loading :waitFlg="waitFlg" />
    <h2>ブレイナーズデータ変換</h2>
    <div>
      <div>TOWNER</div>
      <input type="file" @change="uploadCsv($event, 'owner')" />
      <div>TPET</div>
      <input type="file" @change="uploadCsv($event, 'pet')" />
      <div>TTEL</div>
      <input type="file" @change="uploadCsv($event, 'tel')" />
      <div>TMEMO</div>
      <input type="file" @change="uploadCsv($event, 'memo')" />
      <div>TINSURANCE</div>
      <input type="file" @change="uploadCsv($event, 'insurance')" />
      <div>MSPECIES</div>
      <input type="file" @change="uploadCsv($event, 'species')" />
      <div>MSTAFF</div>
      <input type="file" @change="uploadCsv($event, 'staff')" />
      <div>clinicId</div>
      <input type="text" v-model="clinicId" />
      <div>pass</div>
      <input type="password" v-model="pass" />
    </div>
    <base-button-medium-orange class="button" @click="addData"
      >登録</base-button-medium-orange
    >
    <base-button-medium-orange class="button" @click="deleteData"
      >削除</base-button-medium-orange
    >
  </div>
</template>

<script>
import Encoding from 'encoding-japanese'
import BaseButtonMediumOrange from '@/components/parts/atoms/BaseButtonMediumOrange'
import BaseLoading from '@/components/parts/atoms/BaseLoading'
import { getAxiosObject } from '@/utils/library'
export default {
  name: 'Vrainers',

  components: {
    BaseButtonMediumOrange,
    BaseLoading
  },

  data() {
    return {
      uploadFile: {},
      waitFlg: false,
      fileData: null,
      timeOut: null,
      owner: null,
      pet: null,
      tel: null,
      memo: null,
      species: null,
      staff: null,
      insurance: null,
      clinicId: 0,
      pass: ''
    }
  },

  computed: {},

  watch: {},

  methods: {
    async deleteData() {
      try {
        const axiosObject = getAxiosObject()
        const res = await axiosObject.post('/vrainers/dell', {
          clinicId: this.clinicId,
          pass: this.pass
        })
      } catch (err) {
        //
      }
    },
    async addData() {
      try {
        const axiosObject = getAxiosObject()
        const res = await axiosObject.post('/vrainers', {
          clinicId: this.clinicId,
          pass: this.pass,
          rawOwner: this.owner,
          rawPet: this.pet,
          rawTel: this.tel,
          rawMemo: this.memo,
          rawSpecies: this.species,
          rawStaff: this.staff,
          rawInsurance: this.insurance
        })
        this.downloadFunc(res.data.lostPetsCsv, '未入力患者')
        this.downloadFunc(res.data.ownerChangesCsv, '変更飼主リスト')
        this.downloadFunc(res.data.patientChangesCsv, '変更患者リスト')
      } catch (err) {
        //
      }
    },
    uploadCsv(e, dataName) {
      this.waitFlg = true
      this.timeOut = setTimeout(async () => {
        const res = await this.readCsv(e)
        if (res[0]) {
          this[dataName] = res[1]
        }
        this.waitFlg = false
      }, 100)
    },
    readCsv(e) {
      return new Promise((resolve, reject) => {
        const file = e.target.files[0]
        const reader = new FileReader()
        file ? reader.readAsArrayBuffer(file) : (this.waitFlg = false)
        reader.onload = () => {
          const binary = new Uint8Array(reader.result)
          let csvStr
          switch (Encoding.detect(binary)) {
            case 'UTF8':
              csvStr = new TextDecoder().decode(binary)
              break
            case 'SJIS': {
              const utf8Array = Encoding.convert(binary, {
                to: 'UNICODE',
                from: 'SJIS'
              })
              csvStr = Encoding.codeToString(utf8Array)
              break
            }
          }
          resolve([true, csvStr])
        }
        reader.onerror = () => reject([false])
      })
    },
    downloadFunc(csvStr, fileName) {
      const bom = new Uint8Array([0xef, 0xbb, 0xbf])
      const blob = new Blob([bom, csvStr], { type: 'text/csv' })
      if (window.navigator.msSaveBlob) {
        window.navigator.msSaveBlob(blob, fileName)
      } else {
        const url = window.URL.createObjectURL(blob)
        const anchor = document.createElement('a')
        anchor.href = url
        anchor.download = fileName
        anchor.click()
      }
    }
  }
}
</script>

<style lang="scss" scoped>
.apply-form {
  display: flex;
  flex-direction: column;
  > .validation-observer {
    > img {
      margin-top: 20px;
    }
    > .button {
      margin: 40px 0 100px;
    }
  }
}
</style>
