<template>
  <div class="vrainers-image">
    <base-loading :waitFlg="waitFlg" />
    <h2>ブレイナーズ画像取り込み</h2>
    <div>
      <div>clinicId</div>
      <input type="text" v-model="clinicId" />
      <div>pass</div>
      <input type="password" v-model="pass" />
      <div>フォルダ選択</div>
      <input
        id="file"
        type="file"
        name="upfile[]"
        @change="selectFolder"
        webkitdirectory
      />
    </div>
    <base-button-medium-orange class="button" @click="addData"
      >登録</base-button-medium-orange
    >
  </div>
</template>

<script>
import BaseButtonMediumOrange from '@/components/parts/atoms/BaseButtonMediumOrange'
import BaseLoading from '@/components/parts/atoms/BaseLoading'
import { getAxiosObject } from '@/utils/library'
import path from 'path'
import imageCompression from 'browser-image-compression'
export default {
  name: 'Vrainers',

  components: {
    BaseButtonMediumOrange,
    BaseLoading
  },

  data() {
    return {
      uploadFile: {},
      waitFlg: false,
      timeOut: null,
      clinicId: 0,
      pass: '',
      images: []
    }
  },

  computed: {},

  watch: {},

  methods: {
    async selectFolder(e) {
      const imgFileObj = {}
      let files = e.target.files
      for (let i = 0; files.length; i++) {
        let file = files[i]
        if (file) {
          let relativePath = file.webkitRelativePath
          if (relativePath.includes('TPETIMG')) {
            let parent = path.dirname(relativePath)
            if (!imgFileObj[parent]) {
              imgFileObj[parent] = []
            }
            imgFileObj[parent].push(file)
          }
        } else {
          break
        }
      }
      //同じフォルダの名前で並べた時に最後の画像を取り出す
      const imgFiles = []
      Object.entries(imgFileObj).forEach(([key, value]) => {
        imgFileObj[key].sort((a, b) => {
          return a.name - b.name
        })
        imgFiles.push(imgFileObj[key][imgFileObj[key].length - 1])
      })
      const petIds = imgFiles.map(file => {
        const imagePath = file.webkitRelativePath.split('/')
        const petId = imagePath[imagePath.findIndex(i => i === 'TPETIMG') - 1]
        return parseInt(petId)
      })
      const images = []
      //array.map 等で書くと Promiseが返ってきて、いつ解決されるか不明なため for にした
      for (let i = 0; i < imgFiles.length; i++) {
        let file = imgFiles[i]
        let resize = await this.resizeImage(file)
        let image = await this.encodeBase64(resize)
        let imagePath = file.webkitRelativePath.split('/')
        let petId = parseInt(
          imagePath[imagePath.findIndex(i => i === 'TPETIMG') - 1]
        )
        images.push({ petId, image })
      }
      this.images = images
      alert(images[images.length - 1].image)
    },
    async addData() {
      const images = this.images
      const axiosObject = getAxiosObject()
      const res = await axiosObject.post('/vrainers-image', {
        clinicId: this.clinicId,
        pass: this.pass,
        images
      })
    },
    async resizeImage(file) {
      return await imageCompression(file, {
        maxSizeMB: 0.5,
        maxWidthOrHeight: this.maxWidthOrHeight
      })
    },
    encodeBase64(file) {
      return new Promise(resolve => {
        const reader = new FileReader()
        reader.readAsDataURL(file)
        reader.onload = () => resolve(reader.result)
      })
    },
    downloadFunc(csvStr, fileName) {
      const bom = new Uint8Array([0xef, 0xbb, 0xbf])
      const blob = new Blob([bom, csvStr], { type: 'text/csv' })
      if (window.navigator.msSaveBlob) {
        window.navigator.msSaveBlob(blob, fileName)
      } else {
        const url = window.URL.createObjectURL(blob)
        const anchor = document.createElement('a')
        anchor.href = url
        anchor.download = fileName
        anchor.click()
      }
    }
  }
}
</script>

<style lang="scss" scoped>
.apply-form {
  display: flex;
  flex-direction: column;
  > .validation-observer {
    > img {
      margin-top: 20px;
    }
    > .button {
      margin: 40px 0 100px;
    }
  }
}
</style>
