<template>
  <div class="maintenance-mode">
    <div class="main-header">
      <img
        src="@/assets/images/header_logo.png"
        class="header-logo-img"
        width="199px"
        height="34px"
        alt="header-logo"
      />
    </div>
    <div class="announcement-text">
      メンテナンス中です。<br />暫くしてから再度お試し下さい。
    </div>
    <base-button-medium-orange @click="$router.push({ path: '/login' })"
      >ログイントップへ</base-button-medium-orange
    >
  </div>
</template>

<script>
import BaseButtonMediumOrange from '@/components/parts/atoms/BaseButtonMediumOrange'
export default {
  name: 'MaintenanceMode',
  components: {
    BaseButtonMediumOrange
  }
}
</script>

<style lang="scss" scoped>
.maintenance-mode {
  padding-bottom: 30px;
  > .main-header {
    width: 100%;
    min-width: 768px;
    height: 64px;
    box-sizing: border-box;
    border-bottom: solid 2px #{$pumpkin};
    > .header-logo-img {
      float: left;
      margin-top: 15px;
      margin-left: 15px;
    }
  }
  > .announcement-text {
    margin: 55px 0;
    font-size: 18px;
    font-weight: bold;
  }
}
</style>
