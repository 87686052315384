export const mutationUpdateAfter = (state, data, dataName) => {
  if (Array.isArray(state[dataName])) {
    data.forEach(datum => {
      const id = datum.id
      const updatedAt = datum.updatedAt
      const index = state[dataName].findIndex(v => v.id === id)
      if (index !== -1) {
        if (state[dataName][index].updatedAt <= updatedAt) {
          state[dataName].splice(index, 1, datum)
        }
      } else {
        state[dataName].push(datum)
      }
    })
  } else {
    if (state[dataName].updatedAt <= data.updatedAt) {
      state[dataName] = data
    }
  }
}
