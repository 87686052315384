<template>
  <div class="medical-content-template-popup-input-form">
    <text-input-form
      :value="templateName"
      :maxlength="15"
      :requiredFlg="true"
      :styles="{ width: '350px' }"
      @input="inputTemplateName"
      >テンプレート名</text-input-form
    >
    <div class="content">
      <div class="text-editor-set">
        <medical-content-template-popup-input-form-tool-bar
          :editor="editor"
          :content="value"
          class="tool-bar"
          @font-family="changeFontFamily"
          @font-size="changeFontSize"
          @undo="undo"
          @redo="redo"
          @bold="bold"
          @italic="italic"
          @strike="strike"
          @underline="underline"
          @font-color="changeFontColor"
          @background-color="changeBackgroundColor"
        />
        <div class="rich-text-editor">
          <editor-content
            class="editor"
            :editor="editor"
            :style="editorContentStyles"
          />
        </div>
        <div class="error">{{ errorMsg }}</div>
      </div>
    </div>
  </div>
</template>

<script>
import TextInputForm from '@/components/parts/molecules/TextInputForm'
import MedicalContentTemplatePopupInputFormToolBar from '@/components/parts/organisms/MedicalContentTemplatePopupInputFormToolBar'
import { Editor, EditorContent } from '@tiptap/vue-2'
import TextStyle from '@tiptap/extension-text-style'
import FontFamily from '@tiptap/extension-font-family'
import Underline from '@tiptap/extension-underline'
import { Color } from '@tiptap/extension-color'
import {
  configureStarterKit,
  FontSize,
  BackgroundColor
} from '@/utils/rich_text_editor_custom_options'
export default {
  name: 'MedicalContentTemplatePopupInputForm',

  components: {
    TextInputForm,
    MedicalContentTemplatePopupInputFormToolBar,
    EditorContent
  },

  props: {
    templateName: { type: String, default: '' },
    value: { type: String, default: '' }
  },

  data() {
    return {
      editor: null,
      editorContentStyles: {
        '--min-height': '280px',
        '--border': '1px solid #cecece'
      }
    }
  },

  computed: {
    errorMsg() {
      const value = this.value
      if (/[\uD800-\uDBFF][\uDC00-\uDFFF]/.test(value)) {
        this.$emit('invalid-content', true)
        return '使用できない文字が含まれています'
      } else {
        this.$emit('invalid-content', false)
        return ''
      }
    }
  },

  mounted() {
    this.setEditor()
  },

  methods: {
    setEditor() {
      this.editor = new Editor({
        extensions: [
          configureStarterKit(),
          TextStyle,
          Color,
          FontSize,
          BackgroundColor,
          FontFamily,
          Underline
        ],
        content: this.value,
        onUpdate: () => {
          this.$emit('input', this.editor.getHTML())
        }
      })
    },
    inputTemplateName(value) {
      this.$emit('input-name', value)
    },
    changeFontFamily(fontFamily) {
      this.editor
        .chain()
        .focus()
        .setFontFamily(fontFamily)
        .run()
    },
    changeFontSize(size) {
      this.editor
        .chain()
        .focus()
        .setFontSize(size)
        .run()
    },
    undo() {
      this.editor
        .chain()
        .focus()
        .undo()
        .run()
    },
    redo() {
      this.editor
        .chain()
        .focus()
        .redo()
        .run()
    },
    bold() {
      this.editor
        .chain()
        .focus()
        .toggleBold()
        .run()
    },
    italic() {
      this.editor
        .chain()
        .focus()
        .toggleItalic()
        .run()
    },
    strike() {
      this.editor
        .chain()
        .focus()
        .toggleStrike()
        .run()
    },
    underline() {
      this.editor
        .chain()
        .focus()
        .toggleUnderline()
        .run()
    },
    changeFontColor(color) {
      this.editor
        .chain()
        .focus()
        .setColor(color)
        .run()
    },
    changeBackgroundColor(color) {
      this.editor
        .chain()
        .focus()
        .setBackgroundColor(color)
        .run()
    }
  }
}
</script>

<style lang="scss" scoped>
.medical-content-template-popup-input-form {
  > .content {
    display: flex;
    margin-top: 20px;
    > .text-editor-set {
      > .tool-bar {
        margin-bottom: 10px;
      }
      > .rich-text-editor {
        width: 583px;
        height: 280px;
        overflow-y: auto;
        > .editor {
          transform-origin: 0 0;
          height: 100%;
          ::v-deep .ProseMirror {
            width: 100%;
            min-height: var(--min-height);
            border: var(--border);
            background-color: #{$white};
            padding: 5px;
            box-sizing: border-box;
            > p {
              margin: 0;
            }
            font-display: swap;
          }
        }
      }
      > .error {
        height: 20px;
        color: #{$tomato};
        font-size: 12px;
        text-align: left;
      }
    }
  }
}
</style>
