<template>
  <div class="password-reset">
    <main-header :logined-flg="false" />
    <validation-observer
      v-if="!clinicVersionUpNotReloadFlg"
      v-slot="{ invalid }"
      class="observer"
    >
      <div class="page-title">
        パスワードの再設定
      </div>
      <div class="password-reset-progress-bar">
        <div class="progress-bar-list-text orange">
          メールアドレス入力
        </div>
        <div class="arrow orange"></div>
        <div class="progress-bar-list-text orange">
          本人確認
        </div>
        <div class="arrow orange"></div>
        <div class="progress-bar-list-text orange">
          パスワード設定
        </div>
        <div class="arrow"></div>
        <div class="progress-bar-list-text">
          完了
        </div>
      </div>
      <div class="password-reset-text">
        新しいパスワードを設定してください。
      </div>
      <div class="password-reset-input">
        <div class="password-reset-input-label">
          パスワード<span class="red"> ※必須</span>
        </div>
        <validation-provider
          :rules="{
            requiredRule: true,
            regexHalfWidthAlphaNumeric: validPasswordRegex,
            minPassRule: 8
          }"
          v-slot="{ errors }"
          class="provider"
        >
          <base-text-input-password
            v-bind="passwordBox"
            :newFlg="true"
            v-model="password"
          />
          <div class="error">
            {{ errors[0] }}
          </div>
        </validation-provider>
      </div>
      <div class="password-reset-input re-enter">
        <div class="password-reset-input-label re-enter">
          パスワード（再入力）<span class="red"> ※必須</span>
        </div>
        <validation-provider
          :rules="{
            requiredRule: true,
            regexHalfWidthAlphaNumeric: validPasswordRegex,
            minPassRule: 8,
            isRePassRule: password
          }"
          v-slot="{ errors }"
          class="provider"
        >
          <base-text-input-password
            v-bind="passwordBox"
            :newFlg="true"
            v-model="reEnterPassword"
          />
          <div class="error">
            {{ errors[0] }}
          </div>
        </validation-provider>
      </div>
      <div class="submit-button">
        <base-button-large-orange
          @click="handleUpdatePassword"
          :disabled="invalid || waitFlg"
          >次へ</base-button-large-orange
        >
      </div>
    </validation-observer>
    <div v-else class="version-uploading-error">
      現在PETORELU+をアップデートしております。暫くお待ちになってから再度お試し下さい。
    </div>
    <announce-popup
      v-if="alertFlg"
      @close="closeAlert"
      :title="title"
      :buttons="buttons"
      :type="type"
      >{{ popupMessage }}</announce-popup
    >
  </div>
</template>

<script>
import { ValidationObserver, ValidationProvider } from 'vee-validate'
import MainHeader from '@/components/parts/organisms/MainHeader'
import BaseTextInputPassword from '@/components/parts/atoms/BaseTextInputPassword'
import BaseButtonLargeOrange from '@/components/parts/atoms/BaseButtonLargeOrange'
import AnnouncePopup from '@/components/popups/AnnouncePopup'
import ApiPasswordReset from '@/components/mixins/ApiPasswordReset'
import { VALID_PASSWORD_REGEX } from '@/utils/define'

export default {
  name: 'PasswordReset',

  components: {
    MainHeader,
    BaseTextInputPassword,
    BaseButtonLargeOrange,
    AnnouncePopup,
    ValidationObserver,
    ValidationProvider
  },

  mixins: [ApiPasswordReset],

  data() {
    return {
      password: '',
      reEnterPassword: '',
      clinicVersionUpNotReloadFlg: false,
      passwordBox: {
        placeholder: '半角英数字記号8～100文字',
        styles: {
          width: '410px',
          height: '45px'
        }
      },
      validPasswordRegex: VALID_PASSWORD_REGEX,
      alertFlg: false,
      title: '失敗',
      buttons: ['閉じる'],
      type: 'failure',
      popupMessage: '',
      waitFlg: false
    }
  },

  created() {
    this.authenticate()
  },

  methods: {
    async authenticate() {
      const result = await this.mixinAuthenticateToken(this.$route.query.token)
      if (result !== true) {
        if (result === 'not the same as required version') {
          this.clinicVersionUpNotReloadFlg = true
        } else {
          this.$router.push({ name: 'login' })
        }
      }
    },
    async handleUpdatePassword() {
      this.waitFlg = true
      const result = await this.mixinUpdatePassword(
        this.$route.query.token,
        this.reEnterPassword
      )
      if (result === true) {
        this.$router.push({ name: 'password-reset-registered' })
      } else if (result === 'email and password already used') {
        this.popupMessage = `同じメールアドレスとパスワードの組み合わせのスタッフが既に存在します。\n別のパスワードでお願いします。`
        this.title = '失敗'
        this.type = 'failure'
        this.alertFlg = true
      } else {
        this.popupMessage = 'パスワードの登録に失敗しました'
        this.title = '失敗'
        this.type = 'failure'
        this.alertFlg = true
      }
      this.waitFlg = false
    },
    closeAlert() {
      this.alertFlg = false
    }
  }
}
</script>

<style lang="scss" scoped>
.password-reset {
  > .observer {
    > .page-title {
      margin-top: 67px;
      font-size: 30px;
      font-weight: bold;
    }
    > .password-reset-progress-bar {
      margin: 27px auto 0;
      width: 630px;
      height: 45px;
      border: solid 2px #{$pumpkin};
      border-radius: 50px;
      box-sizing: border-box;
      display: flex;
      justify-content: space-around;
      align-items: center;
      > .arrow {
        width: 8px;
        height: 8px;
        border-top: 2px solid #{$light-grey};
        border-right: 2px solid #{$light-grey};
        transform: rotate(45deg);
        &.orange {
          border-top: 2px solid #{$pumpkin};
          border-right: 2px solid #{$pumpkin};
        }
      }
      > .progress-bar-list-text {
        font-size: 15px;
        font-weight: bold;
        color: #{$light-grey};
        &.orange {
          color: #{$pumpkin};
        }
      }
    }
    > .password-reset-text {
      margin: 39px auto 0;
      font-weight: bold;
    }
    > .password-reset-input {
      margin: 42px auto 0;
      width: 755px;
      display: flex;
      &.re-enter {
        margin: 24px auto 0;
      }
      > .password-reset-input-label {
        line-height: 45px;
        font-weight: bold;
        margin-right: 133px;
        margin-left: 30px;
        &.re-enter {
          margin-right: 43px;
        }
        > .red {
          color: #{$tomato};
        }
      }
      @include validate-message();
      > .provider {
        > .error {
          text-align: left;
        }
      }
    }
    > .submit-button {
      margin-top: 79px;
    }
  }
  > .version-uploading-error {
    margin: 30px 10px 0;
    font-size: 20px;
    font-weight: bold;
  }
}
</style>
