var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('div',{staticClass:"full-name-input-form"},[_c('div',{staticClass:"heading",style:({ height: _vm.headingHeight })},[_c('div',{staticClass:"text"},[_vm._t("default")],2),(_vm.requiredFlg)?_c('div',{staticClass:"required"},[_vm._v("必須")]):_vm._e()]),_c('div',{staticClass:"body"},[_c('div',{staticClass:"name last"},[_c('validation-provider',{staticClass:"validate",attrs:{"rules":Object.assign({}, {requiredRule: _vm.requiredFlg,
          regexKana: _vm.kanaFlg,
          nameRule: true},
          _vm.validationRules)},scopedSlots:_vm._u([{key:"default",fn:function(ref){
        var errors = ref.errors;
return [_c('div',{staticClass:"label-field"},[_c('label',[_vm._v(_vm._s(_vm.kanaFlg ? 'セイ' : '姓'))]),_c('base-text-box',{attrs:{"data-test":"last-name","styles":_vm.styles,"maxlength":15},model:{value:(_vm.InputLastName),callback:function ($$v) {_vm.InputLastName=$$v},expression:"InputLastName"}})],1),(_vm.errorShowFlg)?_c('div',{staticClass:"error",attrs:{"data-test":"last-name-err"}},_vm._l((errors),function(error,index){return _c('div',{key:index},[_vm._v(_vm._s(error))])}),0):_vm._e()]}}])})],1),_c('div',{staticClass:"name first"},[_c('validation-provider',{staticClass:"validate",attrs:{"rules":Object.assign({}, {requiredRule: _vm.requiredFlg,
          regexKana: _vm.kanaFlg,
          nameRule: true},
          _vm.validationRules)},scopedSlots:_vm._u([{key:"default",fn:function(ref){
        var errors = ref.errors;
return [_c('div',{staticClass:"label-field"},[_c('label',[_vm._v(_vm._s(_vm.kanaFlg ? 'メイ' : '名'))]),_c('base-text-box',{attrs:{"data-test":"first-name","styles":_vm.styles,"maxlength":15},model:{value:(_vm.InputFirstName),callback:function ($$v) {_vm.InputFirstName=$$v},expression:"InputFirstName"}})],1),(_vm.errorShowFlg)?_c('div',{staticClass:"error",attrs:{"data-test":"first-name-err"}},_vm._l((errors),function(error,index){return _c('div',{key:index},[_vm._v(_vm._s(error))])}),0):_vm._e()]}}])})],1)])])}
var staticRenderFns = []

export { render, staticRenderFns }