<template>
  <div class="arrow-box">
    <div>
      <p @click="pushToStaffEdit">ユーザー情報の変更</p>
      <p @click="pushToPasswordResetSendMail">ユーザーパスワードの変更</p>
      <p @click="logout">ログアウト</p>
    </div>
  </div>
</template>

<script>
export default {
  name: 'ArrowBox',
  props: {
    id: { type: Number, default: 0 }
  },
  methods: {
    pushToStaffEdit() {
      this.$router.push({
        path: `/settings/staffs/${this.id}/edit`
      })
    },
    pushToPasswordResetSendMail() {
      this.$router.push({ name: 'password-reset-send-mail' })
    },
    logout() {
      this.$router.push({ name: 'login' })
    }
  }
}
</script>

<style lang="scss" scoped>
.arrow-box {
  width: 170px;
  height: 110px;
  background: #{$white};
  border: 1px solid #{$light-grey};
  box-shadow: 0px 0px 1px;
  border-radius: 5px;
  z-index: 1100;
  position: relative;
  > div {
    position: absolute;
    width: 100%;
    height: 120px;
    > p {
      font-size: 13px;
      color: #{$pumpkin};
      &:hover {
        opacity: 0.8;
        cursor: pointer;
      }
    }
  }
}
.arrow-box:after,
.arrow-box:before {
  bottom: 100%;
  left: 50%;
  border: solid transparent;
  content: ' ';
  height: 0;
  width: 0;
  position: absolute;
  pointer-events: none;
}

.arrow-box:after {
  border-color: rgba(255, 255, 255, 0);
  border-bottom-color: #{$white};
  border-width: 15px;
  margin-left: -15px;
}
.arrow-box:before {
  border-color: rgba(206, 206, 206, 0);
  border-bottom-color: #{$light-grey};
  border-width: 16px;
  margin-left: -16px;
}
</style>
