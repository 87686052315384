<template>
  <focus-trap :escapeDeactivates="false" :clickDeactivates="false">
    <validation-observer class="validation-observer" v-slot="{ invalid }">
      <div
        class="medical-content-template-edit-popup"
        @keyup.esc.stop="checkDiff"
        tabindex="-1"
      >
        <popup
          :invalid="invalid || waitFlg || invalidContent"
          :buttons="['閉じる', '登録']"
          :layerNumber="2"
          @cancel="checkDiff"
          @decision="update"
          @click-close-mark="checkDiff"
        >
          <template v-slot:content>
            <div class="content">
              <div class="header">
                <div class="title">テンプレート編集</div>
                <base-button-small-red
                  class="delete-button"
                  v-if="lookOnlyFlg === 0"
                  @click="openDeletePopup"
                  >削除</base-button-small-red
                >
              </div>
              <div class="input-form-wrapper">
                <medical-content-template-popup-input-form
                  :templateName="editMedicalContentTemplate.name"
                  v-model="editMedicalContentTemplate.content"
                  @invalid-content="val => (invalidContent = val)"
                  @input-name="val => (editMedicalContentTemplate.name = val)"
                />
              </div>
            </div>
          </template>
        </popup>
        <announce-popup
          v-if="popup.popupFlg"
          v-bind="popup"
          :disabled="waitFlg"
          @cancel="resetPopup"
          @decision="popup.decide"
          @close="closePopup"
          >{{ popup.content }}</announce-popup
        >
      </div>
    </validation-observer>
  </focus-trap>
</template>

<script>
import Popup from '@/components/popups/Popup'
import BaseButtonSmallRed from '@/components/parts/atoms/BaseButtonSmallRed'
import MedicalContentTemplatePopupInputForm from '@/components/parts/organisms/MedicalContentTemplatePopupInputForm'
import AnnouncePopup from '@/components/popups/AnnouncePopup'
import CheckPopupInputDifference from '@/components/mixins/CheckPopupInputDifference'
import { ValidationObserver } from 'vee-validate'
import _ from 'lodash'
import { FocusTrap } from 'focus-trap-vue'
import { mapGetters } from 'vuex'

export default {
  name: 'MedicalContentTemplateEditPopup',

  components: {
    FocusTrap,
    Popup,
    BaseButtonSmallRed,
    MedicalContentTemplatePopupInputForm,
    ValidationObserver,
    AnnouncePopup
  },

  mixins: [CheckPopupInputDifference],

  props: {
    id: { type: Number, default: 0 }
  },

  data() {
    return {
      initialData: { name: '', content: '<p></p>' },
      editMedicalContentTemplate: { name: '', content: '<p></p>' },
      waitFlg: false,
      popup: {
        popupFlg: false,
        type: '',
        title: '',
        buttons: [],
        layerNumber: 3,
        content: '',
        decide: () => {}
      },
      richTextEditorFlg: true,
      invalidContent: false
    }
  },

  computed: {
    ...mapGetters({
      getMedicalContentTemplate: 'medicalContentTemplates/getDataById',
      lookOnlyFlg: 'auth/lookOnlyFlg'
    }),
    diffFlg() {
      return !_.isEqual(this.initialData, this.editMedicalContentTemplate)
    }
  },

  created() {
    const { order, ...medicalContentTemplate } = this.getMedicalContentTemplate(
      this.id
    )
    this.editMedicalContentTemplate = _.cloneDeep(medicalContentTemplate)
    this.initialData = _.cloneDeep(this.editMedicalContentTemplate)
  },

  methods: {
    checkDiff() {
      if (this.diffFlg) {
        this.popup = {
          popupFlg: true,
          type: 'alert',
          title: '確認',
          buttons: ['キャンセル', '閉じる'],
          layerNumber: 3,
          content:
            '入力内容を保存せずに閉じようとしています。\nよろしいですか？',
          decide: () => this.closePopup()
        }
      } else {
        this.closePopup()
      }
    },
    resetPopup() {
      this.popup = {
        popupFlg: false,
        type: '',
        title: '',
        buttons: [],
        layerNumber: 3,
        content: '',
        decide: () => {}
      }
    },
    closePopup() {
      this.popup.type !== 'failure' ||
      this.popup.content ===
        '編集に失敗しました。\n編集中の診療内容テンプレートは既に削除されています。'
        ? this.$emit('close')
        : this.resetPopup()
    },
    async update() {
      this.waitFlg = true
      const res = await this.$store.dispatch(
        'medicalContentTemplates/update',
        this.editMedicalContentTemplate
      )
      if (res === true) {
        this.initialData = _.cloneDeep(this.editMedicalContentTemplate)
        this.popup.type = 'success'
        this.popup.title = '完了'
        this.popup.content = '編集しました'
      } else if (res === 'no data in clinic' || res === 'no data') {
        this.popup.type = 'failure'
        this.popup.title = '失敗'
        this.popup.content =
          '編集に失敗しました。\n編集中の診療内容テンプレートは既に削除されています。'
      } else {
        this.popup.type = 'failure'
        this.popup.title = '失敗'
        this.popup.content = '編集に失敗しました'
      }
      this.popup.buttons = ['閉じる']
      this.popup.popupFlg = true
      this.waitFlg = false
    },
    openDeletePopup() {
      this.popup = {
        popupFlg: true,
        type: 'alert',
        title: '注意',
        buttons: ['削除しない', '削除する'],
        content: '削除してもよろしいですか？',
        layerNumber: 3,
        decide: () => this.decideDelete()
      }
    },
    async decideDelete() {
      this.waitFlg = true
      const res = await this.$store.dispatch(
        'medicalContentTemplates/delete',
        this.id
      )
      if (res === true) {
        this.initialData = _.cloneDeep(this.editMedicalContentTemplate)
        this.popup.type = 'success'
        this.popup.title = '完了'
        this.popup.content = '削除しました'
      } else {
        this.popup.type = 'failure'
        this.popup.title = '失敗'
        this.popup.content = '削除に失敗しました'
      }
      this.popup.buttons = ['閉じる']
      this.popup.popupFlg = true
      this.waitFlg = false
    }
  }
}
</script>

<style lang="scss" scoped>
.medical-content-template-edit-popup {
  .content {
    text-align: left;
    > .header {
      display: flex;
      > .title {
        font-size: 16px;
        font-weight: bold;
        color: #{$greyish-brown};
      }
      > .delete-button {
        margin: 0 0 0 auto;
      }
    }
    > .input-form-wrapper {
      margin-top: 15px;
    }
  }
}
</style>
