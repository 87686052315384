<template>
  <div class="base-multiple-lines-text-box">
    <textarea
      class="multiple-lines-text-box"
      :value="value"
      :placeholder="placeholder"
      :style="styles"
      :rows="rows"
      :wrap="wrap"
      :maxlength="maxlength"
      @input="inputText"
      @blur="$emit('blur')"
      data-test="text-area"
      data-e2e="base-multiple-lines-text-box"
    />
  </div>
</template>

<script>
export default {
  name: 'BaseMultipleLinesTextBox',

  props: {
    value: { type: String },
    placeholder: { type: String, default: '' },
    styles: {
      type: Object,
      validator: function(obj) {
        return Object.values(obj).every(v => typeof v === 'string')
      }
    },
    wrap: { type: String, default: 'soft' },
    autoLineMode: { type: Boolean, default: false },
    maxlength: { type: Number }
  },

  computed: {
    rows() {
      if (this.autoLineMode && this.value) {
        return String(this.value).split('\n').length
      }
      return 2
    }
  },

  methods: {
    inputText(e) {
      this.$emit('input', e.target.value)
    }
  }
}
</script>

<style lang="scss" scoped>
.base-multiple-lines-text-box {
  > .multiple-lines-text-box {
    display: block;
    width: 364px;
    height: 182px;
    font-size: 15px;
    border-radius: 4px;
    border: solid 2px #{$light-grey};
    background-color: #{$white};
    padding: 5px 8px;
    line-height: 1.5;
    box-sizing: border-box;
    resize: none;
  }
}
</style>
