import { getAxiosObject } from '@/utils/library'
import {
  commonGetters,
  commonMutations,
  commonActions
} from '@/store/store_utils'

const dataName = 'treatmentSets'

const state = {
  treatmentSets: [],
  dataIndexedById: {},
  updatedAt: '20101010101010'
}

const getters = {
  ...commonGetters(dataName, ['getData', 'getDataById', 'getUpdatedAt'], {
    filterDel: true
  })
}

const actions = {
  ...commonActions(['setData', 'setUpdatedAt', 'updateAfter']),
  async create({ commit }, { treatmentSet, treatmentSetItems }) {
    try {
      const axiosObject = getAxiosObject()
      const res = await axiosObject.post('/treatment-sets', {
        treatmentSet,
        treatmentSetItems
      })
      if (res.status === 200) {
        commit('updateAfter', [res.data.treatmentSet])
        commit('treatmentSetItems/updateAfter', res.data.treatmentSetItems, {
          root: true
        })
        return true
      }
    } catch (error) {
      if (error.response?.data?.message === 'no data') {
        error.response.data.extra.dataName === 'treatment'
          ? commit('treatments/setData', error.response.data.extra.data, {
              root: true
            })
          : commit('medicines/setData', error.response.data.extra.data, {
              root: true
            })
        return `no ${error.response.data.extra.dataName}`
      } else if (error.response?.data?.message) {
        return error.response.data.message
      } else {
        return 'server error'
      }
    }
  },
  async delete({ commit }, id) {
    try {
      const axiosObject = getAxiosObject()
      const res = await axiosObject.delete('/treatment-sets', { data: { id } })
      if (res.status === 200) {
        commit('updateAfter', [res.data.treatmentSet])
        commit('treatmentSetItems/updateAfter', res.data.treatmentSetItems, {
          root: true
        })
        return true
      }
    } catch (error) {
      if (error.response?.data?.extra?.data) {
        commit('setData', error.response.data.extra.data)
        return error.response.data.message
      } else {
        return 'server error'
      }
    }
  },
  async update(
    { commit },
    { treatmentSet, treatmentSetItems, deleteTreatmentSetItems }
  ) {
    try {
      const axiosObject = getAxiosObject()
      const res = await axiosObject.put('/treatment-sets', {
        treatmentSet,
        treatmentSetItems,
        deleteTreatmentSetItems
      })
      if (res.status === 200) {
        commit('updateAfter', [res.data.treatmentSet])
        commit('treatmentSetItems/updateAfter', res.data.treatmentSetItems, {
          root: true
        })
        return true
      }
    } catch (error) {
      if (error.response?.data?.message === 'no data in clinic') {
        commit('setData', error.response.data.extra.data)
        return `no ${error.response.data.extra.dataName}`
      } else if (error.response?.data?.message === 'no data') {
        if (error.response.data.extra.dataName === 'treatment') {
          commit('treatments/setData', error.response.data.extra.data, {
            root: true
          })
          return `no ${error.response.data.extra.dataName}`
        } else if (error.response.data.extra.dataName === 'medicine') {
          commit('medicines/setData', error.response.data.extra.data, {
            root: true
          })
          return `no ${error.response.data.extra.dataName}`
        } else {
          return `no ${error.response.data.extra}`
        }
      } else if (error.response?.data?.message) {
        return error.response.data.message
      } else {
        return 'server error'
      }
    }
  }
}

const mutations = {
  ...commonMutations(dataName, ['setData', 'setUpdatedAt', 'updateAfter'], {
    order: true,
    indexedById: true
  })
}

export default {
  namespaced: true,
  state,
  getters,
  actions,
  mutations
}
